import React, { useEffect, useMemo, useState } from 'react';
import {
  Route as ReactRoute,
  Redirect,
  RouteProps,
  useHistory,
} from 'react-router-dom';

import { useAuth } from '@/contexts/auth-context';
import { UserProvider } from '@/contexts/user-context';
import TooltipWrrapper from '../tooltip/tooltip-wrapper';
import AgbChangedModal from '../dialogs/agb-changed';
import {
  DailyTipperStatusType,
  useDailyTipperDataQuery,
  useDtWeeklyProgressQuery,
  useGlobalDialogQuery,
  useUserNotificationsQuery,
} from '@/graphql';
import { NotifTypes } from '@/type';
import StripeWrapper from '../../stripe-wrapper';
import PremiumExpiredModal from '../dialogs/premium-expired';
import SubscribeNewslettersModal from '../dialogs/subscribe-newsletters';

const Route: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { isAuthorized } = useAuth();
  const history = useHistory();
  const { data: dataGlobalDialog } = useGlobalDialogQuery();

  const { data } = useUserNotificationsQuery();
  const { data: dtData, loading: loadingDtData } = useDailyTipperDataQuery();
  const { data: dtWeek, loading: loadingDtWeek } = useDtWeeklyProgressQuery();

  const [agbChangedNotificationId, setAgbChangedNotificationId] =
    useState<string | null | undefined>(null);
  const [premiumExpiredNotificationId, setPremiumExpiredNotificationId] =
    useState<string | null | undefined>(null);
  const [
    subscribeNewslettersNotificationId,
    setSubscribeNewslettersNotificationId,
  ] = useState<string | null | undefined>(null);

  useEffect(() => {
    data?.notifications?.forEach((it) => {
      if (JSON.parse(it?.data)?.type === NotifTypes.AGB && !it?.isRead) {
        setAgbChangedNotificationId(it?.id);
      }
      if (JSON.parse(it?.data)?.type === NotifTypes.PREMIUM && !it?.isRead) {
        setPremiumExpiredNotificationId(it?.id);
      }
      if (
        JSON.parse(it?.data)?.type === NotifTypes.SUBSCRIBE_NEWSLETTERS &&
        !it?.isRead
      ) {
        setSubscribeNewslettersNotificationId(it?.id);
      }
    });
  }, [data]);

  const dtWeekStatus = dtWeek?.me?.dailytipper?.dailyStatus;
  const isDtOpen = useMemo(
    () =>
      dtData?.dailyTipperData &&
      (dtWeekStatus === DailyTipperStatusType.Pending ||
        dtWeekStatus === DailyTipperStatusType.InProgress)
        ? true
        : false,
    [dtWeekStatus, dtData]
  );

  useEffect(() => {
    history.listen((newLocation, action) => {
      if (action === 'POP') {
        // disable 'go history back' on the Duel, LEK Test pages
        if (
          history.location.pathname?.search('duel/') !== -1 &&
          history.location.pathname?.search('/create-round') === -1 &&
          history.location.pathname?.search('/overview') === -1
        )
          history.go(1);
        if (
          newLocation.pathname?.includes('sign-in') ||
          newLocation.pathname?.includes('sign-up')
        )
          history.replace('/home');
      }
    });
  }, [history]);

  const dialogsCanBeVisible =
    !loadingDtData &&
    !loadingDtWeek &&
    !isDtOpen &&
    !dataGlobalDialog?.dialogId;

  return (
    <ReactRoute
      {...rest}
      render={({ location }) =>
        isAuthorized ? (
          <StripeWrapper>
            <UserProvider>
              <TooltipWrrapper>
                {children}
                {dialogsCanBeVisible &&
                  agbChangedNotificationId &&
                  !premiumExpiredNotificationId && (
                    <AgbChangedModal
                      agbChangedNotificationId={agbChangedNotificationId}
                    />
                  )}
                {dialogsCanBeVisible && premiumExpiredNotificationId && (
                  <PremiumExpiredModal
                    premiumExpiredNotificationId={premiumExpiredNotificationId}
                  />
                )}
                {dialogsCanBeVisible &&
                  !agbChangedNotificationId &&
                  !premiumExpiredNotificationId &&
                  subscribeNewslettersNotificationId && (
                    <SubscribeNewslettersModal
                      notificationId={subscribeNewslettersNotificationId}
                    />
                  )}
              </TooltipWrrapper>
            </UserProvider>
          </StripeWrapper>
        ) : (
          <Redirect
            to={{
              pathname: '/auth',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default Route;
