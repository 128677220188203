import React, { useCallback, useEffect, useRef } from 'react';
import {
  useGlobalDialogQuery,
  useUpdateCookieAcceptedSettingsMutation,
} from '@/graphql';
import { useUser } from '@/contexts/user-context';
import { useApolloClient } from '@apollo/client';
import { USER_SETTINGS_FRAGMENT } from '@/apollo/fragments';
import { useGlobalDialog } from '@/hooks/useGlobalDialog';
import { GlobalDialogTypes } from '@/type';
import {
  USERCENTRICS_SCRIPT_ID,
  USERCENTRICS_SCRIPT_SRC,
  USERCENTRICS_SETTINGS_ID,
} from '@/utils/constants';

declare const window: Window &
  typeof globalThis & {
    UC_UI: any;
  };

const DialogCookieAccepted = () => {
  const client = useApolloClient();
  const { user } = useUser();
  const isOpenRef = useRef(false);
  const isMarketingCheckedRef = useRef(true);

  const [update, { loading: cookieAcceptedLoading }] =
    useUpdateCookieAcceptedSettingsMutation();
  const { setGlobalDialog } = useGlobalDialog();
  const { data: dataGlobalDialog } = useGlobalDialogQuery();

  const handleConfirm = useCallback(async () => {
    client.writeFragment({
      id: `${user?.settings?.__typename}:${user?.settings?.id}`,
      fragment: USER_SETTINGS_FRAGMENT,
      data: {
        ...user?.settings,
        isCookieAccepted: true,
        isMarketingCookieAccepted: isMarketingCheckedRef.current,
      },
    });

    try {
      await update({
        variables: {
          isCookieAccepted: true,
          isMarketingCookieAccepted: isMarketingCheckedRef.current,
        },
      });
      isOpenRef.current = false;
    } catch (err) {
      console.log(err);
    }
  }, [update, client, user, isMarketingCheckedRef]);

  useEffect(() => {
    if (!dataGlobalDialog?.dialogId) setGlobalDialog(GlobalDialogTypes.COOKIE);
    document.body.classList.remove('usercentrics-is-hidden');

    const listenerUsercentricInit = (event: any) => {
      window?.UC_UI?.showSecondLayer();
    };
    const listenerUsercentricCmpEvent = (event: any) => {
      if (event?.detail?.type === 'DENY_ALL') {
        isMarketingCheckedRef.current = false;
      }
      if (
        event?.detail?.type === 'ACCEPT_ALL' ||
        event?.detail?.type === 'DENY_ALL' ||
        event?.detail?.type === 'SAVE'
      )
        handleConfirm();
    };
    const listenerUsercentricViewChanged = (event: any) => {
      if (
        isOpenRef.current &&
        event?.detail?.view === 'PRIVACY_BUTTON' &&
        (event?.detail?.previousView === 'FIRST_LAYER' ||
          event?.detail?.previousView === 'SECOND_LAYER')
      )
        handleConfirm();
    };
    const isTagAlreadyExist = document.getElementById(USERCENTRICS_SCRIPT_ID);
    if (
      user?.settings &&
      !user?.settings?.isCookieAccepted &&
      !cookieAcceptedLoading &&
      !isOpenRef.current &&
      !isTagAlreadyExist
    ) {
      const script = document.createElement('script');
      script.src = USERCENTRICS_SCRIPT_SRC;
      script.type = 'text/javascript';
      script.async = true;
      script.id = USERCENTRICS_SCRIPT_ID;
      script.dataset.settingsId = USERCENTRICS_SETTINGS_ID;
      document.body.appendChild(script);
      if (
        window?.UC_UI &&
        window?.UC_UI?.isInitialized() &&
        user?.settings &&
        !user?.settings?.isCookieAccepted
      ) {
        window?.UC_UI?.showSecondLayer();
      } else {
        window.addEventListener('UC_UI_INITIALIZED', listenerUsercentricInit);
        window?.UC_UI?.showSecondLayer();
      }

      window.addEventListener(
        'UC_UI_VIEW_CHANGED',
        listenerUsercentricViewChanged
      );
      window.addEventListener('UC_UI_CMP_EVENT', listenerUsercentricCmpEvent);
      isOpenRef.current = true;
    }

    return () => {
      if (window?.UC_UI) {
        window.removeEventListener(
          'UC_UI_INITIALIZED',
          listenerUsercentricInit
        );
        window.removeEventListener(
          'UC_UI_VIEW_CHANGED',
          listenerUsercentricViewChanged
        );
        window.removeEventListener(
          'UC_UI_CMP_EVENT',
          listenerUsercentricCmpEvent
        );
      }
      if (dataGlobalDialog?.dialogId) setGlobalDialog(null);
      document.body.classList.add('usercentrics-is-hidden');
    };
  }, [
    user,
    isOpenRef,
    handleConfirm,
    cookieAcceptedLoading,
    dataGlobalDialog,
    setGlobalDialog,
  ]);

  return <div />;
};

export default DialogCookieAccepted;
