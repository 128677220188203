import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Typography } from '@material-ui/core';
import { useQuizTimeDetailsQuery, useUserDuelQuery } from '@/graphql';
import StackLayout from '@/screens/main/components/stack-layout';
import Fallback from '@/components/fallback';
import AlarmIcon from '@/components/icons/alarm';

import { useStyles } from './styles';
import { PermIdentityOutlined } from '@material-ui/icons';

const QuizDuel: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const { duelId, sessionId } =
    useParams<{ duelId: string; sessionId: string }>();
  const classes = useStyles();
  const history = useHistory();

  const { data: quizTimeData } = useQuizTimeDetailsQuery({
    variables: { quizSessionId: sessionId },
  });
  const { data, error } = useUserDuelQuery({ variables: { duelId } });

  const userData = data?.duelData?.userScore;
  const opponentData = data?.duelData?.opponentScore;
  const isDuelOverviewPage = history.location.pathname.includes('/overview');

  const RoundDuration = useMemo(
    () =>
      !isDuelOverviewPage ? (
        <Box
          display="grid"
          gridColumnGap={4}
          gridTemplateColumns="auto auto"
          justifyContent="flex-end"
          flexGrow={1}
        >
          <AlarmIcon htmlColor="currentColor" />
          <Typography color="inherit" variant="caption">
            {t('time-relative.minutes', {
              minutes: quizTimeData?.quizTimeSession?.duelDuration,
            })}
          </Typography>
        </Box>
      ) : (
        <></>
      ),
    [t, quizTimeData, isDuelOverviewPage]
  );

  if (!!error) {
    console.log('DUEL_QUERY', error);
    return (
      <StackLayout
        title={t('common.duel.title')}
        toolbar={RoundDuration}
        hideBackArrow
      >
        <Fallback />
      </StackLayout>
    );
  }

  return (
    <StackLayout
      title={t('common.duel.title')}
      toolbar={RoundDuration}
      hideBackArrow
    >
      <div className={classes.wrapper}>
        <div className={classes.topbar}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            overflow="auto"
          >
            <Avatar
              className={classes.avatar}
              src={userData?.user?.avatar ?? undefined}
              style={{ fontSize: '1.75rem' }}
            >
              {!userData?.user?.avatar && (
                <PermIdentityOutlined fontSize="inherit" />
              )}
            </Avatar>
            <Typography
              variant="subtitle2"
              color="inherit"
              className={classes.label}
            >
              {t('common.me')}
            </Typography>
          </Box>
          <Typography variant="h2" color="inherit" className={classes.score}>
            {`${userData?.duelScore ?? 0} : ${opponentData?.duelScore ?? 0}`}
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            overflow="auto"
          >
            <Avatar
              className={classes.avatar}
              src={opponentData?.user?.avatar ?? undefined}
              style={{ fontSize: '1.75rem' }}
            >
              {!opponentData?.user?.avatar && (
                <PermIdentityOutlined fontSize="inherit" />
              )}
            </Avatar>
            <Typography
              variant="subtitle2"
              color="inherit"
              className={classes.label}
            >
              {(opponentData?.user?.username &&
                opponentData?.user?.username.replace(
                  /DELETED[0-9]{0,11}_/,
                  ''
                )) ?? <>&nbsp;</>}
            </Typography>
          </Box>
        </div>
        <div className={classes.content}>{children}</div>
      </div>
    </StackLayout>
  );
};

export default QuizDuel;
