import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';

import {
  DailyTipperStatusType,
  useDailyTipperDataQuery,
  useDtWeeklyProgressQuery,
  useGlobalDialogQuery,
} from '@/graphql';
import { useUser } from '@/contexts/user-context';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import MainLayout from '@/screens/main/components/main-layout';
import Fallback from '@/components/fallback';
import DailyTipperCard from './daily-tipper-card';
import DuelCard from './duel-card';
import DialogCookieAccepted from '../dialog-cookie-accepted';
import AddIcon from '../add-icon';
import QuiztimeHomepage from '../quiztime/quiztime-homepage';
import MyLearningHomepage from '../classrooms/my-learning-homepage';
import { GlobalDialogTypes } from '@/type';
import { LS_KEY_LAST_ONBOARD_PAGE } from '@/utils/constants';
import DiscountBanner from '@/components/discount-banner';
import { useStyles } from './styles';

const Home: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { user, error } = useUser();
  const { data: dataGlobalDialog } = useGlobalDialogQuery();
  const { data: dtData, loading: loadingDtData } = useDailyTipperDataQuery();
  const { data: dtWeek, loading: loadingDtWeek } = useDtWeeklyProgressQuery();
  const { dailyTipperEnabled, cookiesEnabled } = useFeatureFlags();

  const dtWeekStatus = dtWeek?.me?.dailytipper?.dailyStatus;
  const isDtOpen = useMemo(
    () =>
      dtData?.dailyTipperData &&
      (dtWeekStatus === DailyTipperStatusType.Pending ||
        dtWeekStatus === DailyTipperStatusType.InProgress)
        ? true
        : false,
    [dtWeekStatus, dtData]
  );

  useEffect(() => {
    if (
      loadingDtData === false &&
      loadingDtWeek === false &&
      !dataGlobalDialog?.dialogId &&
      ((cookiesEnabled && user?.settings?.isCookieAccepted) ||
        !cookiesEnabled) &&
      !history.location.search.includes('/onboard-welcome') &&
      user?.settings?.isIntroCompleted === false
    ) {
      if (
        !history.location.search.includes('isOnboardingActive') &&
        !user.classRoomLearningSession.length &&
        (DateTime.fromISO(user.dateJoined).hasSame(DateTime.now(), 'day') ||
          !isDtOpen)
      ) {
        const path = localStorage.getItem(LS_KEY_LAST_ONBOARD_PAGE);
        history.push(path ? path : '/onboard-welcome');
      }
    }
  }, [
    cookiesEnabled,
    user,
    history,
    isDtOpen,
    dataGlobalDialog,
    loadingDtWeek,
    loadingDtData,
  ]);

  if (!!error) {
    console.error('createDuel', error);
    return (
      <MainLayout title={t('home.title')}>
        <Fallback />
      </MainLayout>
    );
  }

  return (
    <MainLayout title={t('home.title')}>
      <DiscountBanner />
      <div className={classes.root}>
        <QuiztimeHomepage />
        {dailyTipperEnabled && <DailyTipperCard />}
        <DuelCard />
        <MyLearningHomepage />
      </div>
      {!window?.ReactNativeWebView &&
        ((cookiesEnabled && user?.settings?.isCookieAccepted) ||
          !cookiesEnabled) &&
        !history.location.pathname.includes('/onboard-welcome') &&
        (!dataGlobalDialog?.dialogId ||
          dataGlobalDialog?.dialogId === GlobalDialogTypes.ADD_ICON) && (
          <AddIcon />
        )}
      {cookiesEnabled && user?.settings?.isCookieAccepted === false && (
        <DialogCookieAccepted />
      )}
    </MainLayout>
  );
};

export default Home;
