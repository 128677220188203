import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { orderBy, some } from 'lodash';
import { useHistory } from 'react-router';

import { Box, List, Paper, Typography } from '@material-ui/core';

import NotificationIcon from '@/components/icons/notification';
import Loading from '@/components/loading';
import { useDuel } from '@/hooks/useDuel';
import {
  DuelStatusesEnum,
  NotificationType,
  useReadNotificationMutation,
  useUserNotificationsQuery,
  useUserDuelLazyQuery,
  UserMeQuery,
  useQuizScoreLazyQuery,
} from '@/graphql';

import Item from './item';
import ResultDialog from '../components/result-dialog';
import MainLayout from '../components/main-layout';
import {
  NotifTypes,
  ProfessionTypesEnum,
  QueriesNamesEnum,
  SubscriptionStatus,
  SubscriptionTypes,
} from '../../../type';
import { useUser } from '@/contexts/user-context';
import { useApolloClient } from '@apollo/client';
import { ME_QUERY } from '@/apollo/queries';
import { useConfirmPayment } from '@/hooks/useConfirmPayment';
import QuizResultDialog from '../quiztime/quiz-result-dialog';
import { LS_KEY_IS_PAY_CONF_ACTIVE } from '@/utils/constants';

const Notifications: React.FC = () => {
  const { openDuel } = useDuel();
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useUser();
  const { cache } = useApolloClient();
  const { handlePaymentConfirmation } = useConfirmPayment();

  const { data, loading } = useUserNotificationsQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });
  const [dialogVisibility, setDialogVisibility] = useState(false);

  const [readNotification] = useReadNotificationMutation();
  const [getDuel, { data: duel }] = useUserDuelLazyQuery({
    onCompleted: (data) => {
      if (data?.duelData?.status === DuelStatusesEnum.InProgress) {
        return openDuel(data.duelData?.id, true);
      } else {
        setDialogVisibility(true);
      }
    },
  });
  const [getQuizScore, { data: quizScoreData, variables: quizVariables }] =
    useQuizScoreLazyQuery({
      onCompleted: (data) => {
        setDialogVisibility(true);
      },
    });

  const userData: UserMeQuery | undefined | null = cache.readQuery({
    query: ME_QUERY,
  });
  const subscriptionType =
    userData?.me?.customer?.subscription?.subscriptionType;
  const isProfessionStudent =
    user?.profession?.type?.type === ProfessionTypesEnum.TraineeToNurse ||
    user?.profession?.type?.type === ProfessionTypesEnum.Trainee ||
    user?.profession?.type?.type === ProfessionTypesEnum.Student;

  const handleSelected = useCallback(
    (notification: NotificationType) => {
      const notifData = JSON.parse(notification?.data);

      if (notification?.id) {
        readNotification({ variables: { id: notification.id } });
      }

      switch (notifData.type) {
        case NotifTypes.LEARNING_SESSION: {
          const isClassRoomLearningSession = some(
            user?.classRoomLearningSession,
            { id: notifData.id }
          );
          const url = `/classroom${
            isClassRoomLearningSession ? '' : '-details'
          }/${notifData.id}`;
          history.push(url);
          break;
        }
        case NotifTypes.DUEL: {
          getDuel({ variables: { duelId: notifData?.id } });
          break;
        }
        case NotifTypes.IS_HUMEO_LIKE: {
          history.push('notifications/is-humeo-like');
          break;
        }
        case NotifTypes.QT_GOING_T0_START:
        case NotifTypes.QT_STARTED:
        case NotifTypes.QT_SESSION_FREE_PLACE: {
          history.push(`/quiztime/${notifData.id}`);
          break;
        }
        case NotifTypes.QT_DUEL_IGNORED: {
          history.push(`/quiztime/${notifData.quiz_session_id}`);
          break;
        }
        case NotifTypes.QT_FINISHED: {
          getQuizScore({ variables: { quizSessionId: notifData?.id } });
          break;
        }
        case NotifTypes.SUBSCRIPTION:
        case NotifTypes.PREMIUM: {
          history.push('my-profile/network');
          break;
        }
        case NotifTypes.PROMOTION_PREMIUM: {
          cache.evict({ fieldName: QueriesNamesEnum.ME });
          history.push('my-profile/network', {
            prevPage: '/notifications',
          });
          break;
        }
        case NotifTypes.PAYMENT_REQUIRES_CONFIRMATION: {
          const isSubscribe = notifData?.caused_by === 'subscribe';
          const planId = isSubscribe
            ? subscriptionType
            : isProfessionStudent
            ? SubscriptionTypes.StudentYearly
            : SubscriptionTypes.ProfessionalYearly;

          history.replace(
            isSubscribe ? 'payment' : 'change-subscription-payment',
            {
              planId,
              isRedirectedFromNotifications: true,
            }
          );

          localStorage.setItem(LS_KEY_IS_PAY_CONF_ACTIVE, 'true');

          const data: UserMeQuery | undefined | null = cache.readQuery({
            query: ME_QUERY,
          });

          cache.writeQuery({
            query: ME_QUERY,
            data: {
              ...data,
              me: {
                ...data?.me,
                customer: {
                  ...data?.me?.customer,
                  subscription: {
                    ...data?.me?.customer?.subscription,
                    status: SubscriptionStatus.INCOMPLETE,
                  },
                },
              },
            },
          });

          handlePaymentConfirmation(notification);
          break;
        }
        default:
          break;
      }
    },
    [
      readNotification,
      getDuel,
      getQuizScore,
      history,
      user,
      subscriptionType,
      cache,
      handlePaymentConfirmation,
      isProfessionStudent,
    ]
  );

  const newNotifications = useMemo(
    () =>
      data
        ? orderBy(
            (data?.notifications || []).filter((it) => it && !it.isRead),
            ['created']
          )
            .filter((it) => !JSON.parse(it?.data)?.hidden)
            .reverse()
            .map((it) => {
              const notifData = JSON.parse(it?.data);
              return (
                <Item
                  key={it?.id}
                  isNew
                  avatar={
                    notifData?.specialityGroupIcon ||
                    notifData?.imageUrl ||
                    undefined
                  }
                  specialityGroupIcon={notifData?.specialityGroupIcon}
                  message={it?.message || ''}
                  created={it?.created}
                  onSelect={() => handleSelected(it as NotificationType)}
                  type={notifData?.type}
                />
              );
            })
        : [],
    [data, handleSelected]
  );

  const oldNotifications = useMemo(
    () =>
      data
        ? orderBy(
            (data?.notifications || []).filter(
              (it) => it && it.isRead && !JSON.parse(it?.data)?.hidden
            ),
            ['created']
          )
            .reverse()
            .filter((it) => !!it)
            .map((it) => {
              const notifData = JSON.parse(it?.data);
              return (
                <Item
                  key={it?.id}
                  avatar={
                    notifData?.specialityGroupIcon ||
                    notifData?.imageUrl ||
                    undefined
                  }
                  specialityGroupIcon={notifData?.specialityGroupIcon}
                  message={it?.message || ''}
                  created={it?.created}
                  type={notifData?.type}
                />
              );
            })
        : [],
    [data]
  );

  const isEmpty = useMemo(
    () => newNotifications.length === 0 && oldNotifications.length === 0,
    [newNotifications.length, oldNotifications.length]
  );

  if (loading)
    return (
      <MainLayout title={t('notifications.title')}>
        <Loading />
      </MainLayout>
    );

  return (
    <MainLayout title={t('notifications.title')}>
      {isEmpty ? (
        <Box
          clone
          data-cy-notifications-empty
          display="grid"
          position="fixed"
          alignContent="center"
          height="100%"
          width="100%"
        >
          <Paper>
            <Box paddingX={10}>
              <Box display="flex" justifyContent="center" marginBottom={5}>
                <NotificationIcon />
              </Box>
              <Typography align="center" variant="body2">
                {t('notifications.empty')}
              </Typography>
            </Box>
          </Paper>
        </Box>
      ) : (
        <>
          <Paper>
            <Box minHeight={'calc(100vh - 100px)'}>
              {!!newNotifications.length && (
                <>
                  <Box paddingY={3} paddingX={4}>
                    <Typography variant="overline">
                      {t('notifications.new')}
                    </Typography>
                  </Box>
                  <List data-cy-notifications-new>{newNotifications}</List>
                </>
              )}
              {!!oldNotifications.length && (
                <>
                  <Box paddingY={3} paddingX={4} paddingTop={7}>
                    <Typography variant="overline">
                      {t('notifications.old')}
                    </Typography>
                  </Box>
                  <List data-cy-notifications-old>{oldNotifications}</List>
                </>
              )}
            </Box>
          </Paper>

          {dialogVisibility && duel?.duelData?.id && (
            <ResultDialog
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              status={duel?.duelData.status as any}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              userScore={duel?.duelData?.userScore as any}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              opponentScore={duel?.duelData?.opponentScore as any}
              onClose={() => setDialogVisibility(false)}
              maxRoundsCount={duel?.duelData.rounds?.length}
              duelId={duel.duelData.id}
            />
          )}
          {dialogVisibility && quizScoreData?.quizDuelScore?.id && (
            <QuizResultDialog
              sessionId={quizVariables?.quizSessionId}
              rank={quizScoreData?.quizDuelScore?.rank || 0}
              onClose={() => setDialogVisibility(false)}
            />
          )}
        </>
      )}
    </MainLayout>
  );
};

export default Notifications;
