import React, { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@material-ui/core';

import AppButton from '@/components/app-button';
import {
  useSubscribeToNewsMutation,
  useUnsubscribeToNewsMutation,
} from '@/graphql';

const Unsubscribe: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const query = new URLSearchParams(location.search);
  const userId = query.get('id')?.replaceAll('/', '');
  const userEmail = query.get('email')?.replaceAll('/', '');

  const [unsubscribe, { loading: unLoading }] = useUnsubscribeToNewsMutation();
  const [subscribe, { loading: subLoading }] = useSubscribeToNewsMutation();

  useEffect(() => {
    const load = async () => {
      try {
        await unsubscribe({
          variables: {
            userId,
          },
        });
      } catch (error) {
        console.log(JSON.stringify(error));
      }
    };

    if (userId) load();
  }, [unsubscribe, userId]);

  const reSubscribe = useCallback(async () => {
    try {
      await subscribe({
        variables: {
          userId,
        },
      });

      history.push(`/subscribed-to-news?email=${userEmail}`);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  }, [history, subscribe, userId, userEmail]);

  return (
    <>
      <Box mt={3} mb={7}>
        <Typography variant="body2">{t('unsubscribe.sub-title')}</Typography>
      </Box>
      <AppButton
        fullWidth
        color="primary"
        variant="contained"
        loading={unLoading || subLoading}
        onClick={reSubscribe}
      >
        {t('unsubscribe.re-subscribe')}
      </AppButton>
    </>
  );
};

export default Unsubscribe;
