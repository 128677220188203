import React, { useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { useUpdateRulesSettingsMutation, useUserMeQuery } from '@/graphql';
import { USER_SETTINGS_FRAGMENT } from '@/apollo/fragments';
import { useDuelRulesFullScreenModal } from '@/hooks/useDuelRulesFullScreenModal';

const RuleWrapper: React.FC = ({ children }) => {
  const client = useApolloClient();
  const duelRulesFullScreenModal = useDuelRulesFullScreenModal();
  const { data } = useUserMeQuery({ fetchPolicy: 'cache-first' });
  const [update, { loading }] = useUpdateRulesSettingsMutation();
  const isDuelRulesRead = data?.me?.settings?.isDuelRulesRead;

  useEffect(() => {
    if (isDuelRulesRead === false && !loading) {
      client.writeFragment({
        id: `${data?.me?.settings?.__typename}:${data?.me?.settings?.id}`,
        fragment: USER_SETTINGS_FRAGMENT,
        data: {
          ...data?.me?.settings,
          isDuelRulesRead: true,
        },
      });

      try {
        update({
          variables: {
            isDuelRulesRead: true,
          },
        });
      } catch (err) {
        console.log(err);
      }
      duelRulesFullScreenModal.open();
    }
  }, [
    data,
    isDuelRulesRead,
    client,
    loading,
    update,
    duelRulesFullScreenModal,
  ]);

  return <>{children}</>;
};

export default RuleWrapper;
