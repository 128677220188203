import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Paper } from '@material-ui/core';
import StackLayout from '../../components/stack-layout';
import PaymentForm from '../payment-form';
import { useChangeUserCardMutation, UserSubscriptionDocument } from '@/graphql';
import { useHistory } from 'react-router-dom';
import { stripeBackendErrorsCodes } from '@/utils/helpers';

const ChangePaymentMethod: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [error, setError] = useState<string | null>(null);

  const [changeCard] = useChangeUserCardMutation();

  const handleSubmit = useCallback(
    async (token) => {
      try {
        await changeCard({
          variables: {
            stripeToken: token,
          },
          refetchQueries: [{ query: UserSubscriptionDocument }],
        });
        history.go(-1);
      } catch (error) {
        const stripeErrorObj =
          error?.networkError?.result?.errors[0]?.extensions?.stripe;
        const errorCode = stripeErrorObj?.decline_code || stripeErrorObj?.code;
        if (errorCode && stripeBackendErrorsCodes[errorCode]) {
          setError(
            t(`payment.errors-codes.${stripeBackendErrorsCodes[errorCode]}`)
          );
        } else if (errorCode) {
          setError(t('payment.declined'));
        } else {
          setError(t('payment.declined'));
        }
      }
    },
    [history, changeCard, t]
  );

  return (
    <StackLayout title={t('payment.change-card')}>
      <Box display="grid" height="100%">
        <Paper>
          <Box px={4} py={6}>
            <PaymentForm
              btnText={t('payment.save-card')}
              onSubmit={handleSubmit}
              onSetExternalError={setError}
              externalError={error}
            />
          </Box>
        </Paper>
      </Box>
    </StackLayout>
  );
};

export default ChangePaymentMethod;
