import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SignUpProvider } from '@/contexts/sign-up-context';

import PrivateRoute from './screens/main/components/route';
import PublicRoute from './screens/auth/components/route';
import { useFeatureFlags } from './hooks/useFeatureFlags';
import { GlobalDialogTypes } from './type';
import { useGlobalDialog } from './hooks/useGlobalDialog';

import Fallback from './components/fallback';

import Main from './screens/auth/main';
import SignUp from './screens/auth/sign-up';
import LogIn from './screens/auth/sign-in';
import Forgot from './screens/auth/forgot-password/forgot';
import Forgoted from './screens/auth/forgot-password/forgoted';
import Reseted from './screens/auth/forgot-password/reseted';
import Reset from './screens/auth/forgot-password/reset';
import Unsubscribe from './screens/auth/unsubscribe';
import Duel from './screens/main/duel';
import StartDailyTipper from './screens/main/start-daily-tipper';
import Duels from './screens/main/duels';
import DuelOverview from './screens/main/duel/overview';
import CreateRound from './screens/main/duel/create-round';
import Question from './screens/main/duel/question';
import Settings from './screens/main/settings';
import Notifications from './screens/main/notifications';
import MyProfile from './screens/main/my-profile';
import MyProfilePersonal from './screens/main/my-profile/personal';
import MyProfileProfession from './screens/main/my-profile/profession';
import MyProfileNetwork from './screens/main/my-profile/network';
import DailyTipper from './screens/main/daily-tipper';
import DailyTipperQuestion from './screens/main/daily-tipper/question';
import DailyTipperResult from './screens/main/daily-tipper/result-dialog';
import ChooseSubscription from './screens/main/subscriptions/choose-subscription';
import Payment from './screens/main/subscriptions/payment';
import Congratulation from './screens/main/subscriptions/congratulation';
import ChangeSubscriptionPayment from './screens/main/subscriptions/change-subscription-payment';
import PaymentMethod from './screens/main/subscriptions/payment-method';
import ChangePaymentMethod from './screens/main/subscriptions/change-payment-method';
import PaymentMethodDetails from './screens/main/subscriptions/payment-method-details';
import StripeWrapper from './screens/main/stripe-wrapper';
import ComfirmEmail from './screens/auth/sign-up/confirm-email';
import CtaSubscription from './screens/main/cta-subscription';
import Imprint from './screens/main/policy/imprint';
import Dataprotection from './screens/main/policy/dataprotection';
import AGB from './screens/main/policy/agb';
import QuizTimeDetails from './screens/main/quiztime/quiztime-details';
import QuizTimeRules from './screens/main/quiztime/rules';
import QuizActivateCode from './screens/main/quiztime/quiz-activate-code';
import QuizTimeList from './screens/main/quiztime/quiztime-list';
import QuizTime from './screens/main/quiztime/quiz-single';
import QuizDuel from './screens/main/quiztime/quiz-duel';
import QuizDuelQuestion from './screens/main/quiztime/quiz-duel/question';
import QuizCreateRound from './screens/main/quiztime/quiz-duel/create-round';
import QuizDuelOverview from './screens/main/quiztime/quiz-duel/overview';
import QuizCompletedDuels from './screens/main/quiztime/quiz-completed-duels';
import QuizSearchMember from './screens/main/quiztime/quiz-search-members';
import GoTo from './screens/deep-links/go-to';
import PublicQuizTimeDetails from './screens/main/quiztime/public-quiztime-details';
import PublicClassRoomDetails from './screens/main/classrooms/pages/public-classroom-details';
import SpecialitiesList from './screens/main/classrooms/pages/specialities-list';
import SearchNetworksSessions from './screens/main/classrooms/pages/search-networks-sessions';
import NetworkSessionsBySpec from './screens/main/classrooms/pages/network-sessions-by-spec';
import ClassRoomDetails from './screens/main/classrooms/pages/classroom-details';
import ClassRoom from './screens/main/classrooms/pages/classroom-single';
import ClassroomLeaveDialog from './screens/main/classrooms/classroom-leave-dialog';
import ClassroomRules from './screens/main/classrooms/pages/classroom-rules';
import ClassRoomLek from './screens/main/classrooms/pages/classroom-lek';
import ClassRoomLekResults from './screens/main/classrooms/pages/classroom-lek-result';
import ClassRoomSearchMember from './screens/main/classrooms/pages/classroom-search-members';
import ClassroomLector from './screens/main/classrooms/pages/classroom-lector';
import ClassroomOrganizer from './screens/main/classrooms/pages/classroom-organizer';
import ClassRoomActivityDetails from './screens/main/classrooms/pages/classroom-activity-details';
import SubscribedToNews from './screens/auth/subscribed';
import DtAchievements from './screens/main/daily-tipper/achievements';
import YearCongrats from './screens/main/daily-tipper/year-congrats';
import OnboardFirstDuel from './screens/main/onboard-first-duel';
import OnboardKeepLearning from './screens/main/onboard-keep-learning';
import OnboardWelcome from './screens/main/onboard-welcome';
import PlugForFutureSubs from './screens/main/subscriptions/plug-for-future-subs';
import SettingsPushes from './screens/main/settings-pushes';
import InviteFriends from './screens/main/invite-friends';

import AsyncConfirmRegistration from '././screens/auth/confirm-registration';
import Home from './screens/main/home';
import Classrooms from './screens/main/classrooms/pages/classrooms';
import InAppSubscriptions from './screens/main/in-app-subscriptions';

const Routes: React.FC = () => {
  const { t } = useTranslation();
  const {
    dailyTipperEnabled,
    classroomsEnabled,
    humeoAppEnabled,
    cookiesEnabled,
    me,
    userSettings,
  } = useFeatureFlags();
  const query = new URLSearchParams(window.location.search);
  const { data: dataGlobalDialog } = useGlobalDialog();

  return (
    <Router>
      <Switch>
        {humeoAppEnabled === false && <Route path="/" component={Fallback} />}
        <Route path="/confirm-registration/" exact>
          <AsyncConfirmRegistration />
        </Route>
        <Route
          title={t('page-header.sign-up')}
          path="/sign-up-confirm-email"
          exact
        >
          <ComfirmEmail />
        </Route>
        <Route path="/auth" exact component={Main} />
        <Route title={t('page-header.sign-up')} path="/sign-up" exact>
          <SignUpProvider>
            <SignUp />
          </SignUpProvider>
        </Route>
        <PublicRoute
          title={t('page-header.sign-in-with')}
          path="/sign-in"
          exact
        >
          <LogIn />
        </PublicRoute>
        <PublicRoute
          title={t('page-header.forgot-password.title')}
          path="/forgot-password"
          exact
        >
          <Forgot />
        </PublicRoute>
        <PublicRoute
          title={t('page-header.reset.title')}
          path="/reset-password"
          exact
        >
          <Reset />
        </PublicRoute>
        <PublicRoute
          title={t('page-header.reseted.title')}
          path="/reset-password/completed"
          exact
        >
          <Reseted />
        </PublicRoute>
        <PublicRoute
          title={t('page-header.forgoted.title')}
          path="/forgot-password/completed"
          exact
        >
          <Forgoted />
        </PublicRoute>

        <PublicRoute title={t('unsubscribe.title')} path="/unsubscribe" exact>
          <Unsubscribe />
        </PublicRoute>
        <PublicRoute title="" path="/subscribed-to-news" exact>
          <SubscribedToNews />
        </PublicRoute>
        <Route path="/goto/:hash/" exact>
          <GoTo />
        </Route>
        <Route path="/public-classroom-details/:sessionId/:hash" exact>
          <PublicClassRoomDetails />
        </Route>
        <Route path="/public-quiztime-details/:quizSessionId/:hash" exact>
          <PublicQuizTimeDetails />
        </Route>

        <PrivateRoute path="/classrooms/" exact>
          {classroomsEnabled && <Classrooms />}
        </PrivateRoute>
        <PrivateRoute path="/biblio/" exact>
          {classroomsEnabled && <SpecialitiesList />}
        </PrivateRoute>
        <PrivateRoute path="/biblio/search-sessions" exact>
          {classroomsEnabled && <SearchNetworksSessions />}
        </PrivateRoute>
        <PrivateRoute path="/network-sessions-by-spec/:specialityGroupId" exact>
          {classroomsEnabled && <NetworkSessionsBySpec />}
        </PrivateRoute>
        <PrivateRoute path="/classroom-details/:sessionId" exact>
          {classroomsEnabled && <ClassRoomDetails />}
        </PrivateRoute>
        <PrivateRoute
          path="/classroom-details/:sessionId/:specialityGroupId"
          exact
        >
          {classroomsEnabled && <ClassRoomDetails />}
        </PrivateRoute>
        <PrivateRoute path="/classroom/:sessionId" exact>
          {classroomsEnabled && <ClassRoom />}
        </PrivateRoute>
        <PrivateRoute path="/classroom-finished/:sessionId" exact>
          {classroomsEnabled && <ClassRoom isFinished />}
        </PrivateRoute>
        <PrivateRoute
          path="/classroom-finished/:sessionId/:userSessionSettingId"
          exact
        >
          {classroomsEnabled && <ClassRoom isFinished />}
        </PrivateRoute>
        <PrivateRoute
          path="/classroom/:sessionId/:userSessionSettingId/activity-details"
          exact
        >
          {classroomsEnabled && <ClassRoomActivityDetails />}
        </PrivateRoute>
        <PrivateRoute
          path="/classroom/:sessionId/:userSessionSettingId/leave"
          exact
        >
          {classroomsEnabled && (
            <ClassRoom>
              <ClassroomLeaveDialog />
            </ClassRoom>
          )}
        </PrivateRoute>
        <PrivateRoute path="/classroom/:sessionId/search-members" exact>
          {classroomsEnabled && <ClassRoomSearchMember />}
        </PrivateRoute>
        <PrivateRoute path="/classrooms/lector/:lectorId" exact>
          {classroomsEnabled && <ClassroomLector />}
        </PrivateRoute>
        <PrivateRoute path="/classrooms/organizer/:organizerId" exact>
          {classroomsEnabled && <ClassroomOrganizer />}
        </PrivateRoute>
        <PrivateRoute path="/classroom/:sessionId/lek/:lekSessionId" exact>
          {classroomsEnabled && <ClassRoomLek />}
        </PrivateRoute>
        <PrivateRoute path="/classroom/:sessionId/lek-results" exact>
          {classroomsEnabled && <ClassRoomLekResults />}
        </PrivateRoute>
        <PrivateRoute path="/classroom-rules/" exact>
          {classroomsEnabled && <ClassroomRules />}
        </PrivateRoute>

        {me &&
          me?.confirmationToken &&
          window.location &&
          !query.get('token') && <Redirect to="/sign-up-confirm-email" />}
        <PublicRoute path="/privacy-imprint" title="Imprint" exact>
          <Imprint />
        </PublicRoute>
        <PublicRoute path="/privacy-agb" title="AGB" exact>
          <AGB />
        </PublicRoute>
        <PublicRoute
          path="/privacy-dataprotection"
          title="Data protection"
          exact
        >
          <Dataprotection />
        </PublicRoute>
        <PrivateRoute path="/home">
          <Home />
          {dailyTipperEnabled &&
            ((cookiesEnabled && userSettings?.isCookieAccepted) ||
              !cookiesEnabled) &&
            (!dataGlobalDialog?.dialogId ||
              dataGlobalDialog?.dialogId ===
                GlobalDialogTypes.DAILY_TIPPER) && <StartDailyTipper />}
        </PrivateRoute>

        <PrivateRoute path="/onboard-welcome" exact>
          <OnboardWelcome />
        </PrivateRoute>
        <PrivateRoute path="/onboard-first-duel" exact>
          <OnboardFirstDuel />
        </PrivateRoute>
        <PrivateRoute path="/onboard-keep-learning" exact>
          <OnboardKeepLearning />
        </PrivateRoute>
        <PrivateRoute path="/invite-friends" exact>
          <InviteFriends />
        </PrivateRoute>

        <PrivateRoute path="/duels">
          <Duels />
          {dailyTipperEnabled &&
            ((cookiesEnabled && userSettings?.isCookieAccepted) ||
              !cookiesEnabled) && <StartDailyTipper />}
        </PrivateRoute>

        <PrivateRoute path="/duel/:duelId/create-round" exact>
          <Duel>
            <CreateRound />
          </Duel>
        </PrivateRoute>
        <PrivateRoute
          path="/duel/:duelId/create-round/session/:sessionId/member/:memberId"
          exact
        >
          <Duel>
            <CreateRound />
          </Duel>
        </PrivateRoute>
        <PrivateRoute
          path="/duel/:duelId/create-round/session/:sessionId"
          exact
        >
          <Duel>
            <CreateRound />
          </Duel>
        </PrivateRoute>
        <PrivateRoute
          path="/duel/:duelId/round/:roundId/question/:questionId"
          exact
        >
          <Question />
        </PrivateRoute>
        <PrivateRoute path="/duel/:duelId/overview" exact>
          <Duel>
            <DuelOverview />
          </Duel>
        </PrivateRoute>

        <PrivateRoute path="/daily-tipper/achievements">
          <DtAchievements />
        </PrivateRoute>
        <PrivateRoute path="/daily-tipper">
          {dailyTipperEnabled && (
            <>
              <DailyTipper />
              <Route
                path="/daily-tipper/result"
                exact
                component={DailyTipperResult}
              />
              <Route
                path="/daily-tipper/year-congrats"
                exact
                component={YearCongrats}
              />
            </>
          )}
        </PrivateRoute>

        <PrivateRoute path="/daily-tipper-question/:questionId/" exact>
          {dailyTipperEnabled && <DailyTipperQuestion />}
        </PrivateRoute>

        <PrivateRoute path="/daily-tipper-question/:questionId/completed" exact>
          {dailyTipperEnabled && <DailyTipperQuestion />}
        </PrivateRoute>

        <PrivateRoute path="/settings" exact>
          <Settings />
        </PrivateRoute>
        <PrivateRoute path="/settings/pushes" exact>
          <SettingsPushes />
        </PrivateRoute>
        <PrivateRoute path="/my-profile" exact>
          <MyProfile />
        </PrivateRoute>
        <PrivateRoute path="/my-profile/personal">
          <MyProfilePersonal />
        </PrivateRoute>
        <PrivateRoute path="/my-profile/profession/:professionType">
          <MyProfileProfession />
        </PrivateRoute>
        <PrivateRoute path="/my-profile/network">
          <MyProfileNetwork />
        </PrivateRoute>
        <PrivateRoute path="/choose-subscription">
          <ChooseSubscription />
        </PrivateRoute>
        <PrivateRoute path="/purchase-in-app-subscription">
          <InAppSubscriptions />
        </PrivateRoute>
        <PrivateRoute path="/change-subscription-payment">
          <ChangeSubscriptionPayment />
        </PrivateRoute>
        <PrivateRoute path="/subscription-payment-congratulation">
          <Congratulation />
        </PrivateRoute>
        <PrivateRoute path="/my-profile/payment-method">
          <PaymentMethod />
        </PrivateRoute>
        <PrivateRoute path="/my-profile/payment-method-details">
          <PaymentMethodDetails />
        </PrivateRoute>
        <PrivateRoute path="/my-profile/change-payment-method">
          {!window?.ReactNativeWebView ? (
            <StripeWrapper>
              <ChangePaymentMethod />
            </StripeWrapper>
          ) : (
            <PlugForFutureSubs />
          )}
        </PrivateRoute>
        <PrivateRoute path="/payment">
          {!window?.ReactNativeWebView ? (
            <StripeWrapper>
              <Payment />
            </StripeWrapper>
          ) : (
            <PlugForFutureSubs />
          )}
        </PrivateRoute>

        <PrivateRoute path="/notifications">
          <Notifications />
          <Route
            path="/notifications/is-humeo-like"
            exact
            component={CtaSubscription}
          />
        </PrivateRoute>
        <PrivateRoute path="/quiz-list">
          <QuizTimeList />
        </PrivateRoute>
        <PrivateRoute path="/quiztime-details/:quizSessionId" exact>
          <QuizTimeDetails />
        </PrivateRoute>

        <PrivateRoute path="/quiztime-rules/:quizSessionId" exact>
          <QuizTimeRules />
        </PrivateRoute>

        <PrivateRoute path="/quiztime/:quizSessionId" exact>
          <QuizTime />
        </PrivateRoute>

        <PrivateRoute path="/quiztime/activate-code/:quizSessionId" exact>
          <QuizActivateCode />
        </PrivateRoute>
        <PrivateRoute path="/quiztime/:quizSessionId/completed-duels" exact>
          <QuizCompletedDuels />
        </PrivateRoute>

        <PrivateRoute path="/quiztime/:quizSessionId/search-members" exact>
          <QuizSearchMember />
        </PrivateRoute>

        <PrivateRoute
          path="/quiz-duel/:duelId/create-round/session/:sessionId"
          exact
        >
          <QuizDuel>
            <QuizCreateRound />
          </QuizDuel>
        </PrivateRoute>
        <PrivateRoute
          path="/quiz-duel/:duelId/round/:roundId/question/:questionId/session/:sessionId"
          exact
        >
          <QuizDuelQuestion />
        </PrivateRoute>
        <PrivateRoute
          path="/quiz-duel/:duelId/overview/session/:sessionId"
          exact
        >
          <QuizDuel>
            <QuizDuelOverview />
          </QuizDuel>
        </PrivateRoute>

        <Redirect from="/" to="/home" exact />
        {/* 404 */}
        <Redirect to="/" />
      </Switch>
    </Router>
  );
};

export default Routes;
