import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import refetchQueries from 'refetch-queries';
import { useApolloClient } from '@apollo/client';
import { Skeleton } from '@material-ui/lab';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Link,
  Paper,
  Typography,
} from '@material-ui/core';
import QuizTimeHeader from '../header';
import QuizTimeDescription from '../description';
import StackLayout from '../../components/stack-layout';
import QuizTimePrizes from '../prizes';
import AppButton from '@/components/app-button';
import EmptyState from '@/screens/deep-links/empty-state';
import { InfoOutlined } from '@material-ui/icons';
import {
  GetUserQuizSessionSettingsDocument,
  QuizSessionsAnnouncedDocument,
  QuizSessionsOngoingDocument,
  QuizSessionsOnHomePageDocument,
  QuizTimeDetailsDocument,
  useAddWaitingUserNotificationMutation,
  useApplyQuizTimeCodeMutation,
  useGetUserQuizSessionSettingsQuery,
  useQuizTimeDetailsQuery,
  UserQuizSessionSettingsUserStatus,
  useUserSubscriptionQuery,
} from '@/graphql';
import { useQuizFullModal } from '@/hooks/useModal';
import { SubscriptionStatus, TabNavIdsEnum } from '@/type';
import CloseIcon from '@material-ui/icons/Close';
import { useGetPremiumData } from '@/hooks/useGetPremiumData';
import { useStyles } from './styles';
import { LS_KEY_TABINDEX } from '@/utils/constants';
import { getSubscriptionsPath } from '@/utils/helpers';

type QuizTimeParams = {
  quizSessionId: string;
};

const QuizTimeDetails: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const { quizSessionId } = useParams<QuizTimeParams>();
  const [submitted, setSubmitted] = useState(false);
  const [dialogVisibility, setDialogVisibility] = useState(false);

  const params = new URLSearchParams(history.location.search);
  const deepLinkHash = params.get('hash');

  const { data, loading, refetch, error } = useQuizTimeDetailsQuery({
    variables: { quizSessionId },
    fetchPolicy: 'cache-and-network',
  });

  const [addWaitingUser] = useAddWaitingUserNotificationMutation();
  const client = useApolloClient();

  const [joinQuiz] = useApplyQuizTimeCodeMutation();
  const { data: subsData } = useUserSubscriptionQuery();
  const { isUserPremium } = useGetPremiumData();

  const { data: userQuizSettingsData } = useGetUserQuizSessionSettingsQuery({
    variables: { quizSessionId },
    fetchPolicy: 'cache-and-network',
  });

  const organizer = data?.quizTimeSession?.quizTime?.organizer;
  const isForPremiumOnly = data?.quizTimeSession?.isForPremiumOnly;
  const isUseCode = data?.quizTimeSession?.isUseCode;
  const maxCapacity = data?.quizTimeSession?.maxUserCapacity
    ? data?.quizTimeSession?.maxUserCapacity
    : null;
  const numberOfMembers = data?.quizTimeSession?.usersCount;
  const participationConditions =
    data?.quizTimeSession?.quizTime?.participationConditions;
  const startDate = data?.quizTimeSession?.startDate;
  const startDateTime = data?.quizTimeSession?.startDateTime;
  const quizStatus = data?.quizTimeSession?.status;
  const finishDateTime = data?.quizTimeSession?.finishDateTime;
  const userStatus = userQuizSettingsData?.userQuizSessionSettings?.userStatus;
  const sponsor = data?.quizTimeSession?.quizTime?.sponsor;

  const subscribeUserToNotification = useCallback(() => {
    try {
      addWaitingUser({
        variables: {
          sessionId: quizSessionId,
        },
      });
    } catch (err) {
      console.log(err);
    }
  }, [quizSessionId, addWaitingUser]);

  const quizIsFullModal = useQuizFullModal(subscribeUserToNotification);

  const joinQuizTime = async () => {
    if (numberOfMembers && maxCapacity && numberOfMembers >= maxCapacity) {
      const usersCountData = await refetch();
      const freshUsersCount = usersCountData?.data?.quizTimeSession?.usersCount;
      if (freshUsersCount && freshUsersCount >= maxCapacity) {
        quizIsFullModal.open();
        return;
      }
    }

    if (isForPremiumOnly && !isUserPremium) setDialogVisibility(true);
    else if (
      userStatus !== UserQuizSessionSettingsUserStatus.Out &&
      isUseCode
    ) {
      history.push(
        `/quiztime/activate-code/${quizSessionId}`,
        history.location.state
      );
    } else sendCode();
  };

  const backLink = useMemo(() => {
    const historyState: any = history.location.state;
    if (historyState?.rootPageForBack === '/home') return '/home';
    return historyState?.backPageForQuiz
      ? historyState?.backPageForQuiz
      : undefined;
  }, [history]);

  useEffect(() => {
    if (
      userStatus &&
      userStatus !== UserQuizSessionSettingsUserStatus.Out &&
      userStatus !== UserQuizSessionSettingsUserStatus.WaitingForJoining
    ) {
      localStorage.setItem(`${TabNavIdsEnum.QUIZTIME}${LS_KEY_TABINDEX}`, '0');
      history.replace(`/quiztime/${quizSessionId}`, history.location.state);
    }
  });

  const sendCode = useCallback(async () => {
    setSubmitted(true);
    try {
      const res = await joinQuiz({
        variables: {
          sessionId: quizSessionId,
          deepLinkHash,
        },
        refetchQueries: [
          {
            query: QuizSessionsOngoingDocument,
          },
          {
            query: QuizSessionsAnnouncedDocument,
          },
          {
            query: QuizTimeDetailsDocument,
            variables: { quizSessionId },
          },
          {
            query: GetUserQuizSessionSettingsDocument,
            variables: { quizSessionId },
          },
          {
            query: QuizSessionsOnHomePageDocument,
            variables: { startLess24Hours: true },
          },
        ],
      });
      const id = res?.data?.joinToQuiz?.quizSession?.id;
      setSubmitted(false);
      if (id) {
        localStorage.setItem(
          `${TabNavIdsEnum.QUIZTIME}${LS_KEY_TABINDEX}`,
          '0'
        );
        localStorage.setItem(
          `${TabNavIdsEnum.QUIZTIME}${LS_KEY_TABINDEX}`,
          '0'
        );
        history.replace(`/quiztime/${id}`, { showSnackQtJoined: true });
      }
    } catch (error) {
      refetchQueries(client, [
        { query: QuizTimeDetailsDocument, variables: { quizSessionId } },
      ]);
      console.log(error);
      if (
        error?.networkError?.result?.errors[0]?.message ===
        'Quiz only for premium users!'
      )
        setDialogVisibility(true);
      if (
        error?.networkError?.result?.errors[0]?.message ===
        'Quiz user capacity achieved!'
      )
        quizIsFullModal.open();
      if (isUseCode) {
        history.push(`/quiztime/activate-code/${quizSessionId}`);
      }
      setSubmitted(false);
    }
  }, [
    joinQuiz,
    isUseCode,
    client,
    quizIsFullModal,
    quizSessionId,
    history,
    deepLinkHash,
  ]);

  const goToPremium = () => {
    const historyState: any = history.location.state;
    setDialogVisibility(false);
    history.replace(getSubscriptionsPath(), {
      prevPage: `/quiztime-details/${quizSessionId}`,
      backPageForQuiz: historyState?.backPageForQuiz || '/quiz-list',
      quizSessionId,
    });
  };

  if (error?.message) return <EmptyState isQuiztime />;

  return (
    <Box id="top">
      <StackLayout
        title={t('quiztime.title')}
        toolbar={
          <IconButton
            color="primary"
            component={RouterLink}
            size="small"
            to={`/quiztime-rules/${quizSessionId}`}
          >
            <InfoOutlined htmlColor="#fff" />
          </IconButton>
        }
        back={backLink}
      >
        {!loading && (
          <QuizTimeHeader
            quizSessionId={quizSessionId}
            quizStatus={quizStatus}
            image={data?.quizTimeSession?.quizTime?.quizTimeImage}
            startDate={startDate}
            startDateTime={startDateTime}
            finishDateTime={finishDateTime}
            title={data?.quizTimeSession?.quizTime?.title}
            organizerName={organizer?.name}
            isForPremiumOnly={isForPremiumOnly}
            duration={data?.quizTimeSession?.quizTimeDuration}
            maxCount={maxCapacity}
            numberOfMembers={numberOfMembers}
            userStatus={userStatus}
          />
        )}
        <div className={classes.content}>
          {loading ? (
            <Paper>
              <Box py={5} px={6}>
                {[...Array(15)].map((i, idx) => (
                  <Skeleton key={idx} height={30} />
                ))}
              </Box>
            </Paper>
          ) : (
            <>
              <QuizTimeDescription
                description={data?.quizTimeSession?.quizTime?.description}
                orgId={organizer?.id}
                orgName={organizer?.name}
                orgEmail={organizer?.email}
                orgLogo={organizer?.logo}
                orgWebsite={organizer?.linkToSite}
                isUseCode={isUseCode}
                sponsor={sponsor}
              ></QuizTimeDescription>
              <QuizTimePrizes quizSessionId={quizSessionId} />
              <AppButton
                color="primary"
                variant="contained"
                onClick={joinQuizTime}
                disabled={submitted}
                className={classes.joinBtn}
              >
                {t('quiztime.btn-join')}
              </AppButton>
              <Box px={4} mt={1}>
                <Typography variant="caption">
                  {t('quiztime.rules.text-join')}
                  {participationConditions && (
                    <Link href={participationConditions} target="_blank">
                      <span className={classes.link}>
                        {t('quiztime.rules.terms-of-participation.title')}
                      </span>
                    </Link>
                  )}
                  {t('quiztime.rules.text-join-postfix')}
                </Typography>
              </Box>
            </>
          )}
        </div>
        <Dialog
          open={dialogVisibility}
          onClose={() => setDialogVisibility(false)}
        >
          <IconButton
            className={classes.close}
            onClick={() => setDialogVisibility(false)}
          >
            <CloseIcon />
          </IconButton>

          <Box className={classes.dialogConent}>
            {subsData?.me?.customer?.subscription?.status ===
            SubscriptionStatus.INCOMPLETE ? (
              <Typography align="center" variant="body1">
                {t('payment.is-processing')}
              </Typography>
            ) : (
              <>
                <Typography align="center" color="primary" variant="h2">
                  {t('dialogs.upgrade.title')}
                </Typography>
                <Box mt={2} mb={12}>
                  <Typography align="center" variant="body1">
                    {t('dialogs.upgrade.content')}
                  </Typography>
                </Box>
                <Button
                  fullWidth
                  onClick={goToPremium}
                  color="primary"
                  variant="contained"
                >
                  {t('dialogs.upgrade.cta')}
                </Button>
              </>
            )}
          </Box>
        </Dialog>
      </StackLayout>
    </Box>
  );
};

export default QuizTimeDetails;
