export enum RNMessageType {
  RN_EXPO_TOKEN = 'RN_EXPO_TOKEN',
  RN_ERROR = 'RN_ERROR',
  RN_PURCHASE_COMPLETED = 'RN_PURCHASE_COMPLETED',
  RN_FETCH_PACKAGES_SUCCESS = 'RN_FETCH_PACKAGES_SUCCESS',
  RN_SUBSCRIPTION_RENEW_SUCCESS = 'RN_SUBSCRIPTION_RENEW_SUCCESS',
  RN_SUBSCRIPTION_UPGRADE_SUCCESS = 'RN_SUBSCRIPTION_UPGRADE_SUCCESS',
}
export enum WebActionType {
  Share = 'WEB_ACTION_SHARE',
  SendUserData = 'WEB_ACTION_SEND_USER_DATA',
  SendExpoToken = 'WEB_ACTION_SEND_EXPO_TOKEN',
  UpgradeSubscription = 'WEB_ACTION_UPGRADE_SUBSCRIPTION',
  CancelSubscription = 'WEB_ACTION_CANCEL_SUBSCRIPTION',
  RenewSubscription = 'WEB_ACTION_RENEW_SUBSCRIPTION',
  FetchPackages = 'WEB_ACTION_FETCH_PACKAGES',
  InitiatePurchase = 'WEB_ACTION_INITIATE_PURCHASE',
}
