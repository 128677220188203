import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import { Box, Divider, Switch, Typography } from '@material-ui/core';
import {
  useSubscribeToNewsMutation,
  useUnsubscribeToNewsMutation,
  useUserMeQuery,
} from '@/graphql';
import { USER_FRAGMENT } from '@/apollo/fragments';
import { useStyles } from '../styles';

const NewsLetters: React.FC = () => {
  const classes = useStyles();
  const client = useApolloClient();
  const { t } = useTranslation();
  const { data } = useUserMeQuery({
    fetchPolicy: 'cache-first',
  });
  const [unsubscribe] = useUnsubscribeToNewsMutation();
  const [subscribe] = useSubscribeToNewsMutation();

  const changeField = useCallback(
    async (checked: boolean) => {
      const userId = data?.me?.id;
      try {
        if (!checked) {
          await unsubscribe({
            variables: {
              userId,
            },
          });
        } else {
          await subscribe({
            variables: {
              userId,
            },
          });
        }
      } catch (error) {
        console.log(JSON.stringify(error));
      }

      client.writeFragment({
        id: `${data?.me?.__typename}:${data?.me?.id}`,
        fragment: USER_FRAGMENT,
        data: {
          ...data?.me,
          subscribeToNews: !data?.me?.subscribeToNews,
        },
      });
    },
    [client, data, subscribe, unsubscribe]
  );

  return (
    <Box data-cy-duel-settings>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle2">
          {t('settings.newsletters.title')}
        </Typography>
        <Switch
          color="primary"
          checked={!!data?.me?.subscribeToNews}
          onChange={(e, checked) => changeField(checked)}
        />
      </Box>
      <Typography variant="caption" component="p" className={classes.helpText}>
        {t('settings.newsletters.help-text')}
      </Typography>
      <Box mt={6} mb={4}>
        <Divider />
      </Box>
    </Box>
  );
};

export default NewsLetters;
