import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  makeStyles,
  Typography,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { datadogRum } from '@datadog/browser-rum';
import { useHistory } from 'react-router-dom';

import { ModalContext } from '@/contexts/modal-context';
import { FREEMIUM_MAX_DUEL_COUNT } from '@/utils/constants';
import { getSubscriptionsPath } from '@/utils/helpers';

const useStyles = makeStyles((theme) => ({
  infoBox: {
    background: '#E2F5FD',
    border: '1px solid #B6E4FA',
    borderRadius: 4,
    padding: 12,
  },
}));

export const useDuelLimitModal = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const { openModal, closeModal } = React.useContext(ModalContext);

  const onButton2Click = React.useCallback(() => {
    datadogRum.addAction('modal duel limit freemium', {
      type: 'go to subs',
    });
    closeModal();
    history.push(getSubscriptionsPath());
  }, [closeModal, history]);

  const onButtonClick = React.useCallback(() => {
    datadogRum.addAction('modal duel limit freemium', {
      type: 'close',
    });
    closeModal();
  }, [closeModal]);

  const element = React.useMemo(
    () => (
      <Box>
        <DialogTitle>{t('dialogs.duel-create-error.title')}</DialogTitle>
        <Box mt={-6} mb={2}>
          <DialogContent>
            <DialogContentText variant="body2">
              {t('dialogs.duel-create-error.content', {
                value: FREEMIUM_MAX_DUEL_COUNT,
              })}
            </DialogContentText>
            <Box className={classes.infoBox}>
              <Typography variant="subtitle1">
                <Trans
                  i18nKey="discount-box.title"
                  components={{
                    link1: <span style={{ color: '#3498DB' }} />,
                  }}
                  values={{ percent: 50 }}
                />
              </Typography>
              <Box mt={1}>
                <Typography variant="caption">
                  {t('discount-box.description')}
                </Typography>
              </Box>
            </Box>
          </DialogContent>
        </Box>
        <DialogActions>
          <Button onClick={() => onButtonClick()} color="default">
            {t('common.back')}
          </Button>
          <Button onClick={onButton2Click} style={{ color: '#2B75B3' }}>
            {t('common.upgrade')}
          </Button>
        </DialogActions>
      </Box>
    ),
    [onButton2Click, onButtonClick, t, classes]
  );

  const open = React.useCallback(() => {
    openModal({
      content: element,
      handleClose: closeModal,
    });
    datadogRum.addAction('modal duel limit freemium', {
      type: 'open',
    });
  }, [openModal, element, closeModal]);

  return { open, close: closeModal };
};
