import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Box, Chip, Typography } from '@material-ui/core';
import CheckCircleIcon from '../../../../components/icons/check-circle';

import { useStyles } from './styles';
import { CURRENCY } from '@/utils/constants';

type PlanItemProps = {
  id: string;
  title: string;
  subtitle?: string;
  price?: string | number | null;
  profit?: string | number | null;
  save?: number | null;
  isChosen?: boolean | null;
  isFrosen?: boolean | null;
};

const PlanItem: React.FC<PlanItemProps> = ({
  id,
  title,
  subtitle,
  price,
  profit,
  save,
  isChosen,
  isFrosen,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={clsx(classes.plan, isChosen && classes.planChosen)}>
      {save && (
        <Chip
          label={`${t('common.save')} ${save}%`}
          color="primary"
          size="small"
          className={classes.chipGolden}
        />
      )}
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography variant="subtitle1">{t(title)}</Typography>
          {subtitle && (
            <Typography variant="caption" style={{ color: '#4A647C' }}>
              {t(subtitle)}
            </Typography>
          )}
        </Box>
        <Box
          display="grid"
          alignItems="center"
          gridTemplateColumns="auto auto"
          gridColumnGap={4}
          ml={2}
          fontSize={16}
          color="#2C3E50"
        >
          {price && (
            <Typography
              color="inherit"
              variant="caption"
              style={{ fontSize: 'inherit' }}
            >
              {price}
              {CURRENCY}
            </Typography>
          )}
          {profit && (
            <Typography
              color="textSecondary"
              variant="caption"
              style={{ fontSize: 'inherit' }}
            >
              ({profit}
              {CURRENCY}/{t('payment.month')})
            </Typography>
          )}
        </Box>
        <Box ml="auto">
          {!isFrosen ? (
            <Box
              className={clsx(classes.radio, isChosen && classes.radioActive)}
            >
              {isChosen && <Box className={classes.radioInner} />}
            </Box>
          ) : (
            <CheckCircleIcon htmlColor="#F2C94D" style={{ marginRight: 6 }} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PlanItem;
