import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';

import { useStyles } from './styles';
import { plansConfig } from '../../subscriptions/plansConfig';
import { CURRENCY } from '@/utils/constants';

type DiscountBoxProps = {
  planId: string;
  hideTotalAmount?: boolean | undefined;
};

const DiscountBox: React.FC<DiscountBoxProps> = ({
  planId,
  hideTotalAmount,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const choosedPlan = plansConfig.find((it) => it.id === planId);

  if (!choosedPlan) return <></>;

  return (
    <Box className={classes.discountBox}>
      <Box className={classes.discountBoxHeader}>
        <Typography variant="caption" color="inherit">
          {t(choosedPlan.paymentProfitTitle || '')}
        </Typography>
        <Typography variant="caption" color="inherit">
          {choosedPlan.paymentProfitValue}
          {CURRENCY}
        </Typography>
      </Box>
      {!hideTotalAmount && (
        <Box mt={3}>
          <Typography variant="caption" color="inherit">
            {t('subscription.total-amount')}
          </Typography>
          <Box display="flex" alignItems="baseline">
            <Typography className={classes.priceTitle} color="inherit">
              {choosedPlan.discountPrice}
              {CURRENCY}
            </Typography>
            {choosedPlan.discountProfit && (
              <Typography
                color="inherit"
                variant="body1"
                style={{ marginLeft: 5 }}
              >
                ({choosedPlan.discountProfit}
                {CURRENCY}/{t('payment.month')})
              </Typography>
            )}
          </Box>
        </Box>
      )}
      {choosedPlan.id.includes('Monthly') && (
        <Box mt={3}>
          <Typography variant="caption">
            {t('subscription.payment-info', {
              discountPrice: choosedPlan.discountPrice,
              price: choosedPlan.price,
            })}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default DiscountBox;
