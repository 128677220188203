import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import { NotificationType, useReadNotificationMutation } from '@/graphql';
import {
  LS_KEY_IS_PAY_CONF_ACTIVE,
  LS_KEY_PAY_START_PREMIUM_PRESSED,
} from '@/utils/constants';
import { SripeErrorsEnum } from '@/type';

export const useConfirmPayment = () => {
  const history = useHistory();
  const stripe = useStripe();
  const [readNotification] = useReadNotificationMutation();

  const handlePaymentConfirmation = useCallback(
    (notification: NotificationType) => {
      if (
        (notification?.id && history.location.pathname.includes('payment')) ||
        history.location.pathname.includes('network') ||
        history.location.pathname.includes('subscription') ||
        history.location.pathname.includes('notifications')
      ) {
        const notifData = JSON.parse(notification?.data);
        localStorage.setItem(LS_KEY_IS_PAY_CONF_ACTIVE, 'true');
        stripe
          ?.confirmCardPayment(notifData?.client_secret, {
            setup_future_usage: 'off_session',
          })
          .then(function (result) {
            localStorage.removeItem(LS_KEY_PAY_START_PREMIUM_PRESSED);
            if (result.error) {
              console.log(result.error);
              // this replace breaks redirection logic
              history.replace(
                `${history?.location?.pathname}${history?.location?.search}`,

                {
                  isStripeError:
                    result.error &&
                    result.error.type !== SripeErrorsEnum.CARD &&
                    result.error.type !== SripeErrorsEnum.AUTHENTICATION &&
                    result.error.type !== SripeErrorsEnum.INVALID_REQUEST
                      ? true
                      : false,
                  isStripeCardError:
                    result.error.type === SripeErrorsEnum.CARD ? true : false,
                  isStripeAuthentificationError:
                    result.error.type === SripeErrorsEnum.AUTHENTICATION
                      ? true
                      : false,
                  isStripeInvalidRequestError:
                    result.error.type === SripeErrorsEnum.INVALID_REQUEST
                      ? true
                      : false,
                  isRedirectedFromNotifications: true,
                }
              );
            }
            localStorage.removeItem(LS_KEY_IS_PAY_CONF_ACTIVE);
            readNotification({
              variables: { id: notification?.id },
            });
          });
      }
    },
    [readNotification, history, stripe]
  );

  return {
    handlePaymentConfirmation,
  };
};
