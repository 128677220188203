import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { Box, Paper, Typography } from '@material-ui/core';
import AppButton from '@/components/app-button';
import StackLayout from '../../components/stack-layout';
import { plansConfig } from '../plansConfig';
import PlanItem from '../plan-item';
import {
  useChangePlanMutation,
  UserSubscriptionDocument,
  useUserSubscriptionQuery,
} from '@/graphql';
import AlertIcon from '@/components/icons/alert';
import { useUser } from '@/contexts/user-context';
import CurrentPaymentMethod from '../current-payment-method';
import PaymentProcessing from '../payment-processing';
import { SubscriptionStatus, SubscriptionTypes } from '@/type';
import { stripeBackendErrorsCodes } from '@/utils/helpers';
import DiscountBox from '../../components/discount-box';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { useStyles } from './styles';
import { LS_KEY_IS_PAY_CONF_ACTIVE } from '@/utils/constants';

const ChangeSubscriptionPayment: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { t } = useTranslation();
  const { user } = useUser();
  const { subsDiscountsEnabled } = useFeatureFlags();
  const classes = useStyles();
  const [error, setError] = useState<string | null>(null);
  const [processingVisibility, setProcessingVisibility] = useState(false);
  const [formWasSubmitted, setFormWasSubmitted] = useState(false);

  const { data } = useUserSubscriptionQuery();
  useEffect(() => {
    if (
      data?.me?.customer?.subscription?.status ===
        SubscriptionStatus.INCOMPLETE ||
      data?.me?.customer?.subscription?.upgradeStatus ===
        SubscriptionStatus.INCOMPLETE
    )
      setProcessingVisibility(true);
  }, [data]);

  const backLink = useMemo(() => {
    const historyState: any = history.location.state;
    if (historyState?.isRedirectedFromNetwork) return '/my-profile/network';
    else if (historyState?.isRedirectedFromNotifications)
      return '/notifications';
    else return undefined;
  }, [history]);

  useEffect(() => {
    const historyState: any = history.location.state;
    if (
      historyState?.isStripeError &&
      !historyState?.isStripeAuthentificationError &&
      !historyState?.isStripeInvalidRequestError
    ) {
      setError(t('payment.declined'));
    }
  }, [history.location, setError, t]);

  const params = new URLSearchParams(search);
  const planId = params.get('planId');
  const choosedPlan =
    plansConfig.find((it) => it.id === planId) || plansConfig[0];

  const [changePlan, { loading }] = useChangePlanMutation();

  useEffect(() => {
    if (formWasSubmitted) {
      let message = data?.me?.customer?.subscription?.lastError;
      const errorCode = data?.me?.customer?.subscription?.lastErrorCode;
      if (errorCode && stripeBackendErrorsCodes[errorCode]) {
        message = t(
          `payment.errors-codes.${stripeBackendErrorsCodes[errorCode]}`
        );
      } else if (errorCode) {
        setError(t('payment.declined'));
      }
      if (message) setError(message);
    }
  }, [data, formWasSubmitted, t]);

  const handleSubmit = useCallback(async () => {
    try {
      const res = await changePlan({
        variables: {
          subscriptionType: choosedPlan.id,
        },
        refetchQueries: [{ query: UserSubscriptionDocument }],
      });
      const subUpgradesStatus =
        res.data?.changeSubscription?.subscription?.upgradeStatus;
      if (
        subUpgradesStatus === SubscriptionStatus.ACTIVE ||
        subUpgradesStatus === SubscriptionStatus.TRIALING ||
        subUpgradesStatus === null
      ) {
        history.push('/my-profile/network', {
          prevPage: '/home',
        });
      } else if (subUpgradesStatus === SubscriptionStatus.INCOMPLETE) {
        setProcessingVisibility(true);
      } else {
        throw new Error(t('payment.declined'));
      }
    } catch (error) {
      console.log(JSON.stringify(error));
      const stripeErrorObj =
        error?.networkError?.result?.errors[0]?.extensions?.stripe;
      const errorCode = stripeErrorObj?.decline_code || stripeErrorObj?.code;
      if (stripeBackendErrorsCodes[errorCode]) {
        setError(
          t(`payment.errors-codes.${stripeBackendErrorsCodes[errorCode]}`)
        );
      } else {
        setError(t('payment.declined'));
      }
    } finally {
      setFormWasSubmitted(true);
    }
  }, [changePlan, history, t, choosedPlan.id]);

  return (
    <>
      <StackLayout
        title={t('subscription.change-subs-title')}
        back={backLink}
        backState={{
          prevPage: '/home',
        }}
      >
        <Box display="grid" height="100%">
          <Paper>
            <Box padding={4}>
              <div>
                {planId && <PlanItem {...choosedPlan} isChosen isFrosen />}
              </div>
              {!!subsDiscountsEnabled && planId && (
                <DiscountBox planId={planId} />
              )}
              <CurrentPaymentMethod />
              {error && (
                <Box marginTop={3} className={classes.errorBox}>
                  <AlertIcon />
                  <Box ml={2}>
                    <Typography variant="body2">{error}</Typography>
                  </Box>
                </Box>
              )}
              <Box marginTop={4}>
                <AppButton
                  fullWidth
                  color="primary"
                  variant="contained"
                  disabled={!user?.customer?.defaultCard?.last4}
                  loading={loading}
                  onClick={handleSubmit}
                >
                  {t(choosedPlan.btnText)}
                </AppButton>
              </Box>
              <Box marginTop={7}>
                <Paper variant="outlined">
                  <Box paddingX={6} paddingY={4} className={classes.root}>
                    <Typography variant="caption">
                      {t(
                        (subsDiscountsEnabled
                          ? choosedPlan.agr1Discounts
                          : choosedPlan.agr1) || '',
                        {
                          discountPrice: choosedPlan.discountPrice,
                          price: choosedPlan.price,
                        }
                      )}{' '}
                      {(subsDiscountsEnabled ||
                        (subsDiscountsEnabled === false &&
                          choosedPlan.id !== SubscriptionTypes.Code)) && (
                        <>
                          <Link
                            className={classes.link}
                            color="primary"
                            rel="noopener"
                            to="/privacy-agb"
                          >
                            <Typography
                              variant="caption"
                              component="span"
                              display="inline"
                              className={classes.link}
                            >
                              {t('payment.agreement-terms-and-conditions')}
                            </Typography>
                          </Link>{' '}
                          {t('payment.agreement-part2')}
                        </>
                      )}{' '}
                      <Link
                        className={classes.link}
                        color="primary"
                        rel="noopener"
                        to="/privacy-dataprotection"
                      >
                        <Typography
                          variant="caption"
                          component="span"
                          display="inline"
                          className={classes.link}
                        >
                          {t('payment.agreement-data-privacy')}
                        </Typography>
                      </Link>
                      .
                    </Typography>
                  </Box>
                </Paper>
              </Box>
            </Box>
          </Paper>
        </Box>
      </StackLayout>
      {(processingVisibility ||
        localStorage.getItem(LS_KEY_IS_PAY_CONF_ACTIVE)) && (
        <PaymentProcessing
          setVisibility={setProcessingVisibility}
          setError={setError}
          isUpgradingSubscription
        />
      )}
    </>
  );
};

export default ChangeSubscriptionPayment;
