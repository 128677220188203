import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import {
  useReadNotificationMutation,
  useSubscribeToNewsMutation,
  useUserMeQuery,
} from '@/graphql';
import {
  Box,
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Link,
} from '@material-ui/core';
import SmallLogoIcon from '@/components/icons/small-logo';
import { USER_FRAGMENT } from '@/apollo/fragments';

interface SubscribeNewslettersProps {
  notificationId: string;
}

const SubscribeNewslettersModal: React.FC<SubscribeNewslettersProps> = ({
  notificationId,
}) => {
  const client = useApolloClient();
  const [readNotification] = useReadNotificationMutation();
  const { t } = useTranslation();
  const [opened, setOpened] = useState(true);

  const { data } = useUserMeQuery({
    fetchPolicy: 'cache-first',
  });

  const [subscribe] = useSubscribeToNewsMutation();
  const userId = data?.me?.id;

  const onClose = useCallback(() => {
    if (notificationId) {
      const readNotificationWithError = async () => {
        try {
          await readNotification({
            variables: { id: notificationId },
          });
        } catch (error) {
          console.log(JSON.stringify(error));
        }
      };
      readNotificationWithError();
      setOpened(false);
    }
  }, [notificationId, readNotification]);

  const subscribeToNewsletters = useCallback(async () => {
    try {
      await subscribe({
        variables: {
          userId,
        },
      });
      client.writeFragment({
        id: `${data?.me?.__typename}:${data?.me?.id}`,
        fragment: USER_FRAGMENT,
        data: {
          ...data?.me,
          subscribeToNews: !data?.me?.subscribeToNews,
        },
      });
    } catch (error) {
      console.log(JSON.stringify(error));
    } finally {
      onClose();
    }
  }, [subscribe, userId, onClose, client, data]);

  return (
    <Dialog
      data-cy-subscribe-newsletters-dialog
      open={opened}
      onClose={onClose}
    >
      <DialogTitle style={{ paddingBottom: 0 }}>
        <Box marginBottom={3}>
          <SmallLogoIcon />
        </Box>
        {t('dialogs.subscribe-newsletters.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText variant="body2">
          <Box component="span" marginBottom={2}>
            <Trans
              i18nKey="dialogs.subscribe-newsletters.text"
              components={{
                link1: (
                  <Link
                    to="/privacy-agb"
                    component={RouterLink}
                    color="primary"
                  />
                ),
              }}
            />
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box
          width="100%"
          display="grid"
          gridRowGap={12}
          flexDirection="column"
          padding={4}
          paddingTop={0}
        >
          <Button
            data-cy-subscribe-btn
            onClick={subscribeToNewsletters}
            color="primary"
            variant="contained"
          >
            {t('dialogs.subscribe-newsletters.button')}
          </Button>

          <Button data-cy-later-btn variant="outlined" onClick={onClose}>
            {t('common.later')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default SubscribeNewslettersModal;
