import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box, Typography, IconButton, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useUser } from '@/contexts/user-context';
import BoxMagicIcon from '@/components/icons/box-magic';
import FingerClickIcon from '@/components/icons/finger-click';
import BulbIcon from '@/components/icons/bulb';
import BoxIntoIcon from '@/components/icons/box-into';
import { useStyles } from './styles';
import { ProfessionTypesEnum } from '@/type';

const ClassroomRules = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const { user } = useUser();

  const dataArr = [
    { key: '1', icon: <BoxMagicIcon /> },
    { key: '2', icon: <FingerClickIcon /> },
    { key: '3', icon: <BulbIcon /> },
    { key: '4', icon: <BoxIntoIcon /> },
  ];
  const isUserStudent =
    user?.profession?.type?.type === ProfessionTypesEnum.Student;

  const closePage = () =>
    history.replace(isUserStudent ? { pathname: '/biblio/' } : '/home/');

  return (
    <Box className={classes.root}>
      <IconButton
        aria-label="close"
        className={classes.close}
        onClick={closePage}
      >
        <CloseIcon htmlColor="#4A647C" />
      </IconButton>
      <Typography variant="h2">{t('classrooms.rules-page.title')}</Typography>
      <Box mt={5} mb={7}>
        {dataArr.map((it) => (
          <Box key={it.key} mt={2} className={classes.listItem}>
            <Box className={classes.iconBox}>{it.icon}</Box>
            <Box>
              <Typography variant="subtitle2">
                {t(`classrooms.rules-page.rule-${it.key}.caption`)}
              </Typography>
              <Typography variant="body2">
                {t(`classrooms.rules-page.rule-${it.key}.descr`)}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
      <Button fullWidth color="primary" variant="contained" onClick={closePage}>
        {t('common.lets-go')}
      </Button>
    </Box>
  );
};

export default ClassroomRules;
