import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import clsx from 'clsx';
import { Box, Grid } from '@material-ui/core';
import AppButton from '@/components/app-button';
import { useStyles } from './styles';
import { isString } from 'lodash';
import ErrorBlock from '../error-block';

type PaymentFormProps = {
  btnText: string;
  onSubmit: (token: string) => void;
  onSetExternalError: (error: string | null) => void;
  externalError?: string | null;
  externalLoading?: boolean | undefined;
};

const PaymentForm: React.FC<PaymentFormProps> = ({
  onSubmit,
  btnText,
  onSetExternalError,
  externalError,
  externalLoading,
  children,
}) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();
  const [error, setError] = useState<string | null>();
  const [focusedField, setFocusedField] = useState<string | null>(null);
  const [canContinue, setCanContinue] = useState(false);
  const [loading, setLoading] = useState(false);

  const [cardNumberCompleat, setCardNumberCompleat] = useState(false);
  const [cardExpiryCompleat, setCardExpiryCompleat] = useState(false);
  const [cardCvcCompleat, setCardCvcCompleat] = useState(false);

  const options = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#47525E',
        color: '#47525E',
        fontWeight: 400,
        fontFamily: 'Lato, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#47525E',
        },
        '::placeholder': {
          color: '#647485',
          fontFamily: 'Lato, sans-serif',
        },
      },
      invalid: {
        iconColor: '#EB5757',
        color: '#EB5757',
      },
    },
  };

  useEffect(() => {
    if (cardNumberCompleat && cardExpiryCompleat && cardCvcCompleat) {
      setCanContinue(true);
    } else {
      setCanContinue(false);
    }
  }, [cardNumberCompleat, cardExpiryCompleat, cardCvcCompleat]);

  const handleValidationError = (error: any) => {
    switch (error?.code) {
      default:
        setError(error?.message || '');
        break;
    }
  };
  const stripeOnChange = (event: any) => {
    switch (event.elementType) {
      case 'cardNumber':
        setCardNumberCompleat(event.complete);
        break;
      case 'cardExpiry':
        setCardExpiryCompleat(event.complete);
        break;
      case 'cardCvc':
        setCardCvcCompleat(event.complete);
        break;
      default:
        break;
    }
    if (externalError) onSetExternalError(null);
    handleValidationError(event?.error || '');
  };

  const handleSubmit = useCallback(async () => {
    const cardNumber = elements?.getElement(CardNumberElement);
    if (canContinue && cardNumber) {
      setLoading(true);
      try {
        const tokenRes = await stripe?.createToken(cardNumber);
        if (tokenRes?.error) handleValidationError(tokenRes?.error);
        if (tokenRes?.token && isString(tokenRes?.token?.id)) {
          onSubmit(tokenRes.token.id);
        }
      } catch (error) {
        console.log(JSON.stringify(error));
        setError(t('payment.declined'));
      } finally {
        setLoading(false);
      }
    }
  }, [canContinue, elements, stripe, t, onSubmit]);

  return (
    <>
      <Box>
        <div className="credit-card-stripe">
          <div className="credit-cards__front">
            <CardNumberElement
              id="cardNumber"
              onChange={stripeOnChange}
              options={{
                ...options,
                placeholder: t('payment.card.number'),
              }}
              className={clsx(
                classes.cardField,
                focusedField === 'number' && classes.cardFieldFocused
              )}
              onFocus={() => setFocusedField('number')}
              onBlur={() => setFocusedField(null)}
            />
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <CardExpiryElement
                  id="expiry"
                  onChange={stripeOnChange}
                  options={{
                    ...options,
                    placeholder: t('payment.card.exp-date'),
                  }}
                  className={clsx(
                    classes.cardField,
                    focusedField === 'expDate' && classes.cardFieldFocused
                  )}
                  onFocus={() => setFocusedField('expDate')}
                  onBlur={() => setFocusedField(null)}
                />
              </Grid>
              <Grid item xs={6}>
                <CardCvcElement
                  id="cvc"
                  onChange={stripeOnChange}
                  options={{
                    ...options,
                    placeholder: t('payment.card.cvv'),
                  }}
                  className={clsx(
                    classes.cardField,
                    focusedField === 'cvv' && classes.cardFieldFocused
                  )}
                  onFocus={() => setFocusedField('cvv')}
                  onBlur={() => setFocusedField(null)}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </Box>
      {(error || externalError) && (
        <ErrorBlock message={error || externalError || t('payment.declined')} />
      )}
      <Box marginTop={4}>
        {children}
        <AppButton
          fullWidth
          color="primary"
          variant="contained"
          disabled={!canContinue || !!error || !!externalError}
          loading={loading || externalLoading}
          onClick={handleSubmit}
        >
          {btnText}
        </AppButton>
      </Box>
    </>
  );
};

export default PaymentForm;
