import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Box, makeStyles, Typography } from '@material-ui/core';
import AppButton from '@/components/app-button';
import * as Form from '@/components/form';
import {
  useApplyNetworkCodeMutation,
  UserNetworkCodesDocument,
  useUserNetworkCodesQuery,
} from '@/graphql';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { SubscriptionTypes } from '@/type';

interface Form {
  code: string;
}

const useStyles = makeStyles({
  formWrapper: {
    '& .MuiFormLabel-asterisk': {
      display: 'none',
    },
    '& .MuiFormLabel-filled': {
      color: '#929DA8',
    },
  },
});

const ActivateCode = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const { register, setError, setValue, watch, errors } = useForm<Form>({
    mode: 'onChange',
    defaultValues: { code: '' },
  });

  const { data } = useUserNetworkCodesQuery();

  const [applyNetworkCode] = useApplyNetworkCodeMutation();

  const sendCode = useCallback(async () => {
    datadogRum.addAction('subscription', {
      event: `pay for plan "${SubscriptionTypes.Code}"`,
    });
    try {
      await applyNetworkCode({
        variables: {
          code: Number(watch('code')),
        },
        refetchQueries: [{ query: UserNetworkCodesDocument }],
      });
      const historyState: any = history.location.state;
      history.push(
        data?.codes?.length
          ? '/my-profile/network'
          : '/subscription-payment-congratulation',
        historyState
      );
    } catch (error) {
      setError('code', {
        type: 'server',
        message: t('fields.activation-code.validation.invalid'),
      });
    }
  }, [applyNetworkCode, setError, t, watch, history, data]);

  return (
    <>
      <Box mt={6}>
        <Typography variant="body2">
          {t('payment.activation-code.title')}
        </Typography>
        <Box mt={4} className={classes.formWrapper}>
          <Form.Row>
            <Form.TextField
              fullWidth
              required
              name="code"
              variant="outlined"
              label={t('subscription.activation-code')}
              errors={errors.code}
              onChange={(e) => {
                const val = e?.target?.value?.replace(/[^0-9]/g, '') || '';
                if (val && val.length > 6)
                  setError('code', {
                    type: 'string',
                    message: t(
                      'fields.activation-code.validation.invalid-digits'
                    ),
                  });
                setValue('code', val);
              }}
              inputRef={register()}
            />
          </Form.Row>
        </Box>
        <Box marginTop={4}>
          <AppButton
            fullWidth
            data-cy-forgot-btn
            color="primary"
            variant="contained"
            disabled={!isEmpty(errors) || watch('code').length < 6}
            onClick={sendCode}
          >
            {t('payment.start')}
          </AppButton>
        </Box>
      </Box>
    </>
  );
};

export default ActivateCode;
