import { CircularProgress } from '@material-ui/core';
import React from 'react';

import { useStyles } from './styles';

type QuestionImageProps = {
  src?: string;
  alt?: string;
};

const QuestionImage: React.FC<QuestionImageProps> = ({ src, alt, ...rest }) => {
  const classes = useStyles();
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const handleErrro = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    if (event) event.currentTarget.style.display = 'none';
  };
  const handleImageLoaded = () => {
    setLoading(false);
  };

  return !!src ? (
    <div
      className={classes.root}
      style={{
        height: isLoading ? '100%' : 'auto',
      }}
    >
      {isLoading && <CircularProgress className={classes.progressWrap} />}
      <img
        src={src}
        className={classes.image}
        style={{
          visibility: isLoading ? 'hidden' : 'visible',
        }}
        alt={alt}
        onError={handleErrro}
        onLoad={handleImageLoaded}
      />
    </div>
  ) : (
    <></>
  );
};

export default QuestionImage;
