import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
} from '@material-ui/core';
import { useDeleteUserAccountMutation } from '@/graphql';
import { useAuth } from '@/contexts/auth-context';
import StackLayout from '@/screens/main/components/stack-layout';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import DuelSettings from './duel-settings';
import DailyTipperSettings from './daily-tipper-settings';
import { useStyles } from './styles';

import NewsLetters from './newsletters-settings';
import PushNotificationsSettings from './push-notifications-settings';

const Settings: React.FC<unknown> = () => {
  const { cache } = useApolloClient();
  const { signOut } = useAuth();
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const { dailyTipperEnabled } = useFeatureFlags();
  const [deleteAccountMutation] = useDeleteUserAccountMutation();

  const [isOpen, setIsOpen] = useState(false);
  const openLeaveDialog = useCallback(() => {
    setIsOpen(true);
  }, []);

  const deleteAccount = useCallback(async () => {
    try {
      const res = await deleteAccountMutation();
      if (res.data?.deleteAccount?.isDeleted) {
        cache.reset();
        signOut();
        return history.push(`/`);
      }
    } catch (err) {
      console.log(err);
    }
  }, [history, deleteAccountMutation, cache, signOut]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <StackLayout title={t('settings.title')}>
      <Paper elevation={0} className={classes.root}>
        <DuelSettings />
        {dailyTipperEnabled && <DailyTipperSettings />}
        {!!window?.ReactNativeWebView && <PushNotificationsSettings />}
        <NewsLetters />
        <Box data-cy-classroom-settings>
          <Box mb={2}>
            <Typography variant="subtitle2">
              {t('settings.remove-account.title')}
            </Typography>
          </Box>
          <Box mb={6}>
            <Typography variant="caption" style={{ color: '#647485' }}>
              {t('settings.remove-account.help-text')}
            </Typography>
          </Box>
          <Box color="error.main">
            <Button
              color="inherit"
              onClick={openLeaveDialog}
              className={classes.btnLeave}
            >
              {t('settings.remove-account.title')}
            </Button>
          </Box>
        </Box>
      </Paper>

      <Dialog data-cy-welcome-dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>{t('settings.remove-account.title')}</DialogTitle>
        <DialogContent>
          <Box marginBottom={5}>
            <Typography variant="body2">
              {t('settings.remove-account.dialog-info')}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            data-cy-later-btn
            onClick={handleClose}
            style={{ color: '#647485' }}
          >
            {t('common.cancel')}
          </Button>
          <Box color="error.main">
            <Button
              data-cy-enter-code-btn
              onClick={deleteAccount}
              color="inherit"
            >
              {t('common.remove')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </StackLayout>
  );
};

export default Settings;
