import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';

import { Box, Divider, Switch, Typography } from '@material-ui/core';
import { USER_SETTINGS_FRAGMENT } from '@/apollo/fragments';
import { useUpdateDuelsSettingsMutation, useUserMeQuery } from '@/graphql';
import { SettingsField } from '@/type';
import { useStyles } from '../styles';

const DuelSettings: React.FC = () => {
  const classes = useStyles();
  const client = useApolloClient();
  const { t } = useTranslation();

  const { data } = useUserMeQuery({
    fetchPolicy: 'cache-first',
  });
  const [update] = useUpdateDuelsSettingsMutation();

  const changeField = useCallback(
    (checked: boolean, field: SettingsField) => {
      const duelMode =
        field === SettingsField.mode ? checked : !!data?.me?.settings?.duelMode;

      client.writeFragment({
        id: `${data?.me?.settings?.__typename}:${data?.me?.settings?.id}`,
        fragment: USER_SETTINGS_FRAGMENT,
        data: {
          ...data?.me?.settings,
          duelMode,
        },
      });

      try {
        update({
          variables: {
            mode: duelMode,
          },
        });
      } catch (err) {
        console.log(err);
      }
    },
    [update, client, data]
  );

  return (
    <Box data-cy-duel-settings>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle2">{t('settings.duel.title')}</Typography>
        <Switch
          color="primary"
          disabled={!!data?.me?.settings?.activeQuizTime?.id}
          checked={!!data?.me?.settings?.duelMode}
          onChange={(e, checked) => changeField(checked, SettingsField.mode)}
        />
      </Box>
      <Typography variant="caption" component="p" className={classes.helpText}>
        {t('settings.duel.help-text')}
      </Typography>
      <Box mt={6} mb={4}>
        <Divider />
      </Box>
    </Box>
  );
};

export default DuelSettings;
