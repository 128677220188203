import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { Box, Paper, Typography } from '@material-ui/core';
import {
  usePlanSubscribeMutation,
  UserSubscriptionDocument,
  useUserSubscriptionQuery,
} from '@/graphql';
import StackLayout from '../../components/stack-layout';
import { useStyles } from './styles';
import { plansConfig } from '../plansConfig';
import PlanItem from '../plan-item';
import ActivateCode from '../activate-code';
import PaymentForm from '../payment-form';
import { SubscriptionStatus, SubscriptionTypes } from '@/type';
import PaymentProcessing from '../payment-processing';
import CurrentPaymentMethod from '../current-payment-method';
import { useUser } from '@/contexts/user-context';
import AppButton from '@/components/app-button';
import ErrorBlock from '../error-block';
import refetchQueries from 'refetch-queries';
import { useApolloClient } from '@apollo/client';
import { stripeBackendErrorsCodes } from '@/utils/helpers';
import DiscountBox from '../../components/discount-box';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import {
  LS_KEY_IS_PAY_CONF_ACTIVE,
  LS_KEY_PAY_START_PREMIUM_PRESSED,
} from '@/utils/constants';
import { datadogRum } from '@datadog/browser-rum';

const Payment: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { user } = useUser();
  const { t, i18n } = useTranslation();
  const { subsDiscountsEnabled } = useFeatureFlags();
  const classes = useStyles();
  const client = useApolloClient();

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [formWasSubmitted, setFormWasSubmitted] = useState(false);
  const [processingVisibility, setProcessingVisibility] = useState(false);
  const recaptchaRef = useRef<any>();
  const userHasPaymentMethod = user?.customer?.defaultCard?.last4;

  const { data } = useUserSubscriptionQuery();
  useEffect(() => {
    if (
      data?.me?.customer?.subscription?.status === SubscriptionStatus.INCOMPLETE
    )
      setProcessingVisibility(true);
  }, [data]);

  const backLink = useMemo(() => {
    const historyState: any = history.location.state;
    if (historyState?.isRedirectedFormPlanChooser)
      return '/choose-subscription';
    else if (historyState?.isRedirectedFromNotifications)
      return '/notifications';
    else return undefined;
  }, [history]);

  useEffect(() => {
    if (formWasSubmitted) {
      let message = data?.me?.customer?.subscription?.lastError;
      const errorCode = data?.me?.customer?.subscription?.lastErrorCode;
      if (errorCode && stripeBackendErrorsCodes[errorCode]) {
        message = t(
          `payment.errors-codes.${stripeBackendErrorsCodes[errorCode]}`
        );
      } else if (errorCode) {
        setError(t('payment.declined'));
      }
      if (message) {
        setError(message);
      }
    }
  }, [data, formWasSubmitted, t]);

  const params = new URLSearchParams(search);
  const planId = params.get('planId');
  const choosedPlan =
    plansConfig.find((it) => it.id === planId) || plansConfig[0];

  const [planSubscribe] = usePlanSubscribeMutation();

  const handleSubmit = useCallback(
    async (token?: string) => {
      datadogRum.addAction('subscription', {
        event: `pay for plan "${choosedPlan.id}"`,
      });
      setLoading(true);
      const recaptchaToken = await recaptchaRef.current?.executeAsync();
      const historyState: any = history.location.state;
      try {
        const res = await planSubscribe({
          variables: {
            recaptchaToken,
            stripeToken: token,
            subscriptionType: choosedPlan.id,
          },
          refetchQueries: [{ query: UserSubscriptionDocument }],
        });
        const subsStatus = res.data?.subscribe?.subscription?.status;
        if (
          subsStatus === SubscriptionStatus.ACTIVE ||
          subsStatus === SubscriptionStatus.TRIALING
        ) {
          history.push('/subscription-payment-congratulation', historyState);
        } else if (subsStatus === SubscriptionStatus.INCOMPLETE) {
          setProcessingVisibility(true);
        } else {
          throw new Error(t('payment.declined'));
        }
      } catch (error) {
        refetchQueries(client, [
          {
            query: UserSubscriptionDocument,
          },
        ]);
        const stripeErrorObj =
          error?.networkError?.result?.errors[0]?.extensions?.stripe;
        const errorCode = stripeErrorObj?.decline_code || stripeErrorObj?.code;
        if (stripeBackendErrorsCodes[errorCode]) {
          setError(
            t(`payment.errors-codes.${stripeBackendErrorsCodes[errorCode]}`)
          );
        } else {
          setError(t('payment.declined'));
        }
      } finally {
        setLoading(false);
        setFormWasSubmitted(true);
      }
    },
    [history, choosedPlan, planSubscribe, t, client]
  );

  return (
    <>
      <StackLayout title={t('payment.title')} back={backLink}>
        <Box display="grid" height="100%">
          <Paper>
            <Box padding={4}>
              <div>
                {choosedPlan?.id && (
                  <>
                    <PlanItem {...choosedPlan} isChosen isFrosen />
                    {!!subsDiscountsEnabled &&
                      choosedPlan.id !== SubscriptionTypes.Code &&
                      planId && <DiscountBox planId={planId} />}
                  </>
                )}
              </div>
              {choosedPlan?.id === SubscriptionTypes.Code ? (
                <ActivateCode />
              ) : userHasPaymentMethod ? (
                <>
                  <CurrentPaymentMethod />
                  {error && (
                    <ErrorBlock message={error || t('payment.declined')} />
                  )}
                  <Box marginTop={4}>
                    {process.env.REACT_APP_INVISIBLE_RECAPTCHA_SITE_KEY && (
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        hl={i18n.language}
                        size="invisible"
                        sitekey={
                          process.env.REACT_APP_INVISIBLE_RECAPTCHA_SITE_KEY
                        }
                      />
                    )}
                    <AppButton
                      fullWidth
                      color="primary"
                      variant="contained"
                      loading={loading}
                      onClick={() => {
                        // reset old stripe errors - dummy implementation as state management refactoring is needed anyway
                        const historyState: any = history.location.state;

                        delete historyState.isStripeError;
                        delete historyState.isStripeInvalidRequestError;
                        delete historyState.isStripeAuthentificationError;
                        delete historyState.isStripeCardError;

                        history.replace(
                          `${history?.location?.pathname}?planId=${choosedPlan?.id}`,
                          historyState
                        );
                        localStorage.setItem(
                          LS_KEY_PAY_START_PREMIUM_PRESSED,
                          'true'
                        );
                        handleSubmit();
                      }}
                      style={{ color: '#fff' }}
                    >
                      {t('payment.start')}
                    </AppButton>
                  </Box>
                </>
              ) : (
                <>
                  <Box mt={6}>
                    <Typography variant="subtitle2">
                      {t('payment.method')}
                    </Typography>
                  </Box>

                  <PaymentForm
                    btnText={t('payment.start')}
                    onSubmit={(token) => {
                      // reset old stripe errors - dummy implementation as state management refactoring is needed anyway
                      const historyState: any = history.location.state;

                      delete historyState.isStripeError;
                      delete historyState.isStripeInvalidRequestError;
                      delete historyState.isStripeAuthentificationError;
                      delete historyState.isStripeCardError;

                      history.replace(
                        `${history?.location?.pathname}?planId=${choosedPlan?.id}`,
                        historyState
                      );
                      localStorage.setItem(
                        LS_KEY_PAY_START_PREMIUM_PRESSED,
                        'true'
                      );
                      handleSubmit(token);
                    }}
                    onSetExternalError={setError}
                    externalError={error}
                    externalLoading={loading}
                  >
                    {process.env.REACT_APP_INVISIBLE_RECAPTCHA_SITE_KEY && (
                      <Box mb={2}>
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          hl={i18n.language}
                          size="invisible"
                          sitekey={
                            process.env.REACT_APP_INVISIBLE_RECAPTCHA_SITE_KEY
                          }
                        />
                      </Box>
                    )}
                  </PaymentForm>
                </>
              )}
              <Box marginTop={7}>
                <Paper variant="outlined">
                  <Box paddingX={5} paddingY={4} className={classes.root}>
                    <Typography variant="caption" color="textPrimary">
                      {t(
                        (subsDiscountsEnabled
                          ? choosedPlan.agr1Discounts
                          : choosedPlan.agr1) || '',
                        {
                          discountPrice: choosedPlan.discountPrice,
                          price: choosedPlan.price,
                        }
                      )}{' '}
                      {(subsDiscountsEnabled ||
                        (subsDiscountsEnabled === false &&
                          choosedPlan.id !== SubscriptionTypes.Code)) && (
                        <>
                          <Link
                            className={classes.link}
                            color="primary"
                            rel="noopener"
                            to="/privacy-agb"
                          >
                            <Typography
                              variant="caption"
                              component="span"
                              display="inline"
                              className={classes.link}
                            >
                              {t('payment.agreement-terms-and-conditions')}
                            </Typography>
                          </Link>{' '}
                          {t('payment.agreement-part2')}
                        </>
                      )}{' '}
                      <Link
                        className={classes.link}
                        color="primary"
                        rel="noopener"
                        to="/privacy-dataprotection"
                      >
                        <Typography
                          variant="caption"
                          component="span"
                          display="inline"
                          className={classes.link}
                        >
                          {t('payment.agreement-data-privacy')}
                        </Typography>
                      </Link>
                      .
                    </Typography>
                  </Box>
                </Paper>
              </Box>
            </Box>
          </Paper>
        </Box>
      </StackLayout>
      {(processingVisibility ||
        localStorage.getItem(LS_KEY_IS_PAY_CONF_ACTIVE)) && (
        <PaymentProcessing
          setVisibility={setProcessingVisibility}
          setError={setError}
        />
      )}
    </>
  );
};

export default Payment;
