import { ApolloClient, ApolloLink, InMemoryCache, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { createUploadLink } from 'apollo-upload-client';

import { getAuth } from './contexts/auth-context';

require('isomorphic-fetch');

const authLink = new ApolloLink((operation, forward) => {
  const { token } = getAuth();
  if (!token && operation.operationName === 'userMe') return null;
  operation.setContext(({ headers }: { headers: Record<string, string> }) => ({
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : '',
    },
  }));

  return forward(operation);
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_SOCKET_URL || '',
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: () => {
      const { token } = getAuth();
      return { token };
    },
  },
});

const httpLink = createUploadLink({
  fetch,
  uri: `${process.env.REACT_APP_BACKEND_URL}/graphql/`,
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);

    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);
export default new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache({
    typePolicies: {
      OngoingUserLearningSessionType: {
        keyFields: ['learningSession', ['id']],
      },
    },
  }),
});
