import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { PurchasesStoreProduct } from 'react-native-purchases';
import { useTranslation } from 'react-i18next';

interface SubscriptionOptionProps {
  product: PurchasesStoreProduct;
  isSelected: boolean;
  onSelect: () => void;
}
const useStyles = makeStyles((theme) => ({
  subscriptionOption: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: 8,
    padding: 16,
    border: '1.5px solid transparent',
    borderRadius: 4,
    marginBottom: 10,
    backgroundColor: '#3498DB0A',
  },
  selectedSubscriptionOption: {
    borderColor: theme.palette.primary.main,
  },
  subscriptionProfit: {
    position: 'absolute',
    right: 0,
    top: 0,
    backgroundColor: theme.palette.primary.main,
    padding: '4px 10px',
    borderBottomLeftRadius: 4,
  },
  subscriptionCheck: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 16,
    height: 16,
    borderRadius: '50%',
    border: `1.5px solid ${theme.palette.text.secondary}`,
  },
  subscriptionCheckSelected: {
    borderColor: theme.palette.primary.main,
  },
  subscriptionCheckDot: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const SubscriptionOption: React.FC<SubscriptionOptionProps> = ({
  product,
  isSelected,
  onSelect,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box
      onClick={onSelect}
      className={clsx(
        classes.subscriptionOption,
        isSelected && classes.selectedSubscriptionOption
      )}
    >
      {product.subscriptionPeriod === 'P1Y' && (
        <Box className={classes.subscriptionProfit}>
          <Typography variant="subtitle2" style={{ color: '#fff' }}>
            Spare 17%
          </Typography>
        </Box>
      )}
      <Box
        className={clsx(
          classes.subscriptionCheck,
          isSelected && classes.subscriptionCheckSelected
        )}
      >
        {isSelected && <Box className={classes.subscriptionCheckDot}></Box>}
      </Box>
      <Typography variant="body1">
        {t(
          product.subscriptionPeriod === 'P1Y'
            ? 'subscription.annual'
            : 'subscription.monthly'
        )}
      </Typography>
      <Typography variant="h2">{product.priceString}</Typography>
    </Box>
  );
};

export default SubscriptionOption;
