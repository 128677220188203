import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  useLeaveNetworkClassroomMutation,
  ClassRoomsOngoingDocument,
  SpecialityGroupListDocument,
} from '@/graphql';

import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type ClassroomLeaveDialogParams = {
  sessionId: string;
  userSessionSettingId: string;
};

const ClassroomLeaveDialog = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { sessionId, userSessionSettingId } =
    useParams<ClassroomLeaveDialogParams>();

  const [leaveNetworkClassroomMutation] = useLeaveNetworkClassroomMutation();

  const leaveNetworkClassroom = useCallback(async () => {
    try {
      leaveNetworkClassroomMutation({
        variables: {
          userSessionSettingsId: userSessionSettingId,
        },
        refetchQueries: [
          { query: ClassRoomsOngoingDocument },
          { query: SpecialityGroupListDocument },
        ],
      });
      return history.push(`/classrooms/`);
    } catch (err) {
      console.log(err);
    }
  }, [userSessionSettingId, leaveNetworkClassroomMutation, history]);

  const handleClose = useCallback(() => {
    history.push(`/classroom/${sessionId}`, {
      fromCongratulation: true,
    });
  }, [history, sessionId]);

  return (
    <Dialog
      data-cy-leave-dialog
      open={!!sessionId && !!userSessionSettingId}
      onClose={handleClose}
    >
      <DialogContent>
        <Typography variant="h2">{t('classrooms.leave-room2')}</Typography>
        <Box mt={4} mb={4}>
          <Typography variant="body2">
            {t('classrooms.leave-room2-dialog-info')}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          data-cy-later-btn
          onClick={handleClose}
          variant="text"
          style={{ color: '#647485' }}
        >
          {t('common.back')}
        </Button>
        <Box color="error.main">
          <Button
            data-cy-enter-code-btn
            onClick={leaveNetworkClassroom}
            variant="text"
            color="primary"
          >
            {t('common.end')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ClassroomLeaveDialog;
