import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import sortBy from 'lodash/sortBy';
import { Box, Paper } from '@material-ui/core';
import { useUserDuelQuery, useUserMeQuery } from '@/graphql';
import { useStyles } from './styles';

const QuizCreateRound: React.FC = () => {
  const history = useHistory();
  const { data: userMeData } = useUserMeQuery({ fetchPolicy: 'cache-first' });

  const { duelId, sessionId } =
    useParams<{
      duelId: string;
      sessionId: string;
    }>();
  const classes = useStyles();
  const [count, setCount] = useState(3);
  const { data } = useUserDuelQuery({ variables: { duelId } });

  const handleStart = useCallback(() => {
    if (data?.duelData?.rounds?.length) {
      const roundId = data?.duelData?.rounds[0]?.id;
      const firstQ = sortBy(
        (data?.duelData?.rounds[0]?.userQuestions || []).filter(
          (it) => it.user?.id === userMeData?.me?.id
        ),
        ['questionNumber']
      )[0];
      return history.push(
        `/quiz-duel/${duelId}/round/${roundId}/question/${firstQ.id}/session/${sessionId}`
      );
    }
  }, [data, duelId, history, userMeData, sessionId]);

  const startCounter = useCallback(() => {
    const counterId = setInterval(() => {
      if (count <= 1) {
        clearInterval(counterId);
        handleStart();
        return;
      }
      setCount(count - 1);
    }, 1000);
    return counterId;
  }, [count, setCount, handleStart]);

  useEffect(() => {
    const counterId = startCounter();
    return () => {
      if (counterId) clearInterval(counterId);
    };
  }, [startCounter]);

  return (
    <Box
      clone
      display="grid"
      gridTemplateRows="1fr auto"
      gridGap={16}
      flexGrow={1}
      paddingX={2}
      paddingY={5}
    >
      <Paper data-cy-create-round elevation={0}>
        <Box>
          <Box paddingX={4} paddingBottom={5}>
            <Box marginTop={3} className={classes.count}>
              {count && count}
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default QuizCreateRound;
