import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { Paper, Typography } from '@material-ui/core';
import BattleIcon from '@/components/icons/battle';
import { useCreateDuel } from '@/hooks/useCreateDuel';
import AppButton from '@/components/app-button';
import { useApolloClient } from '@apollo/client';
import {
  useUpdateIsIntroCompletedSettingsMutation,
  useUserMeQuery,
} from '@/graphql';
import { USER_SETTINGS_FRAGMENT } from '@/apollo/fragments';
import { LS_KEY_LAST_ONBOARD_PAGE } from '@/utils/constants';

const OnboardFirstDuel: React.FC = () => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const [update, { loading: udatingLoading }] =
    useUpdateIsIntroCompletedSettingsMutation();
  const { handleCreateDuel, creatingLoading } = useCreateDuel({
    isCustomDuel: true,
  });
  const { data } = useUserMeQuery();

  function finishIntroWalkthrough() {
    client.writeFragment({
      id: `${data?.me?.settings?.__typename}:${data?.me?.settings?.id}`,
      fragment: USER_SETTINGS_FRAGMENT,
      data: {
        ...data?.me?.settings,
        isIntroCompleted: true,
      },
    });

    try {
      update({
        variables: {
          ...data?.me?.settings,
          isIntroCompleted: true,
        },
      });
      localStorage.removeItem(LS_KEY_LAST_ONBOARD_PAGE);
      handleCreateDuel();
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Box
      p={2}
      display="grid"
      style={{
        height: '-webkit-fill-available',
        background: '#D7D7D7',
      }}
    >
      <Box clone px={2} py={6} display="flex" flexDirection="column">
        <Paper elevation={0}>
          <Box
            display="flex"
            flexDirection="column"
            flexGrow={1}
            justifyContent="center"
            alignItems="center"
            px={4}
            textAlign="center"
          >
            <Typography variant="h2">
              {t('onboard.first-duel.title')}
            </Typography>
            <BattleIcon />
          </Box>
          <Box pt={2}>
            <AppButton
              onClick={finishIntroWalkthrough}
              color="primary"
              variant="contained"
              fullWidth
              loading={udatingLoading || creatingLoading}
            >
              {t('onboard.first-duel.cta')}
            </AppButton>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default OnboardFirstDuel;
