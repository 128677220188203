import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import {
  useCreateUserDuelMutation,
  useDailyTipperDataQuery,
  useUpdateDuelsSettingsMutation,
} from '@/graphql';
import {
  useDuelCreateError4Modal,
  useDuelCreateError5Modal,
  useDuelCreateError3Modal,
} from '@/hooks/useModal';
import { useUser } from '@/contexts/user-context';
import { DuelErrorTypes, ProfessionTypesEnum, TabNavIdsEnum } from '@/type';
import { useSnackbarContext } from '@/contexts/snackbar-context';
import { useGetPremiumData } from './useGetPremiumData';
import { USER_SETTINGS_FRAGMENT } from '@/apollo/fragments';
import { LS_KEY_TABINDEX, PREMIUM_MAX_DUEL_COUNT } from '@/utils/constants';
import { useDuelLimitModal } from './useDuelLimitModal';

type MutationVars = {
  sessionId?: string;
  opponentId?: string;
  isCustomDuel?: boolean;
};

export const useCreateDuel = ({
  opponentId = '',
  sessionId = '',
  memberId = '',
  isCustomDuel = false,
}) => {
  const history = useHistory();
  const { cache } = useApolloClient();
  const { user, joinedClassRooms } = useUser();

  const { isUserPremium } = useGetPremiumData();
  const [updateDuelsSettings] = useUpdateDuelsSettingsMutation();

  const duelLimitModal = useDuelLimitModal();
  const duelCreateError3Modal = useDuelCreateError3Modal();
  const duelCreateError4Modal = useDuelCreateError4Modal();
  const duelCreateError5Modal = useDuelCreateError5Modal();

  const { setCustomSnack } = useSnackbarContext();
  const showDuelsLimitMessage = useCallback(() => {
    if (isUserPremium) {
      setCustomSnack({
        visibility: true,
        message: 'classrooms.snackBars.message-cr-duels-limit',
        messageOpts: {
          value: PREMIUM_MAX_DUEL_COUNT,
        },
      });
    } else {
      duelLimitModal.open();
    }
  }, [setCustomSnack, isUserPremium, duelLimitModal]);

  const mutationVariables: MutationVars = {};
  if (opponentId) {
    mutationVariables.opponentId = opponentId;
  }
  if (sessionId) {
    mutationVariables.sessionId = sessionId;
  }
  if (isCustomDuel) {
    mutationVariables.isCustomDuel = true;
  }
  const dtData = useDailyTipperDataQuery();
  const err = JSON.stringify(dtData.error?.networkError);
  const message = err && JSON.parse(err)?.result?.errors[0]?.message;

  const [createDuel, { loading: creatingLoading }] = useCreateUserDuelMutation({
    variables: mutationVariables,
  });

  const goToNetworkClassrooms = useCallback(() => {
    localStorage.setItem(`${TabNavIdsEnum.CLASSROOMS}${LS_KEY_TABINDEX}`, '1');
    history.replace('/classroom-rules/');
  }, [history]);

  const handleCreateDuel = useCallback(async () => {
    let isUserStudent = false;
    switch (user?.profession?.type?.type) {
      case ProfessionTypesEnum.Student:
        isUserStudent = true;
        break;
      case ProfessionTypesEnum.Trainee:
        isUserStudent = true;
        break;
      case ProfessionTypesEnum.TraineeToNurse:
        isUserStudent = true;
        break;
    }
    if (
      !isCustomDuel &&
      (message === DuelErrorTypes.ERROR_8 ||
        (!joinedClassRooms && isUserStudent))
    ) {
      goToNetworkClassrooms();
      return;
    }
    if (user?.settings?.activeQuizTime?.id) {
      setCustomSnack({
        visibility: true,
        message: 'quiztime.snackBars.snack-message-active',
        durationInMs: 10000,
      });
      return;
    }

    if (!user?.settings?.duelMode) {
      updateDuelsSettings({
        variables: {
          mode: true,
        },
      });
      cache.writeFragment({
        id: `${user?.settings?.__typename}:${user?.settings?.id}`,
        fragment: USER_SETTINGS_FRAGMENT,
        data: {
          ...user?.settings,
          duelMode: true,
        },
      });
    }
    try {
      const { data } = await createDuel();
      history.push(
        `/duel/${data?.createDuel?.duel?.id}/create-round${
          sessionId ? '/session/' + sessionId : ''
        }${memberId ? '/member/' + memberId : ''}`
      );
    } catch (error) {
      console.log(JSON.stringify(error));
      switch (
        error?.networkError?.result?.errors[0]?.message ||
        error?.message
      ) {
        case DuelErrorTypes.ERROR_1:
          setCustomSnack({
            visibility: true,
            message: 'classrooms.snackBars.snack-message-no-opponent-for-duel',
          });
          break;
        case DuelErrorTypes.ERROR_2:
          showDuelsLimitMessage();

          break;
        case DuelErrorTypes.ERROR_3:
          duelCreateError3Modal.open();
          break;
        case DuelErrorTypes.ERROR_4:
          duelCreateError4Modal.open();
          break;
        case DuelErrorTypes.ERROR_5:
          duelCreateError5Modal.open();
          break;
        case DuelErrorTypes.ERROR_8:
          goToNetworkClassrooms();
          break;
        default:
          console.log('Unknown error type');
      }
    }
    return;
  }, [
    history,
    duelCreateError3Modal,
    duelCreateError4Modal,
    duelCreateError5Modal,
    createDuel,
    memberId,
    sessionId,
    cache,
    user,
    goToNetworkClassrooms,
    updateDuelsSettings,
    showDuelsLimitMessage,
    setCustomSnack,
    message,
    joinedClassRooms,
    isCustomDuel,
  ]);

  return {
    handleCreateDuel,
    creatingLoading,
  };
};
