import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
  /**
   * Leverages the internal Python implmeentation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  UUID: any;
  /**
   * Create scalar that ignores normal serialization/deserialization, since
   * that will be handled by the multipart request spec
   */
  Upload: any;
};

export type AchievementType = {
  __typename?: 'AchievementType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  learningDays: Scalars['Int'];
  currentAchivement: Array<DailyTipperType>;
  nextAchievement: Array<DailyTipperType>;
};

export type ActiveDuelsType = {
  __typename?: 'ActiveDuelsType';
  id?: Maybe<Scalars['UUID']>;
  opponent?: Maybe<UserType>;
  isInitiator?: Maybe<Scalars['Boolean']>;
  round?: Maybe<DuelRoundType>;
  youTurn?: Maybe<Scalars['Boolean']>;
  session?: Maybe<ClassRoomLearningSessionType>;
};

export type ActiveQuizDuelsType = {
  __typename?: 'ActiveQuizDuelsType';
  id?: Maybe<Scalars['UUID']>;
  opponent?: Maybe<UserType>;
  round?: Maybe<DuelRoundType>;
  isInitiator?: Maybe<Scalars['Boolean']>;
  session?: Maybe<QuizTimeSessionType>;
};

/** An enumeration. */
export enum ActualLearnWeekStatus {
  /** Pending */
  Pending = 'PENDING',
  /** In Progress */
  InProgress = 'IN_PROGRESS',
  /** Finished */
  Finished = 'FINISHED',
  /** Ignored */
  Ignored = 'IGNORED',
  /** Skipped */
  Skipped = 'SKIPPED'
}

export type ActualLearnWeekType = {
  __typename?: 'ActualLearnWeekType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  status?: Maybe<ActualLearnWeekStatus>;
  weekNumber: Scalars['Int'];
  weeklyGoal: ActualLearnWeekWeeklyGoal;
  completedDays: Scalars['Int'];
  dailyTipper: DailyTipperType;
  correctAnswersCount: Scalars['Int'];
  isPunished: Scalars['Boolean'];
  wrongAnswersCount: Scalars['Int'];
  weekProgress: Scalars['JSONString'];
  dailytipperquestionanswerSet: Array<DailyTipperQuestionAnswerType>;
};

/** An enumeration. */
export enum ActualLearnWeekWeeklyGoal {
  /** 4 */
  A_4 = 'A_4',
  /** 5 */
  A_5 = 'A_5',
  /** 6 */
  A_6 = 'A_6',
  /** 7 */
  A_7 = 'A_7'
}

export type AddWaitingUser = {
  __typename?: 'AddWaitingUser';
  waitingUser?: Maybe<UserQuizSessionSettingsType>;
};

export type AggregatedRateType = {
  __typename?: 'AggregatedRateType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  averageRate?: Maybe<Scalars['Int']>;
  feedbackCount?: Maybe<Scalars['Int']>;
  lectorSet: Array<LectorType>;
  classroomSet: Array<ClassRoomType>;
  classroomlectureSet: Array<ClassRoomLectureType>;
};

export type AnswersType = {
  __typename?: 'AnswersType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  question?: Maybe<QuestionType>;
  value: Scalars['String'];
  isCorrect: Scalars['Boolean'];
  userLekQuestionAnswer: Array<UserLekQuestionAnswerDataType>;
  userLekQuestionAnswerData: Array<UserLekQuestionAnswerDataType>;
  dailytipperquestionanswerSet: Array<DailyTipperQuestionAnswerType>;
  questionCorrectAnswer: Array<DailyTipperQuestionAnswerType>;
  correctAnswer: Array<DuelRoundQuestionAnswerType>;
  userAnswer: Array<DuelRoundQuestionAnswerType>;
};

/** Apply Class Room Code Mutation. */
export type ApplyClassRoomCodeMutation = {
  __typename?: 'ApplyClassRoomCodeMutation';
  classRoom?: Maybe<ClassRoomLearningSessionType>;
};

/** Add Code Mutation. */
export type ApplyNetworkCodeForUserMutation = {
  __typename?: 'ApplyNetworkCodeForUserMutation';
  codes?: Maybe<Array<Maybe<CodesType>>>;
};

export type CancelSubscriptionMutation = {
  __typename?: 'CancelSubscriptionMutation';
  subscription?: Maybe<SubscriptionType>;
};

export type CanceledInAppSubscribeMutation = {
  __typename?: 'CanceledInAppSubscribeMutation';
  inAppSubscription?: Maybe<InAppSubscriptionType>;
};

export type CertificateType = {
  __typename?: 'CertificateType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  title?: Maybe<Scalars['String']>;
  points: Scalars['Int'];
  classroomSet: Array<ClassRoomType>;
};

export type ChangeCardMutation = {
  __typename?: 'ChangeCardMutation';
  customer?: Maybe<CustomerType>;
};

export type ChangeInAppSubscribeMutation = {
  __typename?: 'ChangeInAppSubscribeMutation';
  inAppSubscription?: Maybe<InAppSubscriptionType>;
};

export type ChangeSubscriptionMutation = {
  __typename?: 'ChangeSubscriptionMutation';
  subscription?: Maybe<SubscriptionType>;
};

export type CityType = {
  __typename?: 'CityType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  title?: Maybe<Scalars['String']>;
  organizerSet: Array<OrganizerType>;
  lectorSet: Array<LectorType>;
};

/** An enumeration. */
export enum ClassRoomLearningSessionStatus {
  /** Pending */
  Pending = 'PENDING',
  /** In Progress */
  InProgress = 'IN_PROGRESS',
  /** Finished */
  Finished = 'FINISHED'
}

export type ClassRoomLearningSessionType = {
  __typename?: 'ClassRoomLearningSessionType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  status?: Maybe<ClassRoomLearningSessionStatus>;
  classRoom: ClassRoomType;
  code?: Maybe<Scalars['Int']>;
  /** Duration in days. For Network Class Room. */
  duration?: Maybe<Scalars['Int']>;
  startDate: Scalars['Date'];
  finishDate: Scalars['Date'];
  users: Array<UserType>;
  maxUserCapacity?: Maybe<Scalars['Int']>;
  /** Designates whether the Class Room is available for users in the 'Anstehend' tab. */
  isAvailable: Scalars['Boolean'];
  /** Designates whether the Class Room is for users with 'Premium' status. */
  isForPremiumOnly: Scalars['Boolean'];
  /** Designates whether current session is for test. */
  isForTest: Scalars['Boolean'];
  /** Determines whether the current session is intended for single use. */
  isForSingle: Scalars['Boolean'];
  userClassRoomLearningSessionSettings: Array<UserClassRoomLearningSessionSettingsType>;
  userClassRoomCorrectAnswersData: Array<UserLearningSessionCorrectAnswersDataType>;
  lekSession: Array<LekSessionType>;
  userLectureCorrectAnswersData: Array<UserLectureCorrectAnswersDataType>;
  duelResultUserData: Array<DuelResultUserDataType>;
  specialityGroupIcon?: Maybe<Scalars['String']>;
  deepLink?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum ClassRoomLectureStatus {
  /** JOINED */
  Joined = 'JOINED',
  /** DELETED */
  Deleted = 'DELETED'
}

export type ClassRoomLectureType = {
  __typename?: 'ClassRoomLectureType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  classRoom: ClassRoomType;
  lecture: LectureType;
  aggregatedRate?: Maybe<AggregatedRateType>;
  lectureNumber: Scalars['Int'];
  isVisible?: Maybe<Scalars['Boolean']>;
  status?: Maybe<ClassRoomLectureStatus>;
  lectureQuestionCount?: Maybe<Scalars['Int']>;
  lectureGoal?: Maybe<Scalars['Int']>;
  correctAnswerCount?: Maybe<Scalars['Int']>;
};


export type ClassRoomLectureTypeLectureQuestionCountArgs = {
  userSessionSettingsId?: Maybe<Scalars['UUID']>;
};


export type ClassRoomLectureTypeLectureGoalArgs = {
  learningSessionId?: Maybe<Scalars['UUID']>;
  userSessionSettingsId?: Maybe<Scalars['UUID']>;
};


export type ClassRoomLectureTypeCorrectAnswerCountArgs = {
  learningSessionId?: Maybe<Scalars['UUID']>;
  userSessionSettingsId?: Maybe<Scalars['UUID']>;
};

export type ClassRoomMemberType = {
  __typename?: 'ClassRoomMemberType';
  id?: Maybe<Scalars['UUID']>;
  userData?: Maybe<UserLearningSessionCorrectAnswersDataType>;
  isAvailableForDuel?: Maybe<Scalars['Boolean']>;
};

export type ClassRoomType = {
  __typename?: 'ClassRoomType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  classRoomImage?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  description: Scalars['String'];
  learningMoreInfoLink?: Maybe<Scalars['String']>;
  certificate?: Maybe<CertificateType>;
  enrollmentType?: Maybe<Scalars['Int']>;
  organizer?: Maybe<OrganizerType>;
  professions: Array<ProfessionType>;
  specialities: Array<SpecialityType>;
  lectures: Array<LectureType>;
  aggregatedRate?: Maybe<AggregatedRateType>;
  specialityGroups: Array<SpecialityGroupType>;
  sponsors: Array<SponsorType>;
  /** Choose a university if the profession is "Medizinstudent(in)" */
  university?: Maybe<UniversityType>;
  classRoomLearningSession: Array<ClassRoomLearningSessionType>;
  classRoomLecture: Array<ClassRoomLectureType>;
  isExternalOrganizer?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum CodeModelCodeMode {
  /** Freemium */
  A_1 = 'A_1',
  /** Premium */
  A_2 = 'A_2'
}

export type CodesType = {
  __typename?: 'CodesType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  code: Scalars['Int'];
  maxCodeUserCount?: Maybe<Scalars['Int']>;
  startDate: Scalars['Date'];
  numberOfCodes?: Maybe<Scalars['Int']>;
  periodOfValidity?: Maybe<Scalars['Int']>;
  finishDate?: Maybe<Scalars['Date']>;
  network?: Maybe<NetworkType>;
  professions: Array<ProfessionType>;
  specialities: Array<SpecialityType>;
  sponsor?: Maybe<SponsorType>;
  codeMode?: Maybe<CodeModelCodeMode>;
  users: Array<UserType>;
};

/** An enumeration. */
export enum CouponStatus {
  /** Inactive */
  Inactive = 'INACTIVE',
  /** Active */
  Active = 'ACTIVE',
  /** Expired */
  Expired = 'EXPIRED'
}

/** An enumeration. */
export enum CouponSubscriptionType {
  /** Student Monthly */
  StudentMonthly = 'STUDENT_MONTHLY',
  /** Student Yearly */
  StudentYearly = 'STUDENT_YEARLY',
  /** Professional Monthly */
  ProfessionalMonthly = 'PROFESSIONAL_MONTHLY',
  /** Professional Yearly */
  ProfessionalYearly = 'PROFESSIONAL_YEARLY'
}

export type CouponType = {
  __typename?: 'CouponType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  couponName?: Maybe<Scalars['String']>;
  status: CouponStatus;
  /** Select a subscription type to join the coupon */
  subscriptionType: CouponSubscriptionType;
  startActiveDatetime?: Maybe<Scalars['DateTime']>;
  finishActiveDatetime?: Maybe<Scalars['DateTime']>;
  /** Text field for displaying a discount, for example 50% or 3.50€ */
  discount?: Maybe<Scalars['String']>;
};

export type CreateDailyTipperMutation = {
  __typename?: 'CreateDailyTipperMutation';
  dailyTipperData?: Maybe<DailyTipperDataType>;
};

/** Create Duel mutation with or without opponent_id argument. */
export type CreateDuelMutation = {
  __typename?: 'CreateDuelMutation';
  duel?: Maybe<DuelType>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CreateFeedbackForQuestionMutation = {
  __typename?: 'CreateFeedbackForQuestionMutation';
  feedbackForQuestion?: Maybe<FeedbackForQuestionType>;
};

export type CreateQuizDuelByOpponentMutation = {
  __typename?: 'CreateQuizDuelByOpponentMutation';
  duel?: Maybe<DuelType>;
};

export type CreateQuizDuelMutation = {
  __typename?: 'CreateQuizDuelMutation';
  duel?: Maybe<DuelType>;
};

/** Create Round mutation. */
export type CreateRoundMutation = {
  __typename?: 'CreateRoundMutation';
  round?: Maybe<DuelRoundType>;
};

export type CustomerType = {
  __typename?: 'CustomerType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  user: UserType;
  defaultCard?: Maybe<_DefaultCardType>;
  subscription?: Maybe<SubscriptionType>;
  paymentSubscription?: Maybe<SubscriptionType>;
};

export enum DailyStatusType {
  Plus = 'PLUS',
  Minus = 'MINUS',
  TakeAchievement = 'TAKE_ACHIEVEMENT',
  TakeYearAchievement = 'TAKE_YEAR_ACHIEVEMENT'
}

export type DailyTipperAchievementsType = {
  __typename?: 'DailyTipperAchievementsType';
  achievement?: Maybe<AchievementType>;
  usersWithAchievementCount?: Maybe<Scalars['Int']>;
  usersWithAchievement?: Maybe<Array<Maybe<DailyTipperType>>>;
  isAchieved?: Maybe<Scalars['Boolean']>;
};

export type DailyTipperDataType = {
  __typename?: 'DailyTipperDataType';
  daysLost?: Maybe<Scalars['Int']>;
  currentAchievement?: Maybe<Scalars['Int']>;
  dailyResultStatus?: Maybe<DailyStatusType>;
  dailyTipperQuestions?: Maybe<Array<Maybe<DailyTipperQuestionAnswerType>>>;
  questionsInfo?: Maybe<Array<Maybe<DailyTipperQuestionsInfoType>>>;
  yearNumber?: Maybe<Scalars['Int']>;
};

export type DailyTipperQuestionAnswerDailyStatusType = {
  __typename?: 'DailyTipperQuestionAnswerDailyStatusType';
  dailyTipperQuestionAnswer?: Maybe<DailyTipperQuestionAnswerType>;
  dailyStatus?: Maybe<DailyStatusType>;
};

export type DailyTipperQuestionAnswerType = {
  __typename?: 'DailyTipperQuestionAnswerType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  user?: Maybe<UserType>;
  actualLearningWeek?: Maybe<ActualLearnWeekType>;
  questionNumber?: Maybe<Scalars['Int']>;
  question?: Maybe<QuestionType>;
  userAnswer?: Maybe<AnswersType>;
  correctAnswer?: Maybe<AnswersType>;
  answerIsCorrect?: Maybe<Scalars['Boolean']>;
};

export type DailyTipperQuestionsInfoType = {
  __typename?: 'DailyTipperQuestionsInfoType';
  questionId?: Maybe<Scalars['UUID']>;
  classRoomLabels?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum DailyTipperStatusType {
  Pending = 'PENDING',
  InProgress = 'IN_PROGRESS',
  Finished = 'FINISHED',
  Ignored = 'IGNORED'
}

export type DailyTipperType = {
  __typename?: 'DailyTipperType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  user?: Maybe<UserType>;
  totalProgress?: Maybe<Scalars['Int']>;
  currentAchievement?: Maybe<AchievementType>;
  nextAchievement?: Maybe<AchievementType>;
  yearAchievement?: Maybe<YearAchievementType>;
  actuallearnweekSet: Array<ActualLearnWeekType>;
  dailyStatus?: Maybe<DailyTipperStatusType>;
};

export type DataProtectionType = {
  __typename?: 'DataProtectionType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  text?: Maybe<Scalars['String']>;
};




export type DeepLinkType = {
  __typename?: 'DeepLinkType';
  targetObjectId: Scalars['UUID'];
  targetType?: Maybe<Scalars['String']>;
};

/** Delete User Account mutation. */
export type DeleteAccountMutation = {
  __typename?: 'DeleteAccountMutation';
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type DirectChangeSystemMutation = {
  __typename?: 'DirectChangeSystemMutation';
  result?: Maybe<Scalars['GenericScalar']>;
};

export type DiseaseType = {
  __typename?: 'DiseaseType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  title: Scalars['String'];
  questionsSet: Array<QuestionType>;
  lectureSet: Array<LectureType>;
  lekSet: Array<LekType>;
  quizTime: Array<QuizTimeType>;
};

export type DuelDataType = {
  __typename?: 'DuelDataType';
  id?: Maybe<Scalars['UUID']>;
  userScore?: Maybe<DuelResultUserDataType>;
  opponentScore?: Maybe<DuelResultUserDataType>;
  rounds?: Maybe<Array<Maybe<DuelRoundType>>>;
  status?: Maybe<DuelStatusesEnum>;
  session?: Maybe<ClassRoomLearningSessionType>;
  isOnboardingDuel?: Maybe<Scalars['Boolean']>;
};

export type DuelResultType = {
  __typename?: 'DuelResultType';
  currentUserData?: Maybe<DuelResultUserDataType>;
  opponentData?: Maybe<DuelResultUserDataType>;
};

export type DuelResultUserDataType = {
  __typename?: 'DuelResultUserDataType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  duel?: Maybe<DuelType>;
  user?: Maybe<UserType>;
  classRoomLearningSession?: Maybe<ClassRoomLearningSessionType>;
  quizSession?: Maybe<QuizTimeSessionType>;
  initiator?: Maybe<Scalars['Boolean']>;
  duelScore?: Maybe<Scalars['Int']>;
  userStatus: DuelResultUserDataUserStatus;
  userClassRoomLearningSessionSettings?: Maybe<UserClassRoomLearningSessionSettingsType>;
  youTurn?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum DuelResultUserDataUserStatus {
  /** Tie */
  A_1 = 'A_1',
  /** Win */
  A_2 = 'A_2',
  /** Loss */
  A_3 = 'A_3',
  /** Unfinished */
  A_4 = 'A_4'
}

export type DuelRoundQuestionAnswerType = {
  __typename?: 'DuelRoundQuestionAnswerType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  duelRound?: Maybe<DuelRoundType>;
  user?: Maybe<UserType>;
  questionNumber?: Maybe<Scalars['Int']>;
  question?: Maybe<QuestionType>;
  correctAnswer?: Maybe<AnswersType>;
  userAnswer?: Maybe<AnswersType>;
  answerCorrect?: Maybe<Scalars['Boolean']>;
};

export type DuelRoundType = {
  __typename?: 'DuelRoundType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  duel?: Maybe<DuelType>;
  roundNumber: Scalars['Int'];
  statusIgnored: Scalars['Boolean'];
  specification?: Maybe<SpecificationType>;
  userQuestions: Array<DuelRoundQuestionAnswerType>;
};

/** An enumeration. */
export enum DuelStatus {
  /** Pending */
  Pending = 'PENDING',
  /** In Progress */
  InProgress = 'IN_PROGRESS',
  /** Finished */
  Finished = 'FINISHED',
  /** Ignored */
  Ignored = 'IGNORED'
}

export enum DuelStatusesEnum {
  Pending = 'PENDING',
  InProgress = 'IN_PROGRESS',
  Finished = 'FINISHED',
  Ignored = 'IGNORED'
}

export type DuelType = {
  __typename?: 'DuelType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  status?: Maybe<DuelStatus>;
  isOnboardingDuel: Scalars['Boolean'];
  duelRound: Array<DuelRoundType>;
  duelResultUserData: Array<DuelResultUserDataType>;
  result?: Maybe<DuelResultType>;
};

/** Early Finish Blended Class Room Mutation. */
export type EarlyFinishBlendedClassRoomMutation = {
  __typename?: 'EarlyFinishBlendedClassRoomMutation';
  learningSessionSettings?: Maybe<UserClassRoomLearningSessionSettingsType>;
};

/** Early Finish LEK Class Room Mutation. */
export type EarlyFinishLekClassRoomMutation = {
  __typename?: 'EarlyFinishLEKClassRoomMutation';
  learningSessionSettings?: Maybe<UserClassRoomLearningSessionSettingsType>;
};

/** Early Finish Network Class Room Mutation. */
export type EarlyFinishNetworkClassRoomMutation = {
  __typename?: 'EarlyFinishNetworkClassRoomMutation';
  learningSessionSettings?: Maybe<UserClassRoomLearningSessionSettingsType>;
};

export type EducationLevelType = {
  __typename?: 'EducationLevelType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  title: Scalars['String'];
  users: Array<UserType>;
};

export type ExplanationType = {
  __typename?: 'ExplanationType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
};

export type FeatureFlagType = {
  __typename?: 'FeatureFlagType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  name: Scalars['String'];
  enabled: Scalars['Boolean'];
  description: Scalars['String'];
};

export type FeedbackForQuestionType = {
  __typename?: 'FeedbackForQuestionType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  question: QuestionType;
  user: UserType;
  isReviewed: Scalars['Boolean'];
  relevanceRate?: Maybe<Scalars['Int']>;
  interestRate?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
};

export type FinishedClassRoomLearningSessionType = {
  __typename?: 'FinishedClassRoomLearningSessionType';
  learningSession?: Maybe<ClassRoomLearningSessionType>;
  correctAnswersGoal?: Maybe<Scalars['Int']>;
  questionsAmount?: Maybe<Scalars['Int']>;
  correctAnswersAmount?: Maybe<Scalars['Int']>;
  networkStartDate?: Maybe<Scalars['String']>;
  outViaSubscriptionDate?: Maybe<Scalars['String']>;
  specialityGroupIcon?: Maybe<Scalars['String']>;
  userLearningSessionSettingsId?: Maybe<Scalars['UUID']>;
};

export type FinishedUserQuizSessionType = {
  __typename?: 'FinishedUserQuizSessionType';
  quizSession?: Maybe<QuizTimeSessionType>;
  userRank?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['UUID']>;
};

export type FreeTagType = {
  __typename?: 'FreeTagType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  title: Scalars['String'];
  questionsSet: Array<QuestionType>;
  lecture: Array<LectureType>;
  lekSet: Array<LekType>;
  quizTimes: Array<QuizTimeType>;
};


export type GetDeepLink = {
  __typename?: 'GetDeepLink';
  deepLink?: Maybe<DeepLinkType>;
};

export type HomeQuizTimeSessionsType = {
  __typename?: 'HomeQuizTimeSessionsType';
  id?: Maybe<Scalars['UUID']>;
  quizSession?: Maybe<QuizTimeSessionType>;
  userStatus?: Maybe<Scalars['String']>;
};

export type Icd10TagType = {
  __typename?: 'Icd10TagType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  title: Scalars['String'];
  questionsSet: Array<QuestionType>;
  lecture: Array<LectureType>;
  lekSet: Array<LekType>;
  quizTimes: Array<QuizTimeType>;
};

export type ImpressumType = {
  __typename?: 'ImpressumType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  text?: Maybe<Scalars['String']>;
};

export type InAppSubscribeMutation = {
  __typename?: 'InAppSubscribeMutation';
  inAppSubscription?: Maybe<InAppSubscriptionType>;
};

/** An enumeration. */
export enum InAppSubscriptionSubscriptingType {
  /** Student Monthly */
  StudentMonthly = 'STUDENT_MONTHLY',
  /** Student Yearly */
  StudentYearly = 'STUDENT_YEARLY',
  /** Professional Monthly */
  ProfessionalMonthly = 'PROFESSIONAL_MONTHLY',
  /** Professional Yearly */
  ProfessionalYearly = 'PROFESSIONAL_YEARLY',
  /** Test Product */
  TestProduct = 'TEST_PRODUCT'
}

export type InAppSubscriptionType = {
  __typename?: 'InAppSubscriptionType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  customer: UserType;
  subscriptingType?: Maybe<InAppSubscriptionSubscriptingType>;
  subscriptionObject?: Maybe<Scalars['GenericScalar']>;
  /** The list of webhook objects(json) from the subscription service. */
  webhookObjectList?: Maybe<Scalars['JSONString']>;
  expirationDatetime: Scalars['DateTime'];
  price: Scalars['Decimal'];
  currency?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  store?: Maybe<Scalars['String']>;
};


/** Join To Blended Class Room Mutation. */
export type JoinToBlendedClassRoomMutation = {
  __typename?: 'JoinToBlendedClassRoomMutation';
  classRoomSession?: Maybe<ClassRoomLearningSessionType>;
};

/** Join To LEK Class Room Mutation. */
export type JoinToLekClassRoomMutation = {
  __typename?: 'JoinToLEKClassRoomMutation';
  classRoomSession?: Maybe<ClassRoomLearningSessionType>;
};

/** Join To Network Class Room Mutation. */
export type JoinToNetworkClassRoomMutation = {
  __typename?: 'JoinToNetworkClassRoomMutation';
  classRoomSession?: Maybe<ClassRoomLearningSessionType>;
};

export type JoinToQuizMutation = {
  __typename?: 'JoinToQuizMutation';
  quizSession?: Maybe<QuizTimeSessionType>;
};

export type LekFinishMutation = {
  __typename?: 'LEKFinishMutation';
  userLekData?: Maybe<UserLekDataType>;
};

export type LekQuestionsType = {
  __typename?: 'LEKQuestionsType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  lek: LekType;
  question: QuestionType;
};

/** An enumeration. */
export enum LekSessionFinishTime {
  /** 00:00 */
  A_00_00 = 'A_00_00',
  /** 00:15 */
  A_00_15 = 'A_00_15',
  /** 00:30 */
  A_00_30 = 'A_00_30',
  /** 00:45 */
  A_00_45 = 'A_00_45',
  /** 01:00 */
  A_01_00 = 'A_01_00',
  /** 01:15 */
  A_01_15 = 'A_01_15',
  /** 01:30 */
  A_01_30 = 'A_01_30',
  /** 01:45 */
  A_01_45 = 'A_01_45',
  /** 02:00 */
  A_02_00 = 'A_02_00',
  /** 02:15 */
  A_02_15 = 'A_02_15',
  /** 02:30 */
  A_02_30 = 'A_02_30',
  /** 02:45 */
  A_02_45 = 'A_02_45',
  /** 03:00 */
  A_03_00 = 'A_03_00',
  /** 03:15 */
  A_03_15 = 'A_03_15',
  /** 03:30 */
  A_03_30 = 'A_03_30',
  /** 03:45 */
  A_03_45 = 'A_03_45',
  /** 04:00 */
  A_04_00 = 'A_04_00',
  /** 04:15 */
  A_04_15 = 'A_04_15',
  /** 04:30 */
  A_04_30 = 'A_04_30',
  /** 04:45 */
  A_04_45 = 'A_04_45',
  /** 05:00 */
  A_05_00 = 'A_05_00',
  /** 05:15 */
  A_05_15 = 'A_05_15',
  /** 05:30 */
  A_05_30 = 'A_05_30',
  /** 05:45 */
  A_05_45 = 'A_05_45',
  /** 06:00 */
  A_06_00 = 'A_06_00',
  /** 06:15 */
  A_06_15 = 'A_06_15',
  /** 06:30 */
  A_06_30 = 'A_06_30',
  /** 06:45 */
  A_06_45 = 'A_06_45',
  /** 07:00 */
  A_07_00 = 'A_07_00',
  /** 07:15 */
  A_07_15 = 'A_07_15',
  /** 07:30 */
  A_07_30 = 'A_07_30',
  /** 07:45 */
  A_07_45 = 'A_07_45',
  /** 08:00 */
  A_08_00 = 'A_08_00',
  /** 08:15 */
  A_08_15 = 'A_08_15',
  /** 08:30 */
  A_08_30 = 'A_08_30',
  /** 08:45 */
  A_08_45 = 'A_08_45',
  /** 09:00 */
  A_09_00 = 'A_09_00',
  /** 09:15 */
  A_09_15 = 'A_09_15',
  /** 09:30 */
  A_09_30 = 'A_09_30',
  /** 09:45 */
  A_09_45 = 'A_09_45',
  /** 10:00 */
  A_10_00 = 'A_10_00',
  /** 10:15 */
  A_10_15 = 'A_10_15',
  /** 10:30 */
  A_10_30 = 'A_10_30',
  /** 10:45 */
  A_10_45 = 'A_10_45',
  /** 11:00 */
  A_11_00 = 'A_11_00',
  /** 11:15 */
  A_11_15 = 'A_11_15',
  /** 11:30 */
  A_11_30 = 'A_11_30',
  /** 11:45 */
  A_11_45 = 'A_11_45',
  /** 12:00 */
  A_12_00 = 'A_12_00',
  /** 12:15 */
  A_12_15 = 'A_12_15',
  /** 12:30 */
  A_12_30 = 'A_12_30',
  /** 12:45 */
  A_12_45 = 'A_12_45',
  /** 13:00 */
  A_13_00 = 'A_13_00',
  /** 13:15 */
  A_13_15 = 'A_13_15',
  /** 13:30 */
  A_13_30 = 'A_13_30',
  /** 13:45 */
  A_13_45 = 'A_13_45',
  /** 14:00 */
  A_14_00 = 'A_14_00',
  /** 14:15 */
  A_14_15 = 'A_14_15',
  /** 14:30 */
  A_14_30 = 'A_14_30',
  /** 14:45 */
  A_14_45 = 'A_14_45',
  /** 15:00 */
  A_15_00 = 'A_15_00',
  /** 15:15 */
  A_15_15 = 'A_15_15',
  /** 15:30 */
  A_15_30 = 'A_15_30',
  /** 15:45 */
  A_15_45 = 'A_15_45',
  /** 16:00 */
  A_16_00 = 'A_16_00',
  /** 16:15 */
  A_16_15 = 'A_16_15',
  /** 16:30 */
  A_16_30 = 'A_16_30',
  /** 16:45 */
  A_16_45 = 'A_16_45',
  /** 17:00 */
  A_17_00 = 'A_17_00',
  /** 17:15 */
  A_17_15 = 'A_17_15',
  /** 17:30 */
  A_17_30 = 'A_17_30',
  /** 17:45 */
  A_17_45 = 'A_17_45',
  /** 18:00 */
  A_18_00 = 'A_18_00',
  /** 18:15 */
  A_18_15 = 'A_18_15',
  /** 18:30 */
  A_18_30 = 'A_18_30',
  /** 18:45 */
  A_18_45 = 'A_18_45',
  /** 19:00 */
  A_19_00 = 'A_19_00',
  /** 19:15 */
  A_19_15 = 'A_19_15',
  /** 19:30 */
  A_19_30 = 'A_19_30',
  /** 19:45 */
  A_19_45 = 'A_19_45',
  /** 20:00 */
  A_20_00 = 'A_20_00',
  /** 20:15 */
  A_20_15 = 'A_20_15',
  /** 20:30 */
  A_20_30 = 'A_20_30',
  /** 20:45 */
  A_20_45 = 'A_20_45',
  /** 21:00 */
  A_21_00 = 'A_21_00',
  /** 21:15 */
  A_21_15 = 'A_21_15',
  /** 21:30 */
  A_21_30 = 'A_21_30',
  /** 21:45 */
  A_21_45 = 'A_21_45',
  /** 22:00 */
  A_22_00 = 'A_22_00',
  /** 22:15 */
  A_22_15 = 'A_22_15',
  /** 22:30 */
  A_22_30 = 'A_22_30',
  /** 22:45 */
  A_22_45 = 'A_22_45',
  /** 23:00 */
  A_23_00 = 'A_23_00',
  /** 23:15 */
  A_23_15 = 'A_23_15',
  /** 23:30 */
  A_23_30 = 'A_23_30',
  /** 23:45 */
  A_23_45 = 'A_23_45'
}

/** An enumeration. */
export enum LekSessionStartTime {
  /** 00:00 */
  A_00_00 = 'A_00_00',
  /** 00:15 */
  A_00_15 = 'A_00_15',
  /** 00:30 */
  A_00_30 = 'A_00_30',
  /** 00:45 */
  A_00_45 = 'A_00_45',
  /** 01:00 */
  A_01_00 = 'A_01_00',
  /** 01:15 */
  A_01_15 = 'A_01_15',
  /** 01:30 */
  A_01_30 = 'A_01_30',
  /** 01:45 */
  A_01_45 = 'A_01_45',
  /** 02:00 */
  A_02_00 = 'A_02_00',
  /** 02:15 */
  A_02_15 = 'A_02_15',
  /** 02:30 */
  A_02_30 = 'A_02_30',
  /** 02:45 */
  A_02_45 = 'A_02_45',
  /** 03:00 */
  A_03_00 = 'A_03_00',
  /** 03:15 */
  A_03_15 = 'A_03_15',
  /** 03:30 */
  A_03_30 = 'A_03_30',
  /** 03:45 */
  A_03_45 = 'A_03_45',
  /** 04:00 */
  A_04_00 = 'A_04_00',
  /** 04:15 */
  A_04_15 = 'A_04_15',
  /** 04:30 */
  A_04_30 = 'A_04_30',
  /** 04:45 */
  A_04_45 = 'A_04_45',
  /** 05:00 */
  A_05_00 = 'A_05_00',
  /** 05:15 */
  A_05_15 = 'A_05_15',
  /** 05:30 */
  A_05_30 = 'A_05_30',
  /** 05:45 */
  A_05_45 = 'A_05_45',
  /** 06:00 */
  A_06_00 = 'A_06_00',
  /** 06:15 */
  A_06_15 = 'A_06_15',
  /** 06:30 */
  A_06_30 = 'A_06_30',
  /** 06:45 */
  A_06_45 = 'A_06_45',
  /** 07:00 */
  A_07_00 = 'A_07_00',
  /** 07:15 */
  A_07_15 = 'A_07_15',
  /** 07:30 */
  A_07_30 = 'A_07_30',
  /** 07:45 */
  A_07_45 = 'A_07_45',
  /** 08:00 */
  A_08_00 = 'A_08_00',
  /** 08:15 */
  A_08_15 = 'A_08_15',
  /** 08:30 */
  A_08_30 = 'A_08_30',
  /** 08:45 */
  A_08_45 = 'A_08_45',
  /** 09:00 */
  A_09_00 = 'A_09_00',
  /** 09:15 */
  A_09_15 = 'A_09_15',
  /** 09:30 */
  A_09_30 = 'A_09_30',
  /** 09:45 */
  A_09_45 = 'A_09_45',
  /** 10:00 */
  A_10_00 = 'A_10_00',
  /** 10:15 */
  A_10_15 = 'A_10_15',
  /** 10:30 */
  A_10_30 = 'A_10_30',
  /** 10:45 */
  A_10_45 = 'A_10_45',
  /** 11:00 */
  A_11_00 = 'A_11_00',
  /** 11:15 */
  A_11_15 = 'A_11_15',
  /** 11:30 */
  A_11_30 = 'A_11_30',
  /** 11:45 */
  A_11_45 = 'A_11_45',
  /** 12:00 */
  A_12_00 = 'A_12_00',
  /** 12:15 */
  A_12_15 = 'A_12_15',
  /** 12:30 */
  A_12_30 = 'A_12_30',
  /** 12:45 */
  A_12_45 = 'A_12_45',
  /** 13:00 */
  A_13_00 = 'A_13_00',
  /** 13:15 */
  A_13_15 = 'A_13_15',
  /** 13:30 */
  A_13_30 = 'A_13_30',
  /** 13:45 */
  A_13_45 = 'A_13_45',
  /** 14:00 */
  A_14_00 = 'A_14_00',
  /** 14:15 */
  A_14_15 = 'A_14_15',
  /** 14:30 */
  A_14_30 = 'A_14_30',
  /** 14:45 */
  A_14_45 = 'A_14_45',
  /** 15:00 */
  A_15_00 = 'A_15_00',
  /** 15:15 */
  A_15_15 = 'A_15_15',
  /** 15:30 */
  A_15_30 = 'A_15_30',
  /** 15:45 */
  A_15_45 = 'A_15_45',
  /** 16:00 */
  A_16_00 = 'A_16_00',
  /** 16:15 */
  A_16_15 = 'A_16_15',
  /** 16:30 */
  A_16_30 = 'A_16_30',
  /** 16:45 */
  A_16_45 = 'A_16_45',
  /** 17:00 */
  A_17_00 = 'A_17_00',
  /** 17:15 */
  A_17_15 = 'A_17_15',
  /** 17:30 */
  A_17_30 = 'A_17_30',
  /** 17:45 */
  A_17_45 = 'A_17_45',
  /** 18:00 */
  A_18_00 = 'A_18_00',
  /** 18:15 */
  A_18_15 = 'A_18_15',
  /** 18:30 */
  A_18_30 = 'A_18_30',
  /** 18:45 */
  A_18_45 = 'A_18_45',
  /** 19:00 */
  A_19_00 = 'A_19_00',
  /** 19:15 */
  A_19_15 = 'A_19_15',
  /** 19:30 */
  A_19_30 = 'A_19_30',
  /** 19:45 */
  A_19_45 = 'A_19_45',
  /** 20:00 */
  A_20_00 = 'A_20_00',
  /** 20:15 */
  A_20_15 = 'A_20_15',
  /** 20:30 */
  A_20_30 = 'A_20_30',
  /** 20:45 */
  A_20_45 = 'A_20_45',
  /** 21:00 */
  A_21_00 = 'A_21_00',
  /** 21:15 */
  A_21_15 = 'A_21_15',
  /** 21:30 */
  A_21_30 = 'A_21_30',
  /** 21:45 */
  A_21_45 = 'A_21_45',
  /** 22:00 */
  A_22_00 = 'A_22_00',
  /** 22:15 */
  A_22_15 = 'A_22_15',
  /** 22:30 */
  A_22_30 = 'A_22_30',
  /** 22:45 */
  A_22_45 = 'A_22_45',
  /** 23:00 */
  A_23_00 = 'A_23_00',
  /** 23:15 */
  A_23_15 = 'A_23_15',
  /** 23:30 */
  A_23_30 = 'A_23_30',
  /** 23:45 */
  A_23_45 = 'A_23_45'
}

/** An enumeration. */
export enum LekSessionStatus {
  /** Pending */
  Pending = 'PENDING',
  /** In Progress */
  InProgress = 'IN_PROGRESS',
  /** Finished */
  Finished = 'FINISHED'
}

export type LekSessionType = {
  __typename?: 'LEKSessionType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  status?: Maybe<LekSessionStatus>;
  lek: LekType;
  classRoomLearningSession: ClassRoomLearningSessionType;
  startDate: Scalars['Date'];
  startTime?: Maybe<LekSessionStartTime>;
  finishDate: Scalars['Date'];
  finishTime?: Maybe<LekSessionFinishTime>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  finishDateTime?: Maybe<Scalars['DateTime']>;
  userLekData: Array<UserLekDataType>;
};

export type LekStartMutation = {
  __typename?: 'LEKStartMutation';
  userLekData?: Maybe<UserLekDataType>;
};

export type LekType = {
  __typename?: 'LEKType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  title: Scalars['String'];
  description: Scalars['String'];
  possibleAttempts: Scalars['Int'];
  lekGoal: Scalars['Int'];
  questionCount?: Maybe<Scalars['Int']>;
  /** HH:MM:SS */
  answerTimeDuration: Scalars['Float'];
  specifications: Array<SpecificationType>;
  professions: Array<ProfessionType>;
  diseases: Array<DiseaseType>;
  specialities: Array<SpecialityType>;
  icd10tags: Array<Icd10TagType>;
  freeTags: Array<FreeTagType>;
  questions: Array<QuestionType>;
  lekSession: Array<LekSessionType>;
  lekQuestions: Array<LekQuestionsType>;
};

/** Leave Class Room Mutation. */
export type LeaveClassRoomMutation = {
  __typename?: 'LeaveClassRoomMutation';
  learningSessionSettings?: Maybe<UserClassRoomLearningSessionSettingsType>;
};

/** Leave Network Class Room Mutation. */
export type LeaveNetworkClassRoomMutation = {
  __typename?: 'LeaveNetworkClassRoomMutation';
  learningSessionSettings?: Maybe<UserClassRoomLearningSessionSettingsType>;
};

export type LeaveQuizMutation = {
  __typename?: 'LeaveQuizMutation';
  session?: Maybe<QuizTimeSessionType>;
};

export type LectorType = {
  __typename?: 'LectorType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  professions: Array<ProfessionType>;
  specialities: Array<SpecialityType>;
  aggregatedRate?: Maybe<AggregatedRateType>;
  city?: Maybe<CityType>;
  email?: Maybe<Scalars['String']>;
  about?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  lectureSet: Array<LectureType>;
};

export type LectureType = {
  __typename?: 'LectureType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  title: Scalars['String'];
  /** Only for BLENDED Class Room. IN NUMBERS. */
  correctAnswersGoal?: Maybe<Scalars['Int']>;
  /** Only for NETWORK Class Room. IN PERCENT, %. */
  networkCorrectAnswersGoal?: Maybe<Scalars['Int']>;
  lectors: Array<LectorType>;
  description: Scalars['String'];
  lectureLink?: Maybe<Scalars['String']>;
  specifications: Array<SpecificationType>;
  professions: Array<ProfessionType>;
  diseases: Array<DiseaseType>;
  specialities: Array<SpecialityType>;
  icd10tags: Array<Icd10TagType>;
  freeTags: Array<FreeTagType>;
  questions: Array<QuestionType>;
  classroomSet: Array<ClassRoomType>;
  classRoomLecture: Array<ClassRoomLectureType>;
  userLectureCorrectAnswersData: Array<UserLectureCorrectAnswersDataType>;
};

export type LinkCategoryType = {
  __typename?: 'LinkCategoryType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  title: Scalars['String'];
  /** Place number in link list when user answering. */
  placeNumber: Scalars['Int'];
  link: Array<LinkType>;
};

export type LinkType = {
  __typename?: 'LinkType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  url: Scalars['String'];
  questions: Array<QuestionType>;
  linkCategory: LinkCategoryType;
  questionLink: Array<QuestionLinkType>;
};

export type MonthlyScoreType = {
  __typename?: 'MonthlyScoreType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  user?: Maybe<UserType>;
  duels: Scalars['Int'];
  wins: Scalars['Int'];
  loss: Scalars['Int'];
  ties: Scalars['Int'];
  points: Scalars['Int'];
  rank?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Apply Class Room Code Mutation. */
  applyClassRoomCode?: Maybe<ApplyClassRoomCodeMutation>;
  /** Add Code Mutation. */
  applyNetworkCodeForUser?: Maybe<ApplyNetworkCodeForUserMutation>;
  /** User Registration mutation. */
  userRegistration?: Maybe<UserRegistrationMutation>;
  /** Confirm User mutation. */
  userConfirm?: Maybe<UserConfirmMutation>;
  userLogin?: Maybe<UserLoginMutation>;
  /** Re Subscribe To News User mutation. */
  userSubscribeToNews?: Maybe<UserSubscribeToNewsMutation>;
  /** Unsubscribe To News User mutation. */
  userUnsubscribeToNews?: Maybe<UserUnsubscribeToNewsMutation>;
  /** Forgot Password User mutation. */
  userForgotPassword?: Maybe<UserForgotPasswordMutation>;
  /** Restore Password User mutation. */
  userRestorePassword?: Maybe<UserRestorePasswordMutation>;
  /** Notifications mutation. */
  notificationStatus?: Maybe<NotificationMutation>;
  /** Reset Password Key Check Mutation. */
  resetKeyCheck?: Maybe<ResetPasswordKeyCheckMutation>;
  uploadPhoto?: Maybe<UserPhotoMutations>;
  /** Create Duel mutation with or without opponent_id argument. */
  createDuel?: Maybe<CreateDuelMutation>;
  createQuizDuel?: Maybe<CreateQuizDuelMutation>;
  createQuizDuelByOpponent?: Maybe<CreateQuizDuelByOpponentMutation>;
  /** Create Round mutation. */
  createRound?: Maybe<CreateRoundMutation>;
  /** Set Answer Mutation. */
  setAnswer?: Maybe<SetAnswerMutation>;
  /** Update User data mutation. */
  updateUserData?: Maybe<UserUpdateProfileMutation>;
  /** Update User Settings data mutation. */
  updateUserSettings?: Maybe<UserSettingsUpdateMutation>;
  updateUserClassRoomSettings?: Maybe<UserClassRoomLearningSessionSettingsMutation>;
  /** Set DailyTipper Answer Mutation. */
  setDailyTipperAnswer?: Maybe<SetDailyTipperAnswerMutation>;
  createDailyTipper?: Maybe<CreateDailyTipperMutation>;
  /** Leave Class Room Mutation. */
  leaveClassRoom?: Maybe<LeaveClassRoomMutation>;
  /** Leave Network Class Room Mutation. */
  leaveNetworkClassRoom?: Maybe<LeaveNetworkClassRoomMutation>;
  /** Early Finish Network Class Room Mutation. */
  earlyFinishNetworkClassRoom?: Maybe<EarlyFinishNetworkClassRoomMutation>;
  /** Early Finish Blended Class Room Mutation. */
  earlyFinishBlendedClassRoom?: Maybe<EarlyFinishBlendedClassRoomMutation>;
  /** Early Finish LEK Class Room Mutation. */
  earlyFinishLekClassRoom?: Maybe<EarlyFinishLekClassRoomMutation>;
  lekStart?: Maybe<LekStartMutation>;
  setLekAnswer?: Maybe<SetLekAnswerMutation>;
  lekFinish?: Maybe<LekFinishMutation>;
  /** Delete User Account mutation. */
  deleteAccount?: Maybe<DeleteAccountMutation>;
  subscribe?: Maybe<SubscribeMutation>;
  cancelSubscription?: Maybe<CancelSubscriptionMutation>;
  changeCard?: Maybe<ChangeCardMutation>;
  renewSubscription?: Maybe<RenewSubscriptionMutation>;
  changeSubscription?: Maybe<ChangeSubscriptionMutation>;
  joinToQuiz?: Maybe<JoinToQuizMutation>;
  addWaitingUser?: Maybe<AddWaitingUser>;
  leaveQuiz?: Maybe<LeaveQuizMutation>;
  quizStartByUser?: Maybe<QuizStartByUserMutation>;
  switchPauseMode?: Maybe<SwitchPauseModeMutation>;
  directChangeSystem?: Maybe<DirectChangeSystemMutation>;
  getDeepLink?: Maybe<GetDeepLink>;
  /** Join To Network Class Room Mutation. */
  joinToNetworkClassRoom?: Maybe<JoinToNetworkClassRoomMutation>;
  /** Join To Blended Class Room Mutation. */
  joinToBlendedClassRoom?: Maybe<JoinToBlendedClassRoomMutation>;
  /** Join To LEK Class Room Mutation. */
  joinToLekClassRoom?: Maybe<JoinToLekClassRoomMutation>;
  /** Pause Blended Class Room Session Mutation. */
  pauseBlendedClassRoom?: Maybe<PauseBlendedClassRoomSessionMutation>;
  /** Resume Blended Class Room Session Mutation. */
  resumeBlendedClassRoom?: Maybe<ResumeBlendedClassRoomSessionMutation>;
  createFeedbackForQuestion?: Maybe<CreateFeedbackForQuestionMutation>;
  editFeedbackForQuestion?: Maybe<UpdateFeedbackForQuestionMutation>;
  /** Delete User Account mutation. */
  setUserExpoToken?: Maybe<SetUserExpoTokenMutation>;
  inAppSubscribe?: Maybe<InAppSubscribeMutation>;
  changeInAppSubscription?: Maybe<ChangeInAppSubscribeMutation>;
  canceledInAppSubscription?: Maybe<CanceledInAppSubscribeMutation>;
};


export type MutationApplyClassRoomCodeArgs = {
  code?: Maybe<Scalars['Int']>;
  deepLinkHash?: Maybe<Scalars['String']>;
  isAvailableTab?: Maybe<Scalars['Boolean']>;
  isNetworkType?: Maybe<Scalars['Boolean']>;
  sessionId?: Maybe<Scalars['UUID']>;
  specialityGroupIcon?: Maybe<Scalars['String']>;
};


export type MutationApplyNetworkCodeForUserArgs = {
  code?: Maybe<Scalars['Int']>;
};


export type MutationUserRegistrationArgs = {
  anotherProfession?: Maybe<Scalars['String']>;
  anotherWorkplace?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  deepLinkHash?: Maybe<Scalars['String']>;
  educationLevelId?: Maybe<Scalars['UUID']>;
  efnNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  examYear?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  medicineStudyBegin?: Maybe<Scalars['Date']>;
  password?: Maybe<Scalars['String']>;
  passwordConfirmation?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  professionId?: Maybe<Scalars['UUID']>;
  registrationNumber?: Maybe<Scalars['String']>;
  semesterId?: Maybe<Scalars['UUID']>;
  sharingSenderId?: Maybe<Scalars['UUID']>;
  specialityId?: Maybe<Scalars['UUID']>;
  studyBeginId?: Maybe<Scalars['UUID']>;
  studyYearId?: Maybe<Scalars['UUID']>;
  subscribeToNews?: Maybe<Scalars['Boolean']>;
  universityId?: Maybe<Scalars['UUID']>;
  username?: Maybe<Scalars['String']>;
  workplaceId?: Maybe<Scalars['UUID']>;
};


export type MutationUserConfirmArgs = {
  token?: Maybe<Scalars['UUID']>;
};


export type MutationUserLoginArgs = {
  email?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  username?: Maybe<Scalars['String']>;
};


export type MutationUserSubscribeToNewsArgs = {
  userId?: Maybe<Scalars['UUID']>;
};


export type MutationUserUnsubscribeToNewsArgs = {
  userId?: Maybe<Scalars['UUID']>;
};


export type MutationUserForgotPasswordArgs = {
  email?: Maybe<Scalars['String']>;
};


export type MutationUserRestorePasswordArgs = {
  newPassword?: Maybe<Scalars['String']>;
  newPasswordConfirmation?: Maybe<Scalars['String']>;
  resetPasswordKey?: Maybe<Scalars['String']>;
};


export type MutationNotificationStatusArgs = {
  notificationId?: Maybe<Scalars['UUID']>;
};


export type MutationResetKeyCheckArgs = {
  resetPasswordKey?: Maybe<Scalars['String']>;
};


export type MutationUploadPhotoArgs = {
  file: Scalars['Upload'];
};


export type MutationCreateDuelArgs = {
  isCustomDuel?: Maybe<Scalars['Boolean']>;
  opponentId?: Maybe<Scalars['UUID']>;
  sessionId?: Maybe<Scalars['UUID']>;
};


export type MutationCreateQuizDuelArgs = {
  quizSessionId: Scalars['UUID'];
};


export type MutationCreateQuizDuelByOpponentArgs = {
  opponentId: Scalars['UUID'];
  quizSessionId: Scalars['UUID'];
};


export type MutationCreateRoundArgs = {
  duelId?: Maybe<Scalars['UUID']>;
};


export type MutationSetAnswerArgs = {
  answerId?: Maybe<Scalars['UUID']>;
  duelRoundQuestionAnswerId?: Maybe<Scalars['UUID']>;
};


export type MutationUpdateUserDataArgs = {
  anotherProfession?: Maybe<Scalars['String']>;
  anotherWorkplace?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  educationLevelId?: Maybe<Scalars['UUID']>;
  examYear?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  medicineStudyBegin?: Maybe<Scalars['Date']>;
  postcode?: Maybe<Scalars['String']>;
  registrationNumber?: Maybe<Scalars['String']>;
  semesterId?: Maybe<Scalars['UUID']>;
  specialityId?: Maybe<Scalars['UUID']>;
  studyBeginId?: Maybe<Scalars['UUID']>;
  studyYearId?: Maybe<Scalars['UUID']>;
  universityId?: Maybe<Scalars['UUID']>;
  username?: Maybe<Scalars['String']>;
  workplaceId?: Maybe<Scalars['UUID']>;
};


export type MutationUpdateUserSettingsArgs = {
  dailyTipperMailNotification?: Maybe<Scalars['Boolean']>;
  dailyTipperMode?: Maybe<Scalars['Boolean']>;
  duelMailNotification?: Maybe<Scalars['Boolean']>;
  duelMode?: Maybe<Scalars['Boolean']>;
  goal?: Maybe<Scalars['Int']>;
  isChromeInstallationPopupSkipped?: Maybe<Scalars['Boolean']>;
  isClassRoomIntroCompleted?: Maybe<Scalars['Boolean']>;
  isCookieAccepted?: Maybe<Scalars['Boolean']>;
  isDailyTipperRulesRead?: Maybe<Scalars['Boolean']>;
  isDuelRulesRead?: Maybe<Scalars['Boolean']>;
  isIntroCompleted?: Maybe<Scalars['Boolean']>;
  isIosInstallationPopupSkipped?: Maybe<Scalars['Boolean']>;
  isLikeHumeoQuestionRead?: Maybe<Scalars['Boolean']>;
  isMarketingCookieAccepted?: Maybe<Scalars['Boolean']>;
  isQuizDuelRulesRead?: Maybe<Scalars['Boolean']>;
  pushNotificationDailyTipperMode?: Maybe<Scalars['Boolean']>;
  pushNotificationDuelMode?: Maybe<Scalars['Boolean']>;
  pushNotificationMode?: Maybe<Scalars['Boolean']>;
  pushNotificationNewChapterMode?: Maybe<Scalars['Boolean']>;
  pushNotificationPremiumMode?: Maybe<Scalars['Boolean']>;
  pushNotificationQuizTimeMode?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateUserClassRoomSettingsArgs = {
  dailyTipperMode?: Maybe<Scalars['Boolean']>;
  duelMode?: Maybe<Scalars['Boolean']>;
  isAchieved?: Maybe<Scalars['Boolean']>;
  isClosed?: Maybe<Scalars['Boolean']>;
  isOut?: Maybe<Scalars['Boolean']>;
  sessionId?: Maybe<Scalars['UUID']>;
};


export type MutationSetDailyTipperAnswerArgs = {
  answerId?: Maybe<Scalars['UUID']>;
  questionId?: Maybe<Scalars['UUID']>;
};


export type MutationLeaveClassRoomArgs = {
  sessionId?: Maybe<Scalars['UUID']>;
};


export type MutationLeaveNetworkClassRoomArgs = {
  userSessionSettingsId?: Maybe<Scalars['UUID']>;
};


export type MutationEarlyFinishNetworkClassRoomArgs = {
  userSessionSettingsId?: Maybe<Scalars['UUID']>;
};


export type MutationEarlyFinishBlendedClassRoomArgs = {
  userSessionSettingsId?: Maybe<Scalars['UUID']>;
};


export type MutationEarlyFinishLekClassRoomArgs = {
  userSessionSettingsId?: Maybe<Scalars['UUID']>;
};


export type MutationLekStartArgs = {
  lekSessionId?: Maybe<Scalars['UUID']>;
  startTime?: Maybe<Scalars['DateTime']>;
};


export type MutationSetLekAnswerArgs = {
  answerId?: Maybe<Scalars['UUID']>;
  answerTime?: Maybe<Scalars['DateTime']>;
  lekSessionId?: Maybe<Scalars['UUID']>;
  questionId?: Maybe<Scalars['UUID']>;
};


export type MutationLekFinishArgs = {
  lekSessionId?: Maybe<Scalars['UUID']>;
  userLekDataId?: Maybe<Scalars['UUID']>;
};


export type MutationSubscribeArgs = {
  recaptchaToken?: Maybe<Scalars['String']>;
  stripeToken?: Maybe<Scalars['String']>;
  subscriptionType?: Maybe<Scalars['String']>;
};


export type MutationChangeCardArgs = {
  stripeToken?: Maybe<Scalars['String']>;
};


export type MutationChangeSubscriptionArgs = {
  subscriptionType?: Maybe<Scalars['String']>;
};


export type MutationJoinToQuizArgs = {
  code?: Maybe<Scalars['Int']>;
  deepLinkHash?: Maybe<Scalars['String']>;
  sessionId: Scalars['UUID'];
};


export type MutationAddWaitingUserArgs = {
  sessionId: Scalars['UUID'];
};


export type MutationLeaveQuizArgs = {
  sessionId: Scalars['UUID'];
};


export type MutationQuizStartByUserArgs = {
  quizSessionId: Scalars['UUID'];
};


export type MutationSwitchPauseModeArgs = {
  pauseMode: Scalars['Boolean'];
  quizSessionId: Scalars['UUID'];
};


export type MutationDirectChangeSystemArgs = {
  actions: Array<Maybe<Scalars['GenericScalar']>>;
};


export type MutationGetDeepLinkArgs = {
  hash: Scalars['String'];
  source?: Maybe<Scalars['String']>;
};


export type MutationJoinToNetworkClassRoomArgs = {
  deepLinkHash?: Maybe<Scalars['String']>;
  isAfterOnboarding?: Maybe<Scalars['Boolean']>;
  sessionId?: Maybe<Scalars['UUID']>;
  specialityGroupIcon?: Maybe<Scalars['String']>;
};


export type MutationJoinToBlendedClassRoomArgs = {
  deepLinkHash?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['UUID']>;
};


export type MutationJoinToLekClassRoomArgs = {
  deepLinkHash?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['UUID']>;
};


export type MutationPauseBlendedClassRoomArgs = {
  userSessionSettingsId?: Maybe<Scalars['UUID']>;
};


export type MutationResumeBlendedClassRoomArgs = {
  userSessionSettingsId?: Maybe<Scalars['UUID']>;
};


export type MutationCreateFeedbackForQuestionArgs = {
  comment?: Maybe<Scalars['String']>;
  interestRate: Scalars['Int'];
  questionId: Scalars['UUID'];
  relevanceRate: Scalars['Int'];
};


export type MutationEditFeedbackForQuestionArgs = {
  comment?: Maybe<Scalars['String']>;
  interestRate?: Maybe<Scalars['Int']>;
  questionId: Scalars['UUID'];
  relevanceRate?: Maybe<Scalars['Int']>;
};


export type MutationSetUserExpoTokenArgs = {
  expoToken?: Maybe<Scalars['String']>;
};


export type MutationInAppSubscribeArgs = {
  subscriptionObject?: Maybe<Scalars['GenericScalar']>;
};


export type MutationChangeInAppSubscriptionArgs = {
  subscriptionObject?: Maybe<Scalars['GenericScalar']>;
};


export type MutationCanceledInAppSubscriptionArgs = {
  subscriptionObject?: Maybe<Scalars['GenericScalar']>;
};

export type NetworkType = {
  __typename?: 'NetworkType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  title: Scalars['String'];
  specialities: Array<SpecialityType>;
  professions: Array<ProfessionType>;
  codemodelSet: Array<CodesType>;
  userSet: Array<UserType>;
};

/** Notifications mutation. */
export type NotificationMutation = {
  __typename?: 'NotificationMutation';
  notification?: Maybe<NotificationType>;
};

export type NotificationType = {
  __typename?: 'NotificationType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  user?: Maybe<UserType>;
  data: Scalars['JSONString'];
  message?: Maybe<Scalars['String']>;
  /** Designates whether this notification is read. */
  isRead: Scalars['Boolean'];
};

export type OngoingUserLearningSessionType = {
  __typename?: 'OngoingUserLearningSessionType';
  learningSession?: Maybe<ClassRoomLearningSessionType>;
  isSessionActive?: Maybe<Scalars['Boolean']>;
  correctAnswersGoal?: Maybe<Scalars['Int']>;
  questionsAmount?: Maybe<Scalars['Int']>;
  correctAnswersAmount?: Maybe<Scalars['Int']>;
  networkStartDate?: Maybe<Scalars['String']>;
  outViaSubscriptionDate?: Maybe<Scalars['String']>;
  specialityGroupIcon?: Maybe<Scalars['String']>;
};

export type OrganizerType = {
  __typename?: 'OrganizerType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  linkToSite?: Maybe<Scalars['String']>;
  city?: Maybe<CityType>;
  about?: Maybe<Scalars['String']>;
  classroomSet: Array<ClassRoomType>;
  quizTime: Array<QuizTimeType>;
};

/** Pause Blended Class Room Session Mutation. */
export type PauseBlendedClassRoomSessionMutation = {
  __typename?: 'PauseBlendedClassRoomSessionMutation';
  learningSessionSettings?: Maybe<UserClassRoomLearningSessionSettingsType>;
};

export type PrivatePolicyType = {
  __typename?: 'PrivatePolicyType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  text?: Maybe<Scalars['String']>;
};

export type ProfessionType = {
  __typename?: 'ProfessionType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  title: Scalars['String'];
  type?: Maybe<ProfessionTypeType>;
  isAvailable: Scalars['Boolean'];
  network: Array<NetworkType>;
  codes: Array<CodesType>;
  users: Array<UserType>;
  specificationSet: Array<SpecificationType>;
  questionsSet: Array<QuestionType>;
  lectors: Array<LectorType>;
  lectureSet: Array<LectureType>;
  classRooms: Array<ClassRoomType>;
  lekSet: Array<LekType>;
  quizTime: Array<QuizTimeType>;
};

export type ProfessionTypeType = {
  __typename?: 'ProfessionTypeType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  type?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  specialities: Array<SpecialityType>;
  professions: Array<ProfessionType>;
  workplaces: Array<WorkplaceType>;
};

export type Query = {
  __typename?: 'Query';
  achievementsRang?: Maybe<Array<Maybe<DailyTipperAchievementsType>>>;
  activeDuels?: Maybe<Array<Maybe<ActiveDuelsType>>>;
  activeQuizDuels?: Maybe<Array<Maybe<ActiveQuizDuelsType>>>;
  announcedQuizSessions?: Maybe<Array<Maybe<QuizTimeSessionType>>>;
  answers?: Maybe<Array<Maybe<AnswersType>>>;
  availableClassRooms?: Maybe<Array<Maybe<ClassRoomLearningSessionType>>>;
  availableSessionsBySpecialityGroup?: Maybe<Array<Maybe<ClassRoomLearningSessionType>>>;
  certificate?: Maybe<CertificateType>;
  classRoom?: Maybe<ClassRoomType>;
  classRoomByOrganizer?: Maybe<Array<Maybe<ClassRoomLearningSessionType>>>;
  classRoomCorrectAnswersData?: Maybe<UserLearningSessionCorrectAnswersDataType>;
  classRoomLearningSession?: Maybe<ClassRoomLearningSessionType>;
  classRoomLectures?: Maybe<Array<Maybe<ClassRoomLectureType>>>;
  classRoomMembers?: Maybe<Array<Maybe<ClassRoomMemberType>>>;
  classRoomSessionLectures?: Maybe<Array<Maybe<LectureType>>>;
  code?: Maybe<CodesType>;
  codes?: Maybe<Array<Maybe<CodesType>>>;
  completedDuels?: Maybe<Array<Maybe<UsersDuelsDataType>>>;
  completedQuizDuels?: Maybe<Array<Maybe<UsersQuizDuelsDataType>>>;
  contentAvailable?: Maybe<Scalars['Boolean']>;
  currentLectorLectures?: Maybe<Array<Maybe<LectureType>>>;
  dailyStatus?: Maybe<Scalars['String']>;
  dailyTipperData?: Maybe<DailyTipperDataType>;
  dailyTipperWeeklyProgress?: Maybe<ActualLearnWeekType>;
  dataProtection?: Maybe<DataProtectionType>;
  dialogId?: Maybe<Scalars['String']>;
  discounts?: Maybe<Array<Maybe<CouponType>>>;
  duelData?: Maybe<DuelDataType>;
  educationLevels?: Maybe<Array<Maybe<EducationLevelType>>>;
  efnNumberVerify?: Maybe<Scalars['Boolean']>;
  emailVerify?: Maybe<Scalars['Boolean']>;
  explanation?: Maybe<ExplanationType>;
  featureFlags?: Maybe<Array<Maybe<FeatureFlagType>>>;
  findClassRooms?: Maybe<Array<Maybe<ClassRoomLearningSessionType>>>;
  finishedClassRooms?: Maybe<Array<Maybe<FinishedClassRoomLearningSessionType>>>;
  finishedQuizSessions?: Maybe<Array<Maybe<FinishedUserQuizSessionType>>>;
  getFeedbackForQuestion?: Maybe<FeedbackForQuestionType>;
  homeQuizSessions?: Maybe<Array<Maybe<HomeQuizTimeSessionsType>>>;
  impressum?: Maybe<ImpressumType>;
  inProgressUserQuizSession?: Maybe<QuizTimeSessionType>;
  joinedClassRooms?: Maybe<Scalars['Int']>;
  lastNotification?: Maybe<NotificationType>;
  lector?: Maybe<LectorType>;
  lecture?: Maybe<LectureType>;
  lekSession?: Maybe<LekSessionType>;
  lekSessionsData?: Maybe<Array<Maybe<UserLekDatasType>>>;
  me?: Maybe<UserType>;
  monthlyScore?: Maybe<MonthlyScoreType>;
  notifications?: Maybe<Array<Maybe<NotificationType>>>;
  ongoingClassRooms?: Maybe<Array<Maybe<OngoingUserLearningSessionType>>>;
  ongoingQuizSessions?: Maybe<Array<Maybe<QuizTimeSessionType>>>;
  organizer?: Maybe<OrganizerType>;
  privatePolicy?: Maybe<PrivatePolicyType>;
  professions?: Maybe<Array<Maybe<ProfessionType>>>;
  professionsByType?: Maybe<Array<Maybe<ProfessionType>>>;
  questions?: Maybe<Array<Maybe<QuestionType>>>;
  quizDuelScore?: Maybe<QuizDuelScoreType>;
  quizSessionMembers?: Maybe<Array<Maybe<QuizSessionMemberType>>>;
  quizSessionPrize?: Maybe<QuizSessionPrizeListType>;
  quizTime?: Maybe<QuizTimeType>;
  quizTimeSession?: Maybe<QuizTimeSessionType>;
  quizUsers?: Maybe<Array<Maybe<UserType>>>;
  ranking?: Maybe<Array<Maybe<MonthlyScoreType>>>;
  searchNetworkClassRoomSessions?: Maybe<Array<Maybe<ClassRoomLearningSessionType>>>;
  semesters?: Maybe<Array<Maybe<SemesterType>>>;
  specialities?: Maybe<Array<Maybe<SpecialityType>>>;
  specialitiesByType?: Maybe<Array<Maybe<SpecialityType>>>;
  specialityGroup?: Maybe<SpecialityGroupType>;
  /** @deprecated Deprecated due to removed specifications */
  specifications?: Maybe<Array<Maybe<SpecificationType>>>;
  sponsor?: Maybe<SponsorType>;
  startedDuelsToday?: Maybe<Scalars['Int']>;
  studySemesters?: Maybe<Array<Maybe<StudyBeginType>>>;
  studyYears?: Maybe<Array<Maybe<StudyYearType>>>;
  topUsers?: Maybe<Array<Maybe<MonthlyScoreType>>>;
  types?: Maybe<Array<Maybe<ProfessionTypeType>>>;
  universities?: Maybe<Array<Maybe<UniversityType>>>;
  userClassRoomSettings?: Maybe<UserClassRoomLearningSessionSettingsType>;
  userDuelsInfo?: Maybe<Array<Maybe<DuelResultUserDataType>>>;
  userLectureCorrectAnswersData?: Maybe<UserLectureCorrectAnswersDataType>;
  userLekData?: Maybe<UserLekDataType>;
  userQuestionsCountData?: Maybe<UserLearningSessionQuestionsDataType>;
  userQuizSessionSettings?: Maybe<UserQuizSessionSettingsType>;
  userSettings?: Maybe<SettingsType>;
  userSpecialityGroupList?: Maybe<Array<Maybe<UserSpecialityGroupType>>>;
  usernameVerify?: Maybe<Scalars['Boolean']>;
  usersDuels?: Maybe<Array<Maybe<DuelResultUserDataType>>>;
  workplaces?: Maybe<Array<Maybe<WorkplaceType>>>;
};


export type QueryAchievementsRangArgs = {
  showedInRankUsers?: Maybe<Scalars['Int']>;
};


export type QueryActiveQuizDuelsArgs = {
  quizSessionId?: Maybe<Scalars['UUID']>;
};


export type QueryAvailableClassRoomsArgs = {
  isNetworkType?: Maybe<Scalars['Boolean']>;
};


export type QueryAvailableSessionsBySpecialityGroupArgs = {
  specialityGroupId?: Maybe<Scalars['UUID']>;
};


export type QueryCertificateArgs = {
  certificateId?: Maybe<Scalars['UUID']>;
};


export type QueryClassRoomArgs = {
  classRoomId?: Maybe<Scalars['UUID']>;
};


export type QueryClassRoomByOrganizerArgs = {
  organizerId?: Maybe<Scalars['UUID']>;
};


export type QueryClassRoomCorrectAnswersDataArgs = {
  classRoomSessionId?: Maybe<Scalars['UUID']>;
};


export type QueryClassRoomLearningSessionArgs = {
  sessionId?: Maybe<Scalars['UUID']>;
};


export type QueryClassRoomLecturesArgs = {
  learningSessionId?: Maybe<Scalars['UUID']>;
};


export type QueryClassRoomMembersArgs = {
  classRoomSessionId?: Maybe<Scalars['UUID']>;
};


export type QueryClassRoomSessionLecturesArgs = {
  sessionId?: Maybe<Scalars['UUID']>;
  userSessionSettingsId?: Maybe<Scalars['UUID']>;
};


export type QueryCompletedQuizDuelsArgs = {
  quizSessionId?: Maybe<Scalars['UUID']>;
};


export type QueryCurrentLectorLecturesArgs = {
  lectorId?: Maybe<Scalars['UUID']>;
};


export type QueryDuelDataArgs = {
  duelId?: Maybe<Scalars['UUID']>;
};


export type QueryEfnNumberVerifyArgs = {
  efnNumber?: Maybe<Scalars['String']>;
};


export type QueryEmailVerifyArgs = {
  email?: Maybe<Scalars['String']>;
};


export type QueryExplanationArgs = {
  explanationId?: Maybe<Scalars['UUID']>;
};


export type QueryFindClassRoomsArgs = {
  searchPointer?: Maybe<Scalars['String']>;
};


export type QueryGetFeedbackForQuestionArgs = {
  questionId?: Maybe<Scalars['UUID']>;
};


export type QueryHomeQuizSessionsArgs = {
  startLess24Hours?: Maybe<Scalars['Boolean']>;
};


export type QueryLectorArgs = {
  lectorId?: Maybe<Scalars['UUID']>;
};


export type QueryLectureArgs = {
  lectureId?: Maybe<Scalars['UUID']>;
};


export type QueryLekSessionArgs = {
  lekSessionId?: Maybe<Scalars['UUID']>;
};


export type QueryLekSessionsDataArgs = {
  learningSessionId?: Maybe<Scalars['UUID']>;
};


export type QueryOngoingQuizSessionsArgs = {
  startLess24Hours?: Maybe<Scalars['Boolean']>;
};


export type QueryOrganizerArgs = {
  organizerId?: Maybe<Scalars['UUID']>;
};


export type QueryProfessionsByTypeArgs = {
  professionType?: Maybe<Scalars['Int']>;
};


export type QueryQuizDuelScoreArgs = {
  quizSessionId?: Maybe<Scalars['UUID']>;
};


export type QueryQuizSessionMembersArgs = {
  quizSessionId?: Maybe<Scalars['UUID']>;
};


export type QueryQuizSessionPrizeArgs = {
  quizSessionId?: Maybe<Scalars['UUID']>;
};


export type QueryQuizTimeArgs = {
  quizTimeId?: Maybe<Scalars['UUID']>;
};


export type QueryQuizTimeSessionArgs = {
  quizSessionId?: Maybe<Scalars['UUID']>;
};


export type QueryQuizUsersArgs = {
  quizSessionId?: Maybe<Scalars['UUID']>;
};


export type QuerySearchNetworkClassRoomSessionsArgs = {
  searchPointer?: Maybe<Scalars['String']>;
};


export type QuerySpecialitiesByTypeArgs = {
  professionType?: Maybe<Scalars['Int']>;
};


export type QuerySpecialityGroupArgs = {
  specialityGroupId?: Maybe<Scalars['UUID']>;
};


export type QuerySpecificationsArgs = {
  duelId?: Maybe<Scalars['UUID']>;
};


export type QueryUserClassRoomSettingsArgs = {
  learningSessionId?: Maybe<Scalars['UUID']>;
  userSessionSettingId?: Maybe<Scalars['UUID']>;
};


export type QueryUserLectureCorrectAnswersDataArgs = {
  classRoomSessionId?: Maybe<Scalars['UUID']>;
  lectureId?: Maybe<Scalars['UUID']>;
};


export type QueryUserLekDataArgs = {
  learningSessionId?: Maybe<Scalars['UUID']>;
  attemptNumber?: Maybe<Scalars['Int']>;
};


export type QueryUserQuestionsCountDataArgs = {
  sessionId?: Maybe<Scalars['UUID']>;
  userSessionSettingsId?: Maybe<Scalars['UUID']>;
};


export type QueryUserQuizSessionSettingsArgs = {
  quizSessionId?: Maybe<Scalars['UUID']>;
};


export type QueryUsernameVerifyArgs = {
  username?: Maybe<Scalars['String']>;
};


export type QueryWorkplacesArgs = {
  professionType?: Maybe<Scalars['Int']>;
};

export type QuestionLinkType = {
  __typename?: 'QuestionLinkType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  link: LinkType;
  question: QuestionType;
};

export type QuestionType = {
  __typename?: 'QuestionType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  questionImage?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  specifications: Array<SpecificationType>;
  specialities: Array<SpecialityType>;
  professions: Array<ProfessionType>;
  explanation?: Maybe<Scalars['String']>;
  freeTags: Array<FreeTagType>;
  icd10Tags: Array<Icd10TagType>;
  diseases: Array<DiseaseType>;
  /** The author of the question */
  author?: Maybe<UserType>;
  /** If set 'Private', then this question will be available for use only by the author. */
  isPrivate: Scalars['Boolean'];
  /** If checked, this question will be available for use on Networks. */
  useInNetwork: Scalars['Boolean'];
  /** If checked, this question will be available for use on Classroom. */
  useInClassRoom: Scalars['Boolean'];
  /** If checked, this question will be available for use on LEK. */
  useInLek: Scalars['Boolean'];
  /** If checked, this question will be available for use on QuizTime. */
  useInQuizTime: Scalars['Boolean'];
  /** If checked, this question was updated. */
  isUpdated: Scalars['Boolean'];
  dateOfManualUpdate?: Maybe<Scalars['Date']>;
  answers: Array<AnswersType>;
  links: Array<LinkType>;
  questionLink: Array<QuestionLinkType>;
  lecture: Array<LectureType>;
  lek: Array<LekType>;
  lekQuestions: Array<LekQuestionsType>;
  userLekQuestionAnswerData: Array<UserLekQuestionAnswerDataType>;
  dailytipperquestionanswerSet: Array<DailyTipperQuestionAnswerType>;
  quizTimes: Array<QuizTimeType>;
  userQuestions: Array<DuelRoundQuestionAnswerType>;
  feedbackForQuestion: Array<FeedbackForQuestionType>;
  sortedLinks?: Maybe<Array<Maybe<LinkType>>>;
};

export type QuizDuelScoreType = {
  __typename?: 'QuizDuelScoreType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  user?: Maybe<UserType>;
  duels: Scalars['Int'];
  wins: Scalars['Int'];
  loss: Scalars['Int'];
  ties: Scalars['Int'];
  points: Scalars['Int'];
  rank?: Maybe<Scalars['Int']>;
  quizSession: QuizTimeSessionType;
};

export enum QuizSessionLabelStatusesEnum {
  Active = 'ACTIVE',
  Paused = 'PAUSED',
  NotStarted = 'NOT_STARTED'
}

export type QuizSessionMemberType = {
  __typename?: 'QuizSessionMemberType';
  id?: Maybe<Scalars['UUID']>;
  userData?: Maybe<QuizDuelScoreType>;
  isAvailableForQuizDuel?: Maybe<Scalars['Boolean']>;
};

export type QuizSessionPrizeListType = {
  __typename?: 'QuizSessionPrizeListType';
  placeList?: Maybe<Array<Maybe<QuizSessionPrizeType>>>;
  participationList?: Maybe<Array<Maybe<QuizSessionPrizeType>>>;
  lotteryList?: Maybe<Array<Maybe<QuizSessionPrizeType>>>;
};

/** An enumeration. */
export enum QuizSessionPrizePrizeType {
  /** Place */
  Place = 'PLACE',
  /** Lottery */
  Lottery = 'LOTTERY',
  /** Participation */
  Participation = 'PARTICIPATION'
}

export type QuizSessionPrizeType = {
  __typename?: 'QuizSessionPrizeType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  prizeType?: Maybe<QuizSessionPrizePrizeType>;
  /** Only for Place type. */
  placeNumber?: Maybe<Scalars['Int']>;
  quizSession: QuizTimeSessionType;
  quizTimePrize: QuizTimePrizeType;
  isVisible: Scalars['Boolean'];
  roles?: Maybe<Scalars['String']>;
};

export type QuizStartByUserMutation = {
  __typename?: 'QuizStartByUserMutation';
  quizSession?: Maybe<QuizTimeSessionType>;
};

export type QuizTimePrizeType = {
  __typename?: 'QuizTimePrizeType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  prizeImage?: Maybe<Scalars['String']>;
  /** The author of the Prize */
  author?: Maybe<UserType>;
  quizSession: Array<QuizTimeSessionType>;
  quizSessionPrize: Array<QuizSessionPrizeType>;
};

/** An enumeration. */
export enum QuizTimeSessionAnnouncementPeriod {
  /** 2 */
  A_2 = 'A_2',
  /** 7 */
  A_7 = 'A_7',
  /** 14 */
  A_14 = 'A_14'
}

/** An enumeration. */
export enum QuizTimeSessionStartTime {
  /** 00:00 */
  A_00_00_00 = 'A_00_00_00',
  /** 00:15 */
  A_00_15_00 = 'A_00_15_00',
  /** 00:30 */
  A_00_30_00 = 'A_00_30_00',
  /** 00:45 */
  A_00_45_00 = 'A_00_45_00',
  /** 01:00 */
  A_01_00_00 = 'A_01_00_00',
  /** 01:15 */
  A_01_15_00 = 'A_01_15_00',
  /** 01:30 */
  A_01_30_00 = 'A_01_30_00',
  /** 01:45 */
  A_01_45_00 = 'A_01_45_00',
  /** 02:00 */
  A_02_00_00 = 'A_02_00_00',
  /** 02:15 */
  A_02_15_00 = 'A_02_15_00',
  /** 02:30 */
  A_02_30_00 = 'A_02_30_00',
  /** 02:45 */
  A_02_45_00 = 'A_02_45_00',
  /** 03:00 */
  A_03_00_00 = 'A_03_00_00',
  /** 03:15 */
  A_03_15_00 = 'A_03_15_00',
  /** 03:30 */
  A_03_30_00 = 'A_03_30_00',
  /** 03:45 */
  A_03_45_00 = 'A_03_45_00',
  /** 04:00 */
  A_04_00_00 = 'A_04_00_00',
  /** 04:15 */
  A_04_15_00 = 'A_04_15_00',
  /** 04:30 */
  A_04_30_00 = 'A_04_30_00',
  /** 04:45 */
  A_04_45_00 = 'A_04_45_00',
  /** 05:00 */
  A_05_00_00 = 'A_05_00_00',
  /** 05:15 */
  A_05_15_00 = 'A_05_15_00',
  /** 05:30 */
  A_05_30_00 = 'A_05_30_00',
  /** 05:45 */
  A_05_45_00 = 'A_05_45_00',
  /** 06:00 */
  A_06_00_00 = 'A_06_00_00',
  /** 06:15 */
  A_06_15_00 = 'A_06_15_00',
  /** 06:30 */
  A_06_30_00 = 'A_06_30_00',
  /** 06:45 */
  A_06_45_00 = 'A_06_45_00',
  /** 07:00 */
  A_07_00_00 = 'A_07_00_00',
  /** 07:15 */
  A_07_15_00 = 'A_07_15_00',
  /** 07:30 */
  A_07_30_00 = 'A_07_30_00',
  /** 07:45 */
  A_07_45_00 = 'A_07_45_00',
  /** 08:00 */
  A_08_00_00 = 'A_08_00_00',
  /** 08:15 */
  A_08_15_00 = 'A_08_15_00',
  /** 08:30 */
  A_08_30_00 = 'A_08_30_00',
  /** 08:45 */
  A_08_45_00 = 'A_08_45_00',
  /** 09:00 */
  A_09_00_00 = 'A_09_00_00',
  /** 09:15 */
  A_09_15_00 = 'A_09_15_00',
  /** 09:30 */
  A_09_30_00 = 'A_09_30_00',
  /** 09:45 */
  A_09_45_00 = 'A_09_45_00',
  /** 10:00 */
  A_10_00_00 = 'A_10_00_00',
  /** 10:15 */
  A_10_15_00 = 'A_10_15_00',
  /** 10:30 */
  A_10_30_00 = 'A_10_30_00',
  /** 10:45 */
  A_10_45_00 = 'A_10_45_00',
  /** 11:00 */
  A_11_00_00 = 'A_11_00_00',
  /** 11:15 */
  A_11_15_00 = 'A_11_15_00',
  /** 11:30 */
  A_11_30_00 = 'A_11_30_00',
  /** 11:45 */
  A_11_45_00 = 'A_11_45_00',
  /** 12:00 */
  A_12_00_00 = 'A_12_00_00',
  /** 12:15 */
  A_12_15_00 = 'A_12_15_00',
  /** 12:30 */
  A_12_30_00 = 'A_12_30_00',
  /** 12:45 */
  A_12_45_00 = 'A_12_45_00',
  /** 13:00 */
  A_13_00_00 = 'A_13_00_00',
  /** 13:15 */
  A_13_15_00 = 'A_13_15_00',
  /** 13:30 */
  A_13_30_00 = 'A_13_30_00',
  /** 13:45 */
  A_13_45_00 = 'A_13_45_00',
  /** 14:00 */
  A_14_00_00 = 'A_14_00_00',
  /** 14:15 */
  A_14_15_00 = 'A_14_15_00',
  /** 14:30 */
  A_14_30_00 = 'A_14_30_00',
  /** 14:45 */
  A_14_45_00 = 'A_14_45_00',
  /** 15:00 */
  A_15_00_00 = 'A_15_00_00',
  /** 15:15 */
  A_15_15_00 = 'A_15_15_00',
  /** 15:30 */
  A_15_30_00 = 'A_15_30_00',
  /** 15:45 */
  A_15_45_00 = 'A_15_45_00',
  /** 16:00 */
  A_16_00_00 = 'A_16_00_00',
  /** 16:15 */
  A_16_15_00 = 'A_16_15_00',
  /** 16:30 */
  A_16_30_00 = 'A_16_30_00',
  /** 16:45 */
  A_16_45_00 = 'A_16_45_00',
  /** 17:00 */
  A_17_00_00 = 'A_17_00_00',
  /** 17:15 */
  A_17_15_00 = 'A_17_15_00',
  /** 17:30 */
  A_17_30_00 = 'A_17_30_00',
  /** 17:45 */
  A_17_45_00 = 'A_17_45_00',
  /** 18:00 */
  A_18_00_00 = 'A_18_00_00',
  /** 18:15 */
  A_18_15_00 = 'A_18_15_00',
  /** 18:30 */
  A_18_30_00 = 'A_18_30_00',
  /** 18:45 */
  A_18_45_00 = 'A_18_45_00',
  /** 19:00 */
  A_19_00_00 = 'A_19_00_00',
  /** 19:15 */
  A_19_15_00 = 'A_19_15_00',
  /** 19:30 */
  A_19_30_00 = 'A_19_30_00',
  /** 19:45 */
  A_19_45_00 = 'A_19_45_00',
  /** 20:00 */
  A_20_00_00 = 'A_20_00_00',
  /** 20:15 */
  A_20_15_00 = 'A_20_15_00',
  /** 20:30 */
  A_20_30_00 = 'A_20_30_00',
  /** 20:45 */
  A_20_45_00 = 'A_20_45_00',
  /** 21:00 */
  A_21_00_00 = 'A_21_00_00',
  /** 21:15 */
  A_21_15_00 = 'A_21_15_00',
  /** 21:30 */
  A_21_30_00 = 'A_21_30_00',
  /** 21:45 */
  A_21_45_00 = 'A_21_45_00',
  /** 22:00 */
  A_22_00_00 = 'A_22_00_00',
  /** 22:15 */
  A_22_15_00 = 'A_22_15_00',
  /** 22:30 */
  A_22_30_00 = 'A_22_30_00',
  /** 22:45 */
  A_22_45_00 = 'A_22_45_00',
  /** 23:00 */
  A_23_00_00 = 'A_23_00_00',
  /** 23:15 */
  A_23_15_00 = 'A_23_15_00',
  /** 23:30 */
  A_23_30_00 = 'A_23_30_00',
  /** 23:45 */
  A_23_45_00 = 'A_23_45_00'
}

/** An enumeration. */
export enum QuizTimeSessionStatus {
  /** Pending */
  Pending = 'PENDING',
  /** Announced */
  Announced = 'ANNOUNCED',
  /** In Progress */
  InProgress = 'IN_PROGRESS',
  /** Waiting for finish */
  WaitingForFinish = 'WAITING_FOR_FINISH',
  /** Finished */
  Finished = 'FINISHED'
}

export type QuizTimeSessionType = {
  __typename?: 'QuizTimeSessionType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  status?: Maybe<QuizTimeSessionStatus>;
  code?: Maybe<Scalars['Int']>;
  quizTime: QuizTimeType;
  announcementPeriod?: Maybe<QuizTimeSessionAnnouncementPeriod>;
  startDate: Scalars['Date'];
  startTime?: Maybe<QuizTimeSessionStartTime>;
  quizTimeDuration?: Maybe<Scalars['Int']>;
  duelDuration?: Maybe<Scalars['Int']>;
  users: Array<UserType>;
  maxUserCapacity?: Maybe<Scalars['Int']>;
  /** Designates whether Quiz is available for users. */
  isVisible: Scalars['Boolean'];
  /** Designates whether Quiz is for users with 'Premium' status. */
  isForPremiumOnly: Scalars['Boolean'];
  /** Designates whether current session is for test. */
  isForTest: Scalars['Boolean'];
  /** Designates whether code is need to be created. */
  isUseCode: Scalars['Boolean'];
  startDateTime?: Maybe<Scalars['DateTime']>;
  finishDateTime?: Maybe<Scalars['DateTime']>;
  settingsSet: Array<SettingsType>;
  userQuizSessionSettings: Array<UserQuizSessionSettingsType>;
  quizDuelScore: Array<QuizDuelScoreType>;
  quiztimeprizeSet: Array<QuizTimePrizeType>;
  quizSessionPrize: Array<QuizSessionPrizeType>;
  duelResultUserData: Array<DuelResultUserDataType>;
  usersCount?: Maybe<Scalars['Int']>;
  userQuizSessionSetting?: Maybe<UserQuizSessionSettingsType>;
};

export type QuizTimeType = {
  __typename?: 'QuizTimeType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  quizTimeImage?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  description: Scalars['String'];
  participationConditions?: Maybe<Scalars['String']>;
  professions: Array<ProfessionType>;
  specialities: Array<SpecialityType>;
  diseases: Array<DiseaseType>;
  organizer: OrganizerType;
  sponsor: Array<SponsorType>;
  specifications: Array<SpecificationType>;
  icd10tags: Array<Icd10TagType>;
  freeTags: Array<FreeTagType>;
  questions: Array<QuestionType>;
  quizTimeSession: Array<QuizTimeSessionType>;
};

export type RenewSubscriptionMutation = {
  __typename?: 'RenewSubscriptionMutation';
  subscription?: Maybe<SubscriptionType>;
};

/** Reset Password Key Check Mutation. */
export type ResetPasswordKeyCheckMutation = {
  __typename?: 'ResetPasswordKeyCheckMutation';
  user?: Maybe<UserType>;
};

/** Resume Blended Class Room Session Mutation. */
export type ResumeBlendedClassRoomSessionMutation = {
  __typename?: 'ResumeBlendedClassRoomSessionMutation';
  learningSessionSettings?: Maybe<UserClassRoomLearningSessionSettingsType>;
};

export type SemesterType = {
  __typename?: 'SemesterType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  title: Scalars['String'];
  itemNumber?: Maybe<Scalars['Int']>;
  users: Array<UserType>;
};

/** Set Answer Mutation. */
export type SetAnswerMutation = {
  __typename?: 'SetAnswerMutation';
  duelRoundQuestionAnswer?: Maybe<DuelRoundQuestionAnswerType>;
};

/** Set DailyTipper Answer Mutation. */
export type SetDailyTipperAnswerMutation = {
  __typename?: 'SetDailyTipperAnswerMutation';
  dailyTipperQuestionAnswerStatus?: Maybe<DailyTipperQuestionAnswerDailyStatusType>;
};

export type SetLekAnswerMutation = {
  __typename?: 'SetLEKAnswerMutation';
  userLekQuestionAnswerData?: Maybe<UserLekQuestionAnswerDataType>;
};

/** Delete User Account mutation. */
export type SetUserExpoTokenMutation = {
  __typename?: 'SetUserExpoTokenMutation';
  result?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum SettingsDaysOfIgnoring {
  /** 0 */
  A_0 = 'A_0',
  /** 7 */
  A_7 = 'A_7',
  /** 30 */
  A_30 = 'A_30',
  /** 90 */
  A_90 = 'A_90'
}

/** An enumeration. */
export enum SettingsGoal {
  /** 4 */
  A_4 = 'A_4',
  /** 5 */
  A_5 = 'A_5',
  /** 6 */
  A_6 = 'A_6',
  /** 7 */
  A_7 = 'A_7'
}

export type SettingsType = {
  __typename?: 'SettingsType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  user: UserType;
  duelMode: Scalars['Boolean'];
  pushNotificationMode: Scalars['Boolean'];
  pushNotificationDuelMode: Scalars['Boolean'];
  pushNotificationDailyTipperMode: Scalars['Boolean'];
  pushNotificationQuizTimeMode: Scalars['Boolean'];
  pushNotificationPremiumMode: Scalars['Boolean'];
  pushNotificationNewChapterMode: Scalars['Boolean'];
  duelMailNotification: Scalars['Boolean'];
  dailyTipperMode: Scalars['Boolean'];
  dailyTipperModeUpdated?: Maybe<Scalars['Date']>;
  dailyTipperMailNotification: Scalars['Boolean'];
  goal: SettingsGoal;
  activeQuizTime?: Maybe<QuizTimeSessionType>;
  isDailyTipperRulesRead: Scalars['Boolean'];
  isDuelRulesRead: Scalars['Boolean'];
  isQuizDuelRulesRead: Scalars['Boolean'];
  isLikeHumeoQuestionRead: Scalars['Boolean'];
  isIntroCompleted: Scalars['Boolean'];
  isClassRoomIntroCompleted: Scalars['Boolean'];
  isCookieAccepted: Scalars['Boolean'];
  isIosInstallationPopupSkipped: Scalars['Boolean'];
  isChromeInstallationPopupSkipped: Scalars['Boolean'];
  isMarketingCookieAccepted: Scalars['Boolean'];
  /** Number of days when the user was notified of ignoring */
  daysOfIgnoring: SettingsDaysOfIgnoring;
  temp: Scalars['JSONString'];
};

export type SpecialityGroupType = {
  __typename?: 'SpecialityGroupType';
  id: Scalars['UUID'];
  name: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
};

export type SpecialityType = {
  __typename?: 'SpecialityType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  title: Scalars['String'];
  professionType: Array<ProfessionTypeType>;
  isAvailable: Scalars['Boolean'];
  network: Array<NetworkType>;
  codes: Array<CodesType>;
  users: Array<UserType>;
  questionsSet: Array<QuestionType>;
  lectors: Array<LectorType>;
  lecture: Array<LectureType>;
  classRooms: Array<ClassRoomType>;
  lekSet: Array<LekType>;
  quizTime: Array<QuizTimeType>;
};

export type SpecificationType = {
  __typename?: 'SpecificationType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  title: Scalars['String'];
  profession: Array<ProfessionType>;
  questionsCount: Scalars['Int'];
  questionsSet: Array<QuestionType>;
  lectureSet: Array<LectureType>;
  lekSet: Array<LekType>;
  duelRound: Array<DuelRoundType>;
  quiztimeSet: Array<QuizTimeType>;
};

export type SponsorType = {
  __typename?: 'SponsorType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  title: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  codes: Array<CodesType>;
  classRooms: Array<ClassRoomType>;
  quizTime: Array<QuizTimeType>;
};

export type StudyBeginType = {
  __typename?: 'StudyBeginType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  title: Scalars['String'];
  users: Array<UserType>;
};

export type StudyYearType = {
  __typename?: 'StudyYearType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  title?: Maybe<Scalars['String']>;
  users: Array<UserType>;
};

export type SubscribeMutation = {
  __typename?: 'SubscribeMutation';
  subscription?: Maybe<SubscriptionType>;
};

export type Subscription = {
  __typename?: 'Subscription';
  notificationCreated?: Maybe<NotificationType>;
};

export type SubscriptionType = {
  __typename?: 'SubscriptionType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  customer: CustomerType;
  lastError?: Maybe<Scalars['String']>;
  lastErrorCode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  upgradeStatus?: Maybe<Scalars['String']>;
  subscriptionType?: Maybe<Scalars['String']>;
  paymentSubscriptionType?: Maybe<Scalars['String']>;
  currentPeriodEnd?: Maybe<Scalars['DateTime']>;
  requestCancel?: Maybe<Scalars['Boolean']>;
};

export type SwitchPauseModeMutation = {
  __typename?: 'SwitchPauseModeMutation';
  userQuizSessionSettings?: Maybe<UserQuizSessionSettingsType>;
};


export type UniversityType = {
  __typename?: 'UniversityType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  title?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  users: Array<UserType>;
  /** Choose a university if the profession is "Medizinstudent(in)" */
  classroomSet: Array<ClassRoomType>;
};

export type UpdateFeedbackForQuestionMutation = {
  __typename?: 'UpdateFeedbackForQuestionMutation';
  feedbackForQuestion?: Maybe<FeedbackForQuestionType>;
};


export type UserClassRoomLearningSessionSettingsMutation = {
  __typename?: 'UserClassRoomLearningSessionSettingsMutation';
  userClassRoomSettings?: Maybe<UserClassRoomLearningSessionSettingsType>;
};

export type UserClassRoomLearningSessionSettingsType = {
  __typename?: 'UserClassRoomLearningSessionSettingsType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  classRoomLearningSession: ClassRoomLearningSessionType;
  user: UserType;
  isDuelModeEnabled: Scalars['Boolean'];
  isDailyTipperModeEnabled: Scalars['Boolean'];
  lecturesGoal: Scalars['JSONString'];
  isAchieved: Scalars['Boolean'];
  isClosed: Scalars['Boolean'];
  isOut: Scalars['Boolean'];
  isFirstBlendedClassRoom: Scalars['Boolean'];
  specialityGroupIcon?: Maybe<Scalars['String']>;
  userClassRoomCorrectAnswersData: Array<UserLearningSessionCorrectAnswersDataType>;
  userLectureCorrectAnswersData: Array<UserLectureCorrectAnswersDataType>;
  duelResultUserData: Array<DuelResultUserDataType>;
};

/** Confirm User mutation. */
export type UserConfirmMutation = {
  __typename?: 'UserConfirmMutation';
  user?: Maybe<UserType>;
};

/** Forgot Password User mutation. */
export type UserForgotPasswordMutation = {
  __typename?: 'UserForgotPasswordMutation';
  user?: Maybe<UserType>;
};

/** An enumeration. */
export enum UserLekDataStatus {
  /** In Progress */
  InProgress = 'IN_PROGRESS',
  /** Finished */
  Finished = 'FINISHED',
  /** Ignored */
  Ignored = 'IGNORED'
}

export type UserLekDataType = {
  __typename?: 'UserLEKDataType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  user: UserType;
  lekSession: LekSessionType;
  correctAnswerCount: Scalars['Int'];
  elapsedTime?: Maybe<Scalars['Float']>;
  status?: Maybe<UserLekDataStatus>;
  isPassed: Scalars['Boolean'];
  attemptNumber: Scalars['Int'];
  userLekQuestionAnswerData: Array<UserLekQuestionAnswerDataType>;
};

export type UserLekDatasType = {
  __typename?: 'UserLEKDatasType';
  id?: Maybe<Scalars['UUID']>;
  lekSession?: Maybe<LekSessionType>;
  userLekData?: Maybe<UserLekDataType>;
};

export type UserLekQuestionAnswerDataType = {
  __typename?: 'UserLEKQuestionAnswerDataType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  user: UserType;
  userLekData: UserLekDataType;
  question: QuestionType;
  correctAnswer?: Maybe<AnswersType>;
  userAnswer: AnswersType;
  isCorrect: Scalars['Boolean'];
};

export type UserLearningSessionCorrectAnswersDataType = {
  __typename?: 'UserLearningSessionCorrectAnswersDataType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  classRoomLearningSession: ClassRoomLearningSessionType;
  user: UserType;
  classRoomCorrectAnswersCount: Scalars['Int'];
  userClassRoomLearningSessionSettings?: Maybe<UserClassRoomLearningSessionSettingsType>;
};

export type UserLearningSessionQuestionsDataType = {
  __typename?: 'UserLearningSessionQuestionsDataType';
  user?: Maybe<UserType>;
  session?: Maybe<ClassRoomLearningSessionType>;
  questionCount?: Maybe<Scalars['Int']>;
  questionCountTotal?: Maybe<Scalars['Int']>;
  correctAnswerCount?: Maybe<Scalars['Int']>;
  wrongAnswerCount?: Maybe<Scalars['Int']>;
  questionCountRepeat?: Maybe<Scalars['Int']>;
  correctAnswerCountRepeat?: Maybe<Scalars['Int']>;
  generalQuestionCount?: Maybe<Scalars['Int']>;
};

export type UserLectureCorrectAnswersDataType = {
  __typename?: 'UserLectureCorrectAnswersDataType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  classRoomLearningSession: ClassRoomLearningSessionType;
  user: UserType;
  userClassRoomLearningSessionSettings?: Maybe<UserClassRoomLearningSessionSettingsType>;
  lecture: LectureType;
  lectureCorrectAnswersCount: Scalars['Int'];
};

export type UserLoginMutation = {
  __typename?: 'UserLoginMutation';
  user?: Maybe<UserType>;
  token?: Maybe<Scalars['String']>;
};

export type UserPhotoMutations = {
  __typename?: 'UserPhotoMutations';
  user?: Maybe<UserType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UserQuizSessionSettingsType = {
  __typename?: 'UserQuizSessionSettingsType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  userStatus?: Maybe<UserQuizSessionSettingsUserStatus>;
  user: UserType;
  quizSession: QuizTimeSessionType;
  isQuizSessionAvailableToStart?: Maybe<Scalars['Boolean']>;
  quizSessionLabelStatus?: Maybe<QuizSessionLabelStatusesEnum>;
};

/** An enumeration. */
export enum UserQuizSessionSettingsUserStatus {
  /** Waiting for joining */
  WaitingForJoining = 'WAITING_FOR_JOINING',
  /** Joined */
  Joined = 'JOINED',
  /** Paused */
  Paused = 'PAUSED',
  /** Out */
  Out = 'OUT',
  /** In Progress */
  InProgress = 'IN_PROGRESS',
  /** Left */
  Left = 'LEFT',
  /** Finished */
  Finished = 'FINISHED'
}

/** User Registration mutation. */
export type UserRegistrationMutation = {
  __typename?: 'UserRegistrationMutation';
  user?: Maybe<UserType>;
  token?: Maybe<Scalars['String']>;
};

/** Restore Password User mutation. */
export type UserRestorePasswordMutation = {
  __typename?: 'UserRestorePasswordMutation';
  user?: Maybe<UserType>;
};

/** Update User Settings data mutation. */
export type UserSettingsUpdateMutation = {
  __typename?: 'UserSettingsUpdateMutation';
  userSettings?: Maybe<SettingsType>;
};

export type UserSpecialityGroupType = {
  __typename?: 'UserSpecialityGroupType';
  id?: Maybe<Scalars['UUID']>;
  classRoomSessionCount?: Maybe<Scalars['Int']>;
  specialityGroup?: Maybe<SpecialityGroupType>;
};

/** Re Subscribe To News User mutation. */
export type UserSubscribeToNewsMutation = {
  __typename?: 'UserSubscribeToNewsMutation';
  user?: Maybe<UserType>;
};

export type UserType = {
  __typename?: 'UserType';
  lastLogin?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['UUID']>;
  avatar?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  gender?: Maybe<Scalars['Int']>;
  address?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  profession?: Maybe<ProfessionType>;
  anotherProfession?: Maybe<Scalars['String']>;
  speciality?: Maybe<SpecialityType>;
  workplace?: Maybe<WorkplaceType>;
  anotherWorkplace?: Maybe<Scalars['String']>;
  efnNumber?: Maybe<Scalars['String']>;
  educationLevel?: Maybe<EducationLevelType>;
  university?: Maybe<UniversityType>;
  semester?: Maybe<SemesterType>;
  registrationNumber?: Maybe<Scalars['String']>;
  medicineStudyBegin?: Maybe<Scalars['Date']>;
  studyBegin?: Maybe<StudyBeginType>;
  studyYear?: Maybe<StudyYearType>;
  examYear?: Maybe<Scalars['Int']>;
  networkAccessCode: Array<CodesType>;
  maxDuelCount?: Maybe<Scalars['Int']>;
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean'];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean'];
  dateJoined: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isAdmin: Scalars['Boolean'];
  isSuperuser: Scalars['Boolean'];
  confirmationToken?: Maybe<Scalars['UUID']>;
  subscribeToNews?: Maybe<Scalars['Boolean']>;
  isPremium?: Maybe<Scalars['Boolean']>;
  /** Designates whether confirmation email was sent to user in 24h. after registration. */
  tokenSent: Scalars['Boolean'];
  resetPasswordKey?: Maybe<Scalars['String']>;
  network?: Maybe<NetworkType>;
  isAvailableForDuel: Scalars['Boolean'];
  /** Set True if the user will use in a test */
  isUserForTest: Scalars['Boolean'];
  isBot: Scalars['Boolean'];
  settings?: Maybe<SettingsType>;
  /** The author of the question */
  questionsSet: Array<QuestionType>;
  classRoomLearningSession: Array<ClassRoomLearningSessionType>;
  userClassRoomLearningSessionSettings: Array<UserClassRoomLearningSessionSettingsType>;
  userClassRoomCorrectAnswersData: Array<UserLearningSessionCorrectAnswersDataType>;
  userLekData: Array<UserLekDataType>;
  userLekQuestionAnswerData: Array<UserLekQuestionAnswerDataType>;
  userLectureCorrectAnswersData: Array<UserLectureCorrectAnswersDataType>;
  dailytipper?: Maybe<DailyTipperType>;
  dailytipperquestionanswerSet: Array<DailyTipperQuestionAnswerType>;
  quizTimeSession: Array<QuizTimeSessionType>;
  userQuizSessionSettings: Array<UserQuizSessionSettingsType>;
  quizduelscore: Array<QuizDuelScoreType>;
  /** The author of the Prize */
  quiztimeprizeSet: Array<QuizTimePrizeType>;
  duelResultUserData: Array<DuelResultUserDataType>;
  userQuestions: Array<DuelRoundQuestionAnswerType>;
  monthlyscore: Array<MonthlyScoreType>;
  notifications: Array<NotificationType>;
  customer?: Maybe<CustomerType>;
  inAppSubscription?: Maybe<InAppSubscriptionType>;
  feedbackForQuestion: Array<FeedbackForQuestionType>;
  professionId?: Maybe<Scalars['UUID']>;
  specialityId?: Maybe<Scalars['UUID']>;
  workplaceId?: Maybe<Scalars['UUID']>;
  educationLevelId?: Maybe<Scalars['UUID']>;
  universityId?: Maybe<Scalars['UUID']>;
  semesterId?: Maybe<Scalars['UUID']>;
  studyBeginId?: Maybe<Scalars['UUID']>;
  studyYearId?: Maybe<Scalars['UUID']>;
  isStripePremium?: Maybe<Scalars['Boolean']>;
  isInAppPremium?: Maybe<Scalars['Boolean']>;
  isPromotionPremium?: Maybe<Scalars['Boolean']>;
  referralCount?: Maybe<Scalars['Int']>;
  promotionFinishDate?: Maybe<Scalars['DateTime']>;
};

/** Unsubscribe To News User mutation. */
export type UserUnsubscribeToNewsMutation = {
  __typename?: 'UserUnsubscribeToNewsMutation';
  user?: Maybe<UserType>;
};

/** Update User data mutation. */
export type UserUpdateProfileMutation = {
  __typename?: 'UserUpdateProfileMutation';
  user?: Maybe<UserType>;
  token?: Maybe<Scalars['String']>;
};

export type UsersDuelsDataType = {
  __typename?: 'UsersDuelsDataType';
  id?: Maybe<Scalars['UUID']>;
  userDuelsData?: Maybe<DuelResultUserDataType>;
  opponentDuelsData?: Maybe<DuelResultUserDataType>;
  session?: Maybe<ClassRoomLearningSessionType>;
};

export type UsersQuizDuelsDataType = {
  __typename?: 'UsersQuizDuelsDataType';
  id?: Maybe<Scalars['UUID']>;
  userDuelsData?: Maybe<DuelResultUserDataType>;
  opponentDuelsData?: Maybe<DuelResultUserDataType>;
  session?: Maybe<QuizTimeSessionType>;
};

export type WorkplaceType = {
  __typename?: 'WorkplaceType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  title: Scalars['String'];
  professionType: Array<ProfessionTypeType>;
  users: Array<UserType>;
};

export type YearAchievementType = {
  __typename?: 'YearAchievementType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  yearNumber: Scalars['Int'];
  yearAchievement: Array<DailyTipperType>;
};

export type _DefaultCardType = {
  __typename?: '_DefaultCardType';
  brand?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
};

export type User_FragmentFragment = (
  { __typename?: 'UserType' }
  & Pick<UserType, 'id' | 'avatar' | 'firstName' | 'lastName' | 'username' | 'email' | 'dateOfBirth' | 'gender' | 'postcode' | 'dateJoined' | 'confirmationToken' | 'anotherProfession' | 'anotherWorkplace' | 'efnNumber' | 'registrationNumber' | 'medicineStudyBegin' | 'examYear' | 'professionId' | 'specialityId' | 'workplaceId' | 'educationLevelId' | 'universityId' | 'semesterId' | 'studyBeginId' | 'studyYearId' | 'isPremium' | 'isInAppPremium' | 'isPromotionPremium' | 'isUserForTest' | 'referralCount' | 'subscribeToNews'>
  & { profession?: Maybe<(
    { __typename?: 'ProfessionType' }
    & Pick<ProfessionType, 'id' | 'title'>
    & { type?: Maybe<(
      { __typename?: 'ProfessionTypeType' }
      & Pick<ProfessionTypeType, 'id' | 'title' | 'type'>
    )> }
  )>, speciality?: Maybe<(
    { __typename?: 'SpecialityType' }
    & Pick<SpecialityType, 'id' | 'title'>
  )>, workplace?: Maybe<(
    { __typename?: 'WorkplaceType' }
    & Pick<WorkplaceType, 'id' | 'title'>
  )>, educationLevel?: Maybe<(
    { __typename?: 'EducationLevelType' }
    & Pick<EducationLevelType, 'id' | 'title'>
  )>, university?: Maybe<(
    { __typename?: 'UniversityType' }
    & Pick<UniversityType, 'id' | 'title'>
  )>, semester?: Maybe<(
    { __typename?: 'SemesterType' }
    & Pick<SemesterType, 'id' | 'title'>
  )>, studyBegin?: Maybe<(
    { __typename?: 'StudyBeginType' }
    & Pick<StudyBeginType, 'id' | 'title'>
  )>, studyYear?: Maybe<(
    { __typename?: 'StudyYearType' }
    & Pick<StudyYearType, 'id' | 'title'>
  )>, classRoomLearningSession: Array<(
    { __typename?: 'ClassRoomLearningSessionType' }
    & Pick<ClassRoomLearningSessionType, 'id'>
  )>, network?: Maybe<(
    { __typename?: 'NetworkType' }
    & Pick<NetworkType, 'title'>
  )>, customer?: Maybe<(
    { __typename?: 'CustomerType' }
    & Pick<CustomerType, 'id'>
    & { subscription?: Maybe<(
      { __typename?: 'SubscriptionType' }
      & Pick<SubscriptionType, 'id' | 'subscriptionType' | 'status' | 'upgradeStatus' | 'currentPeriodEnd' | 'requestCancel' | 'lastError' | 'lastErrorCode'>
    )>, defaultCard?: Maybe<(
      { __typename?: '_DefaultCardType' }
      & Pick<_DefaultCardType, 'brand' | 'last4'>
    )> }
  )> }
);

export type User_Settings_FragmentFragment = (
  { __typename?: 'SettingsType' }
  & Pick<SettingsType, 'id' | 'duelMode' | 'goal' | 'dailyTipperMode' | 'pushNotificationMode' | 'pushNotificationDailyTipperMode' | 'pushNotificationDuelMode' | 'pushNotificationNewChapterMode' | 'pushNotificationPremiumMode' | 'pushNotificationQuizTimeMode' | 'isDailyTipperRulesRead' | 'isDuelRulesRead' | 'isQuizDuelRulesRead' | 'isLikeHumeoQuestionRead' | 'isIntroCompleted' | 'isClassRoomIntroCompleted' | 'isCookieAccepted' | 'isMarketingCookieAccepted' | 'isIosInstallationPopupSkipped' | 'isChromeInstallationPopupSkipped'>
  & { activeQuizTime?: Maybe<(
    { __typename?: 'QuizTimeSessionType' }
    & Pick<QuizTimeSessionType, 'id'>
  )> }
);

export type Lecture_FragmentFragment = (
  { __typename?: 'LectureType' }
  & Pick<LectureType, 'id' | 'title' | 'description' | 'lectureLink' | 'correctAnswersGoal'>
  & { questions: Array<(
    { __typename?: 'QuestionType' }
    & Pick<QuestionType, 'id'>
  )>, lectors: Array<(
    { __typename?: 'LectorType' }
    & Pick<LectorType, 'id' | 'firstName' | 'lastName' | 'photo'>
    & { professions: Array<(
      { __typename?: 'ProfessionType' }
      & Pick<ProfessionType, 'id' | 'title'>
    )>, specialities: Array<(
      { __typename?: 'SpecialityType' }
      & Pick<SpecialityType, 'id' | 'title'>
    )> }
  )> }
);

export type Classroom_Ls_FragmentFragment = (
  { __typename?: 'ClassRoomLearningSessionType' }
  & Pick<ClassRoomLearningSessionType, 'id' | 'status' | 'startDate' | 'finishDate' | 'isForPremiumOnly' | 'duration'>
  & { classRoom: (
    { __typename?: 'ClassRoomType' }
    & Pick<ClassRoomType, 'id' | 'title' | 'classRoomImage' | 'backgroundImage' | 'enrollmentType'>
    & { aggregatedRate?: Maybe<(
      { __typename?: 'AggregatedRateType' }
      & Pick<AggregatedRateType, 'id' | 'averageRate' | 'feedbackCount'>
    )>, certificate?: Maybe<(
      { __typename?: 'CertificateType' }
      & Pick<CertificateType, 'id' | 'points'>
    )>, organizer?: Maybe<(
      { __typename?: 'OrganizerType' }
      & Pick<OrganizerType, 'id' | 'name'>
    )> }
  ) }
);

export type User_Question_FragmentFragment = (
  { __typename?: 'DuelRoundQuestionAnswerType' }
  & Pick<DuelRoundQuestionAnswerType, 'id' | 'questionNumber'>
  & { user?: Maybe<(
    { __typename?: 'UserType' }
    & Pick<UserType, 'id'>
  )>, question?: Maybe<(
    { __typename?: 'QuestionType' }
    & Pick<QuestionType, 'id' | 'value' | 'questionImage' | 'explanation'>
    & { sortedLinks?: Maybe<Array<Maybe<(
      { __typename?: 'LinkType' }
      & Pick<LinkType, 'id' | 'url'>
      & { linkCategory: (
        { __typename?: 'LinkCategoryType' }
        & Pick<LinkCategoryType, 'id' | 'title'>
      ) }
    )>>>, answers: Array<(
      { __typename?: 'AnswersType' }
      & Pick<AnswersType, 'id' | 'value'>
    )> }
  )>, userAnswer?: Maybe<(
    { __typename?: 'AnswersType' }
    & Pick<AnswersType, 'id' | 'value'>
  )>, correctAnswer?: Maybe<(
    { __typename?: 'AnswersType' }
    & Pick<AnswersType, 'id' | 'value'>
  )> }
);

export type Daily_Tipper_Question_FragmentFragment = (
  { __typename?: 'DailyTipperQuestionAnswerType' }
  & Pick<DailyTipperQuestionAnswerType, 'id' | 'questionNumber'>
  & { question?: Maybe<(
    { __typename?: 'QuestionType' }
    & Pick<QuestionType, 'id' | 'value' | 'questionImage' | 'explanation'>
    & { sortedLinks?: Maybe<Array<Maybe<(
      { __typename?: 'LinkType' }
      & Pick<LinkType, 'id' | 'url'>
      & { linkCategory: (
        { __typename?: 'LinkCategoryType' }
        & Pick<LinkCategoryType, 'id' | 'title'>
      ) }
    )>>>, answers: Array<(
      { __typename?: 'AnswersType' }
      & Pick<AnswersType, 'id' | 'value'>
    )> }
  )>, userAnswer?: Maybe<(
    { __typename?: 'AnswersType' }
    & Pick<AnswersType, 'id' | 'value'>
  )>, correctAnswer?: Maybe<(
    { __typename?: 'AnswersType' }
    & Pick<AnswersType, 'id' | 'value'>
  )>, actualLearningWeek?: Maybe<(
    { __typename?: 'ActualLearnWeekType' }
    & Pick<ActualLearnWeekType, 'id' | 'weekProgress'>
  )> }
);

export type User_Score_FragmentFragment = (
  { __typename?: 'DuelResultUserDataType' }
  & Pick<DuelResultUserDataType, 'id' | 'initiator' | 'youTurn' | 'duelScore' | 'userStatus'>
  & { user?: Maybe<(
    { __typename?: 'UserType' }
    & Pick<UserType, 'id' | 'avatar' | 'username'>
  )>, classRoomLearningSession?: Maybe<(
    { __typename?: 'ClassRoomLearningSessionType' }
    & Pick<ClassRoomLearningSessionType, 'id'>
    & { classRoom: (
      { __typename?: 'ClassRoomType' }
      & Pick<ClassRoomType, 'id' | 'title'>
    ) }
  )> }
);

export type Quiztime_List_FragmentFragment = (
  { __typename?: 'QuizTimeSessionType' }
  & Pick<QuizTimeSessionType, 'id' | 'status' | 'startDate' | 'quizTimeDuration' | 'startDateTime' | 'finishDateTime' | 'isForPremiumOnly'>
  & { userQuizSessionSetting?: Maybe<(
    { __typename?: 'UserQuizSessionSettingsType' }
    & Pick<UserQuizSessionSettingsType, 'id' | 'userStatus' | 'quizSessionLabelStatus'>
  )>, quizTime: (
    { __typename?: 'QuizTimeType' }
    & Pick<QuizTimeType, 'id' | 'quizTimeImage' | 'title'>
    & { organizer: (
      { __typename?: 'OrganizerType' }
      & Pick<OrganizerType, 'id' | 'name'>
    ) }
  ) }
);

export type Quiztime_Homelist_FragmentFragment = (
  { __typename?: 'QuizTimeSessionType' }
  & Pick<QuizTimeSessionType, 'id' | 'startDate' | 'startDateTime' | 'finishDateTime' | 'status' | 'quizTimeDuration' | 'duelDuration'>
  & { quizTime: (
    { __typename?: 'QuizTimeType' }
    & Pick<QuizTimeType, 'id' | 'title' | 'quizTimeImage'>
  ) }
);

export type Quiz_Prize_ItemFragment = (
  { __typename?: 'QuizSessionPrizeType' }
  & Pick<QuizSessionPrizeType, 'id' | 'placeNumber' | 'prizeType' | 'roles'>
  & { quizTimePrize: (
    { __typename?: 'QuizTimePrizeType' }
    & Pick<QuizTimePrizeType, 'id' | 'title' | 'description' | 'prizeImage'>
  ) }
);

export type SignInMutationVariables = Exact<{
  email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  password: Scalars['String'];
}>;


export type SignInMutation = (
  { __typename?: 'Mutation' }
  & { userLogin?: Maybe<(
    { __typename?: 'UserLoginMutation' }
    & Pick<UserLoginMutation, 'token'>
    & { user?: Maybe<(
      { __typename?: 'UserType' }
      & User_FragmentFragment
    )> }
  )> }
);

export type SignUpMutationVariables = Exact<{
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  gender?: Maybe<Scalars['Int']>;
  postcode?: Maybe<Scalars['String']>;
  specialityId?: Maybe<Scalars['UUID']>;
  anotherWorkplace?: Maybe<Scalars['String']>;
  anotherProfession?: Maybe<Scalars['String']>;
  efnNumber?: Maybe<Scalars['String']>;
  examYear?: Maybe<Scalars['Int']>;
  subscribeToNews?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  passwordConfirmation?: Maybe<Scalars['String']>;
  studyBeginId?: Maybe<Scalars['UUID']>;
  educationLevelId?: Maybe<Scalars['UUID']>;
  universityId?: Maybe<Scalars['UUID']>;
  semesterId?: Maybe<Scalars['UUID']>;
  sharingSenderId?: Maybe<Scalars['UUID']>;
  registrationNumber?: Maybe<Scalars['String']>;
  medicineStudyBegin?: Maybe<Scalars['Date']>;
  workplaceId?: Maybe<Scalars['UUID']>;
  professionId?: Maybe<Scalars['UUID']>;
  studyYearId?: Maybe<Scalars['UUID']>;
  deepLinkHash?: Maybe<Scalars['String']>;
}>;


export type SignUpMutation = (
  { __typename?: 'Mutation' }
  & { userRegistration?: Maybe<(
    { __typename?: 'UserRegistrationMutation' }
    & Pick<UserRegistrationMutation, 'token'>
    & { user?: Maybe<(
      { __typename?: 'UserType' }
      & User_FragmentFragment
    )> }
  )> }
);

export type UpdateUserProfileMutationVariables = Exact<{
  anotherWorkplace?: Maybe<Scalars['String']>;
  anotherProfession?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  educationLevelId?: Maybe<Scalars['UUID']>;
  examYear?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  medicineStudyBegin?: Maybe<Scalars['Date']>;
  postcode?: Maybe<Scalars['String']>;
  registrationNumber?: Maybe<Scalars['String']>;
  semesterId?: Maybe<Scalars['UUID']>;
  specialityId?: Maybe<Scalars['UUID']>;
  studyBeginId?: Maybe<Scalars['UUID']>;
  studyYearId?: Maybe<Scalars['UUID']>;
  universityId?: Maybe<Scalars['UUID']>;
  username?: Maybe<Scalars['String']>;
  workplaceId?: Maybe<Scalars['UUID']>;
}>;


export type UpdateUserProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateUserData?: Maybe<(
    { __typename?: 'UserUpdateProfileMutation' }
    & Pick<UserUpdateProfileMutation, 'token'>
    & { user?: Maybe<(
      { __typename?: 'UserType' }
      & User_FragmentFragment
    )> }
  )> }
);

export type ResetPasswordMutationVariables = Exact<{
  newPassword?: Maybe<Scalars['String']>;
  newPasswordConfirmation?: Maybe<Scalars['String']>;
  resetPasswordKey?: Maybe<Scalars['String']>;
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { userRestorePassword?: Maybe<(
    { __typename?: 'UserRestorePasswordMutation' }
    & { user?: Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'id'>
    )> }
  )> }
);

export type ConfirmTokenMutationVariables = Exact<{
  token?: Maybe<Scalars['UUID']>;
}>;


export type ConfirmTokenMutation = (
  { __typename?: 'Mutation' }
  & { userConfirm?: Maybe<(
    { __typename?: 'UserConfirmMutation' }
    & { user?: Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'id'>
    )> }
  )> }
);

export type UploadPhotoMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadPhotoMutation = (
  { __typename?: 'Mutation' }
  & { uploadPhoto?: Maybe<(
    { __typename?: 'UserPhotoMutations' }
    & { user?: Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'id' | 'avatar'>
    )> }
  )> }
);

export type ApplyNetworkCodeMutationVariables = Exact<{
  code?: Maybe<Scalars['Int']>;
}>;


export type ApplyNetworkCodeMutation = (
  { __typename?: 'Mutation' }
  & { applyNetworkCodeForUser?: Maybe<(
    { __typename?: 'ApplyNetworkCodeForUserMutation' }
    & { codes?: Maybe<Array<Maybe<(
      { __typename?: 'CodesType' }
      & Pick<CodesType, 'code'>
    )>>> }
  )> }
);

export type ApplyClassroomCodeMutationVariables = Exact<{
  code?: Maybe<Scalars['Int']>;
  sessionId?: Maybe<Scalars['UUID']>;
  isNetworkType?: Maybe<Scalars['Boolean']>;
  isAvailableTab?: Maybe<Scalars['Boolean']>;
  specialityGroupIcon?: Maybe<Scalars['String']>;
  deepLinkHash?: Maybe<Scalars['String']>;
}>;


export type ApplyClassroomCodeMutation = (
  { __typename?: 'Mutation' }
  & { applyClassRoomCode?: Maybe<(
    { __typename?: 'ApplyClassRoomCodeMutation' }
    & { classRoom?: Maybe<(
      { __typename?: 'ClassRoomLearningSessionType' }
      & Pick<ClassRoomLearningSessionType, 'id'>
      & { classRoom: (
        { __typename?: 'ClassRoomType' }
        & Pick<ClassRoomType, 'id' | 'enrollmentType'>
      ) }
    )> }
  )> }
);

export type JoinNetworkClassRoomMutationVariables = Exact<{
  sessionId?: Maybe<Scalars['UUID']>;
  deepLinkHash?: Maybe<Scalars['String']>;
  specialityGroupIcon?: Maybe<Scalars['String']>;
  isAfterOnboarding?: Maybe<Scalars['Boolean']>;
}>;


export type JoinNetworkClassRoomMutation = (
  { __typename?: 'Mutation' }
  & { joinToNetworkClassRoom?: Maybe<(
    { __typename?: 'JoinToNetworkClassRoomMutation' }
    & { classRoomSession?: Maybe<(
      { __typename?: 'ClassRoomLearningSessionType' }
      & Pick<ClassRoomLearningSessionType, 'id'>
      & { classRoom: (
        { __typename?: 'ClassRoomType' }
        & Pick<ClassRoomType, 'id' | 'enrollmentType'>
      ) }
    )> }
  )> }
);

export type JoinBlendedClassRoomMutationVariables = Exact<{
  sessionId?: Maybe<Scalars['UUID']>;
  deepLinkHash?: Maybe<Scalars['String']>;
}>;


export type JoinBlendedClassRoomMutation = (
  { __typename?: 'Mutation' }
  & { joinToBlendedClassRoom?: Maybe<(
    { __typename?: 'JoinToBlendedClassRoomMutation' }
    & { classRoomSession?: Maybe<(
      { __typename?: 'ClassRoomLearningSessionType' }
      & Pick<ClassRoomLearningSessionType, 'id'>
      & { classRoom: (
        { __typename?: 'ClassRoomType' }
        & Pick<ClassRoomType, 'id' | 'enrollmentType'>
      ) }
    )> }
  )> }
);

export type JoinLekClassRoomMutationVariables = Exact<{
  sessionId?: Maybe<Scalars['UUID']>;
  deepLinkHash?: Maybe<Scalars['String']>;
}>;


export type JoinLekClassRoomMutation = (
  { __typename?: 'Mutation' }
  & { joinToLekClassRoom?: Maybe<(
    { __typename?: 'JoinToLEKClassRoomMutation' }
    & { classRoomSession?: Maybe<(
      { __typename?: 'ClassRoomLearningSessionType' }
      & Pick<ClassRoomLearningSessionType, 'id'>
      & { classRoom: (
        { __typename?: 'ClassRoomType' }
        & Pick<ClassRoomType, 'id' | 'enrollmentType'>
      ) }
    )> }
  )> }
);

export type CreateUserDuelMutationVariables = Exact<{
  opponentId?: Maybe<Scalars['UUID']>;
  sessionId?: Maybe<Scalars['UUID']>;
  isCustomDuel?: Maybe<Scalars['Boolean']>;
}>;


export type CreateUserDuelMutation = (
  { __typename?: 'Mutation' }
  & { createDuel?: Maybe<(
    { __typename?: 'CreateDuelMutation' }
    & Pick<CreateDuelMutation, 'errors'>
    & { duel?: Maybe<(
      { __typename?: 'DuelType' }
      & Pick<DuelType, 'id' | 'status'>
      & { duelRound: Array<(
        { __typename?: 'DuelRoundType' }
        & Pick<DuelRoundType, 'id' | 'roundNumber'>
        & { userQuestions: Array<(
          { __typename?: 'DuelRoundQuestionAnswerType' }
          & User_Question_FragmentFragment
        )> }
      )> }
    )> }
  )> }
);

export type CreateUserRoundMutationVariables = Exact<{
  duelId?: Maybe<Scalars['UUID']>;
}>;


export type CreateUserRoundMutation = (
  { __typename?: 'Mutation' }
  & { createRound?: Maybe<(
    { __typename?: 'CreateRoundMutation' }
    & { round?: Maybe<(
      { __typename?: 'DuelRoundType' }
      & Pick<DuelRoundType, 'id' | 'roundNumber'>
      & { duel?: Maybe<(
        { __typename?: 'DuelType' }
        & Pick<DuelType, 'id' | 'status'>
        & { duelRound: Array<(
          { __typename?: 'DuelRoundType' }
          & Pick<DuelRoundType, 'id' | 'roundNumber'>
          & { userQuestions: Array<(
            { __typename?: 'DuelRoundQuestionAnswerType' }
            & User_Question_FragmentFragment
          )> }
        )> }
      )>, userQuestions: Array<(
        { __typename?: 'DuelRoundQuestionAnswerType' }
        & User_Question_FragmentFragment
      )> }
    )> }
  )> }
);

export type SetUserAnswerMutationVariables = Exact<{
  duelRoundQuestionAnswerId?: Maybe<Scalars['UUID']>;
  answerId?: Maybe<Scalars['UUID']>;
}>;


export type SetUserAnswerMutation = (
  { __typename?: 'Mutation' }
  & { setAnswer?: Maybe<(
    { __typename?: 'SetAnswerMutation' }
    & { duelRoundQuestionAnswer?: Maybe<(
      { __typename?: 'DuelRoundQuestionAnswerType' }
      & { duelRound?: Maybe<(
        { __typename?: 'DuelRoundType' }
        & Pick<DuelRoundType, 'id'>
        & { duel?: Maybe<(
          { __typename?: 'DuelType' }
          & Pick<DuelType, 'id' | 'status'>
          & { result?: Maybe<(
            { __typename?: 'DuelResultType' }
            & { currentUserData?: Maybe<(
              { __typename?: 'DuelResultUserDataType' }
              & User_Score_FragmentFragment
            )>, opponentData?: Maybe<(
              { __typename?: 'DuelResultUserDataType' }
              & User_Score_FragmentFragment
            )> }
          )> }
        )>, userQuestions: Array<(
          { __typename?: 'DuelRoundQuestionAnswerType' }
          & User_Question_FragmentFragment
        )> }
      )> }
    )> }
  )> }
);

export type SetUserDailyTipperAnswerMutationVariables = Exact<{
  questionId?: Maybe<Scalars['UUID']>;
  answerId?: Maybe<Scalars['UUID']>;
}>;


export type SetUserDailyTipperAnswerMutation = (
  { __typename?: 'Mutation' }
  & { setDailyTipperAnswer?: Maybe<(
    { __typename?: 'SetDailyTipperAnswerMutation' }
    & { dailyTipperQuestionAnswerStatus?: Maybe<(
      { __typename?: 'DailyTipperQuestionAnswerDailyStatusType' }
      & Pick<DailyTipperQuestionAnswerDailyStatusType, 'dailyStatus'>
      & { dailyTipperQuestionAnswer?: Maybe<(
        { __typename?: 'DailyTipperQuestionAnswerType' }
        & Daily_Tipper_Question_FragmentFragment
      )> }
    )> }
  )> }
);

export type ReadNotificationMutationVariables = Exact<{
  id?: Maybe<Scalars['UUID']>;
}>;


export type ReadNotificationMutation = (
  { __typename?: 'Mutation' }
  & { notificationStatus?: Maybe<(
    { __typename?: 'NotificationMutation' }
    & { notification?: Maybe<(
      { __typename?: 'NotificationType' }
      & Pick<NotificationType, 'id' | 'isRead'>
    )> }
  )> }
);

export type UnsubscribeToNewsMutationVariables = Exact<{
  userId?: Maybe<Scalars['UUID']>;
}>;


export type UnsubscribeToNewsMutation = (
  { __typename?: 'Mutation' }
  & { userUnsubscribeToNews?: Maybe<(
    { __typename?: 'UserUnsubscribeToNewsMutation' }
    & { user?: Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'id'>
    )> }
  )> }
);

export type SubscribeToNewsMutationVariables = Exact<{
  userId?: Maybe<Scalars['UUID']>;
}>;


export type SubscribeToNewsMutation = (
  { __typename?: 'Mutation' }
  & { userSubscribeToNews?: Maybe<(
    { __typename?: 'UserSubscribeToNewsMutation' }
    & { user?: Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'id'>
    )> }
  )> }
);

export type UpdateDtSettingsMutationVariables = Exact<{
  goal?: Maybe<Scalars['Int']>;
  mode?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateDtSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateUserSettings?: Maybe<(
    { __typename?: 'UserSettingsUpdateMutation' }
    & { userSettings?: Maybe<(
      { __typename?: 'SettingsType' }
      & Pick<SettingsType, 'id' | 'goal' | 'dailyTipperMode'>
    )> }
  )> }
);

export type UpdateDuelsSettingsMutationVariables = Exact<{
  mode?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateDuelsSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateUserSettings?: Maybe<(
    { __typename?: 'UserSettingsUpdateMutation' }
    & { userSettings?: Maybe<(
      { __typename?: 'SettingsType' }
      & Pick<SettingsType, 'id' | 'duelMode'>
    )> }
  )> }
);

export type UpdatePushNotificationsSettingsMutationVariables = Exact<{
  pushNotificationMode?: Maybe<Scalars['Boolean']>;
  pushNotificationDailyTipperMode?: Maybe<Scalars['Boolean']>;
  pushNotificationDuelMode?: Maybe<Scalars['Boolean']>;
  pushNotificationNewChapterMode?: Maybe<Scalars['Boolean']>;
  pushNotificationPremiumMode?: Maybe<Scalars['Boolean']>;
  pushNotificationQuizTimeMode?: Maybe<Scalars['Boolean']>;
}>;


export type UpdatePushNotificationsSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateUserSettings?: Maybe<(
    { __typename?: 'UserSettingsUpdateMutation' }
    & { userSettings?: Maybe<(
      { __typename?: 'SettingsType' }
      & Pick<SettingsType, 'id' | 'pushNotificationMode' | 'pushNotificationDailyTipperMode' | 'pushNotificationDuelMode' | 'pushNotificationNewChapterMode' | 'pushNotificationPremiumMode' | 'pushNotificationQuizTimeMode'>
    )> }
  )> }
);

export type UpdateRulesSettingsMutationVariables = Exact<{
  isDailyTipperRulesRead?: Maybe<Scalars['Boolean']>;
  isDuelRulesRead?: Maybe<Scalars['Boolean']>;
  isQuizDuelRulesRead?: Maybe<Scalars['Boolean']>;
  isIntroCompleted?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateRulesSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateUserSettings?: Maybe<(
    { __typename?: 'UserSettingsUpdateMutation' }
    & { userSettings?: Maybe<(
      { __typename?: 'SettingsType' }
      & Pick<SettingsType, 'id' | 'isDailyTipperRulesRead' | 'isDuelRulesRead' | 'isQuizDuelRulesRead' | 'isIntroCompleted'>
    )> }
  )> }
);

export type UpdateClassroomSettingsMutationVariables = Exact<{
  sessionId?: Maybe<Scalars['UUID']>;
  dailyTipperMode?: Maybe<Scalars['Boolean']>;
  duelMode?: Maybe<Scalars['Boolean']>;
  isAchieved?: Maybe<Scalars['Boolean']>;
  isClosed?: Maybe<Scalars['Boolean']>;
  isOut?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateClassroomSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateUserClassRoomSettings?: Maybe<(
    { __typename?: 'UserClassRoomLearningSessionSettingsMutation' }
    & { userClassRoomSettings?: Maybe<(
      { __typename?: 'UserClassRoomLearningSessionSettingsType' }
      & Pick<UserClassRoomLearningSessionSettingsType, 'id' | 'isDuelModeEnabled' | 'isDailyTipperModeEnabled' | 'isAchieved' | 'isClosed' | 'isOut'>
    )> }
  )> }
);

export type UpdateIsIntroCompletedSettingsMutationVariables = Exact<{
  isIntroCompleted?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateIsIntroCompletedSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateUserSettings?: Maybe<(
    { __typename?: 'UserSettingsUpdateMutation' }
    & { userSettings?: Maybe<(
      { __typename?: 'SettingsType' }
      & Pick<SettingsType, 'id' | 'isIntroCompleted'>
    )> }
  )> }
);

export type UpdateIsClassRoomIntroCompletedSettingsMutationVariables = Exact<{
  isClassRoomIntroCompleted?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateIsClassRoomIntroCompletedSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateUserSettings?: Maybe<(
    { __typename?: 'UserSettingsUpdateMutation' }
    & { userSettings?: Maybe<(
      { __typename?: 'SettingsType' }
      & Pick<SettingsType, 'id' | 'isClassRoomIntroCompleted'>
    )> }
  )> }
);

export type UpdateCookieAcceptedSettingsMutationVariables = Exact<{
  isCookieAccepted?: Maybe<Scalars['Boolean']>;
  isMarketingCookieAccepted?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateCookieAcceptedSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateUserSettings?: Maybe<(
    { __typename?: 'UserSettingsUpdateMutation' }
    & { userSettings?: Maybe<(
      { __typename?: 'SettingsType' }
      & Pick<SettingsType, 'id' | 'isCookieAccepted' | 'isMarketingCookieAccepted'>
    )> }
  )> }
);

export type LeaveClassroomMutationVariables = Exact<{
  sessionId?: Maybe<Scalars['UUID']>;
}>;


export type LeaveClassroomMutation = (
  { __typename?: 'Mutation' }
  & { leaveClassRoom?: Maybe<(
    { __typename?: 'LeaveClassRoomMutation' }
    & { learningSessionSettings?: Maybe<(
      { __typename?: 'UserClassRoomLearningSessionSettingsType' }
      & Pick<UserClassRoomLearningSessionSettingsType, 'id'>
    )> }
  )> }
);

export type LeaveNetworkClassroomMutationVariables = Exact<{
  userSessionSettingsId?: Maybe<Scalars['UUID']>;
}>;


export type LeaveNetworkClassroomMutation = (
  { __typename?: 'Mutation' }
  & { leaveNetworkClassRoom?: Maybe<(
    { __typename?: 'LeaveNetworkClassRoomMutation' }
    & { learningSessionSettings?: Maybe<(
      { __typename?: 'UserClassRoomLearningSessionSettingsType' }
      & Pick<UserClassRoomLearningSessionSettingsType, 'id'>
    )> }
  )> }
);

export type EarlyFinishNetworkClassroomMutationVariables = Exact<{
  userSessionSettingsId?: Maybe<Scalars['UUID']>;
}>;


export type EarlyFinishNetworkClassroomMutation = (
  { __typename?: 'Mutation' }
  & { earlyFinishNetworkClassRoom?: Maybe<(
    { __typename?: 'EarlyFinishNetworkClassRoomMutation' }
    & { learningSessionSettings?: Maybe<(
      { __typename?: 'UserClassRoomLearningSessionSettingsType' }
      & Pick<UserClassRoomLearningSessionSettingsType, 'id'>
    )> }
  )> }
);

export type EarlyFinishBlendedClassroomMutationVariables = Exact<{
  userSessionSettingsId?: Maybe<Scalars['UUID']>;
}>;


export type EarlyFinishBlendedClassroomMutation = (
  { __typename?: 'Mutation' }
  & { earlyFinishBlendedClassRoom?: Maybe<(
    { __typename?: 'EarlyFinishBlendedClassRoomMutation' }
    & { learningSessionSettings?: Maybe<(
      { __typename?: 'UserClassRoomLearningSessionSettingsType' }
      & Pick<UserClassRoomLearningSessionSettingsType, 'id'>
    )> }
  )> }
);

export type EarlyFinishLekClassroomMutationVariables = Exact<{
  userSessionSettingsId?: Maybe<Scalars['UUID']>;
}>;


export type EarlyFinishLekClassroomMutation = (
  { __typename?: 'Mutation' }
  & { earlyFinishLekClassRoom?: Maybe<(
    { __typename?: 'EarlyFinishLEKClassRoomMutation' }
    & { learningSessionSettings?: Maybe<(
      { __typename?: 'UserClassRoomLearningSessionSettingsType' }
      & Pick<UserClassRoomLearningSessionSettingsType, 'id'>
    )> }
  )> }
);

export type ResumeBlendedClassroomMutationVariables = Exact<{
  userSessionSettingsId: Scalars['UUID'];
}>;


export type ResumeBlendedClassroomMutation = (
  { __typename?: 'Mutation' }
  & { resumeBlendedClassRoom?: Maybe<(
    { __typename?: 'ResumeBlendedClassRoomSessionMutation' }
    & { learningSessionSettings?: Maybe<(
      { __typename?: 'UserClassRoomLearningSessionSettingsType' }
      & Pick<UserClassRoomLearningSessionSettingsType, 'id'>
      & { classRoomLearningSession: (
        { __typename?: 'ClassRoomLearningSessionType' }
        & Pick<ClassRoomLearningSessionType, 'id'>
      ) }
    )> }
  )> }
);

export type PauseBlendedClassroomMutationVariables = Exact<{
  userSessionSettingsId: Scalars['UUID'];
}>;


export type PauseBlendedClassroomMutation = (
  { __typename?: 'Mutation' }
  & { pauseBlendedClassRoom?: Maybe<(
    { __typename?: 'PauseBlendedClassRoomSessionMutation' }
    & { learningSessionSettings?: Maybe<(
      { __typename?: 'UserClassRoomLearningSessionSettingsType' }
      & Pick<UserClassRoomLearningSessionSettingsType, 'id'>
      & { classRoomLearningSession: (
        { __typename?: 'ClassRoomLearningSessionType' }
        & Pick<ClassRoomLearningSessionType, 'id'>
      ) }
    )> }
  )> }
);

export type UserLekStartMutationVariables = Exact<{
  lekSessionId?: Maybe<Scalars['UUID']>;
  startTime?: Maybe<Scalars['DateTime']>;
}>;


export type UserLekStartMutation = (
  { __typename?: 'Mutation' }
  & { lekStart?: Maybe<(
    { __typename?: 'LEKStartMutation' }
    & { userLekData?: Maybe<(
      { __typename?: 'UserLEKDataType' }
      & Pick<UserLekDataType, 'id' | 'created' | 'status' | 'elapsedTime'>
      & { lekSession: (
        { __typename?: 'LEKSessionType' }
        & Pick<LekSessionType, 'id'>
      ), userLekQuestionAnswerData: Array<(
        { __typename?: 'UserLEKQuestionAnswerDataType' }
        & Pick<UserLekQuestionAnswerDataType, 'id'>
        & { question: (
          { __typename?: 'QuestionType' }
          & Pick<QuestionType, 'id'>
        ), userAnswer: (
          { __typename?: 'AnswersType' }
          & Pick<AnswersType, 'id'>
        ) }
      )> }
    )> }
  )> }
);

export type UpdateIsChromeInstallationPopupSkippedSettingsMutationVariables = Exact<{
  isChromeInstallationPopupSkipped?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateIsChromeInstallationPopupSkippedSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateUserSettings?: Maybe<(
    { __typename?: 'UserSettingsUpdateMutation' }
    & { userSettings?: Maybe<(
      { __typename?: 'SettingsType' }
      & Pick<SettingsType, 'id' | 'isChromeInstallationPopupSkipped'>
    )> }
  )> }
);

export type UserLekFinishMutationVariables = Exact<{
  lekSessionId?: Maybe<Scalars['UUID']>;
  userLekDataId?: Maybe<Scalars['UUID']>;
}>;


export type UserLekFinishMutation = (
  { __typename?: 'Mutation' }
  & { lekFinish?: Maybe<(
    { __typename?: 'LEKFinishMutation' }
    & { userLekData?: Maybe<(
      { __typename?: 'UserLEKDataType' }
      & Pick<UserLekDataType, 'id' | 'status' | 'elapsedTime' | 'isPassed' | 'correctAnswerCount'>
    )> }
  )> }
);

export type SetUserLekAnswerMutationVariables = Exact<{
  answerId?: Maybe<Scalars['UUID']>;
  lekSessionId?: Maybe<Scalars['UUID']>;
  questionId?: Maybe<Scalars['UUID']>;
  answerTime?: Maybe<Scalars['DateTime']>;
}>;


export type SetUserLekAnswerMutation = (
  { __typename?: 'Mutation' }
  & { setLekAnswer?: Maybe<(
    { __typename?: 'SetLEKAnswerMutation' }
    & { userLekQuestionAnswerData?: Maybe<(
      { __typename?: 'UserLEKQuestionAnswerDataType' }
      & Pick<UserLekQuestionAnswerDataType, 'id'>
      & { userLekData: (
        { __typename?: 'UserLEKDataType' }
        & Pick<UserLekDataType, 'id' | 'status'>
      ) }
    )> }
  )> }
);

export type DeleteUserAccountMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteUserAccountMutation = (
  { __typename?: 'Mutation' }
  & { deleteAccount?: Maybe<(
    { __typename?: 'DeleteAccountMutation' }
    & Pick<DeleteAccountMutation, 'isDeleted'>
  )> }
);

export type PlanSubscribeMutationVariables = Exact<{
  stripeToken?: Maybe<Scalars['String']>;
  subscriptionType?: Maybe<Scalars['String']>;
  recaptchaToken?: Maybe<Scalars['String']>;
}>;


export type PlanSubscribeMutation = (
  { __typename?: 'Mutation' }
  & { subscribe?: Maybe<(
    { __typename?: 'SubscribeMutation' }
    & { subscription?: Maybe<(
      { __typename?: 'SubscriptionType' }
      & Pick<SubscriptionType, 'id' | 'status' | 'subscriptionType' | 'currentPeriodEnd' | 'requestCancel'>
    )> }
  )> }
);

export type ChangePlanMutationVariables = Exact<{
  subscriptionType?: Maybe<Scalars['String']>;
}>;


export type ChangePlanMutation = (
  { __typename?: 'Mutation' }
  & { changeSubscription?: Maybe<(
    { __typename?: 'ChangeSubscriptionMutation' }
    & { subscription?: Maybe<(
      { __typename?: 'SubscriptionType' }
      & Pick<SubscriptionType, 'id' | 'status' | 'subscriptionType' | 'currentPeriodEnd' | 'requestCancel' | 'upgradeStatus'>
    )> }
  )> }
);

export type ChangeUserCardMutationVariables = Exact<{
  stripeToken?: Maybe<Scalars['String']>;
}>;


export type ChangeUserCardMutation = (
  { __typename?: 'Mutation' }
  & { changeCard?: Maybe<(
    { __typename?: 'ChangeCardMutation' }
    & { customer?: Maybe<(
      { __typename?: 'CustomerType' }
      & Pick<CustomerType, 'id'>
      & { defaultCard?: Maybe<(
        { __typename?: '_DefaultCardType' }
        & Pick<_DefaultCardType, 'brand' | 'last4'>
      )> }
    )> }
  )> }
);

export type CancelUserSubscriptionMutationVariables = Exact<{ [key: string]: never; }>;


export type CancelUserSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { cancelSubscription?: Maybe<(
    { __typename?: 'CancelSubscriptionMutation' }
    & { subscription?: Maybe<(
      { __typename?: 'SubscriptionType' }
      & Pick<SubscriptionType, 'id' | 'status' | 'subscriptionType' | 'currentPeriodEnd' | 'requestCancel'>
    )> }
  )> }
);

export type RenewPlanMutationVariables = Exact<{ [key: string]: never; }>;


export type RenewPlanMutation = (
  { __typename?: 'Mutation' }
  & { renewSubscription?: Maybe<(
    { __typename?: 'RenewSubscriptionMutation' }
    & { subscription?: Maybe<(
      { __typename?: 'SubscriptionType' }
      & Pick<SubscriptionType, 'id' | 'status' | 'subscriptionType' | 'currentPeriodEnd' | 'requestCancel'>
    )> }
  )> }
);

export type InAppUserSubscribeMutationVariables = Exact<{
  subscriptionObject?: Maybe<Scalars['GenericScalar']>;
}>;


export type InAppUserSubscribeMutation = (
  { __typename?: 'Mutation' }
  & { inAppSubscribe?: Maybe<(
    { __typename?: 'InAppSubscribeMutation' }
    & { inAppSubscription?: Maybe<(
      { __typename?: 'InAppSubscriptionType' }
      & Pick<InAppSubscriptionType, 'id'>
      & { customer: (
        { __typename?: 'UserType' }
        & Pick<UserType, 'id' | 'isInAppPremium'>
      ) }
    )> }
  )> }
);

export type UpgradeInAppSubscriptionMutationVariables = Exact<{
  subscriptionObject?: Maybe<Scalars['GenericScalar']>;
}>;


export type UpgradeInAppSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { changeInAppSubscription?: Maybe<(
    { __typename?: 'ChangeInAppSubscribeMutation' }
    & { inAppSubscription?: Maybe<(
      { __typename?: 'InAppSubscriptionType' }
      & Pick<InAppSubscriptionType, 'id'>
      & { customer: (
        { __typename?: 'UserType' }
        & Pick<UserType, 'id' | 'isInAppPremium'>
      ) }
    )> }
  )> }
);

export type UpdateIsIosInstallationPopupSkippedSettingsMutationVariables = Exact<{
  isIosInstallationPopupSkipped?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateIsIosInstallationPopupSkippedSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateUserSettings?: Maybe<(
    { __typename?: 'UserSettingsUpdateMutation' }
    & { userSettings?: Maybe<(
      { __typename?: 'SettingsType' }
      & Pick<SettingsType, 'id' | 'isIosInstallationPopupSkipped'>
    )> }
  )> }
);

export type ApplyQuizTimeCodeMutationVariables = Exact<{
  code?: Maybe<Scalars['Int']>;
  sessionId: Scalars['UUID'];
  deepLinkHash?: Maybe<Scalars['String']>;
}>;


export type ApplyQuizTimeCodeMutation = (
  { __typename?: 'Mutation' }
  & { joinToQuiz?: Maybe<(
    { __typename?: 'JoinToQuizMutation' }
    & { quizSession?: Maybe<(
      { __typename?: 'QuizTimeSessionType' }
      & Pick<QuizTimeSessionType, 'id'>
      & { quizTime: (
        { __typename?: 'QuizTimeType' }
        & Pick<QuizTimeType, 'id'>
      ) }
    )> }
  )> }
);

export type LeaveQuizTimeMutationVariables = Exact<{
  sessionId: Scalars['UUID'];
}>;


export type LeaveQuizTimeMutation = (
  { __typename?: 'Mutation' }
  & { leaveQuiz?: Maybe<(
    { __typename?: 'LeaveQuizMutation' }
    & { session?: Maybe<(
      { __typename?: 'QuizTimeSessionType' }
      & Pick<QuizTimeSessionType, 'id'>
    )> }
  )> }
);

export type StartQuizMutationVariables = Exact<{
  quizSessionId: Scalars['UUID'];
}>;


export type StartQuizMutation = (
  { __typename?: 'Mutation' }
  & { quizStartByUser?: Maybe<(
    { __typename?: 'QuizStartByUserMutation' }
    & { quizSession?: Maybe<(
      { __typename?: 'QuizTimeSessionType' }
      & Pick<QuizTimeSessionType, 'id' | 'status'>
    )> }
  )> }
);

export type PauseQuizMutationVariables = Exact<{
  pauseMode: Scalars['Boolean'];
  quizSessionId: Scalars['UUID'];
}>;


export type PauseQuizMutation = (
  { __typename?: 'Mutation' }
  & { switchPauseMode?: Maybe<(
    { __typename?: 'SwitchPauseModeMutation' }
    & { userQuizSessionSettings?: Maybe<(
      { __typename?: 'UserQuizSessionSettingsType' }
      & Pick<UserQuizSessionSettingsType, 'id' | 'userStatus'>
    )> }
  )> }
);

export type CreateQuiztimeDuelMutationVariables = Exact<{
  quizSessionId: Scalars['UUID'];
}>;


export type CreateQuiztimeDuelMutation = (
  { __typename?: 'Mutation' }
  & { createQuizDuel?: Maybe<(
    { __typename?: 'CreateQuizDuelMutation' }
    & { duel?: Maybe<(
      { __typename?: 'DuelType' }
      & Pick<DuelType, 'id' | 'status'>
      & { duelRound: Array<(
        { __typename?: 'DuelRoundType' }
        & Pick<DuelRoundType, 'id' | 'roundNumber'>
        & { userQuestions: Array<(
          { __typename?: 'DuelRoundQuestionAnswerType' }
          & User_Question_FragmentFragment
        )> }
      )> }
    )> }
  )> }
);

export type CreateQuiztimeDuelByOpponentMutationVariables = Exact<{
  opponentId: Scalars['UUID'];
  quizSessionId: Scalars['UUID'];
}>;


export type CreateQuiztimeDuelByOpponentMutation = (
  { __typename?: 'Mutation' }
  & { createQuizDuelByOpponent?: Maybe<(
    { __typename?: 'CreateQuizDuelByOpponentMutation' }
    & { duel?: Maybe<(
      { __typename?: 'DuelType' }
      & Pick<DuelType, 'id' | 'status'>
      & { duelRound: Array<(
        { __typename?: 'DuelRoundType' }
        & Pick<DuelRoundType, 'id' | 'roundNumber'>
        & { userQuestions: Array<(
          { __typename?: 'DuelRoundQuestionAnswerType' }
          & User_Question_FragmentFragment
        )> }
      )> }
    )> }
  )> }
);

export type AddWaitingUserNotificationMutationVariables = Exact<{
  sessionId: Scalars['UUID'];
}>;


export type AddWaitingUserNotificationMutation = (
  { __typename?: 'Mutation' }
  & { addWaitingUser?: Maybe<(
    { __typename?: 'AddWaitingUser' }
    & { waitingUser?: Maybe<(
      { __typename?: 'UserQuizSessionSettingsType' }
      & Pick<UserQuizSessionSettingsType, 'id'>
      & { quizSession: (
        { __typename?: 'QuizTimeSessionType' }
        & Pick<QuizTimeSessionType, 'id' | 'usersCount'>
      ) }
    )> }
  )> }
);

export type GetDeepLinkMutationVariables = Exact<{
  hash: Scalars['String'];
  source?: Maybe<Scalars['String']>;
}>;


export type GetDeepLinkMutation = (
  { __typename?: 'Mutation' }
  & { getDeepLink?: Maybe<(
    { __typename?: 'GetDeepLink' }
    & { deepLink?: Maybe<(
      { __typename?: 'DeepLinkType' }
      & Pick<DeepLinkType, 'targetObjectId' | 'targetType'>
    )> }
  )> }
);

export type CreateFeedbackQuestionMutationVariables = Exact<{
  questionId: Scalars['UUID'];
  interestRate: Scalars['Int'];
  relevanceRate: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
}>;


export type CreateFeedbackQuestionMutation = (
  { __typename?: 'Mutation' }
  & { createFeedbackForQuestion?: Maybe<(
    { __typename?: 'CreateFeedbackForQuestionMutation' }
    & { feedbackForQuestion?: Maybe<(
      { __typename?: 'FeedbackForQuestionType' }
      & Pick<FeedbackForQuestionType, 'id' | 'relevanceRate' | 'interestRate' | 'comment'>
      & { question: (
        { __typename?: 'QuestionType' }
        & Pick<QuestionType, 'id' | 'value'>
      ) }
    )> }
  )> }
);

export type EditFeedbackQuestionMutationVariables = Exact<{
  questionId: Scalars['UUID'];
  interestRate?: Maybe<Scalars['Int']>;
  relevanceRate?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
}>;


export type EditFeedbackQuestionMutation = (
  { __typename?: 'Mutation' }
  & { editFeedbackForQuestion?: Maybe<(
    { __typename?: 'UpdateFeedbackForQuestionMutation' }
    & { feedbackForQuestion?: Maybe<(
      { __typename?: 'FeedbackForQuestionType' }
      & Pick<FeedbackForQuestionType, 'id' | 'relevanceRate' | 'interestRate' | 'comment'>
      & { question: (
        { __typename?: 'QuestionType' }
        & Pick<QuestionType, 'id' | 'value'>
      ) }
    )> }
  )> }
);

export type SetExpoTokenMutationVariables = Exact<{
  expoToken?: Maybe<Scalars['String']>;
}>;


export type SetExpoTokenMutation = (
  { __typename?: 'Mutation' }
  & { setUserExpoToken?: Maybe<(
    { __typename?: 'SetUserExpoTokenMutation' }
    & Pick<SetUserExpoTokenMutation, 'result'>
  )> }
);

export type UserSubscriptionQueryVariables = Exact<{ [key: string]: never; }>;


export type UserSubscriptionQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'UserType' }
    & Pick<UserType, 'id' | 'isPremium' | 'isPromotionPremium' | 'promotionFinishDate' | 'isInAppPremium'>
    & { customer?: Maybe<(
      { __typename?: 'CustomerType' }
      & Pick<CustomerType, 'id'>
      & { subscription?: Maybe<(
        { __typename?: 'SubscriptionType' }
        & Pick<SubscriptionType, 'id' | 'subscriptionType' | 'status' | 'upgradeStatus' | 'currentPeriodEnd' | 'requestCancel' | 'lastError' | 'lastErrorCode'>
      )>, defaultCard?: Maybe<(
        { __typename?: '_DefaultCardType' }
        & Pick<_DefaultCardType, 'brand' | 'last4'>
      )> }
    )>, inAppSubscription?: Maybe<(
      { __typename?: 'InAppSubscriptionType' }
      & Pick<InAppSubscriptionType, 'id' | 'subscriptingType' | 'expirationDatetime' | 'status' | 'isActive' | 'store' | 'price' | 'currency'>
    )> }
  )> }
);

export type UserDiscountsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserDiscountsQuery = (
  { __typename?: 'Query' }
  & { discounts?: Maybe<Array<Maybe<(
    { __typename?: 'CouponType' }
    & Pick<CouponType, 'id' | 'couponName' | 'status' | 'subscriptionType' | 'discount' | 'startActiveDatetime' | 'finishActiveDatetime'>
  )>>> }
);

export type UserMeQueryVariables = Exact<{ [key: string]: never; }>;


export type UserMeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'contentAvailable' | 'joinedClassRooms'>
  & { me?: Maybe<(
    { __typename?: 'UserType' }
    & Pick<UserType, 'id'>
    & { settings?: Maybe<(
      { __typename?: 'SettingsType' }
      & User_Settings_FragmentFragment
    )> }
    & User_FragmentFragment
  )>, monthlyScore?: Maybe<(
    { __typename?: 'MonthlyScoreType' }
    & Pick<MonthlyScoreType, 'id' | 'points'>
  )>, featureFlags?: Maybe<Array<Maybe<(
    { __typename?: 'FeatureFlagType' }
    & Pick<FeatureFlagType, 'id' | 'name' | 'enabled'>
  )>>> }
);

export type DtWeeklyProgressQueryVariables = Exact<{ [key: string]: never; }>;


export type DtWeeklyProgressQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'UserType' }
    & Pick<UserType, 'id'>
    & { dailytipper?: Maybe<(
      { __typename?: 'DailyTipperType' }
      & Pick<DailyTipperType, 'id' | 'dailyStatus' | 'totalProgress'>
      & { nextAchievement?: Maybe<(
        { __typename?: 'AchievementType' }
        & Pick<AchievementType, 'id' | 'learningDays'>
      )>, currentAchievement?: Maybe<(
        { __typename?: 'AchievementType' }
        & Pick<AchievementType, 'id' | 'learningDays'>
      )>, yearAchievement?: Maybe<(
        { __typename?: 'YearAchievementType' }
        & Pick<YearAchievementType, 'id' | 'yearNumber'>
      )> }
    )> }
  )>, dailyTipperWeeklyProgress?: Maybe<(
    { __typename?: 'ActualLearnWeekType' }
    & Pick<ActualLearnWeekType, 'id' | 'weeklyGoal' | 'completedDays' | 'correctAnswersCount' | 'wrongAnswersCount' | 'weekNumber'>
  )> }
);

export type GetActiveDuelsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActiveDuelsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'startedDuelsToday'>
  & { activeDuels?: Maybe<Array<Maybe<(
    { __typename?: 'ActiveDuelsType' }
    & Pick<ActiveDuelsType, 'id' | 'youTurn' | 'isInitiator'>
    & { opponent?: Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'id' | 'avatar' | 'username'>
    )>, round?: Maybe<(
      { __typename?: 'DuelRoundType' }
      & Pick<DuelRoundType, 'id' | 'roundNumber' | 'created'>
      & { duel?: Maybe<(
        { __typename?: 'DuelType' }
        & Pick<DuelType, 'id' | 'status'>
      )>, userQuestions: Array<(
        { __typename?: 'DuelRoundQuestionAnswerType' }
        & Pick<DuelRoundQuestionAnswerType, 'id' | 'questionNumber'>
        & { user?: Maybe<(
          { __typename?: 'UserType' }
          & Pick<UserType, 'id'>
        )>, question?: Maybe<(
          { __typename?: 'QuestionType' }
          & Pick<QuestionType, 'id' | 'value'>
        )>, userAnswer?: Maybe<(
          { __typename?: 'AnswersType' }
          & Pick<AnswersType, 'id' | 'value'>
        )> }
      )> }
    )>, session?: Maybe<(
      { __typename?: 'ClassRoomLearningSessionType' }
      & Pick<ClassRoomLearningSessionType, 'id'>
      & { classRoom: (
        { __typename?: 'ClassRoomType' }
        & Pick<ClassRoomType, 'id' | 'enrollmentType'>
      ) }
    )> }
  )>>> }
);

export type GetOngoingDuelsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOngoingDuelsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'startedDuelsToday'>
  & { activeDuels?: Maybe<Array<Maybe<(
    { __typename?: 'ActiveDuelsType' }
    & Pick<ActiveDuelsType, 'id' | 'youTurn' | 'isInitiator'>
    & { opponent?: Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'id' | 'avatar' | 'username'>
    )>, round?: Maybe<(
      { __typename?: 'DuelRoundType' }
      & Pick<DuelRoundType, 'id' | 'roundNumber' | 'created'>
      & { duel?: Maybe<(
        { __typename?: 'DuelType' }
        & Pick<DuelType, 'id' | 'status'>
      )>, userQuestions: Array<(
        { __typename?: 'DuelRoundQuestionAnswerType' }
        & Pick<DuelRoundQuestionAnswerType, 'id' | 'questionNumber'>
        & { user?: Maybe<(
          { __typename?: 'UserType' }
          & Pick<UserType, 'id'>
        )>, userAnswer?: Maybe<(
          { __typename?: 'AnswersType' }
          & Pick<AnswersType, 'id' | 'value'>
        )> }
      )> }
    )>, session?: Maybe<(
      { __typename?: 'ClassRoomLearningSessionType' }
      & Pick<ClassRoomLearningSessionType, 'id'>
      & { classRoom: (
        { __typename?: 'ClassRoomType' }
        & Pick<ClassRoomType, 'id' | 'enrollmentType'>
      ) }
    )> }
  )>>>, monthlyScore?: Maybe<(
    { __typename?: 'MonthlyScoreType' }
    & Pick<MonthlyScoreType, 'id' | 'duels' | 'wins' | 'loss' | 'ties' | 'points'>
  )> }
);

export type GetCompletedDuelsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompletedDuelsQuery = (
  { __typename?: 'Query' }
  & { completedDuels?: Maybe<Array<Maybe<(
    { __typename?: 'UsersDuelsDataType' }
    & Pick<UsersDuelsDataType, 'id'>
    & { userDuelsData?: Maybe<(
      { __typename?: 'DuelResultUserDataType' }
      & { duel?: Maybe<(
        { __typename?: 'DuelType' }
        & Pick<DuelType, 'id' | 'updated' | 'status'>
      )> }
      & User_Score_FragmentFragment
    )>, opponentDuelsData?: Maybe<(
      { __typename?: 'DuelResultUserDataType' }
      & User_Score_FragmentFragment
    )>, session?: Maybe<(
      { __typename?: 'ClassRoomLearningSessionType' }
      & Pick<ClassRoomLearningSessionType, 'id'>
      & { classRoom: (
        { __typename?: 'ClassRoomType' }
        & Pick<ClassRoomType, 'id' | 'enrollmentType'>
      ) }
    )> }
  )>>> }
);

export type GetDuelsRankingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDuelsRankingQuery = (
  { __typename?: 'Query' }
  & { ranking?: Maybe<Array<Maybe<(
    { __typename?: 'MonthlyScoreType' }
    & Pick<MonthlyScoreType, 'id' | 'points' | 'rank'>
    & { user?: Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'id' | 'avatar' | 'username'>
    )> }
  )>>>, topUsers?: Maybe<Array<Maybe<(
    { __typename?: 'MonthlyScoreType' }
    & Pick<MonthlyScoreType, 'id' | 'points' | 'rank'>
    & { user?: Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'id' | 'avatar' | 'username'>
    )> }
  )>>> }
);

export type GetDuelsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDuelsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'startedDuelsToday'>
  & { activeDuels?: Maybe<Array<Maybe<(
    { __typename?: 'ActiveDuelsType' }
    & Pick<ActiveDuelsType, 'id' | 'youTurn'>
    & { opponent?: Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'id' | 'avatar' | 'username'>
    )>, round?: Maybe<(
      { __typename?: 'DuelRoundType' }
      & Pick<DuelRoundType, 'id' | 'roundNumber' | 'created'>
      & { duel?: Maybe<(
        { __typename?: 'DuelType' }
        & Pick<DuelType, 'id' | 'status'>
      )>, userQuestions: Array<(
        { __typename?: 'DuelRoundQuestionAnswerType' }
        & Pick<DuelRoundQuestionAnswerType, 'id' | 'questionNumber'>
        & { user?: Maybe<(
          { __typename?: 'UserType' }
          & Pick<UserType, 'id'>
        )>, userAnswer?: Maybe<(
          { __typename?: 'AnswersType' }
          & Pick<AnswersType, 'id' | 'value'>
        )> }
      )> }
    )>, session?: Maybe<(
      { __typename?: 'ClassRoomLearningSessionType' }
      & Pick<ClassRoomLearningSessionType, 'id'>
      & { classRoom: (
        { __typename?: 'ClassRoomType' }
        & Pick<ClassRoomType, 'id' | 'enrollmentType'>
      ) }
    )> }
  )>>>, completedDuels?: Maybe<Array<Maybe<(
    { __typename?: 'UsersDuelsDataType' }
    & Pick<UsersDuelsDataType, 'id'>
    & { userDuelsData?: Maybe<(
      { __typename?: 'DuelResultUserDataType' }
      & { duel?: Maybe<(
        { __typename?: 'DuelType' }
        & Pick<DuelType, 'id' | 'updated' | 'status'>
      )> }
      & User_Score_FragmentFragment
    )>, opponentDuelsData?: Maybe<(
      { __typename?: 'DuelResultUserDataType' }
      & User_Score_FragmentFragment
    )>, session?: Maybe<(
      { __typename?: 'ClassRoomLearningSessionType' }
      & Pick<ClassRoomLearningSessionType, 'id'>
      & { classRoom: (
        { __typename?: 'ClassRoomType' }
        & Pick<ClassRoomType, 'id' | 'enrollmentType'>
      ) }
    )> }
  )>>>, ranking?: Maybe<Array<Maybe<(
    { __typename?: 'MonthlyScoreType' }
    & Pick<MonthlyScoreType, 'id' | 'points' | 'rank'>
    & { user?: Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'id' | 'avatar' | 'username'>
    )> }
  )>>>, topUsers?: Maybe<Array<Maybe<(
    { __typename?: 'MonthlyScoreType' }
    & Pick<MonthlyScoreType, 'id' | 'points' | 'rank'>
    & { user?: Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'id' | 'avatar' | 'username'>
    )> }
  )>>>, monthlyScore?: Maybe<(
    { __typename?: 'MonthlyScoreType' }
    & Pick<MonthlyScoreType, 'id' | 'duels' | 'wins' | 'loss' | 'ties' | 'points'>
  )> }
);

export type UserDuelQueryVariables = Exact<{
  duelId: Scalars['UUID'];
}>;


export type UserDuelQuery = (
  { __typename?: 'Query' }
  & { duelData?: Maybe<(
    { __typename?: 'DuelDataType' }
    & Pick<DuelDataType, 'id' | 'status' | 'isOnboardingDuel'>
    & { opponentScore?: Maybe<(
      { __typename?: 'DuelResultUserDataType' }
      & User_Score_FragmentFragment
    )>, userScore?: Maybe<(
      { __typename?: 'DuelResultUserDataType' }
      & User_Score_FragmentFragment
    )>, rounds?: Maybe<Array<Maybe<(
      { __typename?: 'DuelRoundType' }
      & Pick<DuelRoundType, 'id' | 'created' | 'roundNumber'>
      & { userQuestions: Array<(
        { __typename?: 'DuelRoundQuestionAnswerType' }
        & User_Question_FragmentFragment
      )> }
    )>>>, session?: Maybe<(
      { __typename?: 'ClassRoomLearningSessionType' }
      & Pick<ClassRoomLearningSessionType, 'id'>
      & { classRoom: (
        { __typename?: 'ClassRoomType' }
        & Pick<ClassRoomType, 'id' | 'title'>
      ) }
    )> }
  )> }
);

export type SubscribeNotificationCreatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type SubscribeNotificationCreatedSubscription = (
  { __typename?: 'Subscription' }
  & { notificationCreated?: Maybe<(
    { __typename?: 'NotificationType' }
    & Pick<NotificationType, 'id' | 'updated' | 'created' | 'data' | 'message' | 'isRead'>
  )> }
);

export type UserNotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserNotificationsQuery = (
  { __typename?: 'Query' }
  & { notifications?: Maybe<Array<Maybe<(
    { __typename?: 'NotificationType' }
    & Pick<NotificationType, 'id' | 'created' | 'updated' | 'data' | 'message' | 'isRead'>
  )>>> }
);

export type LastUserNotificationQueryVariables = Exact<{ [key: string]: never; }>;


export type LastUserNotificationQuery = (
  { __typename?: 'Query' }
  & { lastNotification?: Maybe<(
    { __typename?: 'NotificationType' }
    & Pick<NotificationType, 'id' | 'updated' | 'created' | 'data' | 'message' | 'isRead'>
  )> }
);

export type UserNetworkCodesQueryVariables = Exact<{ [key: string]: never; }>;


export type UserNetworkCodesQuery = (
  { __typename?: 'Query' }
  & { codes?: Maybe<Array<Maybe<(
    { __typename?: 'CodesType' }
    & Pick<CodesType, 'id' | 'startDate' | 'finishDate' | 'codeMode'>
    & { sponsor?: Maybe<(
      { __typename?: 'SponsorType' }
      & Pick<SponsorType, 'id' | 'title' | 'text' | 'website' | 'email' | 'logo'>
    )> }
  )>>> }
);

export type UsernameVerifyQueryVariables = Exact<{
  username: Scalars['String'];
}>;


export type UsernameVerifyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'usernameVerify'>
);

export type EmailVerifyQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type EmailVerifyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'emailVerify'>
);

export type EfnNumberVerifyQueryVariables = Exact<{
  efnNumber: Scalars['String'];
}>;


export type EfnNumberVerifyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'efnNumberVerify'>
);

export type AllProfessionsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllProfessionsQuery = (
  { __typename?: 'Query' }
  & { professions?: Maybe<Array<Maybe<(
    { __typename?: 'ProfessionType' }
    & Pick<ProfessionType, 'id' | 'title'>
    & { type?: Maybe<(
      { __typename?: 'ProfessionTypeType' }
      & Pick<ProfessionTypeType, 'id' | 'type'>
    )> }
  )>>> }
);

export type AnotherDataQueryVariables = Exact<{
  professionType: Scalars['Int'];
}>;


export type AnotherDataQuery = (
  { __typename?: 'Query' }
  & { specialitiesByType?: Maybe<Array<Maybe<(
    { __typename?: 'SpecialityType' }
    & Pick<SpecialityType, 'id' | 'title'>
  )>>> }
);

export type DoctorDataQueryVariables = Exact<{
  professionType: Scalars['Int'];
}>;


export type DoctorDataQuery = (
  { __typename?: 'Query' }
  & { specialitiesByType?: Maybe<Array<Maybe<(
    { __typename?: 'SpecialityType' }
    & Pick<SpecialityType, 'id' | 'title'>
  )>>>, workplaces?: Maybe<Array<Maybe<(
    { __typename?: 'WorkplaceType' }
    & Pick<WorkplaceType, 'id' | 'title'>
  )>>> }
);

export type MedicalAssistantDataQueryVariables = Exact<{
  professionType: Scalars['Int'];
}>;


export type MedicalAssistantDataQuery = (
  { __typename?: 'Query' }
  & { professions?: Maybe<Array<Maybe<(
    { __typename?: 'ProfessionType' }
    & Pick<ProfessionType, 'id' | 'title'>
  )>>>, specialitiesByType?: Maybe<Array<Maybe<(
    { __typename?: 'SpecialityType' }
    & Pick<SpecialityType, 'id' | 'title'>
  )>>>, workplaces?: Maybe<Array<Maybe<(
    { __typename?: 'WorkplaceType' }
    & Pick<WorkplaceType, 'id' | 'title'>
  )>>> }
);

export type MedicalAssistantTraineeDataQueryVariables = Exact<{
  professionType: Scalars['Int'];
}>;


export type MedicalAssistantTraineeDataQuery = (
  { __typename?: 'Query' }
  & { workplaces?: Maybe<Array<Maybe<(
    { __typename?: 'WorkplaceType' }
    & Pick<WorkplaceType, 'id' | 'title'>
  )>>>, studyYears?: Maybe<Array<Maybe<(
    { __typename?: 'StudyYearType' }
    & Pick<StudyYearType, 'id' | 'title'>
  )>>> }
);

export type StudentDataQueryVariables = Exact<{ [key: string]: never; }>;


export type StudentDataQuery = (
  { __typename?: 'Query' }
  & { semesters?: Maybe<Array<Maybe<(
    { __typename?: 'SemesterType' }
    & Pick<SemesterType, 'id' | 'title'>
  )>>>, universities?: Maybe<Array<Maybe<(
    { __typename?: 'UniversityType' }
    & Pick<UniversityType, 'id' | 'title'>
  )>>>, studySemesters?: Maybe<Array<Maybe<(
    { __typename?: 'StudyBeginType' }
    & Pick<StudyBeginType, 'id' | 'title'>
  )>>> }
);

export type TraineeDataQueryVariables = Exact<{
  professionType: Scalars['Int'];
}>;


export type TraineeDataQuery = (
  { __typename?: 'Query' }
  & { workplaces?: Maybe<Array<Maybe<(
    { __typename?: 'WorkplaceType' }
    & Pick<WorkplaceType, 'id' | 'title'>
  )>>>, educationLevels?: Maybe<Array<Maybe<(
    { __typename?: 'EducationLevelType' }
    & Pick<EducationLevelType, 'id' | 'title'>
  )>>> }
);

export type TodayDailyTipperQueryVariables = Exact<{ [key: string]: never; }>;


export type TodayDailyTipperQuery = (
  { __typename?: 'Query' }
  & { dailyTipperData?: Maybe<(
    { __typename?: 'DailyTipperDataType' }
    & { dailyTipperQuestions?: Maybe<Array<Maybe<(
      { __typename?: 'DailyTipperQuestionAnswerType' }
      & Daily_Tipper_Question_FragmentFragment
    )>>> }
  )> }
);

export type DailyTipperDataQueryVariables = Exact<{ [key: string]: never; }>;


export type DailyTipperDataQuery = (
  { __typename?: 'Query' }
  & { dailyTipperData?: Maybe<(
    { __typename?: 'DailyTipperDataType' }
    & Pick<DailyTipperDataType, 'dailyResultStatus' | 'currentAchievement' | 'daysLost'>
    & { dailyTipperQuestions?: Maybe<Array<Maybe<(
      { __typename?: 'DailyTipperQuestionAnswerType' }
      & Daily_Tipper_Question_FragmentFragment
    )>>> }
  )> }
);

export type UpdateDtDayslostQueryVariables = Exact<{ [key: string]: never; }>;


export type UpdateDtDayslostQuery = (
  { __typename?: 'Query' }
  & { dailyTipperData?: Maybe<(
    { __typename?: 'DailyTipperDataType' }
    & Pick<DailyTipperDataType, 'daysLost'>
  )> }
);

export type UserAchievementsRangQueryVariables = Exact<{ [key: string]: never; }>;


export type UserAchievementsRangQuery = (
  { __typename?: 'Query' }
  & { achievementsRang?: Maybe<Array<Maybe<(
    { __typename?: 'DailyTipperAchievementsType' }
    & Pick<DailyTipperAchievementsType, 'usersWithAchievementCount'>
    & { achievement?: Maybe<(
      { __typename?: 'AchievementType' }
      & Pick<AchievementType, 'id' | 'learningDays'>
    )>, usersWithAchievement?: Maybe<Array<Maybe<(
      { __typename?: 'DailyTipperType' }
      & Pick<DailyTipperType, 'id'>
      & { user?: Maybe<(
        { __typename?: 'UserType' }
        & Pick<UserType, 'avatar' | 'username'>
      )> }
    )>>> }
  )>>> }
);

export type ClassRoomsAvailableQueryVariables = Exact<{
  isNetworkType?: Maybe<Scalars['Boolean']>;
}>;


export type ClassRoomsAvailableQuery = (
  { __typename?: 'Query' }
  & { availableClassRooms?: Maybe<Array<Maybe<(
    { __typename?: 'ClassRoomLearningSessionType' }
    & Classroom_Ls_FragmentFragment
  )>>> }
);

export type ClassRoomsOngoingQueryVariables = Exact<{ [key: string]: never; }>;


export type ClassRoomsOngoingQuery = (
  { __typename?: 'Query' }
  & { ongoingClassRooms?: Maybe<Array<Maybe<(
    { __typename?: 'OngoingUserLearningSessionType' }
    & Pick<OngoingUserLearningSessionType, 'isSessionActive' | 'correctAnswersGoal' | 'questionsAmount' | 'correctAnswersAmount' | 'networkStartDate' | 'specialityGroupIcon'>
    & { learningSession?: Maybe<(
      { __typename?: 'ClassRoomLearningSessionType' }
      & Pick<ClassRoomLearningSessionType, 'deepLink'>
      & Classroom_Ls_FragmentFragment
    )> }
  )>>> }
);

export type ClassRoomsAvailableBySpecialityGroupQueryVariables = Exact<{
  specialityGroupId?: Maybe<Scalars['UUID']>;
}>;


export type ClassRoomsAvailableBySpecialityGroupQuery = (
  { __typename?: 'Query' }
  & { availableSessionsBySpecialityGroup?: Maybe<Array<Maybe<(
    { __typename?: 'ClassRoomLearningSessionType' }
    & Classroom_Ls_FragmentFragment
  )>>> }
);

export type ClassRoomsFinishedQueryVariables = Exact<{ [key: string]: never; }>;


export type ClassRoomsFinishedQuery = (
  { __typename?: 'Query' }
  & { finishedClassRooms?: Maybe<Array<Maybe<(
    { __typename?: 'FinishedClassRoomLearningSessionType' }
    & Pick<FinishedClassRoomLearningSessionType, 'correctAnswersGoal' | 'questionsAmount' | 'correctAnswersAmount' | 'networkStartDate' | 'outViaSubscriptionDate' | 'specialityGroupIcon' | 'userLearningSessionSettingsId'>
    & { learningSession?: Maybe<(
      { __typename?: 'ClassRoomLearningSessionType' }
      & Classroom_Ls_FragmentFragment
    )> }
  )>>> }
);

export type FindClassRoomsQueryVariables = Exact<{
  searchPointer?: Maybe<Scalars['String']>;
}>;


export type FindClassRoomsQuery = (
  { __typename?: 'Query' }
  & { findClassRooms?: Maybe<Array<Maybe<(
    { __typename?: 'ClassRoomLearningSessionType' }
    & Pick<ClassRoomLearningSessionType, 'specialityGroupIcon'>
    & Classroom_Ls_FragmentFragment
  )>>> }
);

export type SearchNetworkClassRoomSessionsQueryVariables = Exact<{
  searchPointer?: Maybe<Scalars['String']>;
}>;


export type SearchNetworkClassRoomSessionsQuery = (
  { __typename?: 'Query' }
  & { searchNetworkClassRoomSessions?: Maybe<Array<Maybe<(
    { __typename?: 'ClassRoomLearningSessionType' }
    & Pick<ClassRoomLearningSessionType, 'specialityGroupIcon' | 'isForSingle'>
    & Classroom_Ls_FragmentFragment
  )>>> }
);

export type ClassRoomSessionQueryVariables = Exact<{
  sessionId?: Maybe<Scalars['UUID']>;
  userSessionSettingsId?: Maybe<Scalars['UUID']>;
}>;


export type ClassRoomSessionQuery = (
  { __typename?: 'Query' }
  & { classRoomLearningSession?: Maybe<(
    { __typename?: 'ClassRoomLearningSessionType' }
    & Pick<ClassRoomLearningSessionType, 'id' | 'status' | 'startDate' | 'finishDate' | 'isForPremiumOnly' | 'duration' | 'specialityGroupIcon' | 'deepLink'>
    & { classRoom: (
      { __typename?: 'ClassRoomType' }
      & Pick<ClassRoomType, 'id' | 'title' | 'description' | 'learningMoreInfoLink' | 'classRoomImage' | 'backgroundImage' | 'enrollmentType'>
      & { classRoomLecture: Array<(
        { __typename?: 'ClassRoomLectureType' }
        & Pick<ClassRoomLectureType, 'id' | 'lectureNumber' | 'isVisible' | 'lectureQuestionCount' | 'lectureGoal' | 'correctAnswerCount'>
        & { lecture: (
          { __typename?: 'LectureType' }
          & Pick<LectureType, 'id' | 'title'>
        ) }
      )>, aggregatedRate?: Maybe<(
        { __typename?: 'AggregatedRateType' }
        & Pick<AggregatedRateType, 'id' | 'averageRate' | 'feedbackCount'>
      )>, certificate?: Maybe<(
        { __typename?: 'CertificateType' }
        & Pick<CertificateType, 'id' | 'points'>
      )>, organizer?: Maybe<(
        { __typename?: 'OrganizerType' }
        & Pick<OrganizerType, 'id' | 'name' | 'email' | 'logo' | 'linkToSite'>
      )>, sponsors: Array<(
        { __typename?: 'SponsorType' }
        & Pick<SponsorType, 'id' | 'title' | 'logo'>
      )> }
    ) }
  )>, lekSessionsData?: Maybe<Array<Maybe<(
    { __typename?: 'UserLEKDatasType' }
    & Pick<UserLekDatasType, 'id'>
    & { lekSession?: Maybe<(
      { __typename?: 'LEKSessionType' }
      & Pick<LekSessionType, 'id' | 'status' | 'finishDateTime' | 'startDateTime'>
      & { lek: (
        { __typename?: 'LEKType' }
        & Pick<LekType, 'id' | 'title' | 'description' | 'possibleAttempts'>
      ) }
    )>, userLekData?: Maybe<(
      { __typename?: 'UserLEKDataType' }
      & Pick<UserLekDataType, 'id' | 'status' | 'isPassed' | 'attemptNumber'>
    )> }
  )>>> }
);

export type ClassroomLekSessionsQueryVariables = Exact<{
  sessionId?: Maybe<Scalars['UUID']>;
}>;


export type ClassroomLekSessionsQuery = (
  { __typename?: 'Query' }
  & { lekSessionsData?: Maybe<Array<Maybe<(
    { __typename?: 'UserLEKDatasType' }
    & Pick<UserLekDatasType, 'id'>
    & { lekSession?: Maybe<(
      { __typename?: 'LEKSessionType' }
      & Pick<LekSessionType, 'id' | 'status' | 'finishDateTime' | 'startDateTime'>
    )> }
  )>>> }
);

export type CrSessionTypeQueryVariables = Exact<{
  sessionId?: Maybe<Scalars['UUID']>;
}>;


export type CrSessionTypeQuery = (
  { __typename?: 'Query' }
  & { classRoomLearningSession?: Maybe<(
    { __typename?: 'ClassRoomLearningSessionType' }
    & Pick<ClassRoomLearningSessionType, 'id'>
    & { classRoom: (
      { __typename?: 'ClassRoomType' }
      & Pick<ClassRoomType, 'id' | 'enrollmentType'>
    ) }
  )> }
);

export type ClassRoomDetailsQueryVariables = Exact<{
  sessionId?: Maybe<Scalars['UUID']>;
}>;


export type ClassRoomDetailsQuery = (
  { __typename?: 'Query' }
  & { classRoomLearningSession?: Maybe<(
    { __typename?: 'ClassRoomLearningSessionType' }
    & Pick<ClassRoomLearningSessionType, 'id' | 'status' | 'startDate' | 'finishDate' | 'isForPremiumOnly' | 'isForSingle' | 'duration' | 'specialityGroupIcon' | 'deepLink'>
    & { classRoom: (
      { __typename?: 'ClassRoomType' }
      & Pick<ClassRoomType, 'id' | 'title' | 'description' | 'learningMoreInfoLink' | 'classRoomImage' | 'backgroundImage' | 'enrollmentType' | 'isExternalOrganizer'>
      & { aggregatedRate?: Maybe<(
        { __typename?: 'AggregatedRateType' }
        & Pick<AggregatedRateType, 'id' | 'averageRate' | 'feedbackCount'>
      )>, certificate?: Maybe<(
        { __typename?: 'CertificateType' }
        & Pick<CertificateType, 'id' | 'points'>
      )>, organizer?: Maybe<(
        { __typename?: 'OrganizerType' }
        & Pick<OrganizerType, 'id' | 'name' | 'email' | 'logo' | 'linkToSite'>
      )>, sponsors: Array<(
        { __typename?: 'SponsorType' }
        & Pick<SponsorType, 'id' | 'title' | 'logo'>
      )> }
    ) }
  )> }
);

export type SpecialityGroupListQueryVariables = Exact<{ [key: string]: never; }>;


export type SpecialityGroupListQuery = (
  { __typename?: 'Query' }
  & { userSpecialityGroupList?: Maybe<Array<Maybe<(
    { __typename?: 'UserSpecialityGroupType' }
    & Pick<UserSpecialityGroupType, 'classRoomSessionCount'>
    & { specialityGroup?: Maybe<(
      { __typename?: 'SpecialityGroupType' }
      & Pick<SpecialityGroupType, 'id' | 'name' | 'icon'>
    )> }
  )>>> }
);

export type SpecialityGroupQueryVariables = Exact<{
  specialityGroupId?: Maybe<Scalars['UUID']>;
}>;


export type SpecialityGroupQuery = (
  { __typename?: 'Query' }
  & { specialityGroup?: Maybe<(
    { __typename?: 'SpecialityGroupType' }
    & Pick<SpecialityGroupType, 'id' | 'name' | 'icon'>
  )> }
);

export type GetLekSessionQueryVariables = Exact<{
  lekSessionId?: Maybe<Scalars['UUID']>;
  sessionId?: Maybe<Scalars['UUID']>;
}>;


export type GetLekSessionQuery = (
  { __typename?: 'Query' }
  & { lekSession?: Maybe<(
    { __typename?: 'LEKSessionType' }
    & Pick<LekSessionType, 'id' | 'status' | 'startDateTime' | 'finishDateTime'>
    & { lek: (
      { __typename?: 'LEKType' }
      & Pick<LekType, 'id' | 'title' | 'lekGoal' | 'questionCount' | 'answerTimeDuration'>
      & { questions: Array<(
        { __typename?: 'QuestionType' }
        & Pick<QuestionType, 'id' | 'questionImage' | 'value'>
        & { answers: Array<(
          { __typename?: 'AnswersType' }
          & Pick<AnswersType, 'id' | 'value'>
        )> }
      )> }
    ) }
  )>, userLekData?: Maybe<(
    { __typename?: 'UserLEKDataType' }
    & Pick<UserLekDataType, 'id' | 'created' | 'status' | 'elapsedTime'>
    & { userLekQuestionAnswerData: Array<(
      { __typename?: 'UserLEKQuestionAnswerDataType' }
      & Pick<UserLekQuestionAnswerDataType, 'id'>
      & { question: (
        { __typename?: 'QuestionType' }
        & Pick<QuestionType, 'id'>
      ), userAnswer: (
        { __typename?: 'AnswersType' }
        & Pick<AnswersType, 'id'>
      ) }
    )> }
  )> }
);

export type GetLekSessionResultsQueryVariables = Exact<{
  sessionId?: Maybe<Scalars['UUID']>;
}>;


export type GetLekSessionResultsQuery = (
  { __typename?: 'Query' }
  & { lekSessionsData?: Maybe<Array<Maybe<(
    { __typename?: 'UserLEKDatasType' }
    & Pick<UserLekDatasType, 'id'>
    & { lekSession?: Maybe<(
      { __typename?: 'LEKSessionType' }
      & Pick<LekSessionType, 'id' | 'finishDateTime' | 'status'>
      & { lek: (
        { __typename?: 'LEKType' }
        & Pick<LekType, 'id' | 'title' | 'lekGoal' | 'questionCount' | 'possibleAttempts'>
      ), classRoomLearningSession: (
        { __typename?: 'ClassRoomLearningSessionType' }
        & Pick<ClassRoomLearningSessionType, 'id' | 'status'>
      ) }
    )>, userLekData?: Maybe<(
      { __typename?: 'UserLEKDataType' }
      & Pick<UserLekDataType, 'id' | 'updated' | 'status' | 'correctAnswerCount' | 'elapsedTime' | 'isPassed' | 'attemptNumber'>
    )> }
  )>>> }
);

export type ClassRoomSessionProgressQueryVariables = Exact<{
  sessionId?: Maybe<Scalars['UUID']>;
}>;


export type ClassRoomSessionProgressQuery = (
  { __typename?: 'Query' }
  & { classRoomLearningSession?: Maybe<(
    { __typename?: 'ClassRoomLearningSessionType' }
    & Pick<ClassRoomLearningSessionType, 'id'>
    & { classRoom: (
      { __typename?: 'ClassRoomType' }
      & Pick<ClassRoomType, 'id'>
      & { classRoomLecture: Array<(
        { __typename?: 'ClassRoomLectureType' }
        & Pick<ClassRoomLectureType, 'id' | 'correctAnswerCount'>
      )> }
    ) }
  )>, ongoingClassRooms?: Maybe<Array<Maybe<(
    { __typename?: 'OngoingUserLearningSessionType' }
    & Pick<OngoingUserLearningSessionType, 'correctAnswersAmount'>
    & { learningSession?: Maybe<(
      { __typename?: 'ClassRoomLearningSessionType' }
      & Pick<ClassRoomLearningSessionType, 'id'>
    )> }
  )>>> }
);

export type ClassRoomStatisticsQueryVariables = Exact<{
  sessionId?: Maybe<Scalars['UUID']>;
  userSessionSettingsId?: Maybe<Scalars['UUID']>;
}>;


export type ClassRoomStatisticsQuery = (
  { __typename?: 'Query' }
  & { userQuestionsCountData?: Maybe<(
    { __typename?: 'UserLearningSessionQuestionsDataType' }
    & Pick<UserLearningSessionQuestionsDataType, 'questionCountTotal' | 'questionCount' | 'correctAnswerCount' | 'wrongAnswerCount' | 'questionCountRepeat' | 'correctAnswerCountRepeat' | 'generalQuestionCount'>
  )> }
);

export type OrganizerQueryVariables = Exact<{
  organizerId?: Maybe<Scalars['UUID']>;
}>;


export type OrganizerQuery = (
  { __typename?: 'Query' }
  & { organizer?: Maybe<(
    { __typename?: 'OrganizerType' }
    & Pick<OrganizerType, 'id' | 'name' | 'email' | 'about' | 'logo' | 'linkToSite'>
    & { city?: Maybe<(
      { __typename?: 'CityType' }
      & Pick<CityType, 'id' | 'title'>
    )>, classroomSet: Array<(
      { __typename?: 'ClassRoomType' }
      & Pick<ClassRoomType, 'id'>
    )> }
  )> }
);

export type OrganizerClassroomsQueryVariables = Exact<{
  organizerId?: Maybe<Scalars['UUID']>;
}>;


export type OrganizerClassroomsQuery = (
  { __typename?: 'Query' }
  & { organizer?: Maybe<(
    { __typename?: 'OrganizerType' }
    & Pick<OrganizerType, 'id' | 'name'>
    & { classroomSet: Array<(
      { __typename?: 'ClassRoomType' }
      & Pick<ClassRoomType, 'id' | 'title' | 'backgroundImage' | 'classRoomImage' | 'enrollmentType'>
      & { classRoomLearningSession: Array<(
        { __typename?: 'ClassRoomLearningSessionType' }
        & Pick<ClassRoomLearningSessionType, 'id' | 'startDate' | 'finishDate' | 'specialityGroupIcon'>
      )>, aggregatedRate?: Maybe<(
        { __typename?: 'AggregatedRateType' }
        & Pick<AggregatedRateType, 'id' | 'averageRate' | 'feedbackCount'>
      )>, certificate?: Maybe<(
        { __typename?: 'CertificateType' }
        & Pick<CertificateType, 'id' | 'points'>
      )> }
    )> }
  )> }
);

export type ClassroomMembersQueryVariables = Exact<{
  sessionId?: Maybe<Scalars['UUID']>;
}>;


export type ClassroomMembersQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'startedDuelsToday'>
  & { classRoomMembers?: Maybe<Array<Maybe<(
    { __typename?: 'ClassRoomMemberType' }
    & Pick<ClassRoomMemberType, 'id' | 'isAvailableForDuel'>
    & { userData?: Maybe<(
      { __typename?: 'UserLearningSessionCorrectAnswersDataType' }
      & Pick<UserLearningSessionCorrectAnswersDataType, 'id' | 'classRoomCorrectAnswersCount'>
      & { user: (
        { __typename?: 'UserType' }
        & Pick<UserType, 'id' | 'username' | 'avatar'>
      ), userClassRoomLearningSessionSettings?: Maybe<(
        { __typename?: 'UserClassRoomLearningSessionSettingsType' }
        & Pick<UserClassRoomLearningSessionSettingsType, 'isClosed'>
      )> }
    )> }
  )>>> }
);

export type ClassRoomSettingsQueryVariables = Exact<{
  learningSessionId?: Maybe<Scalars['UUID']>;
  userSessionSettingId?: Maybe<Scalars['UUID']>;
}>;


export type ClassRoomSettingsQuery = (
  { __typename?: 'Query' }
  & { userClassRoomSettings?: Maybe<(
    { __typename?: 'UserClassRoomLearningSessionSettingsType' }
    & Pick<UserClassRoomLearningSessionSettingsType, 'id' | 'created' | 'updated' | 'isDuelModeEnabled' | 'isDailyTipperModeEnabled' | 'isAchieved' | 'isClosed' | 'isOut' | 'specialityGroupIcon'>
    & { classRoomLearningSession: (
      { __typename?: 'ClassRoomLearningSessionType' }
      & Pick<ClassRoomLearningSessionType, 'id' | 'status'>
      & { classRoom: (
        { __typename?: 'ClassRoomType' }
        & Pick<ClassRoomType, 'id' | 'enrollmentType'>
      ) }
    ) }
  )> }
);

export type LectorQueryVariables = Exact<{
  lectorId?: Maybe<Scalars['UUID']>;
}>;


export type LectorQuery = (
  { __typename?: 'Query' }
  & { lector?: Maybe<(
    { __typename?: 'LectorType' }
    & Pick<LectorType, 'id' | 'firstName' | 'lastName' | 'email' | 'about' | 'photo'>
    & { professions: Array<(
      { __typename?: 'ProfessionType' }
      & Pick<ProfessionType, 'id' | 'title'>
    )>, specialities: Array<(
      { __typename?: 'SpecialityType' }
      & Pick<SpecialityType, 'id' | 'title'>
    )>, aggregatedRate?: Maybe<(
      { __typename?: 'AggregatedRateType' }
      & Pick<AggregatedRateType, 'id' | 'averageRate' | 'feedbackCount'>
    )>, city?: Maybe<(
      { __typename?: 'CityType' }
      & Pick<CityType, 'id' | 'title'>
    )>, lectureSet: Array<(
      { __typename?: 'LectureType' }
      & Pick<LectureType, 'id' | 'title' | 'description'>
      & { classroomSet: Array<(
        { __typename?: 'ClassRoomType' }
        & Pick<ClassRoomType, 'id' | 'title'>
      )> }
    )> }
  )> }
);

export type ClassroomLecturesQueryVariables = Exact<{
  learningSessionId?: Maybe<Scalars['UUID']>;
}>;


export type ClassroomLecturesQuery = (
  { __typename?: 'Query' }
  & { classRoomLectures?: Maybe<Array<Maybe<(
    { __typename?: 'ClassRoomLectureType' }
    & Pick<ClassRoomLectureType, 'id' | 'lectureNumber'>
    & { lecture: (
      { __typename?: 'LectureType' }
      & Lecture_FragmentFragment
    ) }
  )>>> }
);

export type QuizTimeDetailsQueryVariables = Exact<{
  quizSessionId?: Maybe<Scalars['UUID']>;
}>;


export type QuizTimeDetailsQuery = (
  { __typename?: 'Query' }
  & { quizTimeSession?: Maybe<(
    { __typename?: 'QuizTimeSessionType' }
    & Pick<QuizTimeSessionType, 'id' | 'status' | 'startDate' | 'startDateTime' | 'usersCount' | 'quizTimeDuration' | 'duelDuration' | 'finishDateTime' | 'isForPremiumOnly' | 'isUseCode' | 'maxUserCapacity'>
    & { quizTime: (
      { __typename?: 'QuizTimeType' }
      & Pick<QuizTimeType, 'id' | 'quizTimeImage' | 'title' | 'description' | 'participationConditions'>
      & { organizer: (
        { __typename?: 'OrganizerType' }
        & Pick<OrganizerType, 'id' | 'name' | 'email' | 'logo' | 'linkToSite'>
      ), sponsor: Array<(
        { __typename?: 'SponsorType' }
        & Pick<SponsorType, 'id' | 'title' | 'text' | 'website' | 'email' | 'logo'>
      )> }
    ) }
  )> }
);

export type GetUserQuizSessionSettingsQueryVariables = Exact<{
  quizSessionId?: Maybe<Scalars['UUID']>;
}>;


export type GetUserQuizSessionSettingsQuery = (
  { __typename?: 'Query' }
  & { userQuizSessionSettings?: Maybe<(
    { __typename?: 'UserQuizSessionSettingsType' }
    & Pick<UserQuizSessionSettingsType, 'id' | 'userStatus' | 'isQuizSessionAvailableToStart'>
  )> }
);

export type QuizTimeUsersQueryVariables = Exact<{
  quizSessionId?: Maybe<Scalars['UUID']>;
}>;


export type QuizTimeUsersQuery = (
  { __typename?: 'Query' }
  & { quizSessionMembers?: Maybe<Array<Maybe<(
    { __typename?: 'QuizSessionMemberType' }
    & Pick<QuizSessionMemberType, 'id' | 'isAvailableForQuizDuel'>
    & { userData?: Maybe<(
      { __typename?: 'QuizDuelScoreType' }
      & Pick<QuizDuelScoreType, 'id' | 'rank' | 'points'>
      & { user?: Maybe<(
        { __typename?: 'UserType' }
        & Pick<UserType, 'id' | 'username' | 'avatar'>
      )> }
    )> }
  )>>> }
);

export type PrivacyPolicyQueryVariables = Exact<{ [key: string]: never; }>;


export type PrivacyPolicyQuery = (
  { __typename?: 'Query' }
  & { privatePolicy?: Maybe<(
    { __typename?: 'PrivatePolicyType' }
    & Pick<PrivatePolicyType, 'id' | 'text'>
  )> }
);

export type DataProtectionQueryVariables = Exact<{ [key: string]: never; }>;


export type DataProtectionQuery = (
  { __typename?: 'Query' }
  & { dataProtection?: Maybe<(
    { __typename?: 'DataProtectionType' }
    & Pick<DataProtectionType, 'id' | 'text'>
  )> }
);

export type ImprintQueryVariables = Exact<{ [key: string]: never; }>;


export type ImprintQuery = (
  { __typename?: 'Query' }
  & { impressum?: Maybe<(
    { __typename?: 'ImpressumType' }
    & Pick<ImpressumType, 'id' | 'text'>
  )> }
);

export type QuizSessionsOngoingQueryVariables = Exact<{ [key: string]: never; }>;


export type QuizSessionsOngoingQuery = (
  { __typename?: 'Query' }
  & { ongoingQuizSessions?: Maybe<Array<Maybe<(
    { __typename?: 'QuizTimeSessionType' }
    & Quiztime_List_FragmentFragment
  )>>> }
);

export type QuizSessionsAnnouncedQueryVariables = Exact<{ [key: string]: never; }>;


export type QuizSessionsAnnouncedQuery = (
  { __typename?: 'Query' }
  & { announcedQuizSessions?: Maybe<Array<Maybe<(
    { __typename?: 'QuizTimeSessionType' }
    & Quiztime_List_FragmentFragment
  )>>> }
);

export type QuizSessionsFinishedQueryVariables = Exact<{ [key: string]: never; }>;


export type QuizSessionsFinishedQuery = (
  { __typename?: 'Query' }
  & { finishedQuizSessions?: Maybe<Array<Maybe<(
    { __typename?: 'FinishedUserQuizSessionType' }
    & Pick<FinishedUserQuizSessionType, 'id' | 'userRank'>
    & { quizSession?: Maybe<(
      { __typename?: 'QuizTimeSessionType' }
      & Quiztime_List_FragmentFragment
    )> }
  )>>> }
);

export type QuizSessionsOnHomePageQueryVariables = Exact<{
  startLess24Hours?: Maybe<Scalars['Boolean']>;
}>;


export type QuizSessionsOnHomePageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'UserType' }
    & Pick<UserType, 'id'>
    & { settings?: Maybe<(
      { __typename?: 'SettingsType' }
      & Pick<SettingsType, 'id' | 'duelMode'>
      & { activeQuizTime?: Maybe<(
        { __typename?: 'QuizTimeSessionType' }
        & Pick<QuizTimeSessionType, 'id'>
      )> }
    )> }
  )>, homeQuizSessions?: Maybe<Array<Maybe<(
    { __typename?: 'HomeQuizTimeSessionsType' }
    & Pick<HomeQuizTimeSessionsType, 'userStatus'>
    & { quizSession?: Maybe<(
      { __typename?: 'QuizTimeSessionType' }
      & Quiztime_Homelist_FragmentFragment
    )> }
  )>>>, inProgressUserQuizSession?: Maybe<(
    { __typename?: 'QuizTimeSessionType' }
    & Quiztime_Homelist_FragmentFragment
  )> }
);

export type GetActiveQuizDuelsQueryVariables = Exact<{
  quizSessionId?: Maybe<Scalars['UUID']>;
}>;


export type GetActiveQuizDuelsQuery = (
  { __typename?: 'Query' }
  & { activeQuizDuels?: Maybe<Array<Maybe<(
    { __typename?: 'ActiveQuizDuelsType' }
    & Pick<ActiveQuizDuelsType, 'id' | 'isInitiator'>
    & { opponent?: Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'id' | 'avatar' | 'username'>
    )>, round?: Maybe<(
      { __typename?: 'DuelRoundType' }
      & Pick<DuelRoundType, 'id' | 'roundNumber' | 'created'>
      & { duel?: Maybe<(
        { __typename?: 'DuelType' }
        & Pick<DuelType, 'id' | 'status'>
      )>, userQuestions: Array<(
        { __typename?: 'DuelRoundQuestionAnswerType' }
        & Pick<DuelRoundQuestionAnswerType, 'id' | 'questionNumber'>
        & { user?: Maybe<(
          { __typename?: 'UserType' }
          & Pick<UserType, 'id'>
        )>, question?: Maybe<(
          { __typename?: 'QuestionType' }
          & Pick<QuestionType, 'id' | 'value'>
        )>, userAnswer?: Maybe<(
          { __typename?: 'AnswersType' }
          & Pick<AnswersType, 'id' | 'value'>
        )> }
      )> }
    )>, session?: Maybe<(
      { __typename?: 'QuizTimeSessionType' }
      & Pick<QuizTimeSessionType, 'id'>
    )> }
  )>>> }
);

export type QuizScoreQueryVariables = Exact<{
  quizSessionId?: Maybe<Scalars['UUID']>;
}>;


export type QuizScoreQuery = (
  { __typename?: 'Query' }
  & { quizDuelScore?: Maybe<(
    { __typename?: 'QuizDuelScoreType' }
    & Pick<QuizDuelScoreType, 'id' | 'duels' | 'points' | 'rank' | 'wins' | 'ties' | 'loss'>
  )> }
);

export type GetCompletedQuizDuelsQueryVariables = Exact<{
  quizSessionId?: Maybe<Scalars['UUID']>;
}>;


export type GetCompletedQuizDuelsQuery = (
  { __typename?: 'Query' }
  & { completedQuizDuels?: Maybe<Array<Maybe<(
    { __typename?: 'UsersQuizDuelsDataType' }
    & Pick<UsersQuizDuelsDataType, 'id'>
    & { userDuelsData?: Maybe<(
      { __typename?: 'DuelResultUserDataType' }
      & { duel?: Maybe<(
        { __typename?: 'DuelType' }
        & Pick<DuelType, 'id' | 'updated' | 'status'>
      )> }
      & User_Score_FragmentFragment
    )>, opponentDuelsData?: Maybe<(
      { __typename?: 'DuelResultUserDataType' }
      & User_Score_FragmentFragment
    )>, session?: Maybe<(
      { __typename?: 'QuizTimeSessionType' }
      & Pick<QuizTimeSessionType, 'id'>
    )> }
  )>>> }
);

export type GetQuizSessionPrizeQueryVariables = Exact<{
  quizSessionId?: Maybe<Scalars['UUID']>;
}>;


export type GetQuizSessionPrizeQuery = (
  { __typename?: 'Query' }
  & { quizSessionPrize?: Maybe<(
    { __typename?: 'QuizSessionPrizeListType' }
    & { placeList?: Maybe<Array<Maybe<(
      { __typename?: 'QuizSessionPrizeType' }
      & Quiz_Prize_ItemFragment
    )>>>, participationList?: Maybe<Array<Maybe<(
      { __typename?: 'QuizSessionPrizeType' }
      & Quiz_Prize_ItemFragment
    )>>>, lotteryList?: Maybe<Array<Maybe<(
      { __typename?: 'QuizSessionPrizeType' }
      & Quiz_Prize_ItemFragment
    )>>> }
  )> }
);

export type GetFeedbackQuestionQueryVariables = Exact<{
  questionId: Scalars['UUID'];
}>;


export type GetFeedbackQuestionQuery = (
  { __typename?: 'Query' }
  & { getFeedbackForQuestion?: Maybe<(
    { __typename?: 'FeedbackForQuestionType' }
    & Pick<FeedbackForQuestionType, 'id' | 'relevanceRate' | 'interestRate' | 'comment'>
  )> }
);

export type TakenAchievementQueryVariables = Exact<{ [key: string]: never; }>;


export type TakenAchievementQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'dailyStatus'>
);

export type GlobalDialogQueryVariables = Exact<{ [key: string]: never; }>;


export type GlobalDialogQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'dialogId'>
);

export type ForgotPasswordMutationVariables = Exact<{
  email?: Maybe<Scalars['String']>;
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & { userForgotPassword?: Maybe<(
    { __typename?: 'UserForgotPasswordMutation' }
    & { user?: Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'id'>
    )> }
  )> }
);

export const User_FragmentFragmentDoc = gql`
    fragment user_fragment on UserType {
  id
  avatar
  firstName
  lastName
  username
  email
  dateOfBirth
  gender
  postcode
  dateJoined
  confirmationToken
  profession {
    id
    title
    type {
      id
      title
      type
    }
  }
  anotherProfession
  speciality {
    id
    title
  }
  workplace {
    id
    title
  }
  anotherWorkplace
  efnNumber
  educationLevel {
    id
    title
  }
  university {
    id
    title
  }
  semester {
    id
    title
  }
  registrationNumber
  medicineStudyBegin
  studyBegin {
    id
    title
  }
  studyYear {
    id
    title
  }
  classRoomLearningSession {
    id
  }
  examYear
  professionId
  specialityId
  workplaceId
  educationLevelId
  universityId
  semesterId
  studyBeginId
  studyYearId
  network {
    title
  }
  isPremium
  isInAppPremium
  isPromotionPremium
  isUserForTest
  referralCount
  customer {
    id
    subscription {
      id
      subscriptionType
      status
      upgradeStatus
      currentPeriodEnd
      requestCancel
      lastError
      lastErrorCode
    }
    defaultCard {
      brand
      last4
    }
  }
  subscribeToNews
}
    `;
export const User_Settings_FragmentFragmentDoc = gql`
    fragment user_settings_fragment on SettingsType {
  id
  duelMode
  goal
  dailyTipperMode
  pushNotificationMode
  pushNotificationDailyTipperMode
  pushNotificationDuelMode
  pushNotificationNewChapterMode
  pushNotificationPremiumMode
  pushNotificationQuizTimeMode
  isDailyTipperRulesRead
  isDuelRulesRead
  isQuizDuelRulesRead
  isLikeHumeoQuestionRead
  isIntroCompleted
  isClassRoomIntroCompleted
  isCookieAccepted
  isMarketingCookieAccepted
  isIosInstallationPopupSkipped
  isChromeInstallationPopupSkipped
  activeQuizTime {
    id
  }
}
    `;
export const Lecture_FragmentFragmentDoc = gql`
    fragment lecture_fragment on LectureType {
  id
  title
  description
  lectureLink
  correctAnswersGoal
  questions {
    id
  }
  lectors {
    id
    firstName
    lastName
    photo
    professions {
      id
      title
    }
    specialities {
      id
      title
    }
  }
}
    `;
export const Classroom_Ls_FragmentFragmentDoc = gql`
    fragment classroom_ls_fragment on ClassRoomLearningSessionType {
  id
  status
  startDate
  finishDate
  isForPremiumOnly
  duration
  classRoom {
    id
    title
    classRoomImage
    backgroundImage
    enrollmentType
    aggregatedRate {
      id
      averageRate
      feedbackCount
    }
    certificate {
      id
      points
    }
    organizer {
      id
      name
    }
  }
}
    `;
export const User_Question_FragmentFragmentDoc = gql`
    fragment user_question_fragment on DuelRoundQuestionAnswerType {
  id
  questionNumber
  user {
    id
  }
  question {
    id
    value
    questionImage
    explanation
    sortedLinks {
      id
      url
      linkCategory {
        id
        title
      }
    }
    answers {
      id
      value
    }
  }
  userAnswer {
    id
    value
  }
  correctAnswer {
    id
    value
  }
}
    `;
export const Daily_Tipper_Question_FragmentFragmentDoc = gql`
    fragment daily_tipper_question_fragment on DailyTipperQuestionAnswerType {
  id
  questionNumber
  question {
    id
    value
    questionImage
    explanation
    sortedLinks {
      id
      url
      linkCategory {
        id
        title
      }
    }
    answers {
      id
      value
    }
  }
  userAnswer {
    id
    value
  }
  correctAnswer {
    id
    value
  }
  actualLearningWeek {
    id
    weekProgress
  }
}
    `;
export const User_Score_FragmentFragmentDoc = gql`
    fragment user_score_fragment on DuelResultUserDataType {
  id
  user {
    id
    avatar
    username
  }
  classRoomLearningSession {
    id
    classRoom {
      id
      title
    }
  }
  initiator
  youTurn
  duelScore
  userStatus
}
    `;
export const Quiztime_List_FragmentFragmentDoc = gql`
    fragment quiztime_list_fragment on QuizTimeSessionType {
  id
  status
  startDate
  quizTimeDuration
  startDateTime
  finishDateTime
  isForPremiumOnly
  userQuizSessionSetting {
    id
    userStatus
    quizSessionLabelStatus
  }
  quizTime {
    id
    quizTimeImage
    title
    organizer {
      id
      name
    }
  }
}
    `;
export const Quiztime_Homelist_FragmentFragmentDoc = gql`
    fragment quiztime_homelist_fragment on QuizTimeSessionType {
  id
  startDate
  startDateTime
  finishDateTime
  status
  quizTimeDuration
  duelDuration
  quizTime {
    id
    title
    quizTimeImage
  }
}
    `;
export const Quiz_Prize_ItemFragmentDoc = gql`
    fragment quiz_prize_item on QuizSessionPrizeType {
  id
  placeNumber
  prizeType
  roles
  quizTimePrize {
    id
    title
    description
    prizeImage
  }
}
    `;
export const SignInDocument = gql`
    mutation signIn($email: String, $username: String, $password: String!) {
  userLogin(email: $email, username: $username, password: $password) {
    token
    user {
      ...user_fragment
    }
  }
}
    ${User_FragmentFragmentDoc}`;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
      }
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const SignUpDocument = gql`
    mutation signUp($firstName: String, $lastName: String, $email: String, $username: String, $dateOfBirth: Date, $gender: Int, $postcode: String, $specialityId: UUID, $anotherWorkplace: String, $anotherProfession: String, $efnNumber: String, $examYear: Int, $subscribeToNews: Boolean, $password: String, $passwordConfirmation: String, $studyBeginId: UUID, $educationLevelId: UUID, $universityId: UUID, $semesterId: UUID, $sharingSenderId: UUID, $registrationNumber: String, $medicineStudyBegin: Date, $workplaceId: UUID, $professionId: UUID, $studyYearId: UUID, $deepLinkHash: String) {
  userRegistration(
    firstName: $firstName
    lastName: $lastName
    email: $email
    username: $username
    dateOfBirth: $dateOfBirth
    gender: $gender
    postcode: $postcode
    specialityId: $specialityId
    anotherWorkplace: $anotherWorkplace
    anotherProfession: $anotherProfession
    efnNumber: $efnNumber
    examYear: $examYear
    subscribeToNews: $subscribeToNews
    password: $password
    passwordConfirmation: $passwordConfirmation
    studyBeginId: $studyBeginId
    educationLevelId: $educationLevelId
    universityId: $universityId
    semesterId: $semesterId
    sharingSenderId: $sharingSenderId
    registrationNumber: $registrationNumber
    medicineStudyBegin: $medicineStudyBegin
    workplaceId: $workplaceId
    professionId: $professionId
    studyYearId: $studyYearId
    deepLinkHash: $deepLinkHash
  ) {
    token
    user {
      ...user_fragment
    }
  }
}
    ${User_FragmentFragmentDoc}`;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      username: // value for 'username'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      gender: // value for 'gender'
 *      postcode: // value for 'postcode'
 *      specialityId: // value for 'specialityId'
 *      anotherWorkplace: // value for 'anotherWorkplace'
 *      anotherProfession: // value for 'anotherProfession'
 *      efnNumber: // value for 'efnNumber'
 *      examYear: // value for 'examYear'
 *      subscribeToNews: // value for 'subscribeToNews'
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *      studyBeginId: // value for 'studyBeginId'
 *      educationLevelId: // value for 'educationLevelId'
 *      universityId: // value for 'universityId'
 *      semesterId: // value for 'semesterId'
 *      sharingSenderId: // value for 'sharingSenderId'
 *      registrationNumber: // value for 'registrationNumber'
 *      medicineStudyBegin: // value for 'medicineStudyBegin'
 *      workplaceId: // value for 'workplaceId'
 *      professionId: // value for 'professionId'
 *      studyYearId: // value for 'studyYearId'
 *      deepLinkHash: // value for 'deepLinkHash'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const UpdateUserProfileDocument = gql`
    mutation updateUserProfile($anotherWorkplace: String, $anotherProfession: String, $dateOfBirth: Date, $educationLevelId: UUID, $examYear: Int, $firstName: String, $gender: Int, $lastName: String, $medicineStudyBegin: Date, $postcode: String, $registrationNumber: String, $semesterId: UUID, $specialityId: UUID, $studyBeginId: UUID, $studyYearId: UUID, $universityId: UUID, $username: String, $workplaceId: UUID) {
  updateUserData(
    anotherWorkplace: $anotherWorkplace
    anotherProfession: $anotherProfession
    dateOfBirth: $dateOfBirth
    educationLevelId: $educationLevelId
    examYear: $examYear
    firstName: $firstName
    gender: $gender
    lastName: $lastName
    medicineStudyBegin: $medicineStudyBegin
    postcode: $postcode
    registrationNumber: $registrationNumber
    semesterId: $semesterId
    specialityId: $specialityId
    studyBeginId: $studyBeginId
    studyYearId: $studyYearId
    universityId: $universityId
    username: $username
    workplaceId: $workplaceId
  ) {
    token
    user {
      ...user_fragment
    }
  }
}
    ${User_FragmentFragmentDoc}`;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      anotherWorkplace: // value for 'anotherWorkplace'
 *      anotherProfession: // value for 'anotherProfession'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      educationLevelId: // value for 'educationLevelId'
 *      examYear: // value for 'examYear'
 *      firstName: // value for 'firstName'
 *      gender: // value for 'gender'
 *      lastName: // value for 'lastName'
 *      medicineStudyBegin: // value for 'medicineStudyBegin'
 *      postcode: // value for 'postcode'
 *      registrationNumber: // value for 'registrationNumber'
 *      semesterId: // value for 'semesterId'
 *      specialityId: // value for 'specialityId'
 *      studyBeginId: // value for 'studyBeginId'
 *      studyYearId: // value for 'studyYearId'
 *      universityId: // value for 'universityId'
 *      username: // value for 'username'
 *      workplaceId: // value for 'workplaceId'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(UpdateUserProfileDocument, options);
      }
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($newPassword: String, $newPasswordConfirmation: String, $resetPasswordKey: String) {
  userRestorePassword(
    newPassword: $newPassword
    newPasswordConfirmation: $newPasswordConfirmation
    resetPasswordKey: $resetPasswordKey
  ) {
    user {
      id
    }
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      newPasswordConfirmation: // value for 'newPasswordConfirmation'
 *      resetPasswordKey: // value for 'resetPasswordKey'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ConfirmTokenDocument = gql`
    mutation confirmToken($token: UUID) {
  userConfirm(token: $token) {
    user {
      id
    }
  }
}
    `;
export type ConfirmTokenMutationFn = Apollo.MutationFunction<ConfirmTokenMutation, ConfirmTokenMutationVariables>;

/**
 * __useConfirmTokenMutation__
 *
 * To run a mutation, you first call `useConfirmTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmTokenMutation, { data, loading, error }] = useConfirmTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmTokenMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmTokenMutation, ConfirmTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmTokenMutation, ConfirmTokenMutationVariables>(ConfirmTokenDocument, options);
      }
export type ConfirmTokenMutationHookResult = ReturnType<typeof useConfirmTokenMutation>;
export type ConfirmTokenMutationResult = Apollo.MutationResult<ConfirmTokenMutation>;
export type ConfirmTokenMutationOptions = Apollo.BaseMutationOptions<ConfirmTokenMutation, ConfirmTokenMutationVariables>;
export const UploadPhotoDocument = gql`
    mutation uploadPhoto($file: Upload!) {
  uploadPhoto(file: $file) {
    user {
      id
      avatar
    }
  }
}
    `;
export type UploadPhotoMutationFn = Apollo.MutationFunction<UploadPhotoMutation, UploadPhotoMutationVariables>;

/**
 * __useUploadPhotoMutation__
 *
 * To run a mutation, you first call `useUploadPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPhotoMutation, { data, loading, error }] = useUploadPhotoMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadPhotoMutation(baseOptions?: Apollo.MutationHookOptions<UploadPhotoMutation, UploadPhotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadPhotoMutation, UploadPhotoMutationVariables>(UploadPhotoDocument, options);
      }
export type UploadPhotoMutationHookResult = ReturnType<typeof useUploadPhotoMutation>;
export type UploadPhotoMutationResult = Apollo.MutationResult<UploadPhotoMutation>;
export type UploadPhotoMutationOptions = Apollo.BaseMutationOptions<UploadPhotoMutation, UploadPhotoMutationVariables>;
export const ApplyNetworkCodeDocument = gql`
    mutation applyNetworkCode($code: Int) {
  applyNetworkCodeForUser(code: $code) {
    codes {
      code
    }
  }
}
    `;
export type ApplyNetworkCodeMutationFn = Apollo.MutationFunction<ApplyNetworkCodeMutation, ApplyNetworkCodeMutationVariables>;

/**
 * __useApplyNetworkCodeMutation__
 *
 * To run a mutation, you first call `useApplyNetworkCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyNetworkCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyNetworkCodeMutation, { data, loading, error }] = useApplyNetworkCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useApplyNetworkCodeMutation(baseOptions?: Apollo.MutationHookOptions<ApplyNetworkCodeMutation, ApplyNetworkCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyNetworkCodeMutation, ApplyNetworkCodeMutationVariables>(ApplyNetworkCodeDocument, options);
      }
export type ApplyNetworkCodeMutationHookResult = ReturnType<typeof useApplyNetworkCodeMutation>;
export type ApplyNetworkCodeMutationResult = Apollo.MutationResult<ApplyNetworkCodeMutation>;
export type ApplyNetworkCodeMutationOptions = Apollo.BaseMutationOptions<ApplyNetworkCodeMutation, ApplyNetworkCodeMutationVariables>;
export const ApplyClassroomCodeDocument = gql`
    mutation applyClassroomCode($code: Int, $sessionId: UUID, $isNetworkType: Boolean, $isAvailableTab: Boolean, $specialityGroupIcon: String, $deepLinkHash: String) {
  applyClassRoomCode(
    code: $code
    sessionId: $sessionId
    isNetworkType: $isNetworkType
    isAvailableTab: $isAvailableTab
    specialityGroupIcon: $specialityGroupIcon
    deepLinkHash: $deepLinkHash
  ) {
    classRoom {
      id
      classRoom {
        id
        enrollmentType
      }
    }
  }
}
    `;
export type ApplyClassroomCodeMutationFn = Apollo.MutationFunction<ApplyClassroomCodeMutation, ApplyClassroomCodeMutationVariables>;

/**
 * __useApplyClassroomCodeMutation__
 *
 * To run a mutation, you first call `useApplyClassroomCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyClassroomCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyClassroomCodeMutation, { data, loading, error }] = useApplyClassroomCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *      sessionId: // value for 'sessionId'
 *      isNetworkType: // value for 'isNetworkType'
 *      isAvailableTab: // value for 'isAvailableTab'
 *      specialityGroupIcon: // value for 'specialityGroupIcon'
 *      deepLinkHash: // value for 'deepLinkHash'
 *   },
 * });
 */
export function useApplyClassroomCodeMutation(baseOptions?: Apollo.MutationHookOptions<ApplyClassroomCodeMutation, ApplyClassroomCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyClassroomCodeMutation, ApplyClassroomCodeMutationVariables>(ApplyClassroomCodeDocument, options);
      }
export type ApplyClassroomCodeMutationHookResult = ReturnType<typeof useApplyClassroomCodeMutation>;
export type ApplyClassroomCodeMutationResult = Apollo.MutationResult<ApplyClassroomCodeMutation>;
export type ApplyClassroomCodeMutationOptions = Apollo.BaseMutationOptions<ApplyClassroomCodeMutation, ApplyClassroomCodeMutationVariables>;
export const JoinNetworkClassRoomDocument = gql`
    mutation joinNetworkClassRoom($sessionId: UUID, $deepLinkHash: String, $specialityGroupIcon: String, $isAfterOnboarding: Boolean) {
  joinToNetworkClassRoom(
    sessionId: $sessionId
    deepLinkHash: $deepLinkHash
    specialityGroupIcon: $specialityGroupIcon
    isAfterOnboarding: $isAfterOnboarding
  ) {
    classRoomSession {
      id
      classRoom {
        id
        enrollmentType
      }
    }
  }
}
    `;
export type JoinNetworkClassRoomMutationFn = Apollo.MutationFunction<JoinNetworkClassRoomMutation, JoinNetworkClassRoomMutationVariables>;

/**
 * __useJoinNetworkClassRoomMutation__
 *
 * To run a mutation, you first call `useJoinNetworkClassRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinNetworkClassRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinNetworkClassRoomMutation, { data, loading, error }] = useJoinNetworkClassRoomMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      deepLinkHash: // value for 'deepLinkHash'
 *      specialityGroupIcon: // value for 'specialityGroupIcon'
 *      isAfterOnboarding: // value for 'isAfterOnboarding'
 *   },
 * });
 */
export function useJoinNetworkClassRoomMutation(baseOptions?: Apollo.MutationHookOptions<JoinNetworkClassRoomMutation, JoinNetworkClassRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinNetworkClassRoomMutation, JoinNetworkClassRoomMutationVariables>(JoinNetworkClassRoomDocument, options);
      }
export type JoinNetworkClassRoomMutationHookResult = ReturnType<typeof useJoinNetworkClassRoomMutation>;
export type JoinNetworkClassRoomMutationResult = Apollo.MutationResult<JoinNetworkClassRoomMutation>;
export type JoinNetworkClassRoomMutationOptions = Apollo.BaseMutationOptions<JoinNetworkClassRoomMutation, JoinNetworkClassRoomMutationVariables>;
export const JoinBlendedClassRoomDocument = gql`
    mutation joinBlendedClassRoom($sessionId: UUID, $deepLinkHash: String) {
  joinToBlendedClassRoom(sessionId: $sessionId, deepLinkHash: $deepLinkHash) {
    classRoomSession {
      id
      classRoom {
        id
        enrollmentType
      }
    }
  }
}
    `;
export type JoinBlendedClassRoomMutationFn = Apollo.MutationFunction<JoinBlendedClassRoomMutation, JoinBlendedClassRoomMutationVariables>;

/**
 * __useJoinBlendedClassRoomMutation__
 *
 * To run a mutation, you first call `useJoinBlendedClassRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinBlendedClassRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinBlendedClassRoomMutation, { data, loading, error }] = useJoinBlendedClassRoomMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      deepLinkHash: // value for 'deepLinkHash'
 *   },
 * });
 */
export function useJoinBlendedClassRoomMutation(baseOptions?: Apollo.MutationHookOptions<JoinBlendedClassRoomMutation, JoinBlendedClassRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinBlendedClassRoomMutation, JoinBlendedClassRoomMutationVariables>(JoinBlendedClassRoomDocument, options);
      }
export type JoinBlendedClassRoomMutationHookResult = ReturnType<typeof useJoinBlendedClassRoomMutation>;
export type JoinBlendedClassRoomMutationResult = Apollo.MutationResult<JoinBlendedClassRoomMutation>;
export type JoinBlendedClassRoomMutationOptions = Apollo.BaseMutationOptions<JoinBlendedClassRoomMutation, JoinBlendedClassRoomMutationVariables>;
export const JoinLekClassRoomDocument = gql`
    mutation joinLekClassRoom($sessionId: UUID, $deepLinkHash: String) {
  joinToLekClassRoom(sessionId: $sessionId, deepLinkHash: $deepLinkHash) {
    classRoomSession {
      id
      classRoom {
        id
        enrollmentType
      }
    }
  }
}
    `;
export type JoinLekClassRoomMutationFn = Apollo.MutationFunction<JoinLekClassRoomMutation, JoinLekClassRoomMutationVariables>;

/**
 * __useJoinLekClassRoomMutation__
 *
 * To run a mutation, you first call `useJoinLekClassRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinLekClassRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinLekClassRoomMutation, { data, loading, error }] = useJoinLekClassRoomMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      deepLinkHash: // value for 'deepLinkHash'
 *   },
 * });
 */
export function useJoinLekClassRoomMutation(baseOptions?: Apollo.MutationHookOptions<JoinLekClassRoomMutation, JoinLekClassRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinLekClassRoomMutation, JoinLekClassRoomMutationVariables>(JoinLekClassRoomDocument, options);
      }
export type JoinLekClassRoomMutationHookResult = ReturnType<typeof useJoinLekClassRoomMutation>;
export type JoinLekClassRoomMutationResult = Apollo.MutationResult<JoinLekClassRoomMutation>;
export type JoinLekClassRoomMutationOptions = Apollo.BaseMutationOptions<JoinLekClassRoomMutation, JoinLekClassRoomMutationVariables>;
export const CreateUserDuelDocument = gql`
    mutation createUserDuel($opponentId: UUID, $sessionId: UUID, $isCustomDuel: Boolean) {
  createDuel(
    opponentId: $opponentId
    sessionId: $sessionId
    isCustomDuel: $isCustomDuel
  ) {
    duel {
      id
      status
      duelRound {
        id
        roundNumber
        userQuestions {
          ...user_question_fragment
        }
      }
    }
    errors
  }
}
    ${User_Question_FragmentFragmentDoc}`;
export type CreateUserDuelMutationFn = Apollo.MutationFunction<CreateUserDuelMutation, CreateUserDuelMutationVariables>;

/**
 * __useCreateUserDuelMutation__
 *
 * To run a mutation, you first call `useCreateUserDuelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserDuelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserDuelMutation, { data, loading, error }] = useCreateUserDuelMutation({
 *   variables: {
 *      opponentId: // value for 'opponentId'
 *      sessionId: // value for 'sessionId'
 *      isCustomDuel: // value for 'isCustomDuel'
 *   },
 * });
 */
export function useCreateUserDuelMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserDuelMutation, CreateUserDuelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserDuelMutation, CreateUserDuelMutationVariables>(CreateUserDuelDocument, options);
      }
export type CreateUserDuelMutationHookResult = ReturnType<typeof useCreateUserDuelMutation>;
export type CreateUserDuelMutationResult = Apollo.MutationResult<CreateUserDuelMutation>;
export type CreateUserDuelMutationOptions = Apollo.BaseMutationOptions<CreateUserDuelMutation, CreateUserDuelMutationVariables>;
export const CreateUserRoundDocument = gql`
    mutation createUserRound($duelId: UUID) {
  createRound(duelId: $duelId) {
    round {
      id
      roundNumber
      duel {
        id
        status
        duelRound {
          id
          roundNumber
          userQuestions {
            ...user_question_fragment
          }
        }
      }
      userQuestions {
        ...user_question_fragment
      }
    }
  }
}
    ${User_Question_FragmentFragmentDoc}`;
export type CreateUserRoundMutationFn = Apollo.MutationFunction<CreateUserRoundMutation, CreateUserRoundMutationVariables>;

/**
 * __useCreateUserRoundMutation__
 *
 * To run a mutation, you first call `useCreateUserRoundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserRoundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserRoundMutation, { data, loading, error }] = useCreateUserRoundMutation({
 *   variables: {
 *      duelId: // value for 'duelId'
 *   },
 * });
 */
export function useCreateUserRoundMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserRoundMutation, CreateUserRoundMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserRoundMutation, CreateUserRoundMutationVariables>(CreateUserRoundDocument, options);
      }
export type CreateUserRoundMutationHookResult = ReturnType<typeof useCreateUserRoundMutation>;
export type CreateUserRoundMutationResult = Apollo.MutationResult<CreateUserRoundMutation>;
export type CreateUserRoundMutationOptions = Apollo.BaseMutationOptions<CreateUserRoundMutation, CreateUserRoundMutationVariables>;
export const SetUserAnswerDocument = gql`
    mutation setUserAnswer($duelRoundQuestionAnswerId: UUID, $answerId: UUID) {
  setAnswer(
    duelRoundQuestionAnswerId: $duelRoundQuestionAnswerId
    answerId: $answerId
  ) {
    duelRoundQuestionAnswer {
      duelRound {
        id
        duel {
          id
          status
          result {
            currentUserData {
              ...user_score_fragment
            }
            opponentData {
              ...user_score_fragment
            }
          }
        }
        userQuestions {
          ...user_question_fragment
        }
      }
    }
  }
}
    ${User_Score_FragmentFragmentDoc}
${User_Question_FragmentFragmentDoc}`;
export type SetUserAnswerMutationFn = Apollo.MutationFunction<SetUserAnswerMutation, SetUserAnswerMutationVariables>;

/**
 * __useSetUserAnswerMutation__
 *
 * To run a mutation, you first call `useSetUserAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserAnswerMutation, { data, loading, error }] = useSetUserAnswerMutation({
 *   variables: {
 *      duelRoundQuestionAnswerId: // value for 'duelRoundQuestionAnswerId'
 *      answerId: // value for 'answerId'
 *   },
 * });
 */
export function useSetUserAnswerMutation(baseOptions?: Apollo.MutationHookOptions<SetUserAnswerMutation, SetUserAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserAnswerMutation, SetUserAnswerMutationVariables>(SetUserAnswerDocument, options);
      }
export type SetUserAnswerMutationHookResult = ReturnType<typeof useSetUserAnswerMutation>;
export type SetUserAnswerMutationResult = Apollo.MutationResult<SetUserAnswerMutation>;
export type SetUserAnswerMutationOptions = Apollo.BaseMutationOptions<SetUserAnswerMutation, SetUserAnswerMutationVariables>;
export const SetUserDailyTipperAnswerDocument = gql`
    mutation setUserDailyTipperAnswer($questionId: UUID, $answerId: UUID) {
  setDailyTipperAnswer(questionId: $questionId, answerId: $answerId) {
    dailyTipperQuestionAnswerStatus {
      dailyTipperQuestionAnswer {
        ...daily_tipper_question_fragment
      }
      dailyStatus
    }
  }
}
    ${Daily_Tipper_Question_FragmentFragmentDoc}`;
export type SetUserDailyTipperAnswerMutationFn = Apollo.MutationFunction<SetUserDailyTipperAnswerMutation, SetUserDailyTipperAnswerMutationVariables>;

/**
 * __useSetUserDailyTipperAnswerMutation__
 *
 * To run a mutation, you first call `useSetUserDailyTipperAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserDailyTipperAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserDailyTipperAnswerMutation, { data, loading, error }] = useSetUserDailyTipperAnswerMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      answerId: // value for 'answerId'
 *   },
 * });
 */
export function useSetUserDailyTipperAnswerMutation(baseOptions?: Apollo.MutationHookOptions<SetUserDailyTipperAnswerMutation, SetUserDailyTipperAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserDailyTipperAnswerMutation, SetUserDailyTipperAnswerMutationVariables>(SetUserDailyTipperAnswerDocument, options);
      }
export type SetUserDailyTipperAnswerMutationHookResult = ReturnType<typeof useSetUserDailyTipperAnswerMutation>;
export type SetUserDailyTipperAnswerMutationResult = Apollo.MutationResult<SetUserDailyTipperAnswerMutation>;
export type SetUserDailyTipperAnswerMutationOptions = Apollo.BaseMutationOptions<SetUserDailyTipperAnswerMutation, SetUserDailyTipperAnswerMutationVariables>;
export const ReadNotificationDocument = gql`
    mutation readNotification($id: UUID) {
  notificationStatus(notificationId: $id) {
    notification {
      id
      isRead
    }
  }
}
    `;
export type ReadNotificationMutationFn = Apollo.MutationFunction<ReadNotificationMutation, ReadNotificationMutationVariables>;

/**
 * __useReadNotificationMutation__
 *
 * To run a mutation, you first call `useReadNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readNotificationMutation, { data, loading, error }] = useReadNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadNotificationMutation(baseOptions?: Apollo.MutationHookOptions<ReadNotificationMutation, ReadNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReadNotificationMutation, ReadNotificationMutationVariables>(ReadNotificationDocument, options);
      }
export type ReadNotificationMutationHookResult = ReturnType<typeof useReadNotificationMutation>;
export type ReadNotificationMutationResult = Apollo.MutationResult<ReadNotificationMutation>;
export type ReadNotificationMutationOptions = Apollo.BaseMutationOptions<ReadNotificationMutation, ReadNotificationMutationVariables>;
export const UnsubscribeToNewsDocument = gql`
    mutation unsubscribeToNews($userId: UUID) {
  userUnsubscribeToNews(userId: $userId) {
    user {
      id
    }
  }
}
    `;
export type UnsubscribeToNewsMutationFn = Apollo.MutationFunction<UnsubscribeToNewsMutation, UnsubscribeToNewsMutationVariables>;

/**
 * __useUnsubscribeToNewsMutation__
 *
 * To run a mutation, you first call `useUnsubscribeToNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeToNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeToNewsMutation, { data, loading, error }] = useUnsubscribeToNewsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUnsubscribeToNewsMutation(baseOptions?: Apollo.MutationHookOptions<UnsubscribeToNewsMutation, UnsubscribeToNewsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnsubscribeToNewsMutation, UnsubscribeToNewsMutationVariables>(UnsubscribeToNewsDocument, options);
      }
export type UnsubscribeToNewsMutationHookResult = ReturnType<typeof useUnsubscribeToNewsMutation>;
export type UnsubscribeToNewsMutationResult = Apollo.MutationResult<UnsubscribeToNewsMutation>;
export type UnsubscribeToNewsMutationOptions = Apollo.BaseMutationOptions<UnsubscribeToNewsMutation, UnsubscribeToNewsMutationVariables>;
export const SubscribeToNewsDocument = gql`
    mutation subscribeToNews($userId: UUID) {
  userSubscribeToNews(userId: $userId) {
    user {
      id
    }
  }
}
    `;
export type SubscribeToNewsMutationFn = Apollo.MutationFunction<SubscribeToNewsMutation, SubscribeToNewsMutationVariables>;

/**
 * __useSubscribeToNewsMutation__
 *
 * To run a mutation, you first call `useSubscribeToNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeToNewsMutation, { data, loading, error }] = useSubscribeToNewsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSubscribeToNewsMutation(baseOptions?: Apollo.MutationHookOptions<SubscribeToNewsMutation, SubscribeToNewsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscribeToNewsMutation, SubscribeToNewsMutationVariables>(SubscribeToNewsDocument, options);
      }
export type SubscribeToNewsMutationHookResult = ReturnType<typeof useSubscribeToNewsMutation>;
export type SubscribeToNewsMutationResult = Apollo.MutationResult<SubscribeToNewsMutation>;
export type SubscribeToNewsMutationOptions = Apollo.BaseMutationOptions<SubscribeToNewsMutation, SubscribeToNewsMutationVariables>;
export const UpdateDtSettingsDocument = gql`
    mutation updateDTSettings($goal: Int, $mode: Boolean) {
  updateUserSettings(goal: $goal, dailyTipperMode: $mode) {
    userSettings {
      id
      goal
      dailyTipperMode
    }
  }
}
    `;
export type UpdateDtSettingsMutationFn = Apollo.MutationFunction<UpdateDtSettingsMutation, UpdateDtSettingsMutationVariables>;

/**
 * __useUpdateDtSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateDtSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDtSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDtSettingsMutation, { data, loading, error }] = useUpdateDtSettingsMutation({
 *   variables: {
 *      goal: // value for 'goal'
 *      mode: // value for 'mode'
 *   },
 * });
 */
export function useUpdateDtSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDtSettingsMutation, UpdateDtSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDtSettingsMutation, UpdateDtSettingsMutationVariables>(UpdateDtSettingsDocument, options);
      }
export type UpdateDtSettingsMutationHookResult = ReturnType<typeof useUpdateDtSettingsMutation>;
export type UpdateDtSettingsMutationResult = Apollo.MutationResult<UpdateDtSettingsMutation>;
export type UpdateDtSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateDtSettingsMutation, UpdateDtSettingsMutationVariables>;
export const UpdateDuelsSettingsDocument = gql`
    mutation updateDuelsSettings($mode: Boolean) {
  updateUserSettings(duelMode: $mode) {
    userSettings {
      id
      duelMode
    }
  }
}
    `;
export type UpdateDuelsSettingsMutationFn = Apollo.MutationFunction<UpdateDuelsSettingsMutation, UpdateDuelsSettingsMutationVariables>;

/**
 * __useUpdateDuelsSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateDuelsSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDuelsSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDuelsSettingsMutation, { data, loading, error }] = useUpdateDuelsSettingsMutation({
 *   variables: {
 *      mode: // value for 'mode'
 *   },
 * });
 */
export function useUpdateDuelsSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDuelsSettingsMutation, UpdateDuelsSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDuelsSettingsMutation, UpdateDuelsSettingsMutationVariables>(UpdateDuelsSettingsDocument, options);
      }
export type UpdateDuelsSettingsMutationHookResult = ReturnType<typeof useUpdateDuelsSettingsMutation>;
export type UpdateDuelsSettingsMutationResult = Apollo.MutationResult<UpdateDuelsSettingsMutation>;
export type UpdateDuelsSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateDuelsSettingsMutation, UpdateDuelsSettingsMutationVariables>;
export const UpdatePushNotificationsSettingsDocument = gql`
    mutation updatePushNotificationsSettings($pushNotificationMode: Boolean, $pushNotificationDailyTipperMode: Boolean, $pushNotificationDuelMode: Boolean, $pushNotificationNewChapterMode: Boolean, $pushNotificationPremiumMode: Boolean, $pushNotificationQuizTimeMode: Boolean) {
  updateUserSettings(
    pushNotificationMode: $pushNotificationMode
    pushNotificationDailyTipperMode: $pushNotificationDailyTipperMode
    pushNotificationDuelMode: $pushNotificationDuelMode
    pushNotificationNewChapterMode: $pushNotificationNewChapterMode
    pushNotificationPremiumMode: $pushNotificationPremiumMode
    pushNotificationQuizTimeMode: $pushNotificationQuizTimeMode
  ) {
    userSettings {
      id
      pushNotificationMode
      pushNotificationDailyTipperMode
      pushNotificationDuelMode
      pushNotificationNewChapterMode
      pushNotificationPremiumMode
      pushNotificationQuizTimeMode
    }
  }
}
    `;
export type UpdatePushNotificationsSettingsMutationFn = Apollo.MutationFunction<UpdatePushNotificationsSettingsMutation, UpdatePushNotificationsSettingsMutationVariables>;

/**
 * __useUpdatePushNotificationsSettingsMutation__
 *
 * To run a mutation, you first call `useUpdatePushNotificationsSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePushNotificationsSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePushNotificationsSettingsMutation, { data, loading, error }] = useUpdatePushNotificationsSettingsMutation({
 *   variables: {
 *      pushNotificationMode: // value for 'pushNotificationMode'
 *      pushNotificationDailyTipperMode: // value for 'pushNotificationDailyTipperMode'
 *      pushNotificationDuelMode: // value for 'pushNotificationDuelMode'
 *      pushNotificationNewChapterMode: // value for 'pushNotificationNewChapterMode'
 *      pushNotificationPremiumMode: // value for 'pushNotificationPremiumMode'
 *      pushNotificationQuizTimeMode: // value for 'pushNotificationQuizTimeMode'
 *   },
 * });
 */
export function useUpdatePushNotificationsSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePushNotificationsSettingsMutation, UpdatePushNotificationsSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePushNotificationsSettingsMutation, UpdatePushNotificationsSettingsMutationVariables>(UpdatePushNotificationsSettingsDocument, options);
      }
export type UpdatePushNotificationsSettingsMutationHookResult = ReturnType<typeof useUpdatePushNotificationsSettingsMutation>;
export type UpdatePushNotificationsSettingsMutationResult = Apollo.MutationResult<UpdatePushNotificationsSettingsMutation>;
export type UpdatePushNotificationsSettingsMutationOptions = Apollo.BaseMutationOptions<UpdatePushNotificationsSettingsMutation, UpdatePushNotificationsSettingsMutationVariables>;
export const UpdateRulesSettingsDocument = gql`
    mutation updateRulesSettings($isDailyTipperRulesRead: Boolean, $isDuelRulesRead: Boolean, $isQuizDuelRulesRead: Boolean, $isIntroCompleted: Boolean) {
  updateUserSettings(
    isDailyTipperRulesRead: $isDailyTipperRulesRead
    isDuelRulesRead: $isDuelRulesRead
    isQuizDuelRulesRead: $isQuizDuelRulesRead
    isIntroCompleted: $isIntroCompleted
  ) {
    userSettings {
      id
      isDailyTipperRulesRead
      isDuelRulesRead
      isQuizDuelRulesRead
      isIntroCompleted
    }
  }
}
    `;
export type UpdateRulesSettingsMutationFn = Apollo.MutationFunction<UpdateRulesSettingsMutation, UpdateRulesSettingsMutationVariables>;

/**
 * __useUpdateRulesSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateRulesSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRulesSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRulesSettingsMutation, { data, loading, error }] = useUpdateRulesSettingsMutation({
 *   variables: {
 *      isDailyTipperRulesRead: // value for 'isDailyTipperRulesRead'
 *      isDuelRulesRead: // value for 'isDuelRulesRead'
 *      isQuizDuelRulesRead: // value for 'isQuizDuelRulesRead'
 *      isIntroCompleted: // value for 'isIntroCompleted'
 *   },
 * });
 */
export function useUpdateRulesSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRulesSettingsMutation, UpdateRulesSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRulesSettingsMutation, UpdateRulesSettingsMutationVariables>(UpdateRulesSettingsDocument, options);
      }
export type UpdateRulesSettingsMutationHookResult = ReturnType<typeof useUpdateRulesSettingsMutation>;
export type UpdateRulesSettingsMutationResult = Apollo.MutationResult<UpdateRulesSettingsMutation>;
export type UpdateRulesSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateRulesSettingsMutation, UpdateRulesSettingsMutationVariables>;
export const UpdateClassroomSettingsDocument = gql`
    mutation updateClassroomSettings($sessionId: UUID, $dailyTipperMode: Boolean, $duelMode: Boolean, $isAchieved: Boolean, $isClosed: Boolean, $isOut: Boolean) {
  updateUserClassRoomSettings(
    sessionId: $sessionId
    dailyTipperMode: $dailyTipperMode
    duelMode: $duelMode
    isAchieved: $isAchieved
    isClosed: $isClosed
    isOut: $isOut
  ) {
    userClassRoomSettings {
      id
      isDuelModeEnabled
      isDailyTipperModeEnabled
      isAchieved
      isClosed
      isOut
    }
  }
}
    `;
export type UpdateClassroomSettingsMutationFn = Apollo.MutationFunction<UpdateClassroomSettingsMutation, UpdateClassroomSettingsMutationVariables>;

/**
 * __useUpdateClassroomSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateClassroomSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassroomSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClassroomSettingsMutation, { data, loading, error }] = useUpdateClassroomSettingsMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      dailyTipperMode: // value for 'dailyTipperMode'
 *      duelMode: // value for 'duelMode'
 *      isAchieved: // value for 'isAchieved'
 *      isClosed: // value for 'isClosed'
 *      isOut: // value for 'isOut'
 *   },
 * });
 */
export function useUpdateClassroomSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClassroomSettingsMutation, UpdateClassroomSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClassroomSettingsMutation, UpdateClassroomSettingsMutationVariables>(UpdateClassroomSettingsDocument, options);
      }
export type UpdateClassroomSettingsMutationHookResult = ReturnType<typeof useUpdateClassroomSettingsMutation>;
export type UpdateClassroomSettingsMutationResult = Apollo.MutationResult<UpdateClassroomSettingsMutation>;
export type UpdateClassroomSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateClassroomSettingsMutation, UpdateClassroomSettingsMutationVariables>;
export const UpdateIsIntroCompletedSettingsDocument = gql`
    mutation UpdateIsIntroCompletedSettings($isIntroCompleted: Boolean) {
  updateUserSettings(isIntroCompleted: $isIntroCompleted) {
    userSettings {
      id
      isIntroCompleted
    }
  }
}
    `;
export type UpdateIsIntroCompletedSettingsMutationFn = Apollo.MutationFunction<UpdateIsIntroCompletedSettingsMutation, UpdateIsIntroCompletedSettingsMutationVariables>;

/**
 * __useUpdateIsIntroCompletedSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateIsIntroCompletedSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIsIntroCompletedSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIsIntroCompletedSettingsMutation, { data, loading, error }] = useUpdateIsIntroCompletedSettingsMutation({
 *   variables: {
 *      isIntroCompleted: // value for 'isIntroCompleted'
 *   },
 * });
 */
export function useUpdateIsIntroCompletedSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIsIntroCompletedSettingsMutation, UpdateIsIntroCompletedSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIsIntroCompletedSettingsMutation, UpdateIsIntroCompletedSettingsMutationVariables>(UpdateIsIntroCompletedSettingsDocument, options);
      }
export type UpdateIsIntroCompletedSettingsMutationHookResult = ReturnType<typeof useUpdateIsIntroCompletedSettingsMutation>;
export type UpdateIsIntroCompletedSettingsMutationResult = Apollo.MutationResult<UpdateIsIntroCompletedSettingsMutation>;
export type UpdateIsIntroCompletedSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateIsIntroCompletedSettingsMutation, UpdateIsIntroCompletedSettingsMutationVariables>;
export const UpdateIsClassRoomIntroCompletedSettingsDocument = gql`
    mutation UpdateIsClassRoomIntroCompletedSettings($isClassRoomIntroCompleted: Boolean) {
  updateUserSettings(isClassRoomIntroCompleted: $isClassRoomIntroCompleted) {
    userSettings {
      id
      isClassRoomIntroCompleted
    }
  }
}
    `;
export type UpdateIsClassRoomIntroCompletedSettingsMutationFn = Apollo.MutationFunction<UpdateIsClassRoomIntroCompletedSettingsMutation, UpdateIsClassRoomIntroCompletedSettingsMutationVariables>;

/**
 * __useUpdateIsClassRoomIntroCompletedSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateIsClassRoomIntroCompletedSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIsClassRoomIntroCompletedSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIsClassRoomIntroCompletedSettingsMutation, { data, loading, error }] = useUpdateIsClassRoomIntroCompletedSettingsMutation({
 *   variables: {
 *      isClassRoomIntroCompleted: // value for 'isClassRoomIntroCompleted'
 *   },
 * });
 */
export function useUpdateIsClassRoomIntroCompletedSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIsClassRoomIntroCompletedSettingsMutation, UpdateIsClassRoomIntroCompletedSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIsClassRoomIntroCompletedSettingsMutation, UpdateIsClassRoomIntroCompletedSettingsMutationVariables>(UpdateIsClassRoomIntroCompletedSettingsDocument, options);
      }
export type UpdateIsClassRoomIntroCompletedSettingsMutationHookResult = ReturnType<typeof useUpdateIsClassRoomIntroCompletedSettingsMutation>;
export type UpdateIsClassRoomIntroCompletedSettingsMutationResult = Apollo.MutationResult<UpdateIsClassRoomIntroCompletedSettingsMutation>;
export type UpdateIsClassRoomIntroCompletedSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateIsClassRoomIntroCompletedSettingsMutation, UpdateIsClassRoomIntroCompletedSettingsMutationVariables>;
export const UpdateCookieAcceptedSettingsDocument = gql`
    mutation updateCookieAcceptedSettings($isCookieAccepted: Boolean, $isMarketingCookieAccepted: Boolean) {
  updateUserSettings(
    isCookieAccepted: $isCookieAccepted
    isMarketingCookieAccepted: $isMarketingCookieAccepted
  ) {
    userSettings {
      id
      isCookieAccepted
      isMarketingCookieAccepted
    }
  }
}
    `;
export type UpdateCookieAcceptedSettingsMutationFn = Apollo.MutationFunction<UpdateCookieAcceptedSettingsMutation, UpdateCookieAcceptedSettingsMutationVariables>;

/**
 * __useUpdateCookieAcceptedSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateCookieAcceptedSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCookieAcceptedSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCookieAcceptedSettingsMutation, { data, loading, error }] = useUpdateCookieAcceptedSettingsMutation({
 *   variables: {
 *      isCookieAccepted: // value for 'isCookieAccepted'
 *      isMarketingCookieAccepted: // value for 'isMarketingCookieAccepted'
 *   },
 * });
 */
export function useUpdateCookieAcceptedSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCookieAcceptedSettingsMutation, UpdateCookieAcceptedSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCookieAcceptedSettingsMutation, UpdateCookieAcceptedSettingsMutationVariables>(UpdateCookieAcceptedSettingsDocument, options);
      }
export type UpdateCookieAcceptedSettingsMutationHookResult = ReturnType<typeof useUpdateCookieAcceptedSettingsMutation>;
export type UpdateCookieAcceptedSettingsMutationResult = Apollo.MutationResult<UpdateCookieAcceptedSettingsMutation>;
export type UpdateCookieAcceptedSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateCookieAcceptedSettingsMutation, UpdateCookieAcceptedSettingsMutationVariables>;
export const LeaveClassroomDocument = gql`
    mutation leaveClassroom($sessionId: UUID) {
  leaveClassRoom(sessionId: $sessionId) {
    learningSessionSettings {
      id
    }
  }
}
    `;
export type LeaveClassroomMutationFn = Apollo.MutationFunction<LeaveClassroomMutation, LeaveClassroomMutationVariables>;

/**
 * __useLeaveClassroomMutation__
 *
 * To run a mutation, you first call `useLeaveClassroomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveClassroomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveClassroomMutation, { data, loading, error }] = useLeaveClassroomMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useLeaveClassroomMutation(baseOptions?: Apollo.MutationHookOptions<LeaveClassroomMutation, LeaveClassroomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LeaveClassroomMutation, LeaveClassroomMutationVariables>(LeaveClassroomDocument, options);
      }
export type LeaveClassroomMutationHookResult = ReturnType<typeof useLeaveClassroomMutation>;
export type LeaveClassroomMutationResult = Apollo.MutationResult<LeaveClassroomMutation>;
export type LeaveClassroomMutationOptions = Apollo.BaseMutationOptions<LeaveClassroomMutation, LeaveClassroomMutationVariables>;
export const LeaveNetworkClassroomDocument = gql`
    mutation leaveNetworkClassroom($userSessionSettingsId: UUID) {
  leaveNetworkClassRoom(userSessionSettingsId: $userSessionSettingsId) {
    learningSessionSettings {
      id
    }
  }
}
    `;
export type LeaveNetworkClassroomMutationFn = Apollo.MutationFunction<LeaveNetworkClassroomMutation, LeaveNetworkClassroomMutationVariables>;

/**
 * __useLeaveNetworkClassroomMutation__
 *
 * To run a mutation, you first call `useLeaveNetworkClassroomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveNetworkClassroomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveNetworkClassroomMutation, { data, loading, error }] = useLeaveNetworkClassroomMutation({
 *   variables: {
 *      userSessionSettingsId: // value for 'userSessionSettingsId'
 *   },
 * });
 */
export function useLeaveNetworkClassroomMutation(baseOptions?: Apollo.MutationHookOptions<LeaveNetworkClassroomMutation, LeaveNetworkClassroomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LeaveNetworkClassroomMutation, LeaveNetworkClassroomMutationVariables>(LeaveNetworkClassroomDocument, options);
      }
export type LeaveNetworkClassroomMutationHookResult = ReturnType<typeof useLeaveNetworkClassroomMutation>;
export type LeaveNetworkClassroomMutationResult = Apollo.MutationResult<LeaveNetworkClassroomMutation>;
export type LeaveNetworkClassroomMutationOptions = Apollo.BaseMutationOptions<LeaveNetworkClassroomMutation, LeaveNetworkClassroomMutationVariables>;
export const EarlyFinishNetworkClassroomDocument = gql`
    mutation earlyFinishNetworkClassroom($userSessionSettingsId: UUID) {
  earlyFinishNetworkClassRoom(userSessionSettingsId: $userSessionSettingsId) {
    learningSessionSettings {
      id
    }
  }
}
    `;
export type EarlyFinishNetworkClassroomMutationFn = Apollo.MutationFunction<EarlyFinishNetworkClassroomMutation, EarlyFinishNetworkClassroomMutationVariables>;

/**
 * __useEarlyFinishNetworkClassroomMutation__
 *
 * To run a mutation, you first call `useEarlyFinishNetworkClassroomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEarlyFinishNetworkClassroomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [earlyFinishNetworkClassroomMutation, { data, loading, error }] = useEarlyFinishNetworkClassroomMutation({
 *   variables: {
 *      userSessionSettingsId: // value for 'userSessionSettingsId'
 *   },
 * });
 */
export function useEarlyFinishNetworkClassroomMutation(baseOptions?: Apollo.MutationHookOptions<EarlyFinishNetworkClassroomMutation, EarlyFinishNetworkClassroomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EarlyFinishNetworkClassroomMutation, EarlyFinishNetworkClassroomMutationVariables>(EarlyFinishNetworkClassroomDocument, options);
      }
export type EarlyFinishNetworkClassroomMutationHookResult = ReturnType<typeof useEarlyFinishNetworkClassroomMutation>;
export type EarlyFinishNetworkClassroomMutationResult = Apollo.MutationResult<EarlyFinishNetworkClassroomMutation>;
export type EarlyFinishNetworkClassroomMutationOptions = Apollo.BaseMutationOptions<EarlyFinishNetworkClassroomMutation, EarlyFinishNetworkClassroomMutationVariables>;
export const EarlyFinishBlendedClassroomDocument = gql`
    mutation earlyFinishBlendedClassroom($userSessionSettingsId: UUID) {
  earlyFinishBlendedClassRoom(userSessionSettingsId: $userSessionSettingsId) {
    learningSessionSettings {
      id
    }
  }
}
    `;
export type EarlyFinishBlendedClassroomMutationFn = Apollo.MutationFunction<EarlyFinishBlendedClassroomMutation, EarlyFinishBlendedClassroomMutationVariables>;

/**
 * __useEarlyFinishBlendedClassroomMutation__
 *
 * To run a mutation, you first call `useEarlyFinishBlendedClassroomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEarlyFinishBlendedClassroomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [earlyFinishBlendedClassroomMutation, { data, loading, error }] = useEarlyFinishBlendedClassroomMutation({
 *   variables: {
 *      userSessionSettingsId: // value for 'userSessionSettingsId'
 *   },
 * });
 */
export function useEarlyFinishBlendedClassroomMutation(baseOptions?: Apollo.MutationHookOptions<EarlyFinishBlendedClassroomMutation, EarlyFinishBlendedClassroomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EarlyFinishBlendedClassroomMutation, EarlyFinishBlendedClassroomMutationVariables>(EarlyFinishBlendedClassroomDocument, options);
      }
export type EarlyFinishBlendedClassroomMutationHookResult = ReturnType<typeof useEarlyFinishBlendedClassroomMutation>;
export type EarlyFinishBlendedClassroomMutationResult = Apollo.MutationResult<EarlyFinishBlendedClassroomMutation>;
export type EarlyFinishBlendedClassroomMutationOptions = Apollo.BaseMutationOptions<EarlyFinishBlendedClassroomMutation, EarlyFinishBlendedClassroomMutationVariables>;
export const EarlyFinishLekClassroomDocument = gql`
    mutation earlyFinishLekClassroom($userSessionSettingsId: UUID) {
  earlyFinishLekClassRoom(userSessionSettingsId: $userSessionSettingsId) {
    learningSessionSettings {
      id
    }
  }
}
    `;
export type EarlyFinishLekClassroomMutationFn = Apollo.MutationFunction<EarlyFinishLekClassroomMutation, EarlyFinishLekClassroomMutationVariables>;

/**
 * __useEarlyFinishLekClassroomMutation__
 *
 * To run a mutation, you first call `useEarlyFinishLekClassroomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEarlyFinishLekClassroomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [earlyFinishLekClassroomMutation, { data, loading, error }] = useEarlyFinishLekClassroomMutation({
 *   variables: {
 *      userSessionSettingsId: // value for 'userSessionSettingsId'
 *   },
 * });
 */
export function useEarlyFinishLekClassroomMutation(baseOptions?: Apollo.MutationHookOptions<EarlyFinishLekClassroomMutation, EarlyFinishLekClassroomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EarlyFinishLekClassroomMutation, EarlyFinishLekClassroomMutationVariables>(EarlyFinishLekClassroomDocument, options);
      }
export type EarlyFinishLekClassroomMutationHookResult = ReturnType<typeof useEarlyFinishLekClassroomMutation>;
export type EarlyFinishLekClassroomMutationResult = Apollo.MutationResult<EarlyFinishLekClassroomMutation>;
export type EarlyFinishLekClassroomMutationOptions = Apollo.BaseMutationOptions<EarlyFinishLekClassroomMutation, EarlyFinishLekClassroomMutationVariables>;
export const ResumeBlendedClassroomDocument = gql`
    mutation resumeBlendedClassroom($userSessionSettingsId: UUID!) {
  resumeBlendedClassRoom(userSessionSettingsId: $userSessionSettingsId) {
    learningSessionSettings {
      id
      classRoomLearningSession {
        id
      }
    }
  }
}
    `;
export type ResumeBlendedClassroomMutationFn = Apollo.MutationFunction<ResumeBlendedClassroomMutation, ResumeBlendedClassroomMutationVariables>;

/**
 * __useResumeBlendedClassroomMutation__
 *
 * To run a mutation, you first call `useResumeBlendedClassroomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResumeBlendedClassroomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resumeBlendedClassroomMutation, { data, loading, error }] = useResumeBlendedClassroomMutation({
 *   variables: {
 *      userSessionSettingsId: // value for 'userSessionSettingsId'
 *   },
 * });
 */
export function useResumeBlendedClassroomMutation(baseOptions?: Apollo.MutationHookOptions<ResumeBlendedClassroomMutation, ResumeBlendedClassroomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResumeBlendedClassroomMutation, ResumeBlendedClassroomMutationVariables>(ResumeBlendedClassroomDocument, options);
      }
export type ResumeBlendedClassroomMutationHookResult = ReturnType<typeof useResumeBlendedClassroomMutation>;
export type ResumeBlendedClassroomMutationResult = Apollo.MutationResult<ResumeBlendedClassroomMutation>;
export type ResumeBlendedClassroomMutationOptions = Apollo.BaseMutationOptions<ResumeBlendedClassroomMutation, ResumeBlendedClassroomMutationVariables>;
export const PauseBlendedClassroomDocument = gql`
    mutation pauseBlendedClassroom($userSessionSettingsId: UUID!) {
  pauseBlendedClassRoom(userSessionSettingsId: $userSessionSettingsId) {
    learningSessionSettings {
      id
      classRoomLearningSession {
        id
      }
    }
  }
}
    `;
export type PauseBlendedClassroomMutationFn = Apollo.MutationFunction<PauseBlendedClassroomMutation, PauseBlendedClassroomMutationVariables>;

/**
 * __usePauseBlendedClassroomMutation__
 *
 * To run a mutation, you first call `usePauseBlendedClassroomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseBlendedClassroomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseBlendedClassroomMutation, { data, loading, error }] = usePauseBlendedClassroomMutation({
 *   variables: {
 *      userSessionSettingsId: // value for 'userSessionSettingsId'
 *   },
 * });
 */
export function usePauseBlendedClassroomMutation(baseOptions?: Apollo.MutationHookOptions<PauseBlendedClassroomMutation, PauseBlendedClassroomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PauseBlendedClassroomMutation, PauseBlendedClassroomMutationVariables>(PauseBlendedClassroomDocument, options);
      }
export type PauseBlendedClassroomMutationHookResult = ReturnType<typeof usePauseBlendedClassroomMutation>;
export type PauseBlendedClassroomMutationResult = Apollo.MutationResult<PauseBlendedClassroomMutation>;
export type PauseBlendedClassroomMutationOptions = Apollo.BaseMutationOptions<PauseBlendedClassroomMutation, PauseBlendedClassroomMutationVariables>;
export const UserLekStartDocument = gql`
    mutation userLekStart($lekSessionId: UUID, $startTime: DateTime) {
  lekStart(lekSessionId: $lekSessionId, startTime: $startTime) {
    userLekData {
      id
      created
      status
      elapsedTime
      lekSession {
        id
      }
      userLekQuestionAnswerData {
        id
        question {
          id
        }
        userAnswer {
          id
        }
      }
    }
  }
}
    `;
export type UserLekStartMutationFn = Apollo.MutationFunction<UserLekStartMutation, UserLekStartMutationVariables>;

/**
 * __useUserLekStartMutation__
 *
 * To run a mutation, you first call `useUserLekStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserLekStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userLekStartMutation, { data, loading, error }] = useUserLekStartMutation({
 *   variables: {
 *      lekSessionId: // value for 'lekSessionId'
 *      startTime: // value for 'startTime'
 *   },
 * });
 */
export function useUserLekStartMutation(baseOptions?: Apollo.MutationHookOptions<UserLekStartMutation, UserLekStartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserLekStartMutation, UserLekStartMutationVariables>(UserLekStartDocument, options);
      }
export type UserLekStartMutationHookResult = ReturnType<typeof useUserLekStartMutation>;
export type UserLekStartMutationResult = Apollo.MutationResult<UserLekStartMutation>;
export type UserLekStartMutationOptions = Apollo.BaseMutationOptions<UserLekStartMutation, UserLekStartMutationVariables>;
export const UpdateIsChromeInstallationPopupSkippedSettingsDocument = gql`
    mutation updateIsChromeInstallationPopupSkippedSettings($isChromeInstallationPopupSkipped: Boolean) {
  updateUserSettings(
    isChromeInstallationPopupSkipped: $isChromeInstallationPopupSkipped
  ) {
    userSettings {
      id
      isChromeInstallationPopupSkipped
    }
  }
}
    `;
export type UpdateIsChromeInstallationPopupSkippedSettingsMutationFn = Apollo.MutationFunction<UpdateIsChromeInstallationPopupSkippedSettingsMutation, UpdateIsChromeInstallationPopupSkippedSettingsMutationVariables>;

/**
 * __useUpdateIsChromeInstallationPopupSkippedSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateIsChromeInstallationPopupSkippedSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIsChromeInstallationPopupSkippedSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIsChromeInstallationPopupSkippedSettingsMutation, { data, loading, error }] = useUpdateIsChromeInstallationPopupSkippedSettingsMutation({
 *   variables: {
 *      isChromeInstallationPopupSkipped: // value for 'isChromeInstallationPopupSkipped'
 *   },
 * });
 */
export function useUpdateIsChromeInstallationPopupSkippedSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIsChromeInstallationPopupSkippedSettingsMutation, UpdateIsChromeInstallationPopupSkippedSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIsChromeInstallationPopupSkippedSettingsMutation, UpdateIsChromeInstallationPopupSkippedSettingsMutationVariables>(UpdateIsChromeInstallationPopupSkippedSettingsDocument, options);
      }
export type UpdateIsChromeInstallationPopupSkippedSettingsMutationHookResult = ReturnType<typeof useUpdateIsChromeInstallationPopupSkippedSettingsMutation>;
export type UpdateIsChromeInstallationPopupSkippedSettingsMutationResult = Apollo.MutationResult<UpdateIsChromeInstallationPopupSkippedSettingsMutation>;
export type UpdateIsChromeInstallationPopupSkippedSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateIsChromeInstallationPopupSkippedSettingsMutation, UpdateIsChromeInstallationPopupSkippedSettingsMutationVariables>;
export const UserLekFinishDocument = gql`
    mutation userLekFinish($lekSessionId: UUID, $userLekDataId: UUID) {
  lekFinish(lekSessionId: $lekSessionId, userLekDataId: $userLekDataId) {
    userLekData {
      id
      status
      elapsedTime
      isPassed
      correctAnswerCount
    }
  }
}
    `;
export type UserLekFinishMutationFn = Apollo.MutationFunction<UserLekFinishMutation, UserLekFinishMutationVariables>;

/**
 * __useUserLekFinishMutation__
 *
 * To run a mutation, you first call `useUserLekFinishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserLekFinishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userLekFinishMutation, { data, loading, error }] = useUserLekFinishMutation({
 *   variables: {
 *      lekSessionId: // value for 'lekSessionId'
 *      userLekDataId: // value for 'userLekDataId'
 *   },
 * });
 */
export function useUserLekFinishMutation(baseOptions?: Apollo.MutationHookOptions<UserLekFinishMutation, UserLekFinishMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserLekFinishMutation, UserLekFinishMutationVariables>(UserLekFinishDocument, options);
      }
export type UserLekFinishMutationHookResult = ReturnType<typeof useUserLekFinishMutation>;
export type UserLekFinishMutationResult = Apollo.MutationResult<UserLekFinishMutation>;
export type UserLekFinishMutationOptions = Apollo.BaseMutationOptions<UserLekFinishMutation, UserLekFinishMutationVariables>;
export const SetUserLekAnswerDocument = gql`
    mutation setUserLekAnswer($answerId: UUID, $lekSessionId: UUID, $questionId: UUID, $answerTime: DateTime) {
  setLekAnswer(
    answerId: $answerId
    lekSessionId: $lekSessionId
    questionId: $questionId
    answerTime: $answerTime
  ) {
    userLekQuestionAnswerData {
      id
      userLekData {
        id
        status
      }
    }
  }
}
    `;
export type SetUserLekAnswerMutationFn = Apollo.MutationFunction<SetUserLekAnswerMutation, SetUserLekAnswerMutationVariables>;

/**
 * __useSetUserLekAnswerMutation__
 *
 * To run a mutation, you first call `useSetUserLekAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserLekAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserLekAnswerMutation, { data, loading, error }] = useSetUserLekAnswerMutation({
 *   variables: {
 *      answerId: // value for 'answerId'
 *      lekSessionId: // value for 'lekSessionId'
 *      questionId: // value for 'questionId'
 *      answerTime: // value for 'answerTime'
 *   },
 * });
 */
export function useSetUserLekAnswerMutation(baseOptions?: Apollo.MutationHookOptions<SetUserLekAnswerMutation, SetUserLekAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserLekAnswerMutation, SetUserLekAnswerMutationVariables>(SetUserLekAnswerDocument, options);
      }
export type SetUserLekAnswerMutationHookResult = ReturnType<typeof useSetUserLekAnswerMutation>;
export type SetUserLekAnswerMutationResult = Apollo.MutationResult<SetUserLekAnswerMutation>;
export type SetUserLekAnswerMutationOptions = Apollo.BaseMutationOptions<SetUserLekAnswerMutation, SetUserLekAnswerMutationVariables>;
export const DeleteUserAccountDocument = gql`
    mutation deleteUserAccount {
  deleteAccount {
    isDeleted
  }
}
    `;
export type DeleteUserAccountMutationFn = Apollo.MutationFunction<DeleteUserAccountMutation, DeleteUserAccountMutationVariables>;

/**
 * __useDeleteUserAccountMutation__
 *
 * To run a mutation, you first call `useDeleteUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserAccountMutation, { data, loading, error }] = useDeleteUserAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteUserAccountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserAccountMutation, DeleteUserAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserAccountMutation, DeleteUserAccountMutationVariables>(DeleteUserAccountDocument, options);
      }
export type DeleteUserAccountMutationHookResult = ReturnType<typeof useDeleteUserAccountMutation>;
export type DeleteUserAccountMutationResult = Apollo.MutationResult<DeleteUserAccountMutation>;
export type DeleteUserAccountMutationOptions = Apollo.BaseMutationOptions<DeleteUserAccountMutation, DeleteUserAccountMutationVariables>;
export const PlanSubscribeDocument = gql`
    mutation planSubscribe($stripeToken: String, $subscriptionType: String, $recaptchaToken: String) {
  subscribe(
    stripeToken: $stripeToken
    subscriptionType: $subscriptionType
    recaptchaToken: $recaptchaToken
  ) {
    subscription {
      id
      status
      subscriptionType
      currentPeriodEnd
      requestCancel
    }
  }
}
    `;
export type PlanSubscribeMutationFn = Apollo.MutationFunction<PlanSubscribeMutation, PlanSubscribeMutationVariables>;

/**
 * __usePlanSubscribeMutation__
 *
 * To run a mutation, you first call `usePlanSubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlanSubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [planSubscribeMutation, { data, loading, error }] = usePlanSubscribeMutation({
 *   variables: {
 *      stripeToken: // value for 'stripeToken'
 *      subscriptionType: // value for 'subscriptionType'
 *      recaptchaToken: // value for 'recaptchaToken'
 *   },
 * });
 */
export function usePlanSubscribeMutation(baseOptions?: Apollo.MutationHookOptions<PlanSubscribeMutation, PlanSubscribeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PlanSubscribeMutation, PlanSubscribeMutationVariables>(PlanSubscribeDocument, options);
      }
export type PlanSubscribeMutationHookResult = ReturnType<typeof usePlanSubscribeMutation>;
export type PlanSubscribeMutationResult = Apollo.MutationResult<PlanSubscribeMutation>;
export type PlanSubscribeMutationOptions = Apollo.BaseMutationOptions<PlanSubscribeMutation, PlanSubscribeMutationVariables>;
export const ChangePlanDocument = gql`
    mutation changePlan($subscriptionType: String) {
  changeSubscription(subscriptionType: $subscriptionType) {
    subscription {
      id
      status
      subscriptionType
      currentPeriodEnd
      requestCancel
      upgradeStatus
    }
  }
}
    `;
export type ChangePlanMutationFn = Apollo.MutationFunction<ChangePlanMutation, ChangePlanMutationVariables>;

/**
 * __useChangePlanMutation__
 *
 * To run a mutation, you first call `useChangePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePlanMutation, { data, loading, error }] = useChangePlanMutation({
 *   variables: {
 *      subscriptionType: // value for 'subscriptionType'
 *   },
 * });
 */
export function useChangePlanMutation(baseOptions?: Apollo.MutationHookOptions<ChangePlanMutation, ChangePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePlanMutation, ChangePlanMutationVariables>(ChangePlanDocument, options);
      }
export type ChangePlanMutationHookResult = ReturnType<typeof useChangePlanMutation>;
export type ChangePlanMutationResult = Apollo.MutationResult<ChangePlanMutation>;
export type ChangePlanMutationOptions = Apollo.BaseMutationOptions<ChangePlanMutation, ChangePlanMutationVariables>;
export const ChangeUserCardDocument = gql`
    mutation changeUserCard($stripeToken: String) {
  changeCard(stripeToken: $stripeToken) {
    customer {
      id
      defaultCard {
        brand
        last4
      }
    }
  }
}
    `;
export type ChangeUserCardMutationFn = Apollo.MutationFunction<ChangeUserCardMutation, ChangeUserCardMutationVariables>;

/**
 * __useChangeUserCardMutation__
 *
 * To run a mutation, you first call `useChangeUserCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserCardMutation, { data, loading, error }] = useChangeUserCardMutation({
 *   variables: {
 *      stripeToken: // value for 'stripeToken'
 *   },
 * });
 */
export function useChangeUserCardMutation(baseOptions?: Apollo.MutationHookOptions<ChangeUserCardMutation, ChangeUserCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeUserCardMutation, ChangeUserCardMutationVariables>(ChangeUserCardDocument, options);
      }
export type ChangeUserCardMutationHookResult = ReturnType<typeof useChangeUserCardMutation>;
export type ChangeUserCardMutationResult = Apollo.MutationResult<ChangeUserCardMutation>;
export type ChangeUserCardMutationOptions = Apollo.BaseMutationOptions<ChangeUserCardMutation, ChangeUserCardMutationVariables>;
export const CancelUserSubscriptionDocument = gql`
    mutation cancelUserSubscription {
  cancelSubscription {
    subscription {
      id
      status
      subscriptionType
      currentPeriodEnd
      requestCancel
    }
  }
}
    `;
export type CancelUserSubscriptionMutationFn = Apollo.MutationFunction<CancelUserSubscriptionMutation, CancelUserSubscriptionMutationVariables>;

/**
 * __useCancelUserSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelUserSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelUserSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelUserSubscriptionMutation, { data, loading, error }] = useCancelUserSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelUserSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancelUserSubscriptionMutation, CancelUserSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelUserSubscriptionMutation, CancelUserSubscriptionMutationVariables>(CancelUserSubscriptionDocument, options);
      }
export type CancelUserSubscriptionMutationHookResult = ReturnType<typeof useCancelUserSubscriptionMutation>;
export type CancelUserSubscriptionMutationResult = Apollo.MutationResult<CancelUserSubscriptionMutation>;
export type CancelUserSubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelUserSubscriptionMutation, CancelUserSubscriptionMutationVariables>;
export const RenewPlanDocument = gql`
    mutation renewPlan {
  renewSubscription {
    subscription {
      id
      status
      subscriptionType
      currentPeriodEnd
      requestCancel
    }
  }
}
    `;
export type RenewPlanMutationFn = Apollo.MutationFunction<RenewPlanMutation, RenewPlanMutationVariables>;

/**
 * __useRenewPlanMutation__
 *
 * To run a mutation, you first call `useRenewPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenewPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renewPlanMutation, { data, loading, error }] = useRenewPlanMutation({
 *   variables: {
 *   },
 * });
 */
export function useRenewPlanMutation(baseOptions?: Apollo.MutationHookOptions<RenewPlanMutation, RenewPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RenewPlanMutation, RenewPlanMutationVariables>(RenewPlanDocument, options);
      }
export type RenewPlanMutationHookResult = ReturnType<typeof useRenewPlanMutation>;
export type RenewPlanMutationResult = Apollo.MutationResult<RenewPlanMutation>;
export type RenewPlanMutationOptions = Apollo.BaseMutationOptions<RenewPlanMutation, RenewPlanMutationVariables>;
export const InAppUserSubscribeDocument = gql`
    mutation inAppUserSubscribe($subscriptionObject: GenericScalar) {
  inAppSubscribe(subscriptionObject: $subscriptionObject) {
    inAppSubscription {
      id
      customer {
        id
        isInAppPremium
      }
    }
  }
}
    `;
export type InAppUserSubscribeMutationFn = Apollo.MutationFunction<InAppUserSubscribeMutation, InAppUserSubscribeMutationVariables>;

/**
 * __useInAppUserSubscribeMutation__
 *
 * To run a mutation, you first call `useInAppUserSubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInAppUserSubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inAppUserSubscribeMutation, { data, loading, error }] = useInAppUserSubscribeMutation({
 *   variables: {
 *      subscriptionObject: // value for 'subscriptionObject'
 *   },
 * });
 */
export function useInAppUserSubscribeMutation(baseOptions?: Apollo.MutationHookOptions<InAppUserSubscribeMutation, InAppUserSubscribeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InAppUserSubscribeMutation, InAppUserSubscribeMutationVariables>(InAppUserSubscribeDocument, options);
      }
export type InAppUserSubscribeMutationHookResult = ReturnType<typeof useInAppUserSubscribeMutation>;
export type InAppUserSubscribeMutationResult = Apollo.MutationResult<InAppUserSubscribeMutation>;
export type InAppUserSubscribeMutationOptions = Apollo.BaseMutationOptions<InAppUserSubscribeMutation, InAppUserSubscribeMutationVariables>;
export const UpgradeInAppSubscriptionDocument = gql`
    mutation upgradeInAppSubscription($subscriptionObject: GenericScalar) {
  changeInAppSubscription(subscriptionObject: $subscriptionObject) {
    inAppSubscription {
      id
      customer {
        id
        isInAppPremium
      }
    }
  }
}
    `;
export type UpgradeInAppSubscriptionMutationFn = Apollo.MutationFunction<UpgradeInAppSubscriptionMutation, UpgradeInAppSubscriptionMutationVariables>;

/**
 * __useUpgradeInAppSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpgradeInAppSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpgradeInAppSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upgradeInAppSubscriptionMutation, { data, loading, error }] = useUpgradeInAppSubscriptionMutation({
 *   variables: {
 *      subscriptionObject: // value for 'subscriptionObject'
 *   },
 * });
 */
export function useUpgradeInAppSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpgradeInAppSubscriptionMutation, UpgradeInAppSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpgradeInAppSubscriptionMutation, UpgradeInAppSubscriptionMutationVariables>(UpgradeInAppSubscriptionDocument, options);
      }
export type UpgradeInAppSubscriptionMutationHookResult = ReturnType<typeof useUpgradeInAppSubscriptionMutation>;
export type UpgradeInAppSubscriptionMutationResult = Apollo.MutationResult<UpgradeInAppSubscriptionMutation>;
export type UpgradeInAppSubscriptionMutationOptions = Apollo.BaseMutationOptions<UpgradeInAppSubscriptionMutation, UpgradeInAppSubscriptionMutationVariables>;
export const UpdateIsIosInstallationPopupSkippedSettingsDocument = gql`
    mutation updateIsIosInstallationPopupSkippedSettings($isIosInstallationPopupSkipped: Boolean) {
  updateUserSettings(
    isIosInstallationPopupSkipped: $isIosInstallationPopupSkipped
  ) {
    userSettings {
      id
      isIosInstallationPopupSkipped
    }
  }
}
    `;
export type UpdateIsIosInstallationPopupSkippedSettingsMutationFn = Apollo.MutationFunction<UpdateIsIosInstallationPopupSkippedSettingsMutation, UpdateIsIosInstallationPopupSkippedSettingsMutationVariables>;

/**
 * __useUpdateIsIosInstallationPopupSkippedSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateIsIosInstallationPopupSkippedSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIsIosInstallationPopupSkippedSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIsIosInstallationPopupSkippedSettingsMutation, { data, loading, error }] = useUpdateIsIosInstallationPopupSkippedSettingsMutation({
 *   variables: {
 *      isIosInstallationPopupSkipped: // value for 'isIosInstallationPopupSkipped'
 *   },
 * });
 */
export function useUpdateIsIosInstallationPopupSkippedSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIsIosInstallationPopupSkippedSettingsMutation, UpdateIsIosInstallationPopupSkippedSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIsIosInstallationPopupSkippedSettingsMutation, UpdateIsIosInstallationPopupSkippedSettingsMutationVariables>(UpdateIsIosInstallationPopupSkippedSettingsDocument, options);
      }
export type UpdateIsIosInstallationPopupSkippedSettingsMutationHookResult = ReturnType<typeof useUpdateIsIosInstallationPopupSkippedSettingsMutation>;
export type UpdateIsIosInstallationPopupSkippedSettingsMutationResult = Apollo.MutationResult<UpdateIsIosInstallationPopupSkippedSettingsMutation>;
export type UpdateIsIosInstallationPopupSkippedSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateIsIosInstallationPopupSkippedSettingsMutation, UpdateIsIosInstallationPopupSkippedSettingsMutationVariables>;
export const ApplyQuizTimeCodeDocument = gql`
    mutation applyQuizTimeCode($code: Int, $sessionId: UUID!, $deepLinkHash: String) {
  joinToQuiz(code: $code, sessionId: $sessionId, deepLinkHash: $deepLinkHash) {
    quizSession {
      id
      quizTime {
        id
      }
    }
  }
}
    `;
export type ApplyQuizTimeCodeMutationFn = Apollo.MutationFunction<ApplyQuizTimeCodeMutation, ApplyQuizTimeCodeMutationVariables>;

/**
 * __useApplyQuizTimeCodeMutation__
 *
 * To run a mutation, you first call `useApplyQuizTimeCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyQuizTimeCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyQuizTimeCodeMutation, { data, loading, error }] = useApplyQuizTimeCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *      sessionId: // value for 'sessionId'
 *      deepLinkHash: // value for 'deepLinkHash'
 *   },
 * });
 */
export function useApplyQuizTimeCodeMutation(baseOptions?: Apollo.MutationHookOptions<ApplyQuizTimeCodeMutation, ApplyQuizTimeCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyQuizTimeCodeMutation, ApplyQuizTimeCodeMutationVariables>(ApplyQuizTimeCodeDocument, options);
      }
export type ApplyQuizTimeCodeMutationHookResult = ReturnType<typeof useApplyQuizTimeCodeMutation>;
export type ApplyQuizTimeCodeMutationResult = Apollo.MutationResult<ApplyQuizTimeCodeMutation>;
export type ApplyQuizTimeCodeMutationOptions = Apollo.BaseMutationOptions<ApplyQuizTimeCodeMutation, ApplyQuizTimeCodeMutationVariables>;
export const LeaveQuizTimeDocument = gql`
    mutation LeaveQuizTime($sessionId: UUID!) {
  leaveQuiz(sessionId: $sessionId) {
    session {
      id
    }
  }
}
    `;
export type LeaveQuizTimeMutationFn = Apollo.MutationFunction<LeaveQuizTimeMutation, LeaveQuizTimeMutationVariables>;

/**
 * __useLeaveQuizTimeMutation__
 *
 * To run a mutation, you first call `useLeaveQuizTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveQuizTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveQuizTimeMutation, { data, loading, error }] = useLeaveQuizTimeMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useLeaveQuizTimeMutation(baseOptions?: Apollo.MutationHookOptions<LeaveQuizTimeMutation, LeaveQuizTimeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LeaveQuizTimeMutation, LeaveQuizTimeMutationVariables>(LeaveQuizTimeDocument, options);
      }
export type LeaveQuizTimeMutationHookResult = ReturnType<typeof useLeaveQuizTimeMutation>;
export type LeaveQuizTimeMutationResult = Apollo.MutationResult<LeaveQuizTimeMutation>;
export type LeaveQuizTimeMutationOptions = Apollo.BaseMutationOptions<LeaveQuizTimeMutation, LeaveQuizTimeMutationVariables>;
export const StartQuizDocument = gql`
    mutation startQuiz($quizSessionId: UUID!) {
  quizStartByUser(quizSessionId: $quizSessionId) {
    quizSession {
      id
      status
    }
  }
}
    `;
export type StartQuizMutationFn = Apollo.MutationFunction<StartQuizMutation, StartQuizMutationVariables>;

/**
 * __useStartQuizMutation__
 *
 * To run a mutation, you first call `useStartQuizMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartQuizMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startQuizMutation, { data, loading, error }] = useStartQuizMutation({
 *   variables: {
 *      quizSessionId: // value for 'quizSessionId'
 *   },
 * });
 */
export function useStartQuizMutation(baseOptions?: Apollo.MutationHookOptions<StartQuizMutation, StartQuizMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartQuizMutation, StartQuizMutationVariables>(StartQuizDocument, options);
      }
export type StartQuizMutationHookResult = ReturnType<typeof useStartQuizMutation>;
export type StartQuizMutationResult = Apollo.MutationResult<StartQuizMutation>;
export type StartQuizMutationOptions = Apollo.BaseMutationOptions<StartQuizMutation, StartQuizMutationVariables>;
export const PauseQuizDocument = gql`
    mutation pauseQuiz($pauseMode: Boolean!, $quizSessionId: UUID!) {
  switchPauseMode(pauseMode: $pauseMode, quizSessionId: $quizSessionId) {
    userQuizSessionSettings {
      id
      userStatus
    }
  }
}
    `;
export type PauseQuizMutationFn = Apollo.MutationFunction<PauseQuizMutation, PauseQuizMutationVariables>;

/**
 * __usePauseQuizMutation__
 *
 * To run a mutation, you first call `usePauseQuizMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseQuizMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseQuizMutation, { data, loading, error }] = usePauseQuizMutation({
 *   variables: {
 *      pauseMode: // value for 'pauseMode'
 *      quizSessionId: // value for 'quizSessionId'
 *   },
 * });
 */
export function usePauseQuizMutation(baseOptions?: Apollo.MutationHookOptions<PauseQuizMutation, PauseQuizMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PauseQuizMutation, PauseQuizMutationVariables>(PauseQuizDocument, options);
      }
export type PauseQuizMutationHookResult = ReturnType<typeof usePauseQuizMutation>;
export type PauseQuizMutationResult = Apollo.MutationResult<PauseQuizMutation>;
export type PauseQuizMutationOptions = Apollo.BaseMutationOptions<PauseQuizMutation, PauseQuizMutationVariables>;
export const CreateQuiztimeDuelDocument = gql`
    mutation createQuiztimeDuel($quizSessionId: UUID!) {
  createQuizDuel(quizSessionId: $quizSessionId) {
    duel {
      id
      status
      duelRound {
        id
        roundNumber
        userQuestions {
          ...user_question_fragment
        }
      }
    }
  }
}
    ${User_Question_FragmentFragmentDoc}`;
export type CreateQuiztimeDuelMutationFn = Apollo.MutationFunction<CreateQuiztimeDuelMutation, CreateQuiztimeDuelMutationVariables>;

/**
 * __useCreateQuiztimeDuelMutation__
 *
 * To run a mutation, you first call `useCreateQuiztimeDuelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuiztimeDuelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuiztimeDuelMutation, { data, loading, error }] = useCreateQuiztimeDuelMutation({
 *   variables: {
 *      quizSessionId: // value for 'quizSessionId'
 *   },
 * });
 */
export function useCreateQuiztimeDuelMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuiztimeDuelMutation, CreateQuiztimeDuelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuiztimeDuelMutation, CreateQuiztimeDuelMutationVariables>(CreateQuiztimeDuelDocument, options);
      }
export type CreateQuiztimeDuelMutationHookResult = ReturnType<typeof useCreateQuiztimeDuelMutation>;
export type CreateQuiztimeDuelMutationResult = Apollo.MutationResult<CreateQuiztimeDuelMutation>;
export type CreateQuiztimeDuelMutationOptions = Apollo.BaseMutationOptions<CreateQuiztimeDuelMutation, CreateQuiztimeDuelMutationVariables>;
export const CreateQuiztimeDuelByOpponentDocument = gql`
    mutation createQuiztimeDuelByOpponent($opponentId: UUID!, $quizSessionId: UUID!) {
  createQuizDuelByOpponent(opponentId: $opponentId, quizSessionId: $quizSessionId) {
    duel {
      id
      status
      duelRound {
        id
        roundNumber
        userQuestions {
          ...user_question_fragment
        }
      }
    }
  }
}
    ${User_Question_FragmentFragmentDoc}`;
export type CreateQuiztimeDuelByOpponentMutationFn = Apollo.MutationFunction<CreateQuiztimeDuelByOpponentMutation, CreateQuiztimeDuelByOpponentMutationVariables>;

/**
 * __useCreateQuiztimeDuelByOpponentMutation__
 *
 * To run a mutation, you first call `useCreateQuiztimeDuelByOpponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuiztimeDuelByOpponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuiztimeDuelByOpponentMutation, { data, loading, error }] = useCreateQuiztimeDuelByOpponentMutation({
 *   variables: {
 *      opponentId: // value for 'opponentId'
 *      quizSessionId: // value for 'quizSessionId'
 *   },
 * });
 */
export function useCreateQuiztimeDuelByOpponentMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuiztimeDuelByOpponentMutation, CreateQuiztimeDuelByOpponentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuiztimeDuelByOpponentMutation, CreateQuiztimeDuelByOpponentMutationVariables>(CreateQuiztimeDuelByOpponentDocument, options);
      }
export type CreateQuiztimeDuelByOpponentMutationHookResult = ReturnType<typeof useCreateQuiztimeDuelByOpponentMutation>;
export type CreateQuiztimeDuelByOpponentMutationResult = Apollo.MutationResult<CreateQuiztimeDuelByOpponentMutation>;
export type CreateQuiztimeDuelByOpponentMutationOptions = Apollo.BaseMutationOptions<CreateQuiztimeDuelByOpponentMutation, CreateQuiztimeDuelByOpponentMutationVariables>;
export const AddWaitingUserNotificationDocument = gql`
    mutation addWaitingUserNotification($sessionId: UUID!) {
  addWaitingUser(sessionId: $sessionId) {
    waitingUser {
      id
      quizSession {
        id
        usersCount
      }
    }
  }
}
    `;
export type AddWaitingUserNotificationMutationFn = Apollo.MutationFunction<AddWaitingUserNotificationMutation, AddWaitingUserNotificationMutationVariables>;

/**
 * __useAddWaitingUserNotificationMutation__
 *
 * To run a mutation, you first call `useAddWaitingUserNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWaitingUserNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWaitingUserNotificationMutation, { data, loading, error }] = useAddWaitingUserNotificationMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useAddWaitingUserNotificationMutation(baseOptions?: Apollo.MutationHookOptions<AddWaitingUserNotificationMutation, AddWaitingUserNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddWaitingUserNotificationMutation, AddWaitingUserNotificationMutationVariables>(AddWaitingUserNotificationDocument, options);
      }
export type AddWaitingUserNotificationMutationHookResult = ReturnType<typeof useAddWaitingUserNotificationMutation>;
export type AddWaitingUserNotificationMutationResult = Apollo.MutationResult<AddWaitingUserNotificationMutation>;
export type AddWaitingUserNotificationMutationOptions = Apollo.BaseMutationOptions<AddWaitingUserNotificationMutation, AddWaitingUserNotificationMutationVariables>;
export const GetDeepLinkDocument = gql`
    mutation getDeepLink($hash: String!, $source: String) {
  getDeepLink(hash: $hash, source: $source) {
    deepLink {
      targetObjectId
      targetType
    }
  }
}
    `;
export type GetDeepLinkMutationFn = Apollo.MutationFunction<GetDeepLinkMutation, GetDeepLinkMutationVariables>;

/**
 * __useGetDeepLinkMutation__
 *
 * To run a mutation, you first call `useGetDeepLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetDeepLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getDeepLinkMutation, { data, loading, error }] = useGetDeepLinkMutation({
 *   variables: {
 *      hash: // value for 'hash'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useGetDeepLinkMutation(baseOptions?: Apollo.MutationHookOptions<GetDeepLinkMutation, GetDeepLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetDeepLinkMutation, GetDeepLinkMutationVariables>(GetDeepLinkDocument, options);
      }
export type GetDeepLinkMutationHookResult = ReturnType<typeof useGetDeepLinkMutation>;
export type GetDeepLinkMutationResult = Apollo.MutationResult<GetDeepLinkMutation>;
export type GetDeepLinkMutationOptions = Apollo.BaseMutationOptions<GetDeepLinkMutation, GetDeepLinkMutationVariables>;
export const CreateFeedbackQuestionDocument = gql`
    mutation createFeedbackQuestion($questionId: UUID!, $interestRate: Int!, $relevanceRate: Int!, $comment: String) {
  createFeedbackForQuestion(
    questionId: $questionId
    interestRate: $interestRate
    relevanceRate: $relevanceRate
    comment: $comment
  ) {
    feedbackForQuestion {
      id
      relevanceRate
      interestRate
      comment
      question {
        id
        value
      }
    }
  }
}
    `;
export type CreateFeedbackQuestionMutationFn = Apollo.MutationFunction<CreateFeedbackQuestionMutation, CreateFeedbackQuestionMutationVariables>;

/**
 * __useCreateFeedbackQuestionMutation__
 *
 * To run a mutation, you first call `useCreateFeedbackQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedbackQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedbackQuestionMutation, { data, loading, error }] = useCreateFeedbackQuestionMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      interestRate: // value for 'interestRate'
 *      relevanceRate: // value for 'relevanceRate'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useCreateFeedbackQuestionMutation(baseOptions?: Apollo.MutationHookOptions<CreateFeedbackQuestionMutation, CreateFeedbackQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFeedbackQuestionMutation, CreateFeedbackQuestionMutationVariables>(CreateFeedbackQuestionDocument, options);
      }
export type CreateFeedbackQuestionMutationHookResult = ReturnType<typeof useCreateFeedbackQuestionMutation>;
export type CreateFeedbackQuestionMutationResult = Apollo.MutationResult<CreateFeedbackQuestionMutation>;
export type CreateFeedbackQuestionMutationOptions = Apollo.BaseMutationOptions<CreateFeedbackQuestionMutation, CreateFeedbackQuestionMutationVariables>;
export const EditFeedbackQuestionDocument = gql`
    mutation editFeedbackQuestion($questionId: UUID!, $interestRate: Int, $relevanceRate: Int, $comment: String) {
  editFeedbackForQuestion(
    questionId: $questionId
    interestRate: $interestRate
    relevanceRate: $relevanceRate
    comment: $comment
  ) {
    feedbackForQuestion {
      id
      relevanceRate
      interestRate
      comment
      question {
        id
        value
      }
    }
  }
}
    `;
export type EditFeedbackQuestionMutationFn = Apollo.MutationFunction<EditFeedbackQuestionMutation, EditFeedbackQuestionMutationVariables>;

/**
 * __useEditFeedbackQuestionMutation__
 *
 * To run a mutation, you first call `useEditFeedbackQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditFeedbackQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editFeedbackQuestionMutation, { data, loading, error }] = useEditFeedbackQuestionMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      interestRate: // value for 'interestRate'
 *      relevanceRate: // value for 'relevanceRate'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useEditFeedbackQuestionMutation(baseOptions?: Apollo.MutationHookOptions<EditFeedbackQuestionMutation, EditFeedbackQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditFeedbackQuestionMutation, EditFeedbackQuestionMutationVariables>(EditFeedbackQuestionDocument, options);
      }
export type EditFeedbackQuestionMutationHookResult = ReturnType<typeof useEditFeedbackQuestionMutation>;
export type EditFeedbackQuestionMutationResult = Apollo.MutationResult<EditFeedbackQuestionMutation>;
export type EditFeedbackQuestionMutationOptions = Apollo.BaseMutationOptions<EditFeedbackQuestionMutation, EditFeedbackQuestionMutationVariables>;
export const SetExpoTokenDocument = gql`
    mutation setExpoToken($expoToken: String) {
  setUserExpoToken(expoToken: $expoToken) {
    result
  }
}
    `;
export type SetExpoTokenMutationFn = Apollo.MutationFunction<SetExpoTokenMutation, SetExpoTokenMutationVariables>;

/**
 * __useSetExpoTokenMutation__
 *
 * To run a mutation, you first call `useSetExpoTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetExpoTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setExpoTokenMutation, { data, loading, error }] = useSetExpoTokenMutation({
 *   variables: {
 *      expoToken: // value for 'expoToken'
 *   },
 * });
 */
export function useSetExpoTokenMutation(baseOptions?: Apollo.MutationHookOptions<SetExpoTokenMutation, SetExpoTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetExpoTokenMutation, SetExpoTokenMutationVariables>(SetExpoTokenDocument, options);
      }
export type SetExpoTokenMutationHookResult = ReturnType<typeof useSetExpoTokenMutation>;
export type SetExpoTokenMutationResult = Apollo.MutationResult<SetExpoTokenMutation>;
export type SetExpoTokenMutationOptions = Apollo.BaseMutationOptions<SetExpoTokenMutation, SetExpoTokenMutationVariables>;
export const UserSubscriptionDocument = gql`
    query userSubscription {
  me {
    id
    isPremium
    isPromotionPremium
    promotionFinishDate
    customer {
      id
      subscription {
        id
        subscriptionType
        status
        upgradeStatus
        currentPeriodEnd
        requestCancel
        lastError
        lastErrorCode
      }
      defaultCard {
        brand
        last4
      }
    }
    isInAppPremium
    inAppSubscription {
      id
      subscriptingType
      expirationDatetime
      status
      isActive
      store
      price
      currency
    }
  }
}
    `;

/**
 * __useUserSubscriptionQuery__
 *
 * To run a query within a React component, call `useUserSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSubscriptionQuery(baseOptions?: Apollo.QueryHookOptions<UserSubscriptionQuery, UserSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserSubscriptionQuery, UserSubscriptionQueryVariables>(UserSubscriptionDocument, options);
      }
export function useUserSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserSubscriptionQuery, UserSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserSubscriptionQuery, UserSubscriptionQueryVariables>(UserSubscriptionDocument, options);
        }
export type UserSubscriptionQueryHookResult = ReturnType<typeof useUserSubscriptionQuery>;
export type UserSubscriptionLazyQueryHookResult = ReturnType<typeof useUserSubscriptionLazyQuery>;
export type UserSubscriptionQueryResult = Apollo.QueryResult<UserSubscriptionQuery, UserSubscriptionQueryVariables>;
export const UserDiscountsDocument = gql`
    query userDiscounts {
  discounts {
    id
    couponName
    status
    subscriptionType
    discount
    startActiveDatetime
    finishActiveDatetime
  }
}
    `;

/**
 * __useUserDiscountsQuery__
 *
 * To run a query within a React component, call `useUserDiscountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDiscountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDiscountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserDiscountsQuery(baseOptions?: Apollo.QueryHookOptions<UserDiscountsQuery, UserDiscountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserDiscountsQuery, UserDiscountsQueryVariables>(UserDiscountsDocument, options);
      }
export function useUserDiscountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserDiscountsQuery, UserDiscountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserDiscountsQuery, UserDiscountsQueryVariables>(UserDiscountsDocument, options);
        }
export type UserDiscountsQueryHookResult = ReturnType<typeof useUserDiscountsQuery>;
export type UserDiscountsLazyQueryHookResult = ReturnType<typeof useUserDiscountsLazyQuery>;
export type UserDiscountsQueryResult = Apollo.QueryResult<UserDiscountsQuery, UserDiscountsQueryVariables>;
export const UserMeDocument = gql`
    query userMe {
  me {
    id
    ...user_fragment
    settings {
      ...user_settings_fragment
    }
  }
  monthlyScore {
    id
    points
  }
  contentAvailable
  featureFlags {
    id
    name
    enabled
  }
  joinedClassRooms
}
    ${User_FragmentFragmentDoc}
${User_Settings_FragmentFragmentDoc}`;

/**
 * __useUserMeQuery__
 *
 * To run a query within a React component, call `useUserMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserMeQuery(baseOptions?: Apollo.QueryHookOptions<UserMeQuery, UserMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserMeQuery, UserMeQueryVariables>(UserMeDocument, options);
      }
export function useUserMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserMeQuery, UserMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserMeQuery, UserMeQueryVariables>(UserMeDocument, options);
        }
export type UserMeQueryHookResult = ReturnType<typeof useUserMeQuery>;
export type UserMeLazyQueryHookResult = ReturnType<typeof useUserMeLazyQuery>;
export type UserMeQueryResult = Apollo.QueryResult<UserMeQuery, UserMeQueryVariables>;
export const DtWeeklyProgressDocument = gql`
    query dtWeeklyProgress {
  me {
    id
    dailytipper {
      id
      dailyStatus
      totalProgress
      nextAchievement {
        id
        learningDays
      }
      currentAchievement {
        id
        learningDays
      }
      yearAchievement {
        id
        yearNumber
      }
    }
  }
  dailyTipperWeeklyProgress {
    id
    weeklyGoal
    completedDays
    correctAnswersCount
    wrongAnswersCount
    weekNumber
  }
}
    `;

/**
 * __useDtWeeklyProgressQuery__
 *
 * To run a query within a React component, call `useDtWeeklyProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useDtWeeklyProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDtWeeklyProgressQuery({
 *   variables: {
 *   },
 * });
 */
export function useDtWeeklyProgressQuery(baseOptions?: Apollo.QueryHookOptions<DtWeeklyProgressQuery, DtWeeklyProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DtWeeklyProgressQuery, DtWeeklyProgressQueryVariables>(DtWeeklyProgressDocument, options);
      }
export function useDtWeeklyProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DtWeeklyProgressQuery, DtWeeklyProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DtWeeklyProgressQuery, DtWeeklyProgressQueryVariables>(DtWeeklyProgressDocument, options);
        }
export type DtWeeklyProgressQueryHookResult = ReturnType<typeof useDtWeeklyProgressQuery>;
export type DtWeeklyProgressLazyQueryHookResult = ReturnType<typeof useDtWeeklyProgressLazyQuery>;
export type DtWeeklyProgressQueryResult = Apollo.QueryResult<DtWeeklyProgressQuery, DtWeeklyProgressQueryVariables>;
export const GetActiveDuelsDocument = gql`
    query getActiveDuels {
  activeDuels {
    id
    opponent {
      id
      avatar
      username
    }
    round {
      id
      roundNumber
      created
      duel {
        id
        status
      }
      userQuestions {
        id
        questionNumber
        user {
          id
        }
        question {
          id
          value
        }
        userAnswer {
          id
          value
        }
      }
    }
    youTurn
    isInitiator
    session {
      id
      classRoom {
        id
        enrollmentType
      }
    }
  }
  startedDuelsToday
}
    `;

/**
 * __useGetActiveDuelsQuery__
 *
 * To run a query within a React component, call `useGetActiveDuelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveDuelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveDuelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveDuelsQuery(baseOptions?: Apollo.QueryHookOptions<GetActiveDuelsQuery, GetActiveDuelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveDuelsQuery, GetActiveDuelsQueryVariables>(GetActiveDuelsDocument, options);
      }
export function useGetActiveDuelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveDuelsQuery, GetActiveDuelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveDuelsQuery, GetActiveDuelsQueryVariables>(GetActiveDuelsDocument, options);
        }
export type GetActiveDuelsQueryHookResult = ReturnType<typeof useGetActiveDuelsQuery>;
export type GetActiveDuelsLazyQueryHookResult = ReturnType<typeof useGetActiveDuelsLazyQuery>;
export type GetActiveDuelsQueryResult = Apollo.QueryResult<GetActiveDuelsQuery, GetActiveDuelsQueryVariables>;
export const GetOngoingDuelsDocument = gql`
    query getOngoingDuels {
  activeDuels {
    id
    opponent {
      id
      avatar
      username
    }
    round {
      id
      roundNumber
      created
      duel {
        id
        status
      }
      userQuestions {
        id
        questionNumber
        user {
          id
        }
        userAnswer {
          id
          value
        }
      }
    }
    youTurn
    isInitiator
    session {
      id
      classRoom {
        id
        enrollmentType
      }
    }
  }
  monthlyScore {
    id
    duels
    wins
    loss
    ties
    points
  }
  startedDuelsToday
}
    `;

/**
 * __useGetOngoingDuelsQuery__
 *
 * To run a query within a React component, call `useGetOngoingDuelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOngoingDuelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOngoingDuelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOngoingDuelsQuery(baseOptions?: Apollo.QueryHookOptions<GetOngoingDuelsQuery, GetOngoingDuelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOngoingDuelsQuery, GetOngoingDuelsQueryVariables>(GetOngoingDuelsDocument, options);
      }
export function useGetOngoingDuelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOngoingDuelsQuery, GetOngoingDuelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOngoingDuelsQuery, GetOngoingDuelsQueryVariables>(GetOngoingDuelsDocument, options);
        }
export type GetOngoingDuelsQueryHookResult = ReturnType<typeof useGetOngoingDuelsQuery>;
export type GetOngoingDuelsLazyQueryHookResult = ReturnType<typeof useGetOngoingDuelsLazyQuery>;
export type GetOngoingDuelsQueryResult = Apollo.QueryResult<GetOngoingDuelsQuery, GetOngoingDuelsQueryVariables>;
export const GetCompletedDuelsDocument = gql`
    query getCompletedDuels {
  completedDuels {
    id
    userDuelsData {
      ...user_score_fragment
      duel {
        id
        updated
        status
      }
    }
    opponentDuelsData {
      ...user_score_fragment
    }
    session {
      id
      classRoom {
        id
        enrollmentType
      }
    }
  }
}
    ${User_Score_FragmentFragmentDoc}`;

/**
 * __useGetCompletedDuelsQuery__
 *
 * To run a query within a React component, call `useGetCompletedDuelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompletedDuelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompletedDuelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompletedDuelsQuery(baseOptions?: Apollo.QueryHookOptions<GetCompletedDuelsQuery, GetCompletedDuelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompletedDuelsQuery, GetCompletedDuelsQueryVariables>(GetCompletedDuelsDocument, options);
      }
export function useGetCompletedDuelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompletedDuelsQuery, GetCompletedDuelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompletedDuelsQuery, GetCompletedDuelsQueryVariables>(GetCompletedDuelsDocument, options);
        }
export type GetCompletedDuelsQueryHookResult = ReturnType<typeof useGetCompletedDuelsQuery>;
export type GetCompletedDuelsLazyQueryHookResult = ReturnType<typeof useGetCompletedDuelsLazyQuery>;
export type GetCompletedDuelsQueryResult = Apollo.QueryResult<GetCompletedDuelsQuery, GetCompletedDuelsQueryVariables>;
export const GetDuelsRankingDocument = gql`
    query getDuelsRanking {
  ranking {
    id
    user {
      id
      avatar
      username
    }
    points
    rank
  }
  topUsers {
    id
    user {
      id
      avatar
      username
    }
    points
    rank
  }
}
    `;

/**
 * __useGetDuelsRankingQuery__
 *
 * To run a query within a React component, call `useGetDuelsRankingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDuelsRankingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDuelsRankingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDuelsRankingQuery(baseOptions?: Apollo.QueryHookOptions<GetDuelsRankingQuery, GetDuelsRankingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDuelsRankingQuery, GetDuelsRankingQueryVariables>(GetDuelsRankingDocument, options);
      }
export function useGetDuelsRankingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDuelsRankingQuery, GetDuelsRankingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDuelsRankingQuery, GetDuelsRankingQueryVariables>(GetDuelsRankingDocument, options);
        }
export type GetDuelsRankingQueryHookResult = ReturnType<typeof useGetDuelsRankingQuery>;
export type GetDuelsRankingLazyQueryHookResult = ReturnType<typeof useGetDuelsRankingLazyQuery>;
export type GetDuelsRankingQueryResult = Apollo.QueryResult<GetDuelsRankingQuery, GetDuelsRankingQueryVariables>;
export const GetDuelsDocument = gql`
    query getDuels {
  activeDuels {
    id
    opponent {
      id
      avatar
      username
    }
    round {
      id
      roundNumber
      created
      duel {
        id
        status
      }
      userQuestions {
        id
        questionNumber
        user {
          id
        }
        userAnswer {
          id
          value
        }
      }
    }
    youTurn
    session {
      id
      classRoom {
        id
        enrollmentType
      }
    }
  }
  startedDuelsToday
  completedDuels {
    id
    userDuelsData {
      ...user_score_fragment
      duel {
        id
        updated
        status
      }
    }
    opponentDuelsData {
      ...user_score_fragment
    }
    session {
      id
      classRoom {
        id
        enrollmentType
      }
    }
  }
  ranking {
    id
    user {
      id
      avatar
      username
    }
    points
    rank
  }
  topUsers {
    id
    user {
      id
      avatar
      username
    }
    points
    rank
  }
  monthlyScore {
    id
    duels
    wins
    loss
    ties
    points
  }
}
    ${User_Score_FragmentFragmentDoc}`;

/**
 * __useGetDuelsQuery__
 *
 * To run a query within a React component, call `useGetDuelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDuelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDuelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDuelsQuery(baseOptions?: Apollo.QueryHookOptions<GetDuelsQuery, GetDuelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDuelsQuery, GetDuelsQueryVariables>(GetDuelsDocument, options);
      }
export function useGetDuelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDuelsQuery, GetDuelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDuelsQuery, GetDuelsQueryVariables>(GetDuelsDocument, options);
        }
export type GetDuelsQueryHookResult = ReturnType<typeof useGetDuelsQuery>;
export type GetDuelsLazyQueryHookResult = ReturnType<typeof useGetDuelsLazyQuery>;
export type GetDuelsQueryResult = Apollo.QueryResult<GetDuelsQuery, GetDuelsQueryVariables>;
export const UserDuelDocument = gql`
    query userDuel($duelId: UUID!) {
  duelData(duelId: $duelId) {
    id
    status
    opponentScore {
      ...user_score_fragment
    }
    userScore {
      ...user_score_fragment
    }
    rounds {
      id
      created
      roundNumber
      userQuestions {
        ...user_question_fragment
      }
    }
    session {
      id
      classRoom {
        id
        title
      }
    }
    isOnboardingDuel
  }
}
    ${User_Score_FragmentFragmentDoc}
${User_Question_FragmentFragmentDoc}`;

/**
 * __useUserDuelQuery__
 *
 * To run a query within a React component, call `useUserDuelQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDuelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDuelQuery({
 *   variables: {
 *      duelId: // value for 'duelId'
 *   },
 * });
 */
export function useUserDuelQuery(baseOptions: Apollo.QueryHookOptions<UserDuelQuery, UserDuelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserDuelQuery, UserDuelQueryVariables>(UserDuelDocument, options);
      }
export function useUserDuelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserDuelQuery, UserDuelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserDuelQuery, UserDuelQueryVariables>(UserDuelDocument, options);
        }
export type UserDuelQueryHookResult = ReturnType<typeof useUserDuelQuery>;
export type UserDuelLazyQueryHookResult = ReturnType<typeof useUserDuelLazyQuery>;
export type UserDuelQueryResult = Apollo.QueryResult<UserDuelQuery, UserDuelQueryVariables>;
export const SubscribeNotificationCreatedDocument = gql`
    subscription subscribeNotificationCreated {
  notificationCreated {
    id
    updated
    created
    data
    message
    isRead
  }
}
    `;

/**
 * __useSubscribeNotificationCreatedSubscription__
 *
 * To run a query within a React component, call `useSubscribeNotificationCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeNotificationCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeNotificationCreatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSubscribeNotificationCreatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<SubscribeNotificationCreatedSubscription, SubscribeNotificationCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeNotificationCreatedSubscription, SubscribeNotificationCreatedSubscriptionVariables>(SubscribeNotificationCreatedDocument, options);
      }
export type SubscribeNotificationCreatedSubscriptionHookResult = ReturnType<typeof useSubscribeNotificationCreatedSubscription>;
export type SubscribeNotificationCreatedSubscriptionResult = Apollo.SubscriptionResult<SubscribeNotificationCreatedSubscription>;
export const UserNotificationsDocument = gql`
    query userNotifications {
  notifications {
    id
    created
    updated
    data
    message
    isRead
  }
}
    `;

/**
 * __useUserNotificationsQuery__
 *
 * To run a query within a React component, call `useUserNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<UserNotificationsQuery, UserNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserNotificationsQuery, UserNotificationsQueryVariables>(UserNotificationsDocument, options);
      }
export function useUserNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserNotificationsQuery, UserNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserNotificationsQuery, UserNotificationsQueryVariables>(UserNotificationsDocument, options);
        }
export type UserNotificationsQueryHookResult = ReturnType<typeof useUserNotificationsQuery>;
export type UserNotificationsLazyQueryHookResult = ReturnType<typeof useUserNotificationsLazyQuery>;
export type UserNotificationsQueryResult = Apollo.QueryResult<UserNotificationsQuery, UserNotificationsQueryVariables>;
export const LastUserNotificationDocument = gql`
    query lastUserNotification {
  lastNotification {
    id
    updated
    created
    data
    message
    isRead
  }
}
    `;

/**
 * __useLastUserNotificationQuery__
 *
 * To run a query within a React component, call `useLastUserNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastUserNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastUserNotificationQuery({
 *   variables: {
 *   },
 * });
 */
export function useLastUserNotificationQuery(baseOptions?: Apollo.QueryHookOptions<LastUserNotificationQuery, LastUserNotificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LastUserNotificationQuery, LastUserNotificationQueryVariables>(LastUserNotificationDocument, options);
      }
export function useLastUserNotificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LastUserNotificationQuery, LastUserNotificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LastUserNotificationQuery, LastUserNotificationQueryVariables>(LastUserNotificationDocument, options);
        }
export type LastUserNotificationQueryHookResult = ReturnType<typeof useLastUserNotificationQuery>;
export type LastUserNotificationLazyQueryHookResult = ReturnType<typeof useLastUserNotificationLazyQuery>;
export type LastUserNotificationQueryResult = Apollo.QueryResult<LastUserNotificationQuery, LastUserNotificationQueryVariables>;
export const UserNetworkCodesDocument = gql`
    query userNetworkCodes {
  codes {
    id
    startDate
    finishDate
    sponsor {
      id
      title
      text
      website
      email
      logo
    }
    codeMode
  }
}
    `;

/**
 * __useUserNetworkCodesQuery__
 *
 * To run a query within a React component, call `useUserNetworkCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNetworkCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNetworkCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserNetworkCodesQuery(baseOptions?: Apollo.QueryHookOptions<UserNetworkCodesQuery, UserNetworkCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserNetworkCodesQuery, UserNetworkCodesQueryVariables>(UserNetworkCodesDocument, options);
      }
export function useUserNetworkCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserNetworkCodesQuery, UserNetworkCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserNetworkCodesQuery, UserNetworkCodesQueryVariables>(UserNetworkCodesDocument, options);
        }
export type UserNetworkCodesQueryHookResult = ReturnType<typeof useUserNetworkCodesQuery>;
export type UserNetworkCodesLazyQueryHookResult = ReturnType<typeof useUserNetworkCodesLazyQuery>;
export type UserNetworkCodesQueryResult = Apollo.QueryResult<UserNetworkCodesQuery, UserNetworkCodesQueryVariables>;
export const UsernameVerifyDocument = gql`
    query usernameVerify($username: String!) {
  usernameVerify(username: $username)
}
    `;

/**
 * __useUsernameVerifyQuery__
 *
 * To run a query within a React component, call `useUsernameVerifyQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsernameVerifyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsernameVerifyQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUsernameVerifyQuery(baseOptions: Apollo.QueryHookOptions<UsernameVerifyQuery, UsernameVerifyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsernameVerifyQuery, UsernameVerifyQueryVariables>(UsernameVerifyDocument, options);
      }
export function useUsernameVerifyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsernameVerifyQuery, UsernameVerifyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsernameVerifyQuery, UsernameVerifyQueryVariables>(UsernameVerifyDocument, options);
        }
export type UsernameVerifyQueryHookResult = ReturnType<typeof useUsernameVerifyQuery>;
export type UsernameVerifyLazyQueryHookResult = ReturnType<typeof useUsernameVerifyLazyQuery>;
export type UsernameVerifyQueryResult = Apollo.QueryResult<UsernameVerifyQuery, UsernameVerifyQueryVariables>;
export const EmailVerifyDocument = gql`
    query emailVerify($email: String!) {
  emailVerify(email: $email)
}
    `;

/**
 * __useEmailVerifyQuery__
 *
 * To run a query within a React component, call `useEmailVerifyQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailVerifyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailVerifyQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useEmailVerifyQuery(baseOptions: Apollo.QueryHookOptions<EmailVerifyQuery, EmailVerifyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailVerifyQuery, EmailVerifyQueryVariables>(EmailVerifyDocument, options);
      }
export function useEmailVerifyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailVerifyQuery, EmailVerifyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailVerifyQuery, EmailVerifyQueryVariables>(EmailVerifyDocument, options);
        }
export type EmailVerifyQueryHookResult = ReturnType<typeof useEmailVerifyQuery>;
export type EmailVerifyLazyQueryHookResult = ReturnType<typeof useEmailVerifyLazyQuery>;
export type EmailVerifyQueryResult = Apollo.QueryResult<EmailVerifyQuery, EmailVerifyQueryVariables>;
export const EfnNumberVerifyDocument = gql`
    query efnNumberVerify($efnNumber: String!) {
  efnNumberVerify(efnNumber: $efnNumber)
}
    `;

/**
 * __useEfnNumberVerifyQuery__
 *
 * To run a query within a React component, call `useEfnNumberVerifyQuery` and pass it any options that fit your needs.
 * When your component renders, `useEfnNumberVerifyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEfnNumberVerifyQuery({
 *   variables: {
 *      efnNumber: // value for 'efnNumber'
 *   },
 * });
 */
export function useEfnNumberVerifyQuery(baseOptions: Apollo.QueryHookOptions<EfnNumberVerifyQuery, EfnNumberVerifyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EfnNumberVerifyQuery, EfnNumberVerifyQueryVariables>(EfnNumberVerifyDocument, options);
      }
export function useEfnNumberVerifyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EfnNumberVerifyQuery, EfnNumberVerifyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EfnNumberVerifyQuery, EfnNumberVerifyQueryVariables>(EfnNumberVerifyDocument, options);
        }
export type EfnNumberVerifyQueryHookResult = ReturnType<typeof useEfnNumberVerifyQuery>;
export type EfnNumberVerifyLazyQueryHookResult = ReturnType<typeof useEfnNumberVerifyLazyQuery>;
export type EfnNumberVerifyQueryResult = Apollo.QueryResult<EfnNumberVerifyQuery, EfnNumberVerifyQueryVariables>;
export const AllProfessionsDocument = gql`
    query allProfessions {
  professions {
    id
    title
    type {
      id
      type
    }
  }
}
    `;

/**
 * __useAllProfessionsQuery__
 *
 * To run a query within a React component, call `useAllProfessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProfessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProfessionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllProfessionsQuery(baseOptions?: Apollo.QueryHookOptions<AllProfessionsQuery, AllProfessionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllProfessionsQuery, AllProfessionsQueryVariables>(AllProfessionsDocument, options);
      }
export function useAllProfessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllProfessionsQuery, AllProfessionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllProfessionsQuery, AllProfessionsQueryVariables>(AllProfessionsDocument, options);
        }
export type AllProfessionsQueryHookResult = ReturnType<typeof useAllProfessionsQuery>;
export type AllProfessionsLazyQueryHookResult = ReturnType<typeof useAllProfessionsLazyQuery>;
export type AllProfessionsQueryResult = Apollo.QueryResult<AllProfessionsQuery, AllProfessionsQueryVariables>;
export const AnotherDataDocument = gql`
    query anotherData($professionType: Int!) {
  specialitiesByType(professionType: $professionType) {
    id
    title
  }
}
    `;

/**
 * __useAnotherDataQuery__
 *
 * To run a query within a React component, call `useAnotherDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnotherDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnotherDataQuery({
 *   variables: {
 *      professionType: // value for 'professionType'
 *   },
 * });
 */
export function useAnotherDataQuery(baseOptions: Apollo.QueryHookOptions<AnotherDataQuery, AnotherDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnotherDataQuery, AnotherDataQueryVariables>(AnotherDataDocument, options);
      }
export function useAnotherDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnotherDataQuery, AnotherDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnotherDataQuery, AnotherDataQueryVariables>(AnotherDataDocument, options);
        }
export type AnotherDataQueryHookResult = ReturnType<typeof useAnotherDataQuery>;
export type AnotherDataLazyQueryHookResult = ReturnType<typeof useAnotherDataLazyQuery>;
export type AnotherDataQueryResult = Apollo.QueryResult<AnotherDataQuery, AnotherDataQueryVariables>;
export const DoctorDataDocument = gql`
    query doctorData($professionType: Int!) {
  specialitiesByType(professionType: $professionType) {
    id
    title
  }
  workplaces(professionType: $professionType) {
    id
    title
  }
}
    `;

/**
 * __useDoctorDataQuery__
 *
 * To run a query within a React component, call `useDoctorDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoctorDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorDataQuery({
 *   variables: {
 *      professionType: // value for 'professionType'
 *   },
 * });
 */
export function useDoctorDataQuery(baseOptions: Apollo.QueryHookOptions<DoctorDataQuery, DoctorDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DoctorDataQuery, DoctorDataQueryVariables>(DoctorDataDocument, options);
      }
export function useDoctorDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DoctorDataQuery, DoctorDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DoctorDataQuery, DoctorDataQueryVariables>(DoctorDataDocument, options);
        }
export type DoctorDataQueryHookResult = ReturnType<typeof useDoctorDataQuery>;
export type DoctorDataLazyQueryHookResult = ReturnType<typeof useDoctorDataLazyQuery>;
export type DoctorDataQueryResult = Apollo.QueryResult<DoctorDataQuery, DoctorDataQueryVariables>;
export const MedicalAssistantDataDocument = gql`
    query medicalAssistantData($professionType: Int!) {
  professions {
    id
    title
  }
  specialitiesByType(professionType: $professionType) {
    id
    title
  }
  workplaces(professionType: $professionType) {
    id
    title
  }
}
    `;

/**
 * __useMedicalAssistantDataQuery__
 *
 * To run a query within a React component, call `useMedicalAssistantDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicalAssistantDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicalAssistantDataQuery({
 *   variables: {
 *      professionType: // value for 'professionType'
 *   },
 * });
 */
export function useMedicalAssistantDataQuery(baseOptions: Apollo.QueryHookOptions<MedicalAssistantDataQuery, MedicalAssistantDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MedicalAssistantDataQuery, MedicalAssistantDataQueryVariables>(MedicalAssistantDataDocument, options);
      }
export function useMedicalAssistantDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MedicalAssistantDataQuery, MedicalAssistantDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MedicalAssistantDataQuery, MedicalAssistantDataQueryVariables>(MedicalAssistantDataDocument, options);
        }
export type MedicalAssistantDataQueryHookResult = ReturnType<typeof useMedicalAssistantDataQuery>;
export type MedicalAssistantDataLazyQueryHookResult = ReturnType<typeof useMedicalAssistantDataLazyQuery>;
export type MedicalAssistantDataQueryResult = Apollo.QueryResult<MedicalAssistantDataQuery, MedicalAssistantDataQueryVariables>;
export const MedicalAssistantTraineeDataDocument = gql`
    query medicalAssistantTraineeData($professionType: Int!) {
  workplaces(professionType: $professionType) {
    id
    title
  }
  studyYears {
    id
    title
  }
}
    `;

/**
 * __useMedicalAssistantTraineeDataQuery__
 *
 * To run a query within a React component, call `useMedicalAssistantTraineeDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicalAssistantTraineeDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicalAssistantTraineeDataQuery({
 *   variables: {
 *      professionType: // value for 'professionType'
 *   },
 * });
 */
export function useMedicalAssistantTraineeDataQuery(baseOptions: Apollo.QueryHookOptions<MedicalAssistantTraineeDataQuery, MedicalAssistantTraineeDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MedicalAssistantTraineeDataQuery, MedicalAssistantTraineeDataQueryVariables>(MedicalAssistantTraineeDataDocument, options);
      }
export function useMedicalAssistantTraineeDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MedicalAssistantTraineeDataQuery, MedicalAssistantTraineeDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MedicalAssistantTraineeDataQuery, MedicalAssistantTraineeDataQueryVariables>(MedicalAssistantTraineeDataDocument, options);
        }
export type MedicalAssistantTraineeDataQueryHookResult = ReturnType<typeof useMedicalAssistantTraineeDataQuery>;
export type MedicalAssistantTraineeDataLazyQueryHookResult = ReturnType<typeof useMedicalAssistantTraineeDataLazyQuery>;
export type MedicalAssistantTraineeDataQueryResult = Apollo.QueryResult<MedicalAssistantTraineeDataQuery, MedicalAssistantTraineeDataQueryVariables>;
export const StudentDataDocument = gql`
    query studentData {
  semesters {
    id
    title
  }
  universities {
    id
    title
  }
  studySemesters {
    id
    title
  }
}
    `;

/**
 * __useStudentDataQuery__
 *
 * To run a query within a React component, call `useStudentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentDataQuery(baseOptions?: Apollo.QueryHookOptions<StudentDataQuery, StudentDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentDataQuery, StudentDataQueryVariables>(StudentDataDocument, options);
      }
export function useStudentDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentDataQuery, StudentDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentDataQuery, StudentDataQueryVariables>(StudentDataDocument, options);
        }
export type StudentDataQueryHookResult = ReturnType<typeof useStudentDataQuery>;
export type StudentDataLazyQueryHookResult = ReturnType<typeof useStudentDataLazyQuery>;
export type StudentDataQueryResult = Apollo.QueryResult<StudentDataQuery, StudentDataQueryVariables>;
export const TraineeDataDocument = gql`
    query traineeData($professionType: Int!) {
  workplaces(professionType: $professionType) {
    id
    title
  }
  educationLevels {
    id
    title
  }
}
    `;

/**
 * __useTraineeDataQuery__
 *
 * To run a query within a React component, call `useTraineeDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useTraineeDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTraineeDataQuery({
 *   variables: {
 *      professionType: // value for 'professionType'
 *   },
 * });
 */
export function useTraineeDataQuery(baseOptions: Apollo.QueryHookOptions<TraineeDataQuery, TraineeDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TraineeDataQuery, TraineeDataQueryVariables>(TraineeDataDocument, options);
      }
export function useTraineeDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TraineeDataQuery, TraineeDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TraineeDataQuery, TraineeDataQueryVariables>(TraineeDataDocument, options);
        }
export type TraineeDataQueryHookResult = ReturnType<typeof useTraineeDataQuery>;
export type TraineeDataLazyQueryHookResult = ReturnType<typeof useTraineeDataLazyQuery>;
export type TraineeDataQueryResult = Apollo.QueryResult<TraineeDataQuery, TraineeDataQueryVariables>;
export const TodayDailyTipperDocument = gql`
    query todayDailyTipper {
  dailyTipperData {
    dailyTipperQuestions {
      ...daily_tipper_question_fragment
    }
  }
}
    ${Daily_Tipper_Question_FragmentFragmentDoc}`;

/**
 * __useTodayDailyTipperQuery__
 *
 * To run a query within a React component, call `useTodayDailyTipperQuery` and pass it any options that fit your needs.
 * When your component renders, `useTodayDailyTipperQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTodayDailyTipperQuery({
 *   variables: {
 *   },
 * });
 */
export function useTodayDailyTipperQuery(baseOptions?: Apollo.QueryHookOptions<TodayDailyTipperQuery, TodayDailyTipperQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TodayDailyTipperQuery, TodayDailyTipperQueryVariables>(TodayDailyTipperDocument, options);
      }
export function useTodayDailyTipperLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TodayDailyTipperQuery, TodayDailyTipperQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TodayDailyTipperQuery, TodayDailyTipperQueryVariables>(TodayDailyTipperDocument, options);
        }
export type TodayDailyTipperQueryHookResult = ReturnType<typeof useTodayDailyTipperQuery>;
export type TodayDailyTipperLazyQueryHookResult = ReturnType<typeof useTodayDailyTipperLazyQuery>;
export type TodayDailyTipperQueryResult = Apollo.QueryResult<TodayDailyTipperQuery, TodayDailyTipperQueryVariables>;
export const DailyTipperDataDocument = gql`
    query dailyTipperData {
  dailyTipperData {
    dailyResultStatus
    currentAchievement
    daysLost
    dailyTipperQuestions {
      ...daily_tipper_question_fragment
    }
  }
}
    ${Daily_Tipper_Question_FragmentFragmentDoc}`;

/**
 * __useDailyTipperDataQuery__
 *
 * To run a query within a React component, call `useDailyTipperDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyTipperDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyTipperDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useDailyTipperDataQuery(baseOptions?: Apollo.QueryHookOptions<DailyTipperDataQuery, DailyTipperDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DailyTipperDataQuery, DailyTipperDataQueryVariables>(DailyTipperDataDocument, options);
      }
export function useDailyTipperDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DailyTipperDataQuery, DailyTipperDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DailyTipperDataQuery, DailyTipperDataQueryVariables>(DailyTipperDataDocument, options);
        }
export type DailyTipperDataQueryHookResult = ReturnType<typeof useDailyTipperDataQuery>;
export type DailyTipperDataLazyQueryHookResult = ReturnType<typeof useDailyTipperDataLazyQuery>;
export type DailyTipperDataQueryResult = Apollo.QueryResult<DailyTipperDataQuery, DailyTipperDataQueryVariables>;
export const UpdateDtDayslostDocument = gql`
    query updateDtDayslost {
  dailyTipperData {
    daysLost
  }
}
    `;

/**
 * __useUpdateDtDayslostQuery__
 *
 * To run a query within a React component, call `useUpdateDtDayslostQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateDtDayslostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateDtDayslostQuery({
 *   variables: {
 *   },
 * });
 */
export function useUpdateDtDayslostQuery(baseOptions?: Apollo.QueryHookOptions<UpdateDtDayslostQuery, UpdateDtDayslostQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpdateDtDayslostQuery, UpdateDtDayslostQueryVariables>(UpdateDtDayslostDocument, options);
      }
export function useUpdateDtDayslostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateDtDayslostQuery, UpdateDtDayslostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpdateDtDayslostQuery, UpdateDtDayslostQueryVariables>(UpdateDtDayslostDocument, options);
        }
export type UpdateDtDayslostQueryHookResult = ReturnType<typeof useUpdateDtDayslostQuery>;
export type UpdateDtDayslostLazyQueryHookResult = ReturnType<typeof useUpdateDtDayslostLazyQuery>;
export type UpdateDtDayslostQueryResult = Apollo.QueryResult<UpdateDtDayslostQuery, UpdateDtDayslostQueryVariables>;
export const UserAchievementsRangDocument = gql`
    query userAchievementsRang {
  achievementsRang(showedInRankUsers: 3) {
    achievement {
      id
      learningDays
    }
    usersWithAchievementCount
    usersWithAchievement {
      id
      user {
        avatar
        username
      }
    }
  }
}
    `;

/**
 * __useUserAchievementsRangQuery__
 *
 * To run a query within a React component, call `useUserAchievementsRangQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAchievementsRangQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAchievementsRangQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserAchievementsRangQuery(baseOptions?: Apollo.QueryHookOptions<UserAchievementsRangQuery, UserAchievementsRangQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserAchievementsRangQuery, UserAchievementsRangQueryVariables>(UserAchievementsRangDocument, options);
      }
export function useUserAchievementsRangLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserAchievementsRangQuery, UserAchievementsRangQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserAchievementsRangQuery, UserAchievementsRangQueryVariables>(UserAchievementsRangDocument, options);
        }
export type UserAchievementsRangQueryHookResult = ReturnType<typeof useUserAchievementsRangQuery>;
export type UserAchievementsRangLazyQueryHookResult = ReturnType<typeof useUserAchievementsRangLazyQuery>;
export type UserAchievementsRangQueryResult = Apollo.QueryResult<UserAchievementsRangQuery, UserAchievementsRangQueryVariables>;
export const ClassRoomsAvailableDocument = gql`
    query classRoomsAvailable($isNetworkType: Boolean) {
  availableClassRooms(isNetworkType: $isNetworkType) {
    ...classroom_ls_fragment
  }
}
    ${Classroom_Ls_FragmentFragmentDoc}`;

/**
 * __useClassRoomsAvailableQuery__
 *
 * To run a query within a React component, call `useClassRoomsAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassRoomsAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassRoomsAvailableQuery({
 *   variables: {
 *      isNetworkType: // value for 'isNetworkType'
 *   },
 * });
 */
export function useClassRoomsAvailableQuery(baseOptions?: Apollo.QueryHookOptions<ClassRoomsAvailableQuery, ClassRoomsAvailableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClassRoomsAvailableQuery, ClassRoomsAvailableQueryVariables>(ClassRoomsAvailableDocument, options);
      }
export function useClassRoomsAvailableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClassRoomsAvailableQuery, ClassRoomsAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClassRoomsAvailableQuery, ClassRoomsAvailableQueryVariables>(ClassRoomsAvailableDocument, options);
        }
export type ClassRoomsAvailableQueryHookResult = ReturnType<typeof useClassRoomsAvailableQuery>;
export type ClassRoomsAvailableLazyQueryHookResult = ReturnType<typeof useClassRoomsAvailableLazyQuery>;
export type ClassRoomsAvailableQueryResult = Apollo.QueryResult<ClassRoomsAvailableQuery, ClassRoomsAvailableQueryVariables>;
export const ClassRoomsOngoingDocument = gql`
    query classRoomsOngoing {
  ongoingClassRooms {
    isSessionActive
    correctAnswersGoal
    questionsAmount
    correctAnswersAmount
    networkStartDate
    learningSession {
      ...classroom_ls_fragment
      deepLink
    }
    specialityGroupIcon
  }
}
    ${Classroom_Ls_FragmentFragmentDoc}`;

/**
 * __useClassRoomsOngoingQuery__
 *
 * To run a query within a React component, call `useClassRoomsOngoingQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassRoomsOngoingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassRoomsOngoingQuery({
 *   variables: {
 *   },
 * });
 */
export function useClassRoomsOngoingQuery(baseOptions?: Apollo.QueryHookOptions<ClassRoomsOngoingQuery, ClassRoomsOngoingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClassRoomsOngoingQuery, ClassRoomsOngoingQueryVariables>(ClassRoomsOngoingDocument, options);
      }
export function useClassRoomsOngoingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClassRoomsOngoingQuery, ClassRoomsOngoingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClassRoomsOngoingQuery, ClassRoomsOngoingQueryVariables>(ClassRoomsOngoingDocument, options);
        }
export type ClassRoomsOngoingQueryHookResult = ReturnType<typeof useClassRoomsOngoingQuery>;
export type ClassRoomsOngoingLazyQueryHookResult = ReturnType<typeof useClassRoomsOngoingLazyQuery>;
export type ClassRoomsOngoingQueryResult = Apollo.QueryResult<ClassRoomsOngoingQuery, ClassRoomsOngoingQueryVariables>;
export const ClassRoomsAvailableBySpecialityGroupDocument = gql`
    query classRoomsAvailableBySpecialityGroup($specialityGroupId: UUID) {
  availableSessionsBySpecialityGroup(specialityGroupId: $specialityGroupId) {
    ...classroom_ls_fragment
  }
}
    ${Classroom_Ls_FragmentFragmentDoc}`;

/**
 * __useClassRoomsAvailableBySpecialityGroupQuery__
 *
 * To run a query within a React component, call `useClassRoomsAvailableBySpecialityGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassRoomsAvailableBySpecialityGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassRoomsAvailableBySpecialityGroupQuery({
 *   variables: {
 *      specialityGroupId: // value for 'specialityGroupId'
 *   },
 * });
 */
export function useClassRoomsAvailableBySpecialityGroupQuery(baseOptions?: Apollo.QueryHookOptions<ClassRoomsAvailableBySpecialityGroupQuery, ClassRoomsAvailableBySpecialityGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClassRoomsAvailableBySpecialityGroupQuery, ClassRoomsAvailableBySpecialityGroupQueryVariables>(ClassRoomsAvailableBySpecialityGroupDocument, options);
      }
export function useClassRoomsAvailableBySpecialityGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClassRoomsAvailableBySpecialityGroupQuery, ClassRoomsAvailableBySpecialityGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClassRoomsAvailableBySpecialityGroupQuery, ClassRoomsAvailableBySpecialityGroupQueryVariables>(ClassRoomsAvailableBySpecialityGroupDocument, options);
        }
export type ClassRoomsAvailableBySpecialityGroupQueryHookResult = ReturnType<typeof useClassRoomsAvailableBySpecialityGroupQuery>;
export type ClassRoomsAvailableBySpecialityGroupLazyQueryHookResult = ReturnType<typeof useClassRoomsAvailableBySpecialityGroupLazyQuery>;
export type ClassRoomsAvailableBySpecialityGroupQueryResult = Apollo.QueryResult<ClassRoomsAvailableBySpecialityGroupQuery, ClassRoomsAvailableBySpecialityGroupQueryVariables>;
export const ClassRoomsFinishedDocument = gql`
    query classRoomsFinished {
  finishedClassRooms {
    correctAnswersGoal
    questionsAmount
    correctAnswersAmount
    networkStartDate
    outViaSubscriptionDate
    specialityGroupIcon
    userLearningSessionSettingsId
    learningSession {
      ...classroom_ls_fragment
    }
  }
}
    ${Classroom_Ls_FragmentFragmentDoc}`;

/**
 * __useClassRoomsFinishedQuery__
 *
 * To run a query within a React component, call `useClassRoomsFinishedQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassRoomsFinishedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassRoomsFinishedQuery({
 *   variables: {
 *   },
 * });
 */
export function useClassRoomsFinishedQuery(baseOptions?: Apollo.QueryHookOptions<ClassRoomsFinishedQuery, ClassRoomsFinishedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClassRoomsFinishedQuery, ClassRoomsFinishedQueryVariables>(ClassRoomsFinishedDocument, options);
      }
export function useClassRoomsFinishedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClassRoomsFinishedQuery, ClassRoomsFinishedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClassRoomsFinishedQuery, ClassRoomsFinishedQueryVariables>(ClassRoomsFinishedDocument, options);
        }
export type ClassRoomsFinishedQueryHookResult = ReturnType<typeof useClassRoomsFinishedQuery>;
export type ClassRoomsFinishedLazyQueryHookResult = ReturnType<typeof useClassRoomsFinishedLazyQuery>;
export type ClassRoomsFinishedQueryResult = Apollo.QueryResult<ClassRoomsFinishedQuery, ClassRoomsFinishedQueryVariables>;
export const FindClassRoomsDocument = gql`
    query findClassRooms($searchPointer: String) {
  findClassRooms(searchPointer: $searchPointer) {
    ...classroom_ls_fragment
    specialityGroupIcon
  }
}
    ${Classroom_Ls_FragmentFragmentDoc}`;

/**
 * __useFindClassRoomsQuery__
 *
 * To run a query within a React component, call `useFindClassRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindClassRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindClassRoomsQuery({
 *   variables: {
 *      searchPointer: // value for 'searchPointer'
 *   },
 * });
 */
export function useFindClassRoomsQuery(baseOptions?: Apollo.QueryHookOptions<FindClassRoomsQuery, FindClassRoomsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindClassRoomsQuery, FindClassRoomsQueryVariables>(FindClassRoomsDocument, options);
      }
export function useFindClassRoomsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindClassRoomsQuery, FindClassRoomsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindClassRoomsQuery, FindClassRoomsQueryVariables>(FindClassRoomsDocument, options);
        }
export type FindClassRoomsQueryHookResult = ReturnType<typeof useFindClassRoomsQuery>;
export type FindClassRoomsLazyQueryHookResult = ReturnType<typeof useFindClassRoomsLazyQuery>;
export type FindClassRoomsQueryResult = Apollo.QueryResult<FindClassRoomsQuery, FindClassRoomsQueryVariables>;
export const SearchNetworkClassRoomSessionsDocument = gql`
    query searchNetworkClassRoomSessions($searchPointer: String) {
  searchNetworkClassRoomSessions(searchPointer: $searchPointer) {
    ...classroom_ls_fragment
    specialityGroupIcon
    isForSingle
  }
}
    ${Classroom_Ls_FragmentFragmentDoc}`;

/**
 * __useSearchNetworkClassRoomSessionsQuery__
 *
 * To run a query within a React component, call `useSearchNetworkClassRoomSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchNetworkClassRoomSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchNetworkClassRoomSessionsQuery({
 *   variables: {
 *      searchPointer: // value for 'searchPointer'
 *   },
 * });
 */
export function useSearchNetworkClassRoomSessionsQuery(baseOptions?: Apollo.QueryHookOptions<SearchNetworkClassRoomSessionsQuery, SearchNetworkClassRoomSessionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchNetworkClassRoomSessionsQuery, SearchNetworkClassRoomSessionsQueryVariables>(SearchNetworkClassRoomSessionsDocument, options);
      }
export function useSearchNetworkClassRoomSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchNetworkClassRoomSessionsQuery, SearchNetworkClassRoomSessionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchNetworkClassRoomSessionsQuery, SearchNetworkClassRoomSessionsQueryVariables>(SearchNetworkClassRoomSessionsDocument, options);
        }
export type SearchNetworkClassRoomSessionsQueryHookResult = ReturnType<typeof useSearchNetworkClassRoomSessionsQuery>;
export type SearchNetworkClassRoomSessionsLazyQueryHookResult = ReturnType<typeof useSearchNetworkClassRoomSessionsLazyQuery>;
export type SearchNetworkClassRoomSessionsQueryResult = Apollo.QueryResult<SearchNetworkClassRoomSessionsQuery, SearchNetworkClassRoomSessionsQueryVariables>;
export const ClassRoomSessionDocument = gql`
    query classRoomSession($sessionId: UUID, $userSessionSettingsId: UUID) {
  classRoomLearningSession(sessionId: $sessionId) {
    id
    status
    startDate
    finishDate
    isForPremiumOnly
    duration
    specialityGroupIcon
    deepLink
    classRoom {
      id
      title
      description
      learningMoreInfoLink
      classRoomImage
      backgroundImage
      enrollmentType
      classRoomLecture {
        id
        lectureNumber
        isVisible
        lectureQuestionCount
        lectureGoal(
          learningSessionId: $sessionId
          userSessionSettingsId: $userSessionSettingsId
        )
        correctAnswerCount(
          learningSessionId: $sessionId
          userSessionSettingsId: $userSessionSettingsId
        )
        lecture {
          id
          title
        }
      }
      aggregatedRate {
        id
        averageRate
        feedbackCount
      }
      certificate {
        id
        points
      }
      organizer {
        id
        name
        email
        logo
        linkToSite
      }
      sponsors {
        id
        title
        logo
      }
    }
  }
  lekSessionsData(learningSessionId: $sessionId) {
    id
    lekSession {
      id
      status
      finishDateTime
      startDateTime
      lek {
        id
        title
        description
        possibleAttempts
      }
    }
    userLekData {
      id
      status
      isPassed
      attemptNumber
    }
  }
}
    `;

/**
 * __useClassRoomSessionQuery__
 *
 * To run a query within a React component, call `useClassRoomSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassRoomSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassRoomSessionQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      userSessionSettingsId: // value for 'userSessionSettingsId'
 *   },
 * });
 */
export function useClassRoomSessionQuery(baseOptions?: Apollo.QueryHookOptions<ClassRoomSessionQuery, ClassRoomSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClassRoomSessionQuery, ClassRoomSessionQueryVariables>(ClassRoomSessionDocument, options);
      }
export function useClassRoomSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClassRoomSessionQuery, ClassRoomSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClassRoomSessionQuery, ClassRoomSessionQueryVariables>(ClassRoomSessionDocument, options);
        }
export type ClassRoomSessionQueryHookResult = ReturnType<typeof useClassRoomSessionQuery>;
export type ClassRoomSessionLazyQueryHookResult = ReturnType<typeof useClassRoomSessionLazyQuery>;
export type ClassRoomSessionQueryResult = Apollo.QueryResult<ClassRoomSessionQuery, ClassRoomSessionQueryVariables>;
export const ClassroomLekSessionsDocument = gql`
    query classroomLekSessions($sessionId: UUID) {
  lekSessionsData(learningSessionId: $sessionId) {
    id
    lekSession {
      id
      status
      finishDateTime
      startDateTime
    }
  }
}
    `;

/**
 * __useClassroomLekSessionsQuery__
 *
 * To run a query within a React component, call `useClassroomLekSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassroomLekSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassroomLekSessionsQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useClassroomLekSessionsQuery(baseOptions?: Apollo.QueryHookOptions<ClassroomLekSessionsQuery, ClassroomLekSessionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClassroomLekSessionsQuery, ClassroomLekSessionsQueryVariables>(ClassroomLekSessionsDocument, options);
      }
export function useClassroomLekSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClassroomLekSessionsQuery, ClassroomLekSessionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClassroomLekSessionsQuery, ClassroomLekSessionsQueryVariables>(ClassroomLekSessionsDocument, options);
        }
export type ClassroomLekSessionsQueryHookResult = ReturnType<typeof useClassroomLekSessionsQuery>;
export type ClassroomLekSessionsLazyQueryHookResult = ReturnType<typeof useClassroomLekSessionsLazyQuery>;
export type ClassroomLekSessionsQueryResult = Apollo.QueryResult<ClassroomLekSessionsQuery, ClassroomLekSessionsQueryVariables>;
export const CrSessionTypeDocument = gql`
    query CRSessionType($sessionId: UUID) {
  classRoomLearningSession(sessionId: $sessionId) {
    id
    classRoom {
      id
      enrollmentType
    }
  }
}
    `;

/**
 * __useCrSessionTypeQuery__
 *
 * To run a query within a React component, call `useCrSessionTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrSessionTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrSessionTypeQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useCrSessionTypeQuery(baseOptions?: Apollo.QueryHookOptions<CrSessionTypeQuery, CrSessionTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CrSessionTypeQuery, CrSessionTypeQueryVariables>(CrSessionTypeDocument, options);
      }
export function useCrSessionTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CrSessionTypeQuery, CrSessionTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CrSessionTypeQuery, CrSessionTypeQueryVariables>(CrSessionTypeDocument, options);
        }
export type CrSessionTypeQueryHookResult = ReturnType<typeof useCrSessionTypeQuery>;
export type CrSessionTypeLazyQueryHookResult = ReturnType<typeof useCrSessionTypeLazyQuery>;
export type CrSessionTypeQueryResult = Apollo.QueryResult<CrSessionTypeQuery, CrSessionTypeQueryVariables>;
export const ClassRoomDetailsDocument = gql`
    query classRoomDetails($sessionId: UUID) {
  classRoomLearningSession(sessionId: $sessionId) {
    id
    status
    startDate
    finishDate
    isForPremiumOnly
    isForSingle
    duration
    specialityGroupIcon
    deepLink
    classRoom {
      id
      title
      description
      learningMoreInfoLink
      classRoomImage
      backgroundImage
      enrollmentType
      aggregatedRate {
        id
        averageRate
        feedbackCount
      }
      certificate {
        id
        points
      }
      isExternalOrganizer
      organizer {
        id
        name
        email
        logo
        linkToSite
      }
      sponsors {
        id
        title
        logo
      }
    }
  }
}
    `;

/**
 * __useClassRoomDetailsQuery__
 *
 * To run a query within a React component, call `useClassRoomDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassRoomDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassRoomDetailsQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useClassRoomDetailsQuery(baseOptions?: Apollo.QueryHookOptions<ClassRoomDetailsQuery, ClassRoomDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClassRoomDetailsQuery, ClassRoomDetailsQueryVariables>(ClassRoomDetailsDocument, options);
      }
export function useClassRoomDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClassRoomDetailsQuery, ClassRoomDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClassRoomDetailsQuery, ClassRoomDetailsQueryVariables>(ClassRoomDetailsDocument, options);
        }
export type ClassRoomDetailsQueryHookResult = ReturnType<typeof useClassRoomDetailsQuery>;
export type ClassRoomDetailsLazyQueryHookResult = ReturnType<typeof useClassRoomDetailsLazyQuery>;
export type ClassRoomDetailsQueryResult = Apollo.QueryResult<ClassRoomDetailsQuery, ClassRoomDetailsQueryVariables>;
export const SpecialityGroupListDocument = gql`
    query specialityGroupList {
  userSpecialityGroupList {
    classRoomSessionCount
    specialityGroup {
      id
      name
      icon
    }
  }
}
    `;

/**
 * __useSpecialityGroupListQuery__
 *
 * To run a query within a React component, call `useSpecialityGroupListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecialityGroupListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecialityGroupListQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpecialityGroupListQuery(baseOptions?: Apollo.QueryHookOptions<SpecialityGroupListQuery, SpecialityGroupListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SpecialityGroupListQuery, SpecialityGroupListQueryVariables>(SpecialityGroupListDocument, options);
      }
export function useSpecialityGroupListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpecialityGroupListQuery, SpecialityGroupListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SpecialityGroupListQuery, SpecialityGroupListQueryVariables>(SpecialityGroupListDocument, options);
        }
export type SpecialityGroupListQueryHookResult = ReturnType<typeof useSpecialityGroupListQuery>;
export type SpecialityGroupListLazyQueryHookResult = ReturnType<typeof useSpecialityGroupListLazyQuery>;
export type SpecialityGroupListQueryResult = Apollo.QueryResult<SpecialityGroupListQuery, SpecialityGroupListQueryVariables>;
export const SpecialityGroupDocument = gql`
    query specialityGroup($specialityGroupId: UUID) {
  specialityGroup(specialityGroupId: $specialityGroupId) {
    id
    name
    icon
  }
}
    `;

/**
 * __useSpecialityGroupQuery__
 *
 * To run a query within a React component, call `useSpecialityGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecialityGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecialityGroupQuery({
 *   variables: {
 *      specialityGroupId: // value for 'specialityGroupId'
 *   },
 * });
 */
export function useSpecialityGroupQuery(baseOptions?: Apollo.QueryHookOptions<SpecialityGroupQuery, SpecialityGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SpecialityGroupQuery, SpecialityGroupQueryVariables>(SpecialityGroupDocument, options);
      }
export function useSpecialityGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpecialityGroupQuery, SpecialityGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SpecialityGroupQuery, SpecialityGroupQueryVariables>(SpecialityGroupDocument, options);
        }
export type SpecialityGroupQueryHookResult = ReturnType<typeof useSpecialityGroupQuery>;
export type SpecialityGroupLazyQueryHookResult = ReturnType<typeof useSpecialityGroupLazyQuery>;
export type SpecialityGroupQueryResult = Apollo.QueryResult<SpecialityGroupQuery, SpecialityGroupQueryVariables>;
export const GetLekSessionDocument = gql`
    query GetLekSession($lekSessionId: UUID, $sessionId: UUID) {
  lekSession(lekSessionId: $lekSessionId) {
    id
    status
    startDateTime
    finishDateTime
    lek {
      id
      title
      lekGoal
      questionCount
      answerTimeDuration
      questions {
        id
        questionImage
        value
        answers {
          id
          value
        }
      }
    }
  }
  userLekData(learningSessionId: $sessionId) {
    id
    created
    status
    elapsedTime
    userLekQuestionAnswerData {
      id
      question {
        id
      }
      userAnswer {
        id
      }
    }
  }
}
    `;

/**
 * __useGetLekSessionQuery__
 *
 * To run a query within a React component, call `useGetLekSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLekSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLekSessionQuery({
 *   variables: {
 *      lekSessionId: // value for 'lekSessionId'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useGetLekSessionQuery(baseOptions?: Apollo.QueryHookOptions<GetLekSessionQuery, GetLekSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLekSessionQuery, GetLekSessionQueryVariables>(GetLekSessionDocument, options);
      }
export function useGetLekSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLekSessionQuery, GetLekSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLekSessionQuery, GetLekSessionQueryVariables>(GetLekSessionDocument, options);
        }
export type GetLekSessionQueryHookResult = ReturnType<typeof useGetLekSessionQuery>;
export type GetLekSessionLazyQueryHookResult = ReturnType<typeof useGetLekSessionLazyQuery>;
export type GetLekSessionQueryResult = Apollo.QueryResult<GetLekSessionQuery, GetLekSessionQueryVariables>;
export const GetLekSessionResultsDocument = gql`
    query GetLekSessionResults($sessionId: UUID) {
  lekSessionsData(learningSessionId: $sessionId) {
    id
    lekSession {
      id
      finishDateTime
      status
      lek {
        id
        title
        lekGoal
        questionCount
        possibleAttempts
      }
      classRoomLearningSession {
        id
        status
      }
    }
    userLekData {
      id
      updated
      status
      correctAnswerCount
      elapsedTime
      isPassed
      attemptNumber
    }
  }
}
    `;

/**
 * __useGetLekSessionResultsQuery__
 *
 * To run a query within a React component, call `useGetLekSessionResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLekSessionResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLekSessionResultsQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useGetLekSessionResultsQuery(baseOptions?: Apollo.QueryHookOptions<GetLekSessionResultsQuery, GetLekSessionResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLekSessionResultsQuery, GetLekSessionResultsQueryVariables>(GetLekSessionResultsDocument, options);
      }
export function useGetLekSessionResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLekSessionResultsQuery, GetLekSessionResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLekSessionResultsQuery, GetLekSessionResultsQueryVariables>(GetLekSessionResultsDocument, options);
        }
export type GetLekSessionResultsQueryHookResult = ReturnType<typeof useGetLekSessionResultsQuery>;
export type GetLekSessionResultsLazyQueryHookResult = ReturnType<typeof useGetLekSessionResultsLazyQuery>;
export type GetLekSessionResultsQueryResult = Apollo.QueryResult<GetLekSessionResultsQuery, GetLekSessionResultsQueryVariables>;
export const ClassRoomSessionProgressDocument = gql`
    query classRoomSessionProgress($sessionId: UUID) {
  classRoomLearningSession(sessionId: $sessionId) {
    id
    classRoom {
      id
      classRoomLecture {
        id
        correctAnswerCount(learningSessionId: $sessionId)
      }
    }
  }
  ongoingClassRooms {
    correctAnswersAmount
    learningSession {
      id
    }
  }
}
    `;

/**
 * __useClassRoomSessionProgressQuery__
 *
 * To run a query within a React component, call `useClassRoomSessionProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassRoomSessionProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassRoomSessionProgressQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useClassRoomSessionProgressQuery(baseOptions?: Apollo.QueryHookOptions<ClassRoomSessionProgressQuery, ClassRoomSessionProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClassRoomSessionProgressQuery, ClassRoomSessionProgressQueryVariables>(ClassRoomSessionProgressDocument, options);
      }
export function useClassRoomSessionProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClassRoomSessionProgressQuery, ClassRoomSessionProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClassRoomSessionProgressQuery, ClassRoomSessionProgressQueryVariables>(ClassRoomSessionProgressDocument, options);
        }
export type ClassRoomSessionProgressQueryHookResult = ReturnType<typeof useClassRoomSessionProgressQuery>;
export type ClassRoomSessionProgressLazyQueryHookResult = ReturnType<typeof useClassRoomSessionProgressLazyQuery>;
export type ClassRoomSessionProgressQueryResult = Apollo.QueryResult<ClassRoomSessionProgressQuery, ClassRoomSessionProgressQueryVariables>;
export const ClassRoomStatisticsDocument = gql`
    query classRoomStatistics($sessionId: UUID, $userSessionSettingsId: UUID) {
  userQuestionsCountData(
    sessionId: $sessionId
    userSessionSettingsId: $userSessionSettingsId
  ) {
    questionCountTotal
    questionCount
    correctAnswerCount
    wrongAnswerCount
    questionCountRepeat
    correctAnswerCountRepeat
    generalQuestionCount
  }
}
    `;

/**
 * __useClassRoomStatisticsQuery__
 *
 * To run a query within a React component, call `useClassRoomStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassRoomStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassRoomStatisticsQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      userSessionSettingsId: // value for 'userSessionSettingsId'
 *   },
 * });
 */
export function useClassRoomStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<ClassRoomStatisticsQuery, ClassRoomStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClassRoomStatisticsQuery, ClassRoomStatisticsQueryVariables>(ClassRoomStatisticsDocument, options);
      }
export function useClassRoomStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClassRoomStatisticsQuery, ClassRoomStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClassRoomStatisticsQuery, ClassRoomStatisticsQueryVariables>(ClassRoomStatisticsDocument, options);
        }
export type ClassRoomStatisticsQueryHookResult = ReturnType<typeof useClassRoomStatisticsQuery>;
export type ClassRoomStatisticsLazyQueryHookResult = ReturnType<typeof useClassRoomStatisticsLazyQuery>;
export type ClassRoomStatisticsQueryResult = Apollo.QueryResult<ClassRoomStatisticsQuery, ClassRoomStatisticsQueryVariables>;
export const OrganizerDocument = gql`
    query organizer($organizerId: UUID) {
  organizer(organizerId: $organizerId) {
    id
    name
    email
    about
    logo
    linkToSite
    city {
      id
      title
    }
    classroomSet {
      id
    }
  }
}
    `;

/**
 * __useOrganizerQuery__
 *
 * To run a query within a React component, call `useOrganizerQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizerQuery({
 *   variables: {
 *      organizerId: // value for 'organizerId'
 *   },
 * });
 */
export function useOrganizerQuery(baseOptions?: Apollo.QueryHookOptions<OrganizerQuery, OrganizerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizerQuery, OrganizerQueryVariables>(OrganizerDocument, options);
      }
export function useOrganizerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizerQuery, OrganizerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizerQuery, OrganizerQueryVariables>(OrganizerDocument, options);
        }
export type OrganizerQueryHookResult = ReturnType<typeof useOrganizerQuery>;
export type OrganizerLazyQueryHookResult = ReturnType<typeof useOrganizerLazyQuery>;
export type OrganizerQueryResult = Apollo.QueryResult<OrganizerQuery, OrganizerQueryVariables>;
export const OrganizerClassroomsDocument = gql`
    query organizerClassrooms($organizerId: UUID) {
  organizer(organizerId: $organizerId) {
    id
    name
    classroomSet {
      id
      title
      backgroundImage
      classRoomImage
      enrollmentType
      classRoomLearningSession {
        id
        startDate
        finishDate
        specialityGroupIcon
      }
      aggregatedRate {
        id
        averageRate
        feedbackCount
      }
      certificate {
        id
        points
      }
    }
  }
}
    `;

/**
 * __useOrganizerClassroomsQuery__
 *
 * To run a query within a React component, call `useOrganizerClassroomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizerClassroomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizerClassroomsQuery({
 *   variables: {
 *      organizerId: // value for 'organizerId'
 *   },
 * });
 */
export function useOrganizerClassroomsQuery(baseOptions?: Apollo.QueryHookOptions<OrganizerClassroomsQuery, OrganizerClassroomsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizerClassroomsQuery, OrganizerClassroomsQueryVariables>(OrganizerClassroomsDocument, options);
      }
export function useOrganizerClassroomsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizerClassroomsQuery, OrganizerClassroomsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizerClassroomsQuery, OrganizerClassroomsQueryVariables>(OrganizerClassroomsDocument, options);
        }
export type OrganizerClassroomsQueryHookResult = ReturnType<typeof useOrganizerClassroomsQuery>;
export type OrganizerClassroomsLazyQueryHookResult = ReturnType<typeof useOrganizerClassroomsLazyQuery>;
export type OrganizerClassroomsQueryResult = Apollo.QueryResult<OrganizerClassroomsQuery, OrganizerClassroomsQueryVariables>;
export const ClassroomMembersDocument = gql`
    query classroomMembers($sessionId: UUID) {
  classRoomMembers(classRoomSessionId: $sessionId) {
    id
    isAvailableForDuel
    userData {
      id
      user {
        id
        username
        avatar
      }
      userClassRoomLearningSessionSettings {
        isClosed
      }
      classRoomCorrectAnswersCount
    }
  }
  startedDuelsToday
}
    `;

/**
 * __useClassroomMembersQuery__
 *
 * To run a query within a React component, call `useClassroomMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassroomMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassroomMembersQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useClassroomMembersQuery(baseOptions?: Apollo.QueryHookOptions<ClassroomMembersQuery, ClassroomMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClassroomMembersQuery, ClassroomMembersQueryVariables>(ClassroomMembersDocument, options);
      }
export function useClassroomMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClassroomMembersQuery, ClassroomMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClassroomMembersQuery, ClassroomMembersQueryVariables>(ClassroomMembersDocument, options);
        }
export type ClassroomMembersQueryHookResult = ReturnType<typeof useClassroomMembersQuery>;
export type ClassroomMembersLazyQueryHookResult = ReturnType<typeof useClassroomMembersLazyQuery>;
export type ClassroomMembersQueryResult = Apollo.QueryResult<ClassroomMembersQuery, ClassroomMembersQueryVariables>;
export const ClassRoomSettingsDocument = gql`
    query classRoomSettings($learningSessionId: UUID, $userSessionSettingId: UUID) {
  userClassRoomSettings(
    learningSessionId: $learningSessionId
    userSessionSettingId: $userSessionSettingId
  ) {
    id
    created
    updated
    isDuelModeEnabled
    isDailyTipperModeEnabled
    isAchieved
    isClosed
    isOut
    classRoomLearningSession {
      id
      status
      classRoom {
        id
        enrollmentType
      }
    }
    specialityGroupIcon
  }
}
    `;

/**
 * __useClassRoomSettingsQuery__
 *
 * To run a query within a React component, call `useClassRoomSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassRoomSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassRoomSettingsQuery({
 *   variables: {
 *      learningSessionId: // value for 'learningSessionId'
 *      userSessionSettingId: // value for 'userSessionSettingId'
 *   },
 * });
 */
export function useClassRoomSettingsQuery(baseOptions?: Apollo.QueryHookOptions<ClassRoomSettingsQuery, ClassRoomSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClassRoomSettingsQuery, ClassRoomSettingsQueryVariables>(ClassRoomSettingsDocument, options);
      }
export function useClassRoomSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClassRoomSettingsQuery, ClassRoomSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClassRoomSettingsQuery, ClassRoomSettingsQueryVariables>(ClassRoomSettingsDocument, options);
        }
export type ClassRoomSettingsQueryHookResult = ReturnType<typeof useClassRoomSettingsQuery>;
export type ClassRoomSettingsLazyQueryHookResult = ReturnType<typeof useClassRoomSettingsLazyQuery>;
export type ClassRoomSettingsQueryResult = Apollo.QueryResult<ClassRoomSettingsQuery, ClassRoomSettingsQueryVariables>;
export const LectorDocument = gql`
    query lector($lectorId: UUID) {
  lector(lectorId: $lectorId) {
    id
    firstName
    lastName
    email
    about
    photo
    professions {
      id
      title
    }
    specialities {
      id
      title
    }
    aggregatedRate {
      id
      averageRate
      feedbackCount
    }
    city {
      id
      title
    }
    lectureSet {
      id
      title
      description
      classroomSet {
        id
        title
      }
    }
  }
}
    `;

/**
 * __useLectorQuery__
 *
 * To run a query within a React component, call `useLectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useLectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLectorQuery({
 *   variables: {
 *      lectorId: // value for 'lectorId'
 *   },
 * });
 */
export function useLectorQuery(baseOptions?: Apollo.QueryHookOptions<LectorQuery, LectorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LectorQuery, LectorQueryVariables>(LectorDocument, options);
      }
export function useLectorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LectorQuery, LectorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LectorQuery, LectorQueryVariables>(LectorDocument, options);
        }
export type LectorQueryHookResult = ReturnType<typeof useLectorQuery>;
export type LectorLazyQueryHookResult = ReturnType<typeof useLectorLazyQuery>;
export type LectorQueryResult = Apollo.QueryResult<LectorQuery, LectorQueryVariables>;
export const ClassroomLecturesDocument = gql`
    query classroomLectures($learningSessionId: UUID) {
  classRoomLectures(learningSessionId: $learningSessionId) {
    id
    lectureNumber
    lecture {
      ...lecture_fragment
    }
  }
}
    ${Lecture_FragmentFragmentDoc}`;

/**
 * __useClassroomLecturesQuery__
 *
 * To run a query within a React component, call `useClassroomLecturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassroomLecturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassroomLecturesQuery({
 *   variables: {
 *      learningSessionId: // value for 'learningSessionId'
 *   },
 * });
 */
export function useClassroomLecturesQuery(baseOptions?: Apollo.QueryHookOptions<ClassroomLecturesQuery, ClassroomLecturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClassroomLecturesQuery, ClassroomLecturesQueryVariables>(ClassroomLecturesDocument, options);
      }
export function useClassroomLecturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClassroomLecturesQuery, ClassroomLecturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClassroomLecturesQuery, ClassroomLecturesQueryVariables>(ClassroomLecturesDocument, options);
        }
export type ClassroomLecturesQueryHookResult = ReturnType<typeof useClassroomLecturesQuery>;
export type ClassroomLecturesLazyQueryHookResult = ReturnType<typeof useClassroomLecturesLazyQuery>;
export type ClassroomLecturesQueryResult = Apollo.QueryResult<ClassroomLecturesQuery, ClassroomLecturesQueryVariables>;
export const QuizTimeDetailsDocument = gql`
    query quizTimeDetails($quizSessionId: UUID) {
  quizTimeSession(quizSessionId: $quizSessionId) {
    id
    status
    startDate
    startDateTime
    usersCount
    quizTimeDuration
    duelDuration
    finishDateTime
    isForPremiumOnly
    isUseCode
    maxUserCapacity
    quizTime {
      id
      quizTimeImage
      title
      description
      participationConditions
      organizer {
        id
        name
        email
        logo
        linkToSite
      }
      sponsor {
        id
        title
        text
        website
        email
        logo
      }
    }
  }
}
    `;

/**
 * __useQuizTimeDetailsQuery__
 *
 * To run a query within a React component, call `useQuizTimeDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuizTimeDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuizTimeDetailsQuery({
 *   variables: {
 *      quizSessionId: // value for 'quizSessionId'
 *   },
 * });
 */
export function useQuizTimeDetailsQuery(baseOptions?: Apollo.QueryHookOptions<QuizTimeDetailsQuery, QuizTimeDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuizTimeDetailsQuery, QuizTimeDetailsQueryVariables>(QuizTimeDetailsDocument, options);
      }
export function useQuizTimeDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuizTimeDetailsQuery, QuizTimeDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuizTimeDetailsQuery, QuizTimeDetailsQueryVariables>(QuizTimeDetailsDocument, options);
        }
export type QuizTimeDetailsQueryHookResult = ReturnType<typeof useQuizTimeDetailsQuery>;
export type QuizTimeDetailsLazyQueryHookResult = ReturnType<typeof useQuizTimeDetailsLazyQuery>;
export type QuizTimeDetailsQueryResult = Apollo.QueryResult<QuizTimeDetailsQuery, QuizTimeDetailsQueryVariables>;
export const GetUserQuizSessionSettingsDocument = gql`
    query getUserQuizSessionSettings($quizSessionId: UUID) {
  userQuizSessionSettings(quizSessionId: $quizSessionId) {
    id
    userStatus
    isQuizSessionAvailableToStart
  }
}
    `;

/**
 * __useGetUserQuizSessionSettingsQuery__
 *
 * To run a query within a React component, call `useGetUserQuizSessionSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuizSessionSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuizSessionSettingsQuery({
 *   variables: {
 *      quizSessionId: // value for 'quizSessionId'
 *   },
 * });
 */
export function useGetUserQuizSessionSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuizSessionSettingsQuery, GetUserQuizSessionSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuizSessionSettingsQuery, GetUserQuizSessionSettingsQueryVariables>(GetUserQuizSessionSettingsDocument, options);
      }
export function useGetUserQuizSessionSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuizSessionSettingsQuery, GetUserQuizSessionSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuizSessionSettingsQuery, GetUserQuizSessionSettingsQueryVariables>(GetUserQuizSessionSettingsDocument, options);
        }
export type GetUserQuizSessionSettingsQueryHookResult = ReturnType<typeof useGetUserQuizSessionSettingsQuery>;
export type GetUserQuizSessionSettingsLazyQueryHookResult = ReturnType<typeof useGetUserQuizSessionSettingsLazyQuery>;
export type GetUserQuizSessionSettingsQueryResult = Apollo.QueryResult<GetUserQuizSessionSettingsQuery, GetUserQuizSessionSettingsQueryVariables>;
export const QuizTimeUsersDocument = gql`
    query quizTimeUsers($quizSessionId: UUID) {
  quizSessionMembers(quizSessionId: $quizSessionId) {
    id
    userData {
      id
      rank
      points
      user {
        id
        username
        avatar
      }
    }
    isAvailableForQuizDuel
  }
}
    `;

/**
 * __useQuizTimeUsersQuery__
 *
 * To run a query within a React component, call `useQuizTimeUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuizTimeUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuizTimeUsersQuery({
 *   variables: {
 *      quizSessionId: // value for 'quizSessionId'
 *   },
 * });
 */
export function useQuizTimeUsersQuery(baseOptions?: Apollo.QueryHookOptions<QuizTimeUsersQuery, QuizTimeUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuizTimeUsersQuery, QuizTimeUsersQueryVariables>(QuizTimeUsersDocument, options);
      }
export function useQuizTimeUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuizTimeUsersQuery, QuizTimeUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuizTimeUsersQuery, QuizTimeUsersQueryVariables>(QuizTimeUsersDocument, options);
        }
export type QuizTimeUsersQueryHookResult = ReturnType<typeof useQuizTimeUsersQuery>;
export type QuizTimeUsersLazyQueryHookResult = ReturnType<typeof useQuizTimeUsersLazyQuery>;
export type QuizTimeUsersQueryResult = Apollo.QueryResult<QuizTimeUsersQuery, QuizTimeUsersQueryVariables>;
export const PrivacyPolicyDocument = gql`
    query privacyPolicy {
  privatePolicy {
    id
    text
  }
}
    `;

/**
 * __usePrivacyPolicyQuery__
 *
 * To run a query within a React component, call `usePrivacyPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrivacyPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrivacyPolicyQuery({
 *   variables: {
 *   },
 * });
 */
export function usePrivacyPolicyQuery(baseOptions?: Apollo.QueryHookOptions<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>(PrivacyPolicyDocument, options);
      }
export function usePrivacyPolicyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>(PrivacyPolicyDocument, options);
        }
export type PrivacyPolicyQueryHookResult = ReturnType<typeof usePrivacyPolicyQuery>;
export type PrivacyPolicyLazyQueryHookResult = ReturnType<typeof usePrivacyPolicyLazyQuery>;
export type PrivacyPolicyQueryResult = Apollo.QueryResult<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>;
export const DataProtectionDocument = gql`
    query dataProtection {
  dataProtection {
    id
    text
  }
}
    `;

/**
 * __useDataProtectionQuery__
 *
 * To run a query within a React component, call `useDataProtectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataProtectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataProtectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useDataProtectionQuery(baseOptions?: Apollo.QueryHookOptions<DataProtectionQuery, DataProtectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataProtectionQuery, DataProtectionQueryVariables>(DataProtectionDocument, options);
      }
export function useDataProtectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataProtectionQuery, DataProtectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataProtectionQuery, DataProtectionQueryVariables>(DataProtectionDocument, options);
        }
export type DataProtectionQueryHookResult = ReturnType<typeof useDataProtectionQuery>;
export type DataProtectionLazyQueryHookResult = ReturnType<typeof useDataProtectionLazyQuery>;
export type DataProtectionQueryResult = Apollo.QueryResult<DataProtectionQuery, DataProtectionQueryVariables>;
export const ImprintDocument = gql`
    query imprint {
  impressum {
    id
    text
  }
}
    `;

/**
 * __useImprintQuery__
 *
 * To run a query within a React component, call `useImprintQuery` and pass it any options that fit your needs.
 * When your component renders, `useImprintQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImprintQuery({
 *   variables: {
 *   },
 * });
 */
export function useImprintQuery(baseOptions?: Apollo.QueryHookOptions<ImprintQuery, ImprintQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ImprintQuery, ImprintQueryVariables>(ImprintDocument, options);
      }
export function useImprintLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ImprintQuery, ImprintQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ImprintQuery, ImprintQueryVariables>(ImprintDocument, options);
        }
export type ImprintQueryHookResult = ReturnType<typeof useImprintQuery>;
export type ImprintLazyQueryHookResult = ReturnType<typeof useImprintLazyQuery>;
export type ImprintQueryResult = Apollo.QueryResult<ImprintQuery, ImprintQueryVariables>;
export const QuizSessionsOngoingDocument = gql`
    query QuizSessionsOngoing {
  ongoingQuizSessions {
    ...quiztime_list_fragment
  }
}
    ${Quiztime_List_FragmentFragmentDoc}`;

/**
 * __useQuizSessionsOngoingQuery__
 *
 * To run a query within a React component, call `useQuizSessionsOngoingQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuizSessionsOngoingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuizSessionsOngoingQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuizSessionsOngoingQuery(baseOptions?: Apollo.QueryHookOptions<QuizSessionsOngoingQuery, QuizSessionsOngoingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuizSessionsOngoingQuery, QuizSessionsOngoingQueryVariables>(QuizSessionsOngoingDocument, options);
      }
export function useQuizSessionsOngoingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuizSessionsOngoingQuery, QuizSessionsOngoingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuizSessionsOngoingQuery, QuizSessionsOngoingQueryVariables>(QuizSessionsOngoingDocument, options);
        }
export type QuizSessionsOngoingQueryHookResult = ReturnType<typeof useQuizSessionsOngoingQuery>;
export type QuizSessionsOngoingLazyQueryHookResult = ReturnType<typeof useQuizSessionsOngoingLazyQuery>;
export type QuizSessionsOngoingQueryResult = Apollo.QueryResult<QuizSessionsOngoingQuery, QuizSessionsOngoingQueryVariables>;
export const QuizSessionsAnnouncedDocument = gql`
    query QuizSessionsAnnounced {
  announcedQuizSessions {
    ...quiztime_list_fragment
  }
}
    ${Quiztime_List_FragmentFragmentDoc}`;

/**
 * __useQuizSessionsAnnouncedQuery__
 *
 * To run a query within a React component, call `useQuizSessionsAnnouncedQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuizSessionsAnnouncedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuizSessionsAnnouncedQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuizSessionsAnnouncedQuery(baseOptions?: Apollo.QueryHookOptions<QuizSessionsAnnouncedQuery, QuizSessionsAnnouncedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuizSessionsAnnouncedQuery, QuizSessionsAnnouncedQueryVariables>(QuizSessionsAnnouncedDocument, options);
      }
export function useQuizSessionsAnnouncedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuizSessionsAnnouncedQuery, QuizSessionsAnnouncedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuizSessionsAnnouncedQuery, QuizSessionsAnnouncedQueryVariables>(QuizSessionsAnnouncedDocument, options);
        }
export type QuizSessionsAnnouncedQueryHookResult = ReturnType<typeof useQuizSessionsAnnouncedQuery>;
export type QuizSessionsAnnouncedLazyQueryHookResult = ReturnType<typeof useQuizSessionsAnnouncedLazyQuery>;
export type QuizSessionsAnnouncedQueryResult = Apollo.QueryResult<QuizSessionsAnnouncedQuery, QuizSessionsAnnouncedQueryVariables>;
export const QuizSessionsFinishedDocument = gql`
    query QuizSessionsFinished {
  finishedQuizSessions {
    id
    quizSession {
      ...quiztime_list_fragment
    }
    userRank
  }
}
    ${Quiztime_List_FragmentFragmentDoc}`;

/**
 * __useQuizSessionsFinishedQuery__
 *
 * To run a query within a React component, call `useQuizSessionsFinishedQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuizSessionsFinishedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuizSessionsFinishedQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuizSessionsFinishedQuery(baseOptions?: Apollo.QueryHookOptions<QuizSessionsFinishedQuery, QuizSessionsFinishedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuizSessionsFinishedQuery, QuizSessionsFinishedQueryVariables>(QuizSessionsFinishedDocument, options);
      }
export function useQuizSessionsFinishedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuizSessionsFinishedQuery, QuizSessionsFinishedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuizSessionsFinishedQuery, QuizSessionsFinishedQueryVariables>(QuizSessionsFinishedDocument, options);
        }
export type QuizSessionsFinishedQueryHookResult = ReturnType<typeof useQuizSessionsFinishedQuery>;
export type QuizSessionsFinishedLazyQueryHookResult = ReturnType<typeof useQuizSessionsFinishedLazyQuery>;
export type QuizSessionsFinishedQueryResult = Apollo.QueryResult<QuizSessionsFinishedQuery, QuizSessionsFinishedQueryVariables>;
export const QuizSessionsOnHomePageDocument = gql`
    query quizSessionsOnHomePage($startLess24Hours: Boolean) {
  me {
    id
    settings {
      id
      duelMode
      activeQuizTime {
        id
      }
    }
  }
  homeQuizSessions(startLess24Hours: $startLess24Hours) {
    quizSession {
      ...quiztime_homelist_fragment
    }
    userStatus
  }
  inProgressUserQuizSession {
    ...quiztime_homelist_fragment
  }
}
    ${Quiztime_Homelist_FragmentFragmentDoc}`;

/**
 * __useQuizSessionsOnHomePageQuery__
 *
 * To run a query within a React component, call `useQuizSessionsOnHomePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuizSessionsOnHomePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuizSessionsOnHomePageQuery({
 *   variables: {
 *      startLess24Hours: // value for 'startLess24Hours'
 *   },
 * });
 */
export function useQuizSessionsOnHomePageQuery(baseOptions?: Apollo.QueryHookOptions<QuizSessionsOnHomePageQuery, QuizSessionsOnHomePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuizSessionsOnHomePageQuery, QuizSessionsOnHomePageQueryVariables>(QuizSessionsOnHomePageDocument, options);
      }
export function useQuizSessionsOnHomePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuizSessionsOnHomePageQuery, QuizSessionsOnHomePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuizSessionsOnHomePageQuery, QuizSessionsOnHomePageQueryVariables>(QuizSessionsOnHomePageDocument, options);
        }
export type QuizSessionsOnHomePageQueryHookResult = ReturnType<typeof useQuizSessionsOnHomePageQuery>;
export type QuizSessionsOnHomePageLazyQueryHookResult = ReturnType<typeof useQuizSessionsOnHomePageLazyQuery>;
export type QuizSessionsOnHomePageQueryResult = Apollo.QueryResult<QuizSessionsOnHomePageQuery, QuizSessionsOnHomePageQueryVariables>;
export const GetActiveQuizDuelsDocument = gql`
    query getActiveQuizDuels($quizSessionId: UUID) {
  activeQuizDuels(quizSessionId: $quizSessionId) {
    id
    opponent {
      id
      avatar
      username
    }
    round {
      id
      roundNumber
      created
      duel {
        id
        status
      }
      userQuestions {
        id
        questionNumber
        user {
          id
        }
        question {
          id
          value
        }
        userAnswer {
          id
          value
        }
      }
    }
    isInitiator
    session {
      id
    }
  }
}
    `;

/**
 * __useGetActiveQuizDuelsQuery__
 *
 * To run a query within a React component, call `useGetActiveQuizDuelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveQuizDuelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveQuizDuelsQuery({
 *   variables: {
 *      quizSessionId: // value for 'quizSessionId'
 *   },
 * });
 */
export function useGetActiveQuizDuelsQuery(baseOptions?: Apollo.QueryHookOptions<GetActiveQuizDuelsQuery, GetActiveQuizDuelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveQuizDuelsQuery, GetActiveQuizDuelsQueryVariables>(GetActiveQuizDuelsDocument, options);
      }
export function useGetActiveQuizDuelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveQuizDuelsQuery, GetActiveQuizDuelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveQuizDuelsQuery, GetActiveQuizDuelsQueryVariables>(GetActiveQuizDuelsDocument, options);
        }
export type GetActiveQuizDuelsQueryHookResult = ReturnType<typeof useGetActiveQuizDuelsQuery>;
export type GetActiveQuizDuelsLazyQueryHookResult = ReturnType<typeof useGetActiveQuizDuelsLazyQuery>;
export type GetActiveQuizDuelsQueryResult = Apollo.QueryResult<GetActiveQuizDuelsQuery, GetActiveQuizDuelsQueryVariables>;
export const QuizScoreDocument = gql`
    query quizScore($quizSessionId: UUID) {
  quizDuelScore(quizSessionId: $quizSessionId) {
    id
    duels
    points
    rank
    wins
    ties
    loss
  }
}
    `;

/**
 * __useQuizScoreQuery__
 *
 * To run a query within a React component, call `useQuizScoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuizScoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuizScoreQuery({
 *   variables: {
 *      quizSessionId: // value for 'quizSessionId'
 *   },
 * });
 */
export function useQuizScoreQuery(baseOptions?: Apollo.QueryHookOptions<QuizScoreQuery, QuizScoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuizScoreQuery, QuizScoreQueryVariables>(QuizScoreDocument, options);
      }
export function useQuizScoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuizScoreQuery, QuizScoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuizScoreQuery, QuizScoreQueryVariables>(QuizScoreDocument, options);
        }
export type QuizScoreQueryHookResult = ReturnType<typeof useQuizScoreQuery>;
export type QuizScoreLazyQueryHookResult = ReturnType<typeof useQuizScoreLazyQuery>;
export type QuizScoreQueryResult = Apollo.QueryResult<QuizScoreQuery, QuizScoreQueryVariables>;
export const GetCompletedQuizDuelsDocument = gql`
    query getCompletedQuizDuels($quizSessionId: UUID) {
  completedQuizDuels(quizSessionId: $quizSessionId) {
    id
    userDuelsData {
      ...user_score_fragment
      duel {
        id
        updated
        status
      }
    }
    opponentDuelsData {
      ...user_score_fragment
    }
    session {
      id
    }
  }
}
    ${User_Score_FragmentFragmentDoc}`;

/**
 * __useGetCompletedQuizDuelsQuery__
 *
 * To run a query within a React component, call `useGetCompletedQuizDuelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompletedQuizDuelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompletedQuizDuelsQuery({
 *   variables: {
 *      quizSessionId: // value for 'quizSessionId'
 *   },
 * });
 */
export function useGetCompletedQuizDuelsQuery(baseOptions?: Apollo.QueryHookOptions<GetCompletedQuizDuelsQuery, GetCompletedQuizDuelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompletedQuizDuelsQuery, GetCompletedQuizDuelsQueryVariables>(GetCompletedQuizDuelsDocument, options);
      }
export function useGetCompletedQuizDuelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompletedQuizDuelsQuery, GetCompletedQuizDuelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompletedQuizDuelsQuery, GetCompletedQuizDuelsQueryVariables>(GetCompletedQuizDuelsDocument, options);
        }
export type GetCompletedQuizDuelsQueryHookResult = ReturnType<typeof useGetCompletedQuizDuelsQuery>;
export type GetCompletedQuizDuelsLazyQueryHookResult = ReturnType<typeof useGetCompletedQuizDuelsLazyQuery>;
export type GetCompletedQuizDuelsQueryResult = Apollo.QueryResult<GetCompletedQuizDuelsQuery, GetCompletedQuizDuelsQueryVariables>;
export const GetQuizSessionPrizeDocument = gql`
    query getQuizSessionPrize($quizSessionId: UUID) {
  quizSessionPrize(quizSessionId: $quizSessionId) {
    placeList {
      ...quiz_prize_item
    }
    participationList {
      ...quiz_prize_item
    }
    lotteryList {
      ...quiz_prize_item
    }
  }
}
    ${Quiz_Prize_ItemFragmentDoc}`;

/**
 * __useGetQuizSessionPrizeQuery__
 *
 * To run a query within a React component, call `useGetQuizSessionPrizeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuizSessionPrizeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuizSessionPrizeQuery({
 *   variables: {
 *      quizSessionId: // value for 'quizSessionId'
 *   },
 * });
 */
export function useGetQuizSessionPrizeQuery(baseOptions?: Apollo.QueryHookOptions<GetQuizSessionPrizeQuery, GetQuizSessionPrizeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuizSessionPrizeQuery, GetQuizSessionPrizeQueryVariables>(GetQuizSessionPrizeDocument, options);
      }
export function useGetQuizSessionPrizeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuizSessionPrizeQuery, GetQuizSessionPrizeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuizSessionPrizeQuery, GetQuizSessionPrizeQueryVariables>(GetQuizSessionPrizeDocument, options);
        }
export type GetQuizSessionPrizeQueryHookResult = ReturnType<typeof useGetQuizSessionPrizeQuery>;
export type GetQuizSessionPrizeLazyQueryHookResult = ReturnType<typeof useGetQuizSessionPrizeLazyQuery>;
export type GetQuizSessionPrizeQueryResult = Apollo.QueryResult<GetQuizSessionPrizeQuery, GetQuizSessionPrizeQueryVariables>;
export const GetFeedbackQuestionDocument = gql`
    query getFeedbackQuestion($questionId: UUID!) {
  getFeedbackForQuestion(questionId: $questionId) {
    id
    relevanceRate
    interestRate
    comment
  }
}
    `;

/**
 * __useGetFeedbackQuestionQuery__
 *
 * To run a query within a React component, call `useGetFeedbackQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbackQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedbackQuestionQuery({
 *   variables: {
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useGetFeedbackQuestionQuery(baseOptions: Apollo.QueryHookOptions<GetFeedbackQuestionQuery, GetFeedbackQuestionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeedbackQuestionQuery, GetFeedbackQuestionQueryVariables>(GetFeedbackQuestionDocument, options);
      }
export function useGetFeedbackQuestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeedbackQuestionQuery, GetFeedbackQuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeedbackQuestionQuery, GetFeedbackQuestionQueryVariables>(GetFeedbackQuestionDocument, options);
        }
export type GetFeedbackQuestionQueryHookResult = ReturnType<typeof useGetFeedbackQuestionQuery>;
export type GetFeedbackQuestionLazyQueryHookResult = ReturnType<typeof useGetFeedbackQuestionLazyQuery>;
export type GetFeedbackQuestionQueryResult = Apollo.QueryResult<GetFeedbackQuestionQuery, GetFeedbackQuestionQueryVariables>;
export const TakenAchievementDocument = gql`
    query takenAchievement {
  dailyStatus @client
}
    `;

/**
 * __useTakenAchievementQuery__
 *
 * To run a query within a React component, call `useTakenAchievementQuery` and pass it any options that fit your needs.
 * When your component renders, `useTakenAchievementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTakenAchievementQuery({
 *   variables: {
 *   },
 * });
 */
export function useTakenAchievementQuery(baseOptions?: Apollo.QueryHookOptions<TakenAchievementQuery, TakenAchievementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TakenAchievementQuery, TakenAchievementQueryVariables>(TakenAchievementDocument, options);
      }
export function useTakenAchievementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TakenAchievementQuery, TakenAchievementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TakenAchievementQuery, TakenAchievementQueryVariables>(TakenAchievementDocument, options);
        }
export type TakenAchievementQueryHookResult = ReturnType<typeof useTakenAchievementQuery>;
export type TakenAchievementLazyQueryHookResult = ReturnType<typeof useTakenAchievementLazyQuery>;
export type TakenAchievementQueryResult = Apollo.QueryResult<TakenAchievementQuery, TakenAchievementQueryVariables>;
export const GlobalDialogDocument = gql`
    query globalDialog {
  dialogId @client
}
    `;

/**
 * __useGlobalDialogQuery__
 *
 * To run a query within a React component, call `useGlobalDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalDialogQuery({
 *   variables: {
 *   },
 * });
 */
export function useGlobalDialogQuery(baseOptions?: Apollo.QueryHookOptions<GlobalDialogQuery, GlobalDialogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GlobalDialogQuery, GlobalDialogQueryVariables>(GlobalDialogDocument, options);
      }
export function useGlobalDialogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GlobalDialogQuery, GlobalDialogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GlobalDialogQuery, GlobalDialogQueryVariables>(GlobalDialogDocument, options);
        }
export type GlobalDialogQueryHookResult = ReturnType<typeof useGlobalDialogQuery>;
export type GlobalDialogLazyQueryHookResult = ReturnType<typeof useGlobalDialogLazyQuery>;
export type GlobalDialogQueryResult = Apollo.QueryResult<GlobalDialogQuery, GlobalDialogQueryVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String) {
  userForgotPassword(email: $email) {
    user {
      id
    }
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;