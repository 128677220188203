import { useCallback } from 'react';
import {
  useEarlyFinishNetworkClassroomMutation,
  useEarlyFinishBlendedClassroomMutation,
  useEarlyFinishLekClassroomMutation,
} from '@/graphql';
import { ClassroomTypes } from '@/type';

export const useEarlyFinishClassroom = () => {
  const [earlyFinishNetworkClassroom] =
    useEarlyFinishNetworkClassroomMutation();
  const [earlyFinishBlendedClassroom] =
    useEarlyFinishBlendedClassroomMutation();
  const [earlyFinishLekClassroom] = useEarlyFinishLekClassroomMutation();

  const earlyFinishClassroom = useCallback(
    async (userSessionSettingsId: string, classroomType: ClassroomTypes) => {
      try {
        switch (classroomType) {
          case 1:
            return await earlyFinishBlendedClassroom({
              variables: {
                userSessionSettingsId,
              },
            });
          case 2:
            return await earlyFinishNetworkClassroom({
              variables: {
                userSessionSettingsId,
              },
            });
          case 3:
            return await earlyFinishLekClassroom({
              variables: {
                userSessionSettingsId,
              },
            });
        }
      } catch (error) {
        console.log('Early Finish Classroom Mutation', error);
      }
    },
    [
      earlyFinishBlendedClassroom,
      earlyFinishNetworkClassroom,
      earlyFinishLekClassroom,
    ]
  );

  return {
    earlyFinishClassroom,
  };
};
