import React, { useCallback, useContext, useEffect, useState } from 'react';

interface IEventContext {
  installEvent?: Event | null;
  clearInstallEvent: () => void;
}

export const EventContext =
  React.createContext<IEventContext | undefined>(undefined);

export const EventProvider: React.FC = (props) => {
  const { children } = props;
  const [installEvent, setInstallEvent] = useState(null);

  const listener = (event: any) => {
    event.preventDefault();
    setInstallEvent(event);
  };

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', listener);
  }, []);

  const clearInstallEvent = useCallback(() => {
    window.removeEventListener('beforeinstallprompt', listener);
    setInstallEvent(null);
  }, []);

  return (
    <EventContext.Provider
      value={{
        installEvent,
        clearInstallEvent,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export const useInstallEvent = () => {
  const state = useContext(EventContext);
  if (!state) throw new Error('EventContext not found');
  return state;
};
