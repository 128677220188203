import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  SwipeableDrawer,
  ListItemIcon,
} from '@material-ui/core';
import {
  ChevronRight,
  FolderSpecialOutlined,
  SettingsOutlined,
  StarsOutlined,
} from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import UploadIcon from '@/components/icons/upload';

import { useUser } from '@/contexts/user-context';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import AgreementsFooter from '@/components/agreements-footer';
import Ava from '@/components/ava';
import { ReactComponent as Logo } from './index.svg';
import { useInstallEvent } from '@/contexts/event-context';

import { useStyles } from './styles';

import { SubscriptionStatus } from '@/type';
import BiblioIcon from '@/components/icons/biblio';
import DuelOutlinedIcon from '@/components/icons/duel-outlined-icon';
import DailyTipperIcon from '@/components/icons/daily-tipper-icon';
import { useGetPremiumData } from '@/hooks/useGetPremiumData';
import { getSubscriptionsPath } from '@/utils/helpers';

interface LeftMenu {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
}

const LeftMenu: React.FC<LeftMenu> = ({ open, onClose, onOpen }) => {
  const history = useHistory();
  const { user } = useUser();
  const classes = useStyles();
  const { t } = useTranslation();

  const { dailyTipperEnabled, classroomsEnabled } = useFeatureFlags();
  const { isUserPremium } = useGetPremiumData();

  const { installEvent, clearInstallEvent } = useInstallEvent();

  const description = useMemo(() => {
    if (!user?.profession?.type?.type) return <Skeleton />;

    switch (user.profession.type.type) {
      case 1:
      case 2:
      case 6:
        return user?.speciality?.title || '';
      case 5:
        return user?.semester?.title || '';
      default:
        return '';
    }
  }, [user]);

  const isSubsStatusIncomplete =
    user?.customer?.subscription?.status === SubscriptionStatus.INCOMPLETE;

  const fullNameNode = !!user ? (
    `${user.firstName} ${user.lastName}`
  ) : (
    <Skeleton width="80%" />
  );

  const handleAddToHomeScreen = useCallback(() => {
    if (installEvent) {
      (installEvent as any).prompt();
      (installEvent as any).userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          clearInstallEvent();
        }
      });
    }
  }, [installEvent, clearInstallEvent]);

  return (
    <SwipeableDrawer
      anchor="left"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
    >
      <div className={classes.root}>
        <ListItem
          button
          className={classes.header}
          to="/my-profile"
          component={RouterLink}
        >
          <Box paddingY={4} paddingBottom={3}>
            <Ava src={user?.avatar ?? undefined} />
            <Box paddingTop={5}>
              <Typography
                variant="h2"
                gutterBottom
                className="text-overflow-ellipsis"
              >
                {fullNameNode}
              </Typography>
              <Typography variant="subtitle2">{description}</Typography>
            </Box>
          </Box>
        </ListItem>
        <Divider />
        <div className={classes.content}>
          <Box paddingTop={4} mb={9}>
            <List>
              <ListItem
                to={`${classroomsEnabled ? '/classrooms/' : '/home/'}`}
                button
                className={classes.menuItem}
                component={RouterLink}
              >
                <ListItemIcon>
                  <FolderSpecialOutlined />
                </ListItemIcon>
                <ListItemText primary={t('menu.my-learning')} />
              </ListItem>
              <ListItem
                to="/biblio/"
                button
                className={classes.menuItem}
                component={RouterLink}
              >
                <ListItemIcon>
                  <BiblioIcon />
                </ListItemIcon>
                <ListItemText primary={t('menu.biblio')} />
              </ListItem>
              <ListItem
                button
                className={classes.menuItem}
                to="/quiz-list"
                component={RouterLink}
                onClick={onClose}
              >
                <ListItemIcon>
                  <StarsOutlined />
                </ListItemIcon>
                <ListItemText primary={t('menu.quiztime')} />
              </ListItem>
              <ListItem
                button
                to={dailyTipperEnabled ? '/daily-tipper' : '/home'}
                className={classes.menuItem}
                component={RouterLink}
                onClick={onClose}
              >
                <ListItemIcon>
                  <DailyTipperIcon />
                </ListItemIcon>
                <ListItemText primary={t('menu.daily-tipper')} />
              </ListItem>
              <ListItem
                button
                to="/duels"
                className={classes.menuItem}
                component={RouterLink}
                onClick={onClose}
              >
                <ListItemIcon>
                  <DuelOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={t('menu.duels')} />
              </ListItem>
              <ListItem
                button
                className={classes.menuItem}
                disabled={!user?.network}
                component={RouterLink}
                to="/settings"
                onClick={onClose}
              >
                <ListItemIcon>
                  <SettingsOutlined />
                </ListItemIcon>
                <ListItemText primary={t('menu.settings')} />
              </ListItem>

              <Box px={4} my={1}>
                <Divider />
              </Box>
              <ListItem
                button
                className={classes.menuItem}
                component={RouterLink}
                to="/invite-friends"
                onClick={onClose}
              >
                <ListItemIcon>
                  <UploadIcon />
                </ListItemIcon>
                <ListItemText primary={t('menu.invite-friends')}>
                  <UploadIcon />
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          {!window?.ReactNativeWebView && !!installEvent && (
            <Box display="flex" mx={4} mb={3}>
              <Button
                fullWidth
                variant="outlined"
                onClick={handleAddToHomeScreen}
                startIcon={<Logo />}
                style={{ borderColor: '#e6eefa' }}
              >
                <Typography
                  variant="caption"
                  className={classes.addIconTitle}
                  style={{ color: '#647485' }}
                >
                  {t('add-to-home-screen.add-btn')}
                </Typography>
              </Button>
            </Box>
          )}
          {!isUserPremium && !isSubsStatusIncomplete && (
            <>
              <Box display="flex" px={4} mb={3}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={() =>
                    history.push(getSubscriptionsPath(), {
                      prevPage: '/home',
                      search: '?leftMenu=true',
                      state: { search: '?leftMenu=true' },
                    })
                  }
                  endIcon={<ChevronRight />}
                  component="div"
                  style={{ padding: 12 }}
                >
                  <Box display="flex" flexDirection="column" width="100%">
                    <Typography variant="caption" className={classes.action}>
                      {t('menu.enter-code-title')}
                    </Typography>
                    <Typography variant="caption" className={classes.subAction}>
                      {t('menu.enter-code-sub-title')}
                    </Typography>
                  </Box>
                </Button>
              </Box>
            </>
          )}
        </div>
        <div className={classes.footer}>
          <Divider />
          <AgreementsFooter />
        </div>
      </div>
    </SwipeableDrawer>
  );
};

export default LeftMenu;
