import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Link, Paper, Typography } from '@material-ui/core';

import StackLayout from '../../components/stack-layout';
import ActivateCode from '../activate-code';
import { SubscriptionTypes } from '@/type';
import PlanItem from '../plan-item';
import { plansConfig } from '../plansConfig';
import { datadogRum } from '@datadog/browser-rum';

const PlugForFutureSubs: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { t } = useTranslation();

  const params = new URLSearchParams(search);
  const planId = params.get('planId');
  const choosedPlan =
    plansConfig.find((it) => it.id === planId) || plansConfig[0];

  const backLink = useMemo(() => {
    const historyState: any = history.location.state;
    if (historyState?.isRedirectedFormPlanChooser)
      return '/choose-subscription';
    else if (historyState?.isRedirectedFromNotifications)
      return '/notifications';
    else return undefined;
  }, [history]);

  return (
    <StackLayout title={t('payment.title')} back={backLink}>
      <Box display="grid" height="100%">
        <Paper>
          <Box padding={4}>
            {choosedPlan?.id === SubscriptionTypes.Code && (
              <PlanItem {...choosedPlan} isChosen isFrosen />
            )}
            {choosedPlan?.id === SubscriptionTypes.Code ? (
              <ActivateCode />
            ) : (
              <Box justifyContent="center" alignItems="center" display="flex">
                <Typography variant="body2">
                  Die Premium-Funktion wird in einer zukünftigen Version der App
                  erscheinen.{' '}
                  <Link
                    href="mailto:info@humeo.de"
                    onClick={() =>
                      datadogRum.addAction('subscription', {
                        event: `click email link`,
                      })
                    }
                  >
                    <b>Schreiben Sie uns</b>
                  </Link>
                  , wenn Sie der Erste sein wollen.
                </Typography>
              </Box>
            )}
          </Box>
        </Paper>
      </Box>
    </StackLayout>
  );
};

export default PlugForFutureSubs;
