import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(226, 245, 253, 0.48)',
  },
  errorBox: {
    display: 'flex',
    alignItems: 'center',
    background: 'rgba(235, 87, 87, 0.1)',
    border: '1px solid #EB5757',
    borderRadius: 4,
    padding: 8,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
}));
