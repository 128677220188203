import React, { useCallback, useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { useApolloClient } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';

import { toNumber } from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import {
  ClassRoomLearningSessionStatus,
  ClassRoomsFinishedDocument,
  ClassRoomsOngoingQuery,
  useUpdateClassroomSettingsMutation,
} from '@/graphql';
import { CLASS_ROOMS_ONGOING } from '@/apollo/queries';
import AcadCap from '@/components/icons/acadcap';
import DisappointedIconJson from '@/components/gifs/disappointed.json';
import StarIconJson from '@/components/gifs/star.json';
import { useUser } from '@/contexts/user-context';
import theme from '@/theme';
import { ClassroomTypes, TabNavIdsEnum } from '@/type';
import Lottie from 'lottie-web';
import { flatten } from 'lottie-colorify';
import { useEarlyFinishClassroom } from '@/hooks/useEarlyFinishClassroom';
import { ANIM_ICON_CONTAINER_CSS_ID, LS_KEY_TABINDEX } from '@/utils/constants';

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    width: 280,
    boxSizing: 'border-box',
  },
  close: {
    top: theme.spacing(1),
    right: theme.spacing(1),
    position: 'absolute',
  },
  iconContainer: {
    '& svg': { width: '56px!important', height: '56px!important' },
  },
}));

interface ExpiredDialogProps {
  title: string;
  finishDate: string;
  classroomType: ClassroomTypes | null | undefined;
  sessionStatus: ClassRoomLearningSessionStatus | null | undefined;
  userCrSettingsId: string;
  userCrSettingsIsAchieved?: boolean | undefined;
  userCrSettingsIsOut?: boolean | undefined;
  userCrSettingsIsClosed?: boolean | undefined;
  classRoomLectures: {
    id: string;
    lectureNumber: number | null | undefined;
    lectureQuestionCount?: number | null | undefined;
    correctAnswerCount?: number | null | undefined;
    lectureGoal?: number | null | undefined;
  }[];
  isPremiumEndedInPremiumCR?: boolean | undefined;
  testsFinishedAndLekGoalWasAchieved?: boolean | undefined | null;
  testsFinishedAndAllLekAttemptsWereUsed?: boolean | undefined | null;
}

type ResultDataType = {
  icon?: JSX.Element;
  title: string;
  description?: string;
  message?: string;
  style?: {
    color?: string;
  };
  iconJson?: any;
  iconColor?: string;
};

type ClassRoomParams = {
  sessionId: string;
  userSessionSettingId: string;
};

const ClassroomExpiredDialog: React.FC<ExpiredDialogProps> = ({
  title,
  classRoomLectures,
  finishDate,
  classroomType,
  sessionStatus,
  userCrSettingsId,
  userCrSettingsIsAchieved,
  userCrSettingsIsOut,
  userCrSettingsIsClosed,
  isPremiumEndedInPremiumCR,
  testsFinishedAndLekGoalWasAchieved,
  testsFinishedAndAllLekAttemptsWereUsed,
}) => {
  const { cache } = useApolloClient();
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const [dialogVisibility, setDialogVisibility] = useState<boolean>(false);
  const [isAchieved, setIsAchieved] = useState<boolean | null>(null);
  const [resultVisibility, setResultVisibility] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState<ResultDataType>({
    icon: <AcadCap />,
    title: 'class-room.expired.title',
    style: {
      color: theme.palette.primary.dark,
    },
  });
  const [resultData, setResultData] = useState<ResultDataType>();
  const [iconElemRef, setIconElemRef] = useState<HTMLDivElement | null>(null);

  const { user } = useUser();

  const { sessionId } = useParams<ClassRoomParams>();

  const { earlyFinishClassroom } = useEarlyFinishClassroom();
  const isNetworkType = classroomType === ClassroomTypes.NETWORK;

  const isSessionStatusFinished =
    sessionStatus === ClassRoomLearningSessionStatus.Finished ||
    (isNetworkType &&
      DateTime.fromISO(finishDate) < DateTime.local().endOf('day')) ||
    (classroomType === ClassroomTypes.LEK &&
      testsFinishedAndAllLekAttemptsWereUsed);

  const [updateClassroomSettings, loading] =
    useUpdateClassroomSettingsMutation();
  useEffect(() => {
    if (
      !loading.loading &&
      ((classroomType &&
        classroomType === ClassroomTypes.LEK &&
        (testsFinishedAndLekGoalWasAchieved ||
          testsFinishedAndAllLekAttemptsWereUsed) &&
        !!userCrSettingsId) ||
        (!!classRoomLectures?.length && userCrSettingsIsOut
          ? true
          : userCrSettingsIsClosed === false))
    ) {
      let correctAnswersGoal = 0;
      let correctAnswers = 0;
      let result: ResultDataType | null = null;
      classRoomLectures?.forEach((l) => {
        correctAnswersGoal += toNumber(l?.lectureGoal);
        correctAnswers += l?.correctAnswerCount || 0;
      });
      if (isSessionStatusFinished || isPremiumEndedInPremiumCR) {
        result = {
          icon: undefined,
          title: 'classrooms.title-goal-not-achieved',
          description: 'classrooms.description-goal-not-achieved',
          style: {
            color: '#EB5757',
          },
          iconJson: DisappointedIconJson,
          iconColor: '#EB5757',
        };
        if (
          (correctAnswers > 0 && correctAnswers >= correctAnswersGoal) ||
          testsFinishedAndLekGoalWasAchieved
        ) {
          result = {
            icon: undefined,
            title: 'classrooms.title-goal-achieved',
            description: 'classrooms.description-goal-achieved',
            style: {
              color: theme.palette.primary.main,
            },
            iconJson: StarIconJson,
            iconColor: '#45B3EE',
          };
          setIsAchieved(true);
        }
      }

      if (
        isSessionStatusFinished === false &&
        isPremiumEndedInPremiumCR === false &&
        userCrSettingsIsAchieved === false &&
        ((correctAnswers > 0 && correctAnswers >= correctAnswersGoal) ||
          testsFinishedAndLekGoalWasAchieved)
      ) {
        result = {
          icon: undefined,
          title: 'classrooms.title-goal-super-achieved',
          description: 'classrooms.description-goal-super-achieved',
          message: 'classrooms.message-goal-super-achieved',
          style: {
            color: theme.palette.primary.main,
          },
          iconJson: StarIconJson,
          iconColor: '#45B3EE',
        };
        setIsAchieved(true);
        setResultVisibility(true);
        setDialogData(result);
      }
      if (result) {
        setResultData(result);
        setDialogVisibility(true);
        const elem = iconElemRef?.querySelector(
          `#${ANIM_ICON_CONTAINER_CSS_ID}`
        );
        if (resultVisibility && result?.iconJson && elem && result.iconColor) {
          elem.innerHTML = '';
          Lottie.loadAnimation({
            container: elem,
            animationData: flatten(result.iconColor, result.iconJson),
          });
        }
      }
    }
  }, [
    classRoomLectures,
    user,
    finishDate,
    userCrSettingsIsAchieved,
    userCrSettingsIsClosed,
    userCrSettingsIsOut,
    sessionId,
    isSessionStatusFinished,
    isNetworkType,
    userCrSettingsId,
    classroomType,
    isPremiumEndedInPremiumCR,
    iconElemRef,
    resultVisibility,
    testsFinishedAndLekGoalWasAchieved,
    testsFinishedAndAllLekAttemptsWereUsed,
    loading.loading,
  ]);

  const showResults = () => {
    if (resultData) {
      setDialogData(resultData);
      setResultVisibility(true);
    } else {
      setDialogVisibility(false);
    }
  };

  const handleClose = useCallback(async () => {
    setDialogVisibility(false);
    try {
      if (isSessionStatusFinished || isPremiumEndedInPremiumCR) {
        const res = await updateClassroomSettings({
          variables: {
            sessionId,
            isAchieved: !!isAchieved,
            isClosed: true,
            isOut: false,
          },
          refetchQueries: [{ query: ClassRoomsFinishedDocument }],
          fetchPolicy: 'no-cache',
        });

        const classroomsOngoing: ClassRoomsOngoingQuery | undefined | null =
          cache.readQuery({
            query: CLASS_ROOMS_ONGOING,
          });
        const filteredClassrooms = classroomsOngoing?.ongoingClassRooms?.filter(
          (it) => it?.learningSession?.id !== sessionId
        );
        cache.writeQuery({
          query: CLASS_ROOMS_ONGOING,
          data: {
            ongoingClassRooms: filteredClassrooms,
          },
        });
        cache.modify({
          id: `UserClassRoomLearningSessionSettingsType:${userCrSettingsId}`,
          fields: {
            isClosed() {
              return true;
            },
            isAchieved() {
              return !!isAchieved;
            },
            isOut() {
              return false;
            },
          },
        });

        if (res) {
          localStorage.setItem(
            `${TabNavIdsEnum.CLASSROOMS}${LS_KEY_TABINDEX}`,
            '1'
          );
          history.replace(`/classroom-finished/${sessionId}`, {
            fromCongratulation: true,
          });
        }
      } else if (userCrSettingsIsAchieved === false) {
        await updateClassroomSettings({
          variables: {
            sessionId,
            isAchieved: true,
          },
        });
      }
    } catch (error) {
      console.log('Set Classroom Has Closed Mutation', error);
    }
    setResultVisibility(false);
  }, [
    updateClassroomSettings,
    sessionId,
    userCrSettingsIsAchieved,
    userCrSettingsId,
    cache,
    history,
    isSessionStatusFinished,
    isAchieved,
    isPremiumEndedInPremiumCR,
  ]);

  const handleEarlyFinish = useCallback(async () => {
    setDialogVisibility(false);
    try {
      if (
        userCrSettingsIsAchieved === false &&
        userCrSettingsId &&
        classroomType
      ) {
        await updateClassroomSettings({
          variables: {
            sessionId,
            isAchieved: true,
          },
        });
        await earlyFinishClassroom(userCrSettingsId, classroomType);
        localStorage.setItem(
          `${TabNavIdsEnum.CLASSROOMS}${LS_KEY_TABINDEX}`,
          '1'
        );
        history.replace(`/classrooms/`);
      }
    } catch (error) {
      console.log('Early Finish Classroom Mutation', error);
    }
    setResultVisibility(false);
  }, [
    updateClassroomSettings,
    earlyFinishClassroom,
    sessionId,
    userCrSettingsId,
    userCrSettingsIsAchieved,
    history,
    classroomType,
  ]);

  return (
    <Dialog
      data-cy-classroom-expired-dialog
      innerRef={(el) => {
        setIconElemRef(el);
      }}
      open={dialogVisibility}
      classes={{ paper: classes.paper }}
      onClose={handleClose}
    >
      <Box display="flex" flexDirection="column">
        {(resultVisibility || classroomType === ClassroomTypes.LEK) && (
          <IconButton
            aria-label="close"
            className={classes.close}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        )}

        <Box display="grid" justifyItems="center" gridRowGap={8}>
          <Box display="flex" justifyContent="center">
            {dialogData?.icon}
            <span
              className={classes.iconContainer}
              id={ANIM_ICON_CONTAINER_CSS_ID}
            />
          </Box>
          <Typography align="center" variant="h2" style={dialogData?.style}>
            {resultVisibility
              ? t(dialogData.title)
              : t(dialogData.title, {
                  classroomName: title,
                })}
          </Typography>
          {resultVisibility ? (
            <>
              <Box mb={3}>
                <Typography align="center" variant="body1">
                  {dialogData?.description && (
                    <Trans
                      i18nKey={dialogData.description}
                      components={{
                        link1: <b />,
                      }}
                      values={{ classroomName: title }}
                    />
                  )}
                </Typography>
              </Box>
              {dialogData?.message && (
                <>
                  <Box mt={5} width="100%">
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      onClick={handleEarlyFinish}
                    >
                      {t('common.end')}
                    </Button>
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={handleClose}
                      style={{ marginTop: 16, color: '#647485' }}
                    >
                      {t('common.keep-going')}
                    </Button>
                  </Box>
                </>
              )}
            </>
          ) : (
            <Button
              color="primary"
              variant="contained"
              fullWidth
              style={{ marginTop: 28 }}
              onClick={showResults}
            >
              {t('common.ok')}
            </Button>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default ClassroomExpiredDialog;
