import { CodesType, Maybe, SponsorType } from './graphql';

export enum EGenderEn {
  'Männlich' = 1,
  'Weiblich' = 2,
  'Divers' = 3,
}

export enum EStartOfStudies {
  'Sommersemester' = 'Sommersemester',
  'Wintersemester' = 'Wintersemester',
}

export enum UserGender {
  'Male' = 1,
  'Female' = 2,
  'Divers' = 3,
}

export enum ClassroomTypes {
  BLENDED = 1,
  NETWORK = 2,
  LEK = 3,
}
export enum SubscriptionTypes {
  StudentMonthly = 'Student Monthly',
  StudentYearly = 'Student Yearly',
  ProfessionalMonthly = 'Professional Monthly',
  ProfessionalYearly = 'Professional Yearly',
  Code = 'Code',
  Referral = 'Referral',
}
export enum SubscriptionStatus {
  ACTIVE = 'active',
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  CANCELED = 'canceled',
  TRIALING = 'trialing',
}
export enum DuelErrorTypes {
  ERROR_1 = 'ERROR_1',
  ERROR_2 = 'ERROR_2',
  ERROR_3 = 'ERROR_3',
  ERROR_4 = 'ERROR_4',
  ERROR_5 = 'ERROR_5',
  ERROR_6 = 'ERROR_6',
  ERROR_7 = 'ERROR_7',
  ERROR_8 = 'ERROR_8',
}

export enum NotifTypes {
  LEARNING_SESSION = 'learning_session',
  DUEL = 'duel',
  SUBSCRIPTION = 'subscription',
  SUBSCRIBE_NEWSLETTERS = 'subscribe_newsletters',
  IS_HUMEO_LIKE = 'is_humeo_like',
  AGB = 'update_private_policy',
  PREMIUM = 'premium',
  QT_GOING_T0_START = 'qt_is_going_to_start',
  QT_STARTED = 'qt_is_start_now',
  QT_NEW = 'qt_new',
  QT_WAITING_FOR_FINISH = 'qt_waiting_for_finish',
  QT_FINISHED = 'qt_finished',
  QT_DUEL_FINISHED = 'quiz_duel_finished',
  QT_DUEL_STARTED = 'quiz_duel_start',
  QT_USERS_COUNT = 'qt_users_count',
  PAYMENT_REQUIRES_CONFIRMATION = 'payment_requires_confirmation',
  QT_DUEL_IGNORED = 'quiz_duel_ignored',
  QT_SESSION_FREE_PLACE = 'quiz_time_session',
  PROMOTION_PREMIUM = 'set_promotion_premium',
  IN_APP_SUBSCRIPTION_CANCELING = 'in_app_subscription_canceling',
}

export enum UserStatus {
  tie = 'A_1',
  win = 'A_2',
  loss = 'A_3',
  unfinished = 'A_4',
}

export enum CodeMode {
  Freemium = 'A_1',
  Premium = 'A_2',
}

export enum SettingsField {
  mode = 'MODE',
  notification = 'NOTIFICATION',
  goal = 'GOAL',
}

export type Participant = {
  id: string;
  userId: string;
  username: string;
  avatar: string;
  score: number;
  isAvailableForDuel?: boolean;
  sessionId?: string;
  rank: number;
  joinedRank?: boolean;
  isRanking?: boolean;
  isMemberSticky?: boolean;
  divider?: boolean;
  sessionSettingsIsClosed?: boolean;
};

export type CustomCodesType = Pick<
  CodesType,
  'id' | 'startDate' | 'finishDate' | 'codeMode'
> & {
  sponsor?: Maybe<
    { __typename?: 'SponsorType' } & Pick<
      SponsorType,
      'id' | 'title' | 'text' | 'website' | 'email' | 'logo'
    >
  >;
};

export enum GlobalDialogTypes {
  ADD_ICON = 'addIcon',
  AGB_CHANGED = 'agbChanged',
  COOKIE = 'cookie',
  DAILY_TIPPER = 'dailyTipper',
}

export enum QuizTimeBlockWithTime {
  HOME_PAGE_CARD = 'home',
  LIST_PAGE_CARD = 'list',
  QT_HEADER = 'header',
}

export enum DeepLinkTargetType {
  CLASSROOM = 'classroomlearningsession',
  QUIZTIME = 'quiztimesession',
}
export enum TabNavIdsEnum {
  CLASSROOM = 'classroom_',
  CLASSROOMS = 'classrooms',
  LECTOR = 'lector',
  ORGANIZER = 'organizer',
  QUIZTIMES = 'quiztimes',
  QUIZTIME = 'quiztime_',
}
export enum FeatureFlagsNamesEnum {
  CLASS_ROOMS = 'CLASS_ROOMS',
  COOKIES = 'COOKIES',
  DAILY_TIPPER = 'DAILY_TIPPER',
  HUMEO_APP = 'HUMEO_APP',
  PAYMENT = 'PAYMENT',
  SUBS_DISCOUNTS = 'SUBS_DISCOUNTS',
  CLASSROOM_AVA_GROUP = 'CLASSROOM_AVA_GROUP',
  IN_APP_PAYMENT = 'IN_APP_PAYMENT',
}
export enum QueriesNamesEnum {
  ME = 'me',
  ACTIVE_DUELS = 'activeDuels',
  ACTIVE_QUIZ_DUELS = 'activeQuizDuels',
  COMPLETED_DUELS = 'completedDuels',
  COMPLETED_QUIZ_DUELS = 'completedQuizDuels',
  MONTHLY_SCORE = 'monthlyScore',
  RANKING = 'ranking',
  TOP_USERS = 'topUsers',
  QUIZ_DUEL_SCORE = 'quizDuelScore',
  QUIZTIME_SESSION = 'quizTimeSession',
  QUIZ_SESSION_MEMBERS = 'quizSessionMembers',
}
export enum SripeErrorsEnum {
  AUTHENTICATION = 'authentication_error',
  CARD = 'card_error',
  INVALID_REQUEST = 'invalid_request_error',
}
export enum ClassroomVariantsEnum {
  Laufend = 'Laufend',
  Kapitel = 'Kapitel',
  Blended = 'Blended',
  Fertig = 'Fertig',
}
export enum ProfessionTypesEnum {
  Doctor = 1,
  MedicalAssistant = 2,
  TraineeToNurse = 3,
  Trainee = 4,
  Student = 5,
  Other = 6,
}
