import { useEffect, useMemo } from 'react';
import { DateTime } from 'luxon';
import {
  CodeMode,
  CustomCodesType,
  ProfessionTypesEnum,
  SubscriptionStatus,
} from '@/type';
import {
  useUserMeQuery,
  useUserNetworkCodesQuery,
  useUserSubscriptionQuery,
} from '@/graphql';
import { WebActionType } from '@/screens/main/in-app-subscriptions/types';

export const useGetPremiumData = () => {
  const { data: user } = useUserMeQuery();
  const { data: codesData } = useUserNetworkCodesQuery();
  const { data: subsData } = useUserSubscriptionQuery({
    fetchPolicy: 'cache-and-network',
  });

  const currentCode = useMemo<CustomCodesType | undefined | null>(
    () =>
      codesData?.codes?.reduce((code, it) => {
        const now = DateTime.local();
        const start = DateTime.fromISO(it?.startDate);
        const finish = DateTime.fromISO(it?.finishDate).endOf('day');
        if (start < now && finish > now) {
          if (code && DateTime.fromISO(code?.finishDate) > finish) return code;
          return it;
        }
        return code;
      }, null),
    [codesData]
  );

  const isUserPremiumByCode = useMemo(
    () => currentCode?.codeMode?.valueOf() === CodeMode.Premium,
    [currentCode]
  );

  const isUserPremiumByPayment = useMemo(
    () =>
      subsData?.me?.customer?.subscription?.status ===
        SubscriptionStatus.ACTIVE ||
      subsData?.me?.customer?.subscription?.status ===
        SubscriptionStatus.TRIALING,
    [subsData]
  );

  const isUserPremiumByFriendship = useMemo(
    () => !!subsData?.me?.isPromotionPremium,
    [subsData]
  );
  const isUserInAppPremium = useMemo(
    () => !!subsData?.me?.isInAppPremium,
    [subsData]
  );

  const isUserPremium = useMemo(
    () =>
      (isUserPremiumByPayment && subsData?.me?.isPremium) ||
      isUserInAppPremium ||
      isUserPremiumByCode ||
      isUserPremiumByFriendship,
    [
      isUserPremiumByPayment,
      isUserInAppPremium,
      subsData,
      isUserPremiumByCode,
      isUserPremiumByFriendship,
    ]
  );

  const premiumFinishDate =
    subsData?.me?.inAppSubscription?.expirationDatetime ||
    subsData?.me?.customer?.subscription?.currentPeriodEnd ||
    currentCode?.finishDate ||
    subsData?.me?.promotionFinishDate;

  const subscriptionType = (
    subsData?.me?.customer?.subscription?.subscriptionType ||
    subsData?.me?.inAppSubscription?.subscriptingType
  )?.toLowerCase();

  useEffect(() => {
    const userType = user?.me?.profession?.type?.type;
    const isSpecificProfession =
      user?.me?.speciality?.title === 'Rheumatologie' &&
      userType === ProfessionTypesEnum.MedicalAssistant;
    const isUserStudent =
      userType === ProfessionTypesEnum.Student ||
      userType === ProfessionTypesEnum.Trainee ||
      userType === ProfessionTypesEnum.TraineeToNurse;
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: WebActionType.SendUserData,
        payload: {
          user,
          isUserStudent,
          isUserPremium,
          isUserPremiumByPayment,
          isUserPremiumByCode,
          isUserPremiumByFriendship,
          isPurchaseDisabled: isSpecificProfession,
        },
      })
    );
  }, [
    user,
    isUserPremium,
    isUserPremiumByPayment,
    isUserPremiumByCode,
    isUserPremiumByFriendship,
  ]);

  const isPlatformSuitableForSubscription = useMemo(() => {
    const subscriptionOriginStore =
      isUserInAppPremium && subsData?.me?.inAppSubscription?.store
        ? subsData.me.inAppSubscription.store
        : isUserPremiumByPayment
        ? 'STRIPE'
        : null;
    const nativeOSName = localStorage.getItem('NATIVE_OS_NAME');
    if (nativeOSName === null) {
      return subscriptionOriginStore === 'STRIPE';
    }
    if (nativeOSName === 'android') {
      return subscriptionOriginStore === 'PLAY_STORE';
    }
    if (nativeOSName === 'ios') {
      return subscriptionOriginStore === 'APP_STORE';
    }
    return false;
  }, [subsData, isUserInAppPremium, isUserPremiumByPayment]);

  return {
    isUserPremium,
    isUserPremiumByPayment,
    isUserInAppPremium,
    isUserPremiumByCode,
    isUserPremiumByFriendship,
    premiumFinishDate,
    currentCode,
    isPlatformSuitableForSubscription,
    subscriptionType,
  };
};
