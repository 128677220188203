import React from 'react';
import { useTranslation } from 'react-i18next';
import { Elements } from '@stripe/react-stripe-js';

const StripeWrapper: React.FC = ({ children }) => {
  const { i18n } = useTranslation();
  const stripePromise = window?.ReactNativeWebView
    ? null
    : import('@stripe/stripe-js').then((stripe) => {
        return stripe.loadStripe(
          process.env.REACT_APP_STRIPE_PUBLIC_API_KEY || '',
          {
            locale: i18n.language === 'en' ? 'en' : 'de',
          }
        );
      });

  return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripeWrapper;
