import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import { Box, Divider, Paper, Switch, Typography } from '@material-ui/core';
import StackLayout from '@/screens/main/components/stack-layout';

import { USER_SETTINGS_FRAGMENT } from '@/apollo/fragments';
import {
  useUpdatePushNotificationsSettingsMutation,
  useUserMeQuery,
  SettingsType,
} from '@/graphql';
import { useStyles } from './styles';
import { SettingsField } from '@/type';

const SettingsPushes = () => {
  const classes = useStyles();
  const client = useApolloClient();
  const { t } = useTranslation();

  const { data } = useUserMeQuery({
    fetchPolicy: 'cache-first',
  });
  const [update] = useUpdatePushNotificationsSettingsMutation();

  const changeField = useCallback(
    (checked: boolean, fieldName: string) => {
      client.writeFragment({
        id: `${data?.me?.settings?.__typename}:${data?.me?.settings?.id}`,
        fragment: USER_SETTINGS_FRAGMENT,
        data: {
          ...data?.me?.settings,
          [fieldName]: checked,
        },
      });

      try {
        update({
          variables: {
            [fieldName]: checked,
          },
        });
      } catch (err) {
        console.log(err);
      }
    },
    [update, client, data]
  );

  const toggleMainSwitch = useCallback(
    (checked: boolean, field: SettingsField) => {
      const pushNotificationMode =
        field === SettingsField.mode
          ? checked
          : !!data?.me?.settings?.pushNotificationMode;

      client.writeFragment({
        id: `${data?.me?.settings?.__typename}:${data?.me?.settings?.id}`,
        fragment: USER_SETTINGS_FRAGMENT,
        data: {
          ...data?.me?.settings,
          pushNotificationMode,
        },
      });

      try {
        update({
          variables: {
            pushNotificationMode,
          },
        });
      } catch (err) {
        console.log(err);
      }
    },
    [update, client, data]
  );

  const pushesSwitchersArr = [
    { key: 'pushNotificationDuelMode', tKey: 'quiz-duel' },
    { key: 'pushNotificationDailyTipperMode', tKey: 'dt' },
    { key: 'pushNotificationQuizTimeMode', tKey: 'qt' },
    { key: 'pushNotificationNewChapterMode', tKey: 'new-chapter' },
    // { key: 'pushNotificationPremiumMode', tKey: 'premium' },
  ];

  return (
    <StackLayout title={t('settings.push.page-title')}>
      <Paper elevation={0} className={classes.root}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mr={-2}
        >
          <Typography variant="subtitle2">
            {t('settings.push.title')}
          </Typography>
          <Switch
            color="primary"
            checked={!!data?.me?.settings?.pushNotificationMode}
            onChange={(e, checked) =>
              toggleMainSwitch(checked, SettingsField.mode)
            }
          />
        </Box>
        <Typography
          variant="caption"
          component="p"
          className={classes.helpText}
        >
          {t('settings.push.help-text')}
        </Typography>
        <Box mt={6}>
          <Divider />
        </Box>
        <Box
          pl={6}
          py={3}
          style={{
            opacity: !data?.me?.settings?.pushNotificationMode ? 0.5 : 1,
            pointerEvents: !data?.me?.settings?.pushNotificationMode
              ? 'none'
              : 'initial',
          }}
        >
          {pushesSwitchersArr.map((it) => {
            const fieldVal =
              data?.me?.settings?.[
                it.key as keyof Pick<
                  SettingsType,
                  | 'pushNotificationDuelMode'
                  | 'pushNotificationDailyTipperMode'
                  | 'pushNotificationQuizTimeMode'
                  | 'pushNotificationNewChapterMode'
                  // | 'pushNotificationPremiumMode'
                >
              ];

            return (
              <Box key={it.key}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mr={-2}
                >
                  <Typography variant="subtitle2">
                    {t(`settings.push-${it.tKey}.title`)}
                  </Typography>
                  <Switch
                    color="primary"
                    checked={!!fieldVal}
                    onChange={(e, checked) => changeField(checked, it.key)}
                  />
                </Box>
                <Typography
                  variant="caption"
                  style={{ color: '#647485' }}
                  component="p"
                  className={classes.helpText}
                >
                  {t(`settings.push-${it.tKey}.help-text`)}
                </Typography>
                <Box mt={6} mb={4}>
                  <Divider />
                </Box>
              </Box>
            );
          })}
        </Box>
      </Paper>
    </StackLayout>
  );
};

export default SettingsPushes;
